import React from 'react'
import Text from '../Text/Text'

import fieldErrorStyles from './FieldError.module.css'

type FieldErrorPros = {
    /** The message that should be displayed */
    errorMessage: string
    /** Flag to pass in error state */
    showError: boolean
    /** id of input to link field error to child input for screen readers  */
    inputId: string
}

const FieldError: React.FC<FieldErrorPros> = ({ errorMessage, showError, inputId }) => {
    if (!showError) return null

    return (
        <div className={fieldErrorStyles['error-message']}>
            <Text id={inputId + '-error-message'} size='S' color='red'>
                {errorMessage}
            </Text>
        </div>
    )
}

export default FieldError
