/** parseQueryParams: take a query string and parse key/values from it into an object
 * @param {string} queryParamString - query string params with leading ? removed */
export const parseQueryParams = (queryParamString: string | null): Record<string, any> => {
    /** Creates JSON object where & from query params is a comma and = is a colon
     * i.e. someparam=somevalue&someotherparam=somevalue
     * => '{"someparam":"somevalue","someotherparam":"somevalue"}' */
    if (!queryParamString) return {}

    return JSON.parse(
        '{"' + queryParamString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        (key, value) => {
            return key === '' ? value : decodeURIComponent(value)
        }
    )
}
