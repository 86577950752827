import React from 'react'

import styles from './InlineSpinner.module.css'

type SpinnerProps = {
    text?: string
}
const InlineSpinner: React.FC<SpinnerProps> = ({ text }) => {
    return (
        <div className={styles.spinner}>
            <svg className={styles.circle} viewBox='0 0 50 50'>
                <title>Spinner</title>
                <circle
                    className={styles.path}
                    cx='25'
                    cy='25'
                    r='20'
                    fill='none'
                    strokeWidth='4'
                />
            </svg>
            {text && <span>{text}</span>}
        </div>
    )
}

export default InlineSpinner
