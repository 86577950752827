import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

type usePaginationValueType = {
    pageSize: number
    pageNumber: number
    setPageSize: (size: number) => void
    setPageNumber: (page: number) => void
}

export const usePagination = (
    defaultPageSize = 10,
    defaultPageNumber = 1
): usePaginationValueType => {
    const [searchParams, setSearchParams] = useSearchParams()

    // Retrieve values from URL or use defaults
    const initialPageSize = Number(searchParams.get('page_size')) || defaultPageSize
    const initialPageNumber = Number(searchParams.get('page')) || defaultPageNumber

    const [pageSize, setPageSize] = useState(initialPageSize)
    const [pageNumber, setPageNumber] = useState(initialPageNumber)

    // Sync changes to URL parameters
    useEffect(() => {
        const newParams = new URLSearchParams(searchParams)

        // Update only the pageSize and itemsPerPage in the search params
        newParams.set('page_size', String(pageSize))
        newParams.set('page', String(pageNumber))

        // Set the new search params, preserving others
        setSearchParams(newParams)
    }, [pageSize, pageNumber, searchParams, setSearchParams])

    return {
        pageSize,
        pageNumber,
        setPageSize,
        setPageNumber,
    }
}
