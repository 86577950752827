import React, { SetStateAction, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import FieldsRequired from 'components/basics/FieldsRequired/FieldsRequired'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import Spacing from 'components/basics/Spacing/Spacing'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { getCognitoErrorMessage } from 'utils/cognito-helpers/get-cognito-error-message'

import styles from './TempPasswordForm.module.css'
import allContent from 'content/content'
import { CognitoUser } from 'amazon-cognito-identity-js'

const content = allContent.signUpPage.tempPasswordForm

type TempPasswordFormDataType = {
    userEmail: string
    tempPassword: string
}

type TempPasswordFormProps = {
    userEmail: string
    setNewPassCognitoUser: React.Dispatch<SetStateAction<CognitoUser | undefined>>
}
const TempPasswordForm: React.FC<TempPasswordFormProps> = ({
    userEmail,
    setNewPassCognitoUser,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [tempPasswordApiError, setTempPasswordApiError] = useState('')

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TempPasswordFormDataType>({
        reValidateMode: 'onChange',
        defaultValues: {
            tempPassword: '',
        },
    })

    async function handleSubmitClick(formFields: TempPasswordFormDataType): Promise<void> {
        setIsSubmitting(true)
        setTempPasswordApiError('')
        Auth.signIn(userEmail, formFields.tempPassword.trim())
            .then((user) => {
                datadogLogs.logger.info(`source: SaaSTempPassword, userEmail: ${userEmail}`, {
                    userContext: { userEmail: userEmail },
                })
                setNewPassCognitoUser(user)
            })
            .catch((error) => {
                setIsSubmitting(false)
                setTempPasswordApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                datadogLogs.logger.warn(
                    `source: SaaSTempPassword, error using email: ${userEmail}`,
                    { userEmail: userEmail },
                    error
                )
            })
    }

    return (
        <div className={styles.container}>
            <form
                className={styles['temp-password-form']}
                onSubmit={handleSubmit(async (formFields: TempPasswordFormDataType) => {
                    await handleSubmitClick(formFields)
                })}
            >
                <fieldset disabled={isSubmitting} className={styles['field-set']}>
                    <legend className='visually-hidden'>{`${content.userEmailLabel} + ${content.tempPasswordInput}`}</legend>
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            htmlFor='email'
                            label={content.userEmailLabel}
                            disabled={true}
                        >
                            <TextInput value={userEmail} />
                        </LabelledInput>
                    </div>
                    <Controller
                        control={control}
                        name='tempPassword'
                        rules={{
                            required: true,
                            minLength: 10,
                            maxLength: 10,
                        }}
                        render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                            <div className={styles['input-wrapper']}>
                                <LabelledInput
                                    required={true}
                                    htmlFor='tempPassword'
                                    label={content.tempPasswordInput}
                                    aria-describedby='temp-password-error-message'
                                    disabled={isSubmitting}
                                >
                                    <PasswordInput
                                        className={styles['password-input']}
                                        autoComplete='off'
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='tempPassword'
                                    showError={!!errors.tempPassword}
                                    errorMessage={content.errors.tempPasswordInput}
                                />
                                {!errors.tempPassword && <Spacing size='double' />}
                            </div>
                        )}
                    />
                </fieldset>
                <FieldsRequired />
                <Button
                    className={styles['submit-button']}
                    type='submit'
                    flavour='primary'
                    text={isSubmitting ? 'Submitting' : content.submitButton}
                    showSpinner={isSubmitting}
                />
                {!!tempPasswordApiError && (
                    <InfoBanner
                        id='temp-password-api-error'
                        bannerType='error'
                        text={tempPasswordApiError}
                        isFocusable={true}
                    />
                )}
            </form>
        </div>
    )
}

export default TempPasswordForm
