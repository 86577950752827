/** createQueryParamString: converts an object that of key/values in to a query param string
 * @param { object } keyValues: an object representing the variables to put as url params */
export const createQueryParamString = (keyValues: { [key: string]: string }): string => {
    if (Object.keys(keyValues).length === 0) return ''
    let queryParams = ''

    Object.keys(keyValues).forEach((key: string) => {
        const currentQueryParams = queryParams
        const nextValue = encodeURIComponent(keyValues[key])
        const nextParam = `${key}=${nextValue}&`
        queryParams = currentQueryParams.concat(nextParam)
    })

    return queryParams.slice(0, -1)
}
