import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type useTabNumberValueType = {
    tab: number
    setTab: (tab: number) => void
}

export const useTabNumber = (defaultTab = 0): useTabNumberValueType => {
    const [searchParams, setSearchParams] = useSearchParams()

    // Retrieve values from URL or use defaults
    const initialPageSize = Number(searchParams.get('tab')) || defaultTab

    const [tab, setTab] = useState(initialPageSize)

    // Sync changes to URL parameters

    const onTabChange = (newTab: number): void => {
        const newParams = new URLSearchParams(searchParams)

        // Update only the pageSize and itemsPerPage in the search params
        newParams.set('tab', String(newTab))
        newParams.set('page_size', String(10))
        newParams.set('page', String(1))

        // Set the new search params, preserving others
        setSearchParams(newParams)
        setTab(newTab)
    }

    return {
        tab,
        setTab: onTabChange,
    }
}
