import React, { FC } from 'react'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import Text from 'components/basics/Text/Text'
import styles from './OrderImportedAdditionalInformation.module.css'

import allContent from 'content/content'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import { OrderAdditionalInfo } from 'api-data-models/order/OrderContentModel'
import Heading from 'components/basics/Heading/Heading'

const content = allContent.order.orderPage.importedBooking.additionInfo

interface Props {
    data: OrderAdditionalInfo
}

const OrderImportedAdditionalInformation: FC<Props> = ({ data }): JSX.Element => {
    const activeKeys = data ? Object.keys(data) : []

    return (
        <Accordion
            title={content.header}
            titleSize='2'
            baseId='additional-information'
            darkHeading={true}
            isPadded={true}
        >
            <div className={styles['container']}>
                {activeKeys.map((passengerKey: any) => {
                    const infoData = data[passengerKey]

                    return (
                        <Accordion
                            key={passengerKey}
                            title={
                                <Heading heading='2' size='3' colorOverride='tertiary-blue'>
                                    {passengerKey}
                                </Heading>
                            }
                            baseId={`additional-info-${passengerKey}`}
                            startOpen={passengerKey === 'Lead Passenger'}
                            underscore
                        >
                            {infoData.flightOutbound && (
                                <div className={styles['section']}>
                                    <div>
                                        <Heading heading='3'>{content.flightOutbound}</Heading>
                                    </div>
                                    <div>
                                        <Text size='S' weight='bold'>
                                            {infoData.flightOutbound.name}
                                        </Text>
                                    </div>
                                    <DescriptionListDisplay
                                        itemClassName={styles['list-item']}
                                        overrideMaxKeyLength={12}
                                        keyValueContent={[
                                            [
                                                content.flightNumber,
                                                infoData.flightOutbound.flightNumber,
                                            ],
                                            [content.dateField, infoData.flightOutbound.date],
                                            [
                                                content.flightTimes,
                                                infoData.flightOutbound.flightTimes,
                                            ],
                                        ]}
                                    />
                                    <ColoredLine />
                                </div>
                            )}
                            {infoData.preCruiseStay && (
                                <div className={styles['section']}>
                                    <div>
                                        <Heading heading='3'>{content.preCruiseStay}</Heading>
                                    </div>
                                    <div>
                                        <Text size='S' weight='bold'>
                                            {infoData.preCruiseStay.name}
                                        </Text>
                                    </div>
                                    <DescriptionListDisplay
                                        itemClassName={styles['list-item']}
                                        overrideMaxKeyLength={12}
                                        keyValueContent={[
                                            [content.dateField, infoData.preCruiseStay.date],
                                            [
                                                content.hotelRoomType,
                                                infoData.preCruiseStay.roomType,
                                            ],
                                        ]}
                                    />
                                    <ColoredLine />
                                </div>
                            )}
                            {infoData.transfer && (
                                <div className={styles['section']}>
                                    <div>
                                        <Heading heading='3'>{content.transfer}</Heading>
                                    </div>
                                    <div>
                                        <Text size='S' weight='bold'>
                                            {infoData.transfer.name}
                                        </Text>
                                    </div>
                                    <DescriptionListDisplay
                                        itemClassName={styles['list-item']}
                                        overrideMaxKeyLength={12}
                                        keyValueContent={[
                                            [content.dateField, infoData.transfer.date],
                                            [
                                                content.transferField,
                                                infoData.transfer.transfer.join(' > '),
                                            ],
                                        ]}
                                    />
                                    <ColoredLine />
                                </div>
                            )}
                            {infoData?.shoreExcursions?.days?.length && (
                                <div className={styles['section']}>
                                    <div>
                                        <Heading heading='3'>{content.shoreExcursions}</Heading>
                                    </div>
                                    <DescriptionListDisplay
                                        itemClassName={styles['list-item']}
                                        overrideMaxKeyLength={12}
                                        keyValueContent={infoData.shoreExcursions.days.map(
                                            (day, i) => [`${content.day} ${i + 1}:`, day]
                                        )}
                                    />
                                    <ColoredLine />
                                </div>
                            )}
                            {infoData.postCruiseStay && (
                                <div className={styles['section']}>
                                    <div>
                                        <Heading heading='3'>{content.postCruiseStay}</Heading>
                                    </div>
                                    <div>
                                        <Text size='S' weight='bold'>
                                            {infoData.postCruiseStay.name}
                                        </Text>
                                    </div>
                                    <DescriptionListDisplay
                                        itemClassName={styles['list-item']}
                                        overrideMaxKeyLength={12}
                                        keyValueContent={[
                                            [content.dateField, infoData.postCruiseStay.date],
                                            [
                                                content.hotelRoomType,
                                                infoData.postCruiseStay.roomType,
                                            ],
                                        ]}
                                    />
                                    <ColoredLine />
                                </div>
                            )}
                            {infoData.flightInbound && (
                                <div className={styles['section']}>
                                    <div>
                                        <Heading heading='3'>{content.flightInbound}</Heading>
                                    </div>
                                    <div>
                                        <Text size='S' weight='bold'>
                                            {infoData.flightInbound.name}
                                        </Text>
                                    </div>
                                    <DescriptionListDisplay
                                        itemClassName={styles['list-item']}
                                        overrideMaxKeyLength={12}
                                        keyValueContent={[
                                            [
                                                content.flightNumber,
                                                infoData.flightInbound.flightNumber,
                                            ],
                                            [content.dateField, infoData.flightInbound.date],
                                            [
                                                content.flightTimes,
                                                infoData.flightInbound.flightTimes,
                                            ],
                                        ]}
                                    />
                                    <ColoredLine />
                                </div>
                            )}
                        </Accordion>
                    )
                })}
            </div>
        </Accordion>
    )
}

export default OrderImportedAdditionalInformation
