import React, { useContext, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Image from 'components/basics/Image/Image'
import ImportModal from '../HeaderContent/ImportModal/ImportModal'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { ROUTES } from 'components/sections/app/AppRoutes'
import {
    APP_COMPANY_TIERS,
    APP_PAID_COMPANY_TIERS,
    USER_ROLES,
    VITALLY_EVENTS,
} from 'utils/constants'
import { FeatureToggleContext } from 'App'

import styles from './FooterContent.module.scss'
import allContent from 'content/content'
import { isTestEmailDomain } from '../../../../utils/testing-handlers'

const footerContent = allContent.footer
type FooterContentProps = {
    apiClient?: ApolloClient<NormalizedCacheObject>
    companyTier?: CompanyTierTypes
    isAuthorised: null | boolean
    financeSystemId?: string
    userEmail: string
    userRoles: UserRole[]
}

const FooterContent: React.FC<FooterContentProps> = ({
    apiClient,
    companyTier,
    isAuthorised,
    financeSystemId,
    userEmail,
    userRoles,
}) => {
    const [isImportOrderModalOpen, setImportOrderModalOpen] = useState(false)
    const featureToggles = useContext(FeatureToggleContext)
    const showTestErrorTrigger = isTestEmailDomain(userEmail)
    const onFeaturePortalClick = (): void => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.FEATURE_PORTAL_OPEN,
            properties: {
                page: window.location.pathname,
            },
        })
    }

    return (
        <div className={styles['footer-container']}>
            <div className={styles['link-container']}>
                {isAuthorised && (
                    <div className={styles['link-container--column']}>
                        {financeSystemId &&
                            companyTier &&
                            APP_COMPANY_TIERS.includes(companyTier) && (
                                <Link to={ROUTES.CRUISE_SEARCH} onDarkBackground={true}>
                                    {footerContent.cruiseText}
                                </Link>
                            )}
                        {financeSystemId &&
                            companyTier &&
                            APP_PAID_COMPANY_TIERS.includes(companyTier) &&
                            APP_COMPANY_TIERS.includes(companyTier) &&
                            featureToggles.TURN_ON_ORDERS_PAGE &&
                            apiClient && (
                                <>
                                    <ImportModal
                                        isOpen={isImportOrderModalOpen}
                                        onClose={(): void => setImportOrderModalOpen(false)}
                                        returnFocusId='order-import-button-footer'
                                    />
                                    <Link
                                        onDarkBackground={true}
                                        onClick={(): void => setImportOrderModalOpen(true)}
                                    >
                                        {footerContent.importButton}
                                    </Link>
                                </>
                            )}
                        {financeSystemId &&
                            companyTier &&
                            APP_PAID_COMPANY_TIERS.includes(companyTier) &&
                            APP_COMPANY_TIERS.includes(companyTier) &&
                            featureToggles.TURN_ON_ORDERS_PAGE && (
                                <Link to={ROUTES.ALL_ORDERS} onDarkBackground={true}>
                                    {footerContent.ordersText}
                                </Link>
                            )}
                        {userRoles.includes(USER_ROLES.ADMIN) && (
                            <Link to={ROUTES.ADMIN_DASHBOARD} onDarkBackground={true}>
                                {footerContent.admin}
                            </Link>
                        )}
                    </div>
                )}
                <div className={styles['link-container--column']}>
                    {/* Links going to a traveltek url only need rel='noopener', all other external links will need rel='noopener 'noreferrer' */}
                    <Link
                        href={footerContent.serviceDeskUrl}
                        rel='noopener'
                        onDarkBackground={true}
                        target='_blank'
                    >
                        {footerContent.serviceDeskText}
                    </Link>
                    <Link
                        href={footerContent.faqUrl}
                        rel='noopener'
                        onDarkBackground={true}
                        target='_blank'
                    >
                        {footerContent.faqText}
                    </Link>
                    <Link
                        to={ROUTES.FEATURE_PORTAL}
                        onDarkBackground={true}
                        onClick={onFeaturePortalClick}
                    >
                        {footerContent.feedback}
                    </Link>
                    <Link
                        to={ROUTES.FEATURE_PORTAL_CHANGELOG}
                        onDarkBackground={true}
                        onClick={onFeaturePortalClick}
                    >
                        {footerContent.changelog}
                    </Link>
                </div>
                <div className={styles['link-container--column']}>
                    <Link
                        href={footerContent.privacyPolicyUrl}
                        rel='noopener'
                        onDarkBackground={true}
                        target='_blank'
                    >
                        {footerContent.privacyPolicyText}
                    </Link>
                    <Link
                        href={footerContent.termsOfServiceUrl}
                        rel='noopener'
                        onDarkBackground={true}
                        target='_blank'
                    >
                        {footerContent.termsOfServiceText}
                    </Link>
                    {/** REMOVE once done with testing error catching on sentry - need to be able to throw errors easily for now */}
                    {showTestErrorTrigger && (
                        <button
                            style={{
                                padding: 'none',
                                border: 'none',
                                backgroundColor: 'transparent',
                            }}
                            onClick={(): void => {
                                throw new Error('manual error for testing')
                            }}
                        >
                            .
                        </button>
                    )}
                </div>
            </div>
            <ColoredLine onDarkBackground={true} />
            <div className={styles['logo-and-company-info-container']}>
                <div>
                    <Image className={styles.logo} src={footerContent.logo} alt='TravelTek logo' />
                </div>
                <Text size='XS' color='white'>
                    {footerContent.companyInfoText}
                </Text>
            </div>
        </div>
    )
}

export default FooterContent
