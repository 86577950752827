import React from 'react'
import classnames from 'classnames'
import { Menu as MuiMenu, MenuItem as MuiMenuItem, StyledEngineProvider } from '@mui/material'

import Icon, { IconNames } from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'

import styles from './Menu.module.scss'

type Option = {
    iconName?: IconNames
    label: string
    onClick?: () => void
    disabled?: boolean
    jsxElement?: JSX.Element
}

export interface MenuProps {
    /** Used for react key on mapping menu items */
    id: string
    /** String used on button or as hidden label for Icon */
    buttonText: string
    /** Used to switch word on button to a large Icon */
    iconName?: IconNames
    /** Array of self building menu buttons (onClick, iconName, disabled), or can pass in JSX to show text or other elements */
    options: Option[]
    disabled?: boolean
    /** Switch to styles that work on Primary-blue background (in headers etc) */
    onDarkBackground?: boolean
    /** Doubles margin at top of popup - used for header menus due to wider margin on header */
    doubleTopMargin?: boolean
}

const Menu: React.FC<MenuProps> = ({
    id,
    options,
    iconName,
    onDarkBackground = false,
    buttonText,
    disabled = false,
    doubleTopMargin = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (): void => {
        setAnchorEl(null)
    }

    return (
        <StyledEngineProvider injectFirst>
            <button
                className={classnames(styles['menu__button'], {
                    [styles['menu__button--onDarkBackground']]: onDarkBackground,
                })}
                id={id}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
            >
                {iconName ? (
                    <>
                        <Icon
                            iconName={iconName}
                            iconColor={onDarkBackground ? 'white' : 'dark-gray'}
                            iconSize='XL'
                        />
                        <span className='visually-hidden'>{buttonText}</span>
                    </>
                ) : (
                    <>
                        <Text
                            className={disabled ? styles['menu__button-text--disabled'] : undefined}
                            color={onDarkBackground ? 'white' : 'primary-blue'}
                            weight='bold'
                        >
                            {buttonText}
                        </Text>
                        <Icon
                            iconName={open ? 'ChevronUp' : 'ChevronDown'}
                            iconColor={onDarkBackground ? 'white' : 'dark-gray'}
                        />
                    </>
                )}
            </button>
            <MuiMenu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': id }}
                classes={{
                    paper: classnames(styles['menu__list-container'], {
                        [styles['menu__list-container--invert']]: onDarkBackground,
                        [styles['menu__list-container--top-margin-double']]: doubleTopMargin,
                    }),
                    list: styles['menu__list'],
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {options.map((optionItem: Option) => {
                    if (optionItem.jsxElement) {
                        return (
                            <span
                                key={optionItem.label}
                                className={styles['menu__list-item--custom-element']}
                            >
                                {optionItem.jsxElement}
                            </span>
                        )
                    } else {
                        return (
                            <MuiMenuItem
                                key={optionItem.label}
                                disabled={optionItem.disabled}
                                onClick={(): void => {
                                    if (optionItem.onClick) {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        optionItem.onClick()
                                    }
                                    handleClose()
                                }}
                                classes={{
                                    root: classnames(styles['menu__list-item'], {
                                        [styles['menu__list-item--invert']]: onDarkBackground,
                                    }),
                                }}
                            >
                                {optionItem.iconName && (
                                    <Icon
                                        iconName={optionItem.iconName}
                                        iconColor={onDarkBackground ? 'white' : 'primary-midnight'}
                                    />
                                )}
                                <Text
                                    weight='bold'
                                    color={onDarkBackground ? 'white' : 'primary-blue'}
                                >
                                    {optionItem.label}
                                </Text>
                            </MuiMenuItem>
                        )
                    }
                })}
            </MuiMenu>
        </StyledEngineProvider>
    )
}

export default Menu
