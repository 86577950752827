import { getFormattedDate } from 'utils/date-helpers'
import allContent from '../../content/content'

const content = allContent.cruise.sailingInfo

type CruiseDetailShipLineFromAPI = {
    name: string
    code: string
    description?: string
}

type CruiseDetailShipFromApi = {
    name: string
    code: string
    line: CruiseDetailShipLineFromAPI
}

type CruiseDetailProductFromApi = {
    name: string
    id: number
    description?: string
}

export type CruiseDetailFromApi = {
    id: string
    duration: number
    embark_date: string
    disembark_date: string
    embark_port: string
    disembark_port: string
    product: CruiseDetailProductFromApi
    ship: CruiseDetailShipFromApi
}

export type SailingInfoModel = {
    cruiseId: string
    name: string
    embarkDate: string
    disembarkDate: string
    duration: string
    supplierCode: string
    supplierName: string
    shipName: string
    roundTrip: string
}

export const SailingInfoModelBuild = (cruiseDetail: CruiseDetailFromApi): SailingInfoModel => {
    return {
        cruiseId: cruiseDetail.id,
        name: cruiseDetail.product.name,
        embarkDate: getFormattedDate(cruiseDetail.embark_date),
        disembarkDate: getFormattedDate(cruiseDetail.disembark_date),
        duration: cruiseDetail.duration.toString(),
        supplierName: cruiseDetail.ship.line.name,
        supplierCode: cruiseDetail.ship.line.code,
        shipName: cruiseDetail.ship.name,
        roundTrip:
            cruiseDetail.embark_port === cruiseDetail.disembark_port
                ? content.roundTripYes
                : content.roundTripNo,
    }
}
