import React, { useRef } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'

import styles from './InactiveTenantLayout.module.css'
import allContent from 'content/content'

const content = allContent.app.inactiveTenantPage

const InactiveTenantLayout: React.FC<{ cleanUpAppOnLogout(): void }> = ({ cleanUpAppOnLogout }) => {
    const {
        allData: userContext,
        companyName,
        userEmail,
    } = extractGlobalContextUserData(datadogLogs.getGlobalContext() as GlobalContextUserData)
    const navigate = useNavigate()
    const hasCleanedUp = useRef(false)
    const state = useLocation().state

    const isInactiveTenant = state?.isInactiveTenant
    if (isInactiveTenant) {
        if (!hasCleanedUp.current) {
            datadogLogs.logger.warn(
                `source: inactiveTenant, log in from email: ${userEmail}, company: ${companyName}`,
                { userContext }
            )
            cleanUpAppOnLogout()
            hasCleanedUp.current = true
        }
        return (
            <div className='general-container'>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <Heading heading='1'>{content.heading}</Heading>
                        <p>
                            <Text>{content.message1}</Text>
                        </p>
                        <p>
                            <Text>{content.message2}</Text>
                        </p>
                        <Button
                            onClick={(): void => navigate(ROUTES.LOGIN)}
                            text={content.goToLogin}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return <Navigate to={ROUTES.LOGIN} />
    }
}
export default InactiveTenantLayout
