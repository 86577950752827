import React, { useState } from 'react'
import classnames from 'classnames'

import AlternativeSailingDates from '../../AlternativeSailingDates/AlternativeSailingDates'
import DetailsSection from './ResultsListItemComponents/DetailsSection'
import ListItem from 'components/basics/List/ListItem'
import PricingSection from './ResultsListItemComponents/PricingSection'
import { Cruise } from 'api-data-models/CruisesContentModel'

import styles from './ResultsListItem.module.css'

type ResultListItemProps = React.AllHTMLAttributes<HTMLLIElement> & {
    /** unique key for each <li> rendered to help react track dynamically rendered contents */
    listKey: string
    /** data object of a cruise */
    cruise: Cruise
    /** used to not show cruise select button */
    isStarterTier: boolean
}

const ResultsListItem: React.FC<ResultListItemProps> = ({
    listKey,
    cruise,
    isStarterTier,
}: ResultListItemProps) => {
    const [alternativeSailDatesOpen, setAlternativeSailDatesOpen] = useState<boolean>(false)
    // Calculate if departureTime if we could get it from itinerary, if no - take embarkDate instead
    const departureDate = cruise.itinerary?.portCodesWithNamesAndDaysAndTimes?.find(
        ({ day }) => day === 0 || day === 1
    )
    const departureTimeForCheck =
        departureDate?.itemDate && departureDate?.departureTime
            ? `${departureDate.itemDate}T${departureDate.departureTime}`
            : cruise.embarkDate

    const containerClassNames = classnames(styles.container, {
        [styles['no-bottom-border-radius']]: alternativeSailDatesOpen,
    })

    return (
        <ListItem listKey={listKey} className={containerClassNames}>
            <div className={styles.details}>
                <DetailsSection cruise={cruise} />
                <PricingSection
                    isStarterTier={isStarterTier}
                    pricing={cruise.pricing}
                    cruiseId={cruise.cruiseId}
                    supplierCode={cruise.supplierCode}
                    hasAlternativeSailings={cruise.hasAlternativeSailings}
                    embarkDate={departureTimeForCheck}
                    alternativeSailDatesOpen={alternativeSailDatesOpen}
                    setAlternativeSailDatesOpen={setAlternativeSailDatesOpen}
                />
            </div>
            {alternativeSailDatesOpen && (
                <AlternativeSailingDates
                    isStarterTier={isStarterTier}
                    originallyChosenDate={cruise.embarkDate}
                    cruiseId={cruise.cruiseId}
                    supplierCode={cruise.supplierCode}
                />
            )}
        </ListItem>
    )
}

export default ResultsListItem
