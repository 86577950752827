type GroupFromApi = {
    title: string
    userGroupId: string
}

export type GroupInUserModel = {
    groupId: string
    groupTitle: string
}

export type UserStatus = 'FORCE_CHANGE_PASSWORD' | 'CONFIRMED' | 'DISABLED'

export type User = {
    userId: string
    userStatus: UserStatus
    emailAddress: string
    firstName: string
    lastName: string
    enabled: boolean
    userGroups: GroupInUserModel[]
    userRoles: UserRole[]
}

export type UserFromApi = {
    username: string
    userStatus: UserStatus
    emailAddress: string
    firstName: string
    lastName: string
    enabled: boolean
    userGroups: GroupFromApi[]
    roles: UserRole[]
}

export const UsersModel = (usersData: UserFromApi[]): User[] => {
    return usersData.map(
        (user: UserFromApi): User => ({
            userId: user.username, //TODO: Change to 'userId' when API is ready
            userStatus: user.userStatus,
            emailAddress: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
            enabled: user.enabled,
            userGroups: user.userGroups.map(
                (userGroup: GroupFromApi): GroupInUserModel => ({
                    groupId: userGroup.userGroupId,
                    groupTitle: userGroup.title,
                })
            ),
            userRoles: user.roles,
        })
    )
}
