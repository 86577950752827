import React from 'react'
import classnames from 'classnames'
import listStyles from './List.module.css'

export type ListProps = {
    /** optional: default to ul if not passed */
    isOrdered?: boolean
    /** optional: default to unstyled if not passed */
    isUnstyled?: boolean
    /** optional: indents the list if set to true */
    isIndented?: boolean
    /** optional: class-names */
    className?: string
    /** children always of type ReactNode */
    children: React.ReactNode
}

/** Text: renders a Text tag of a type based on the tagName prop */
const List: React.FC<
    ListProps &
        React.AllHTMLAttributes<HTMLUListElement> &
        React.AllHTMLAttributes<HTMLOListElement>
> = ({
    isOrdered = false,
    isUnstyled = true,
    isIndented = false,
    className,
    children,
}: ListProps) => {
    const Wrapper = `${isOrdered ? 'ol' : 'ul'}` as keyof JSX.IntrinsicElements
    const classNames = classnames(
        className,
        { [listStyles['list-unstyled']]: isUnstyled },
        { [listStyles['item-spacing']]: isIndented }
    )

    return <Wrapper className={classNames}>{children}</Wrapper>
}

export default List
