import React from 'react'
import classnames from 'classnames'
import styles from './DescriptionList.module.css'

export type DescriptionListProps = React.AllHTMLAttributes<HTMLDListElement> & {
    /** optional: class-names */
    className?: string
    /** children always of type ReactNode */
    children: React.ReactNode
}

/** DescriptionList: renders a DescriptionList tag to wrap DescriptionListValue and DataListKey pairs in  */
const DescriptionList: React.FC<DescriptionListProps> = ({
    className,
    children,
}: DescriptionListProps) => {
    const listClassNames = classnames(styles['description-list'], className)

    return <dl className={listClassNames}>{children}</dl>
}

export default DescriptionList
