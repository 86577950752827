type SetDataToSessionStorage = {
    key: string
    data: Record<string, any>
}
/**
 * setDataToSessionStorage: sets a JSON of the searchBarData to sessionStorage under
 * the key passed.
 @param key - string to store data under
 @param data - { object } of key/value pairs for data being set to Session storage
 */
export const setDataToSessionStorage = ({ key, data }: SetDataToSessionStorage): void => {
    /** set searchBarData to sessionStorage under the key passed in */
    window.sessionStorage.setItem(key, JSON.stringify({ ...data }))
}

/**
 * getDataFromSessionStorage: returns a JS Object parsed from the value of the key
 * passed in or undefined if not present.
 @param key - string to fetch data from
 */
export const getDataFromSessionStorage = ({
    key,
}: {
    key: string
}): Record<string, any> | undefined => {
    const data = window.sessionStorage.getItem(key)
    /** if previousSearchData has not been previously set return undefined */
    if (!data) return undefined

    return JSON.parse(data)
}

/**
 * removeDataFromSessionStorage: remove data form sessionStorage by key 
 @param key - string to remove data from
 */
export const removeDataFromSessionStorage = ({ key }: { key: string }): undefined => {
    const data = window.sessionStorage.getItem(key)
    if (data) {
        window.sessionStorage.removeItem(key)
    }
    return undefined
}
