import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Card from 'components/blocks/Card/Card'
import Upgrade from 'components/sections/admin/AdminLanding/Misc/Upgrade/Upgrade'

import styles from './Misc.module.scss'
import allContent from 'content/content'

const content = allContent.admin.adminLanding.upgrade

type MiscProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
}
const Misc: React.FC<MiscProps> = ({ apiClient }: MiscProps): JSX.Element => {
    return (
        <Card header={content.title}>
            <div className={styles.content}>
                <Upgrade
                    // TODO: we don't have a way to tell if they are processing yet - USE the products object from getConnectUserData?
                    upgradeIsProcessing={false}
                    apiClient={apiClient}
                    content={content.appUpgradeSection}
                    productName={'AGENTCONNECTCRUISE'}
                />
            </div>
        </Card>
    )
}

export default Misc
