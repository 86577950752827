import React from 'react'
import classnames from 'classnames'
import styles from './ToggleSwitch.module.scss'

type ToggleSwitchProps = {
    /** Tell the toggle (checkbox) it's status; controlled by consumer via passing onChange function */
    isChecked: boolean
    /** Called when clicked */
    onChange(): void
    /** Text label for screen reader, visually not shown */
    label: string
    /** toggle to make unavailable */
    disabled?: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    isChecked,
    onChange,
    label,
    disabled = false,
    ...rest
}: ToggleSwitchProps) => {
    const containerClassNames = classnames(styles.container, {
        [styles['container--disabled']]: disabled,
    })
    return (
        <label className={containerClassNames}>
            <span className='visually-hidden'>{label}</span>
            <input type='checkbox' checked={isChecked} onChange={onChange} {...rest} />
            <span className={styles.slider} />
        </label>
    )
}

export default ToggleSwitch
