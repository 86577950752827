import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Modal from 'components/blocks/Modal/Modal'
import Select, { SelectOptionType } from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import { HTTP_METHODS, PRODUCT_TYPES, SUPPLIER_NAMES } from 'utils/constants'
import { datadogLogs } from '@datadog/browser-logs'
import * as self from './ImportModal'
import { ROUTES } from '../../AppRoutes'
import { VITALLY_EVENTS } from 'utils/constants'
import { CustomerSuccess } from 'services/customerSuccess/customerSuccess.service'

import styles from './ImportModal.module.css'
import allContent from 'content/content'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { isTestEmailDomain } from 'utils/testing-handlers'
import callApi from 'services/callApi/callApi.service'
import { orderServiceUrls } from 'utils/order-service-urls'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import { convertKeysToDirectCase } from 'utils/camel-case-helpers'

const content = allContent.header.importModal

export const submitRetrieveBooking = ({
    variables,
    setIsSubmitting,
    onSuccess,
    onError,
}: {
    variables: { bookingId: string; supplierCode: string }
    setIsSubmitting: (value: React.SetStateAction<any>) => void
    onSuccess: (values: any) => void
    onError: (error: any) => void
}): void => {
    const requestUrl = orderServiceUrls.retrieveOrder(variables.bookingId, variables.supplierCode)
    setIsSubmitting(true)
    callApi({
        url: requestUrl,
        method: HTTP_METHODS.GET,
    })
        .then((response) => response.json())
        .then((data) => {
            if (data?.id) onSuccess(convertKeysToDirectCase(data))
        })
        .catch((error: any) => {
            const parsedError = getRestErrorMessage({
                errors: error.cause,
                source: 'RetrieveBooking',
            })
            onError(parsedError)
        })
        .finally(() => {
            CustomerSuccess.track({
                eventName: VITALLY_EVENTS.RETRIEVE_BOOKING,
                properties: {
                    productType: PRODUCT_TYPES.CRUISE,
                    supplierCode: variables.supplierCode,
                },
            })
            setIsSubmitting(false)
        })
}

export interface ImportModalProps {
    isOpen: boolean
    onClose: () => void
    returnFocusId: string
}

const supportedSuppliers = ['NCL', 'RCL', 'CEL']

const ImportModal: React.FC<ImportModalProps> = ({ isOpen, onClose, returnFocusId }) => {
    const userContext = datadogLogs.getGlobalContext()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState<any>(null)
    const navigate = useNavigate()

    const { userEmail } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )

    useEffect(() => {
        if (isOpen) {
            datadogLogs.logger.info(`source: ImportModalOpen`, { userContext })
            CustomerSuccess.track({
                eventName: VITALLY_EVENTS.IMPORT_CRUISE_MODAL_OPEN,
                properties: {
                    page: window.location.pathname,
                },
            })
        }
    }, [isOpen, userContext])

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            bookingRef: '',
            supplier: 'NCL',
        },
    })

    const handleClose = (): void => {
        if (!isSubmitting) {
            setError(null)
            onClose()
            reset()
        }
    }

    const suppliersOptions: SelectOptionType[] = Object.keys(SUPPLIER_NAMES)
        .map((code) => {
            const isSupported = isTestEmailDomain(userEmail) || supportedSuppliers.includes(code)
            const label = `${(SUPPLIER_NAMES as any)[code]} ${!isSupported ? '(Coming Soon)' : ''}`
            return {
                value: code,
                text: label,
                label: label,
                disabled: !isSupported,
            }
        })
        .sort((supplier) => (supplier.disabled ? 1 : -1))

    const handleSubmitRequest = (variables: { bookingId: string; supplierCode: string }): void => {
        self.submitRetrieveBooking({
            variables,
            setIsSubmitting,
            onSuccess: (orderData): void => {
                if (orderData) {
                    navigate(
                        `${ROUTES.ORDER_IMPORT_PREVIEW}/bookingId=${variables.bookingId}&supplierCode=${variables.supplierCode}`,
                        { state: { orderData } }
                    )
                    handleClose()
                }
            },
            onError: (error): void => {
                setError(error)
            },
        })
    }

    return (
        <Modal
            headerText={content.title}
            isOpen={isOpen}
            returnFocusId={returnFocusId}
            setClosed={handleClose}
            contentClassName={styles.modalContent}
        >
            {error && <ErrorList errorsList={error} source='RetrieveBooking' />}
            <form
                className={styles.modalForm}
                onSubmit={handleSubmit((data) => {
                    handleSubmitRequest({ bookingId: data.bookingRef, supplierCode: data.supplier })
                })}
            >
                <Text>{content.subtitle}</Text>
                <Controller
                    control={control}
                    name='supplier'
                    rules={{ required: true }}
                    render={({ field: { value, ref, onChange } }): React.ReactElement => (
                        <div>
                            <LabelledInput
                                htmlFor='supplier'
                                label={content.supplierLabel}
                                disabled={isSubmitting}
                                required={true}
                                ref={ref}
                                isErrored={!!errors.supplier}
                            >
                                <Select
                                    value={value}
                                    options={suppliersOptions}
                                    onChange={onChange}
                                />
                            </LabelledInput>
                            <FieldError
                                errorMessage={content.bookingError}
                                showError={!!errors.supplier}
                                inputId='supplier'
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name='bookingRef'
                    rules={{ required: true }}
                    render={({ field: { value, ref } }): React.ReactElement => (
                        <div>
                            <LabelledInput
                                htmlFor='bookingRef'
                                label={content.bookingLabel}
                                disabled={isSubmitting}
                                required={true}
                                ref={ref}
                                isErrored={!!errors.bookingRef}
                            >
                                <TextInput
                                    value={value}
                                    disabled={isSubmitting}
                                    autoComplete='off'
                                    onChangeCallback={(value): void => {
                                        setValue('bookingRef', value)
                                    }}
                                />
                            </LabelledInput>
                            <FieldError
                                errorMessage={content.bookingError}
                                showError={!!errors.bookingRef}
                                inputId='bookingRef'
                            />
                        </div>
                    )}
                />
                <div className={styles.actions}>
                    <Button
                        text={content.cancelButton}
                        disabled={isSubmitting}
                        onClick={onClose}
                        flavour='tertiary'
                        type='button'
                    />
                    <Button text={content.submitButton} showSpinner={isSubmitting} type='submit' />
                </div>
            </form>
        </Modal>
    )
}

export default ImportModal
