import React, { useEffect, useRef } from 'react'

const HtmlComment: React.FC<{ text: string }> = ({ text }: { text: string }) => {
    const element = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (element.current) {
            element.current.innerHTML = `<!-- ${text} -->`
        }
    }, [text, element])

    return <div style={{ display: 'none' }} ref={element} />
}

export default HtmlComment
