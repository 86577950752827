import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

type AuthData = { accessToken?: string; idToken?: string }

export async function getCurrentAuthData(): Promise<AuthData> {
    const authData: AuthData = { accessToken: undefined, idToken: undefined }
    try {
        const session = await Auth.currentSession()
        const accessToken = session.getAccessToken().getJwtToken()
        const idToken = session.getIdToken().getJwtToken()

        authData.accessToken = `Bearer ${accessToken?.toString()}`
        authData.idToken = idToken?.toString()
    } catch (error) {
        datadogLogs.logger.error(
            `getCurrentAuthData, Auth.currentSession failed, error: ${JSON.stringify(error)}`,
            {},
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error
        )
    }
    return authData
}
