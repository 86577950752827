import React, { SyntheticEvent } from 'react'

// omitted crossOrigin due to type conflicts
type ImageProps = Omit<React.AllHTMLAttributes<HTMLImageElement>, 'crossOrigin'> & {
    className?: string
    altImageSrc?: string
}

const Image: React.FC<ImageProps> = ({
    width = '100%',
    alt,
    src,
    className,
    onError,
    altImageSrc,
    ...rest
}: ImageProps) => {
    const handleOnError = (e: SyntheticEvent<HTMLImageElement>): void => {
        e.currentTarget.onerror = null
        e.currentTarget.src = altImageSrc ?? '/images/image-unavailable.png'
    }

    return (
        <img
            alt={alt}
            src={src}
            className={className}
            onError={handleOnError}
            width={width}
            {...rest}
        />
    )
}

export default Image
