import React from 'react'

import ResultsList from '../ResultsList/ResultsList'
import { Cruise } from 'api-data-models/CruisesContentModel'

import styles from './PaginatedResultsList.module.scss'

type PaginatedResultsListProps = {
    filteredItems: Cruise[]
    itemsPerPage: number
    itemOffset: number
}

const PaginatedResultsList: React.FC<PaginatedResultsListProps> = ({
    filteredItems,
    itemsPerPage,
    itemOffset,
}) => {
    const endOffset = Math.min(itemOffset + itemsPerPage, filteredItems.length)
    const currentItems = filteredItems.slice(itemOffset, endOffset)

    return (
        <div className={styles.container}>
            <ResultsList cruisesToDisplay={currentItems} />
        </div>
    )
}

export default PaginatedResultsList
