import React from 'react'

import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'
import ReportingSection from 'components/sections/admin/Reporting/Reporting'

import styles from './ReportingLayout.module.css'

const Reporting = ({ companyTier }: { companyTier?: CompanyTierTypes }): JSX.Element => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation companyTier={companyTier} />
                <ReportingSection />
            </div>
        </div>
    )
}

export default Reporting
