import React, { useEffect } from 'react'

import Text from 'components/basics/Text/Text'
import CruiseItem from './CruiseOrderItem/CruiseOrderItem'
import {
    CruiseProduct,
    FlightProduct,
    OrderItemPaymentType,
} from 'api-data-models/order/OrderContentModel'
import { PRODUCT_TYPES } from '../../../../utils/constants'

import styles from './OrderItem.module.scss'
import allContent from '../../../../content/content'

const content = allContent.order.orderPage

type OrderItemProps = {
    product: CruiseProduct | FlightProduct
    numberOfPassengers: number
    supplierOrderItemReference: string | null
    orderItemStatus: string
    orderItemPaymentOption?: OrderItemPaymentType
    orderId: string
}

const OrderItem = ({
    product,
    numberOfPassengers,
    supplierOrderItemReference,
    orderItemStatus,
    orderItemPaymentOption,
    orderId,
}: OrderItemProps): JSX.Element => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let item = <Text>{content.noProduct}</Text>
    if (product.productType === PRODUCT_TYPES.CRUISE) {
        item = CruiseItem({
            cruiseProduct: product as CruiseProduct,
            numberOfPassengers,
            supplierOrderItemReference,
            orderItemStatus,
            orderItemPaymentOption,
            orderId,
        })
    }
    return <div className={styles['order-item__container']}>{item}</div>
}

export default OrderItem
