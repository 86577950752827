import { gql } from '@apollo/client'

export const API_USER_CREATE = gql`
    mutation apiUserCreate {
        apiUserCreate {
            password
            username
        }
    }
`

export const API_LIST_USERS = gql`
    query listApiUsers {
        listApiUsers {
            password
            username
        }
    }
`

export const API_USER_RESET_PASSWORD = gql`
    mutation apiUserResetPassword {
        apiUserResetPassword {
            password
            username
        }
    }
`

export const SUPPLIER_CREDENTIALS_AND_SALES_CHANNEL = gql`
    query supplierCredentials($salesChannelId: String!) {
        supplierCredentials(salesChannelId: $salesChannelId) {
            available
            created
            supplierCode
        }
        salesChannels {
            salesChannelId
            title
        }
    }
`

export const CREATE_SALES_CHANNEL = gql`
    mutation createSalesChannel($title: String!) {
        createSalesChannel(title: $title) {
            salesChannelId
            title
            userGroups {
                title
                userGroupId
            }
        }
    }
`

export const SUPPLIER_CREDENTIAL_FIELDS = gql`
    query supplierCredentialFields($supplierCode: SupplierCodeEnum!, $salesChannelId: String!) {
        supplierCredentialFields(supplierCode: $supplierCode, salesChannelId: $salesChannelId) {
            helpText
            name
            order
            value
            displayName
        }
    }
`

export const SUPPLIER_CREDENTIAL_CREATE = gql`
    mutation supplierCredentialCreate(
        $salesChannelId: String!
        $supplierCode: SupplierCodeEnum!
        $fields: [SupplierCredentialFieldInput!]!
    ) {
        supplierCredentialCreate(
            salesChannelId: $salesChannelId
            supplierCredential: { supplierCode: $supplierCode, fields: $fields }
        ) {
            available
            created
            supplierCode
        }
    }
`

export const SUPPLIER_CREDENTIAL_UPDATE = gql`
    mutation supplierCredentialUpdate(
        $salesChannelId: String!
        $supplierCode: SupplierCodeEnum!
        $fields: [SupplierCredentialFieldInput!]!
    ) {
        supplierCredentialUpdate(
            salesChannelId: $salesChannelId
            supplierCredential: { supplierCode: $supplierCode, fields: $fields }
        ) {
            created
        }
    }
`

export const SUPPLIER_CREDENTIAL_AVAILABLE_TOGGLE = gql`
    mutation supplierCredentialAvailableToggle(
        $salesChannelId: String!
        $available: Boolean!
        $supplierCode: SupplierCodeEnum!
    ) {
        supplierCredentialAvailableToggle(
            salesChannelId: $salesChannelId
            available: $available
            supplierCode: $supplierCode
        ) {
            available
        }
    }
`

export const REQUEST_PRODUCT_UPGRADE = gql`
    mutation upgradeTenant($productName: ProductNameEnum!) {
        upgradeTenant(productInput: { name: $productName }) {
            status
        }
    }
`

//The below will be swapped to be called just AGENT_CONNECT_GROUP_ADD, but it is 2 for now
export const AGENT_CONNECT_GROUP_ADD_V2 = gql`
    mutation createUserGroup2(
        $title: String!
        $description: String
        $phoneNumber: String
        $salesChannelId: String
        $homeCityCode: String
    ) {
        createUserGroup2(
            title: $title
            description: $description
            phoneNumber: $phoneNumber
            salesChannelId: $salesChannelId
            homeCityCode: $homeCityCode
        ) {
            userGroupId
            title
            description
            tenantId
            phone {
                callingCode
                iso2Code
                number
            }
            homeCityCode
        }
    }
`
export const UPDATE_USER_GROUP = gql`
    mutation updateUserGroup(
        $homeCityCode: String
        $userGroupId: String!
        $phoneNumber: String
        $salesChannelId: String!
    ) {
        updateUserGroup(
            homeCityCode: $homeCityCode
            userGroupId: $userGroupId
            phoneNumber: $phoneNumber
            salesChannelId: $salesChannelId
        ) {
            homeCityCode
            title
            salesChannels {
                salesChannelId
                title
            }
            userGroupId
            phone {
                callingCode
                iso2Code
                number
            }
        }
    }
`

export const AGENT_CONNECT_USER_UPDATE = gql`
    mutation agentConnectUserUpdate(
        $userId: String!
        $emailAddress: String
        $firstName: String
        $lastName: String
        $userRoles: [UserRoleGroupEnum!]
    ) {
        agentConnectUserUpdate(
            user: {
                username: $userId
                emailAddress: $emailAddress
                firstName: $firstName
                lastName: $lastName
                userRoles: $userRoles
            }
        ) {
            status
        }
    }
`

export const AGENT_CONNECT_USER_DISABLE = gql`
    mutation agentConnectUserDisable($emailAddress: String!) {
        agentConnectUserDisable(user: { emailAddress: $emailAddress }) {
            status
        }
    }
`

export const AGENT_CONNECT_USER_ENABLE = gql`
    mutation agentConnectUserEnable($emailAddress: String!) {
        agentConnectUserEnable(user: { emailAddress: $emailAddress }) {
            status
        }
    }
`

export const AGENT_CONNECT_LIST_USERS = gql`
    query agentConnectListUsers {
        listAgentConnectUsers {
            enabled
            username
            userStatus
            emailAddress
            firstName
            lastName
            userGroups {
                title
                userGroupId
            }
            roles
        }
    }
`

export const AGENT_CONNECT_LIST_GROUPS = gql`
    query userGroups {
        userGroups {
            homeCityCode
            title
            salesChannels {
                title
                salesChannelId
            }
            phone {
                callingCode
                iso2Code
                number
            }
            userGroupId
        }
    }
`

export const AGENT_CONNECT_USER_RESET_PASSWORD = gql`
    mutation agentConnectUserResetPassword($emailAddress: String!) {
        agentConnectUserResetPassword(user: { emailAddress: $emailAddress }) {
            emailAddress
            password
        }
    }
`

export const LIST_SALES_CHANNELS = gql`
    query salesChannels {
        salesChannels {
            salesChannelId
            title
            userGroups {
                title
                userGroupId
            }
        }
    }
`

export const ASSIGN_USER_TO_A_GROUP = gql`
    mutation assignUserToAUserGroup($userId: String!, $userGroupId: String!) {
        assignUserToAUserGroup(userGroupId: $userGroupId, userId: $userId) {
            email
            userGroups {
                title
            }
            userId
        }
    }
`

export const AGENT_CONNECT_USER_ADD = gql`
    mutation agentConnectUserAdd(
        $emailAddress: String!
        $firstName: String!
        $lastName: String!
        $userGroupId: String
        $userRoles: [UserRoleGroupEnum!]
    ) {
        agentConnectUserAdd(
            user: {
                emailAddress: $emailAddress
                lastName: $lastName
                firstName: $firstName
                userRoles: $userRoles
            }
            userGroupId: $userGroupId
        ) {
            status
        }
    }
`

export const UPDATE_TENANT = gql`
    mutation updateTenant($id: String!, $financeSystemId: String!, $companyTier: CompanyTierEnum!) {
        updateTenant(
            tenantUpdateInput: {
                id: $id
                financeSystemId: $financeSystemId
                companyTier: $companyTier
            }
        ) {
            status
        }
    }
`

export const PROCESS_CHECKOUT = gql`
    mutation processCheckout(
        $hostedPageId: String!
        $id: String!
        $financeSystemId: String!
        $companyTier: CompanyTierEnum!
    ) {
        processCheckout(
            hostedPageId: $hostedPageId
            tenantUpdateInput: {
                id: $id
                financeSystemId: $financeSystemId
                companyTier: $companyTier
            }
        ) {
            status
        }
    }
`
