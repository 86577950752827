import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Footer from 'components/blocks/Footer/Footer'
import FooterContent from 'components/sections/app/FooterContent/FooterContent'
import HeaderSection from 'components/sections/app/HeaderContent/HeaderContent'

import styles from './GenericLayout.module.css'

type GenericLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    children: React.ReactNode
    logUserOut({ to }: { to: string }): void
    userData: GlobalContextUserData
    isFetchingUserData: boolean
}

const GenericLayout: React.FC<GenericLayoutProps> = ({
    apiClient,
    children,
    logUserOut,
    userData,
    isFetchingUserData,
}: GenericLayoutProps): JSX.Element => {
    const { userRoles, userId, companyTier, userEmail, financeSystemId } = userData ?? {}
    const isAuthorised = !!userId
    return (
        <div className={styles['full-page']}>
            <HeaderSection
                apiClient={apiClient}
                isAuthorised={isAuthorised}
                logUserOut={logUserOut}
                userEmail={userEmail}
                companyTier={companyTier}
                userRoles={userRoles}
                financeSystemId={financeSystemId}
                isFetchingUserData={isFetchingUserData}
            />
            <main className={styles.content}>{children}</main>
            <Footer>
                <FooterContent
                    apiClient={apiClient}
                    companyTier={companyTier}
                    isAuthorised={isAuthorised}
                    financeSystemId={financeSystemId}
                    userEmail={userEmail}
                    userRoles={userRoles}
                />
            </Footer>
        </div>
    )
}

export default GenericLayout
