export function toCamelCase(str: string): string {
    return str.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase())
}

export function toSnakeCase(str: string): string {
    return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

type Case = 'snake' | 'camel'

export function convertKeysToDirectCase(
    obj: Record<string, any>,
    selectedCase: Case = 'camel'
): Record<string, any> {
    const converter = selectedCase === 'snake' ? toSnakeCase : toCamelCase
    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => convertKeysToDirectCase(item, selectedCase))
    }

    return Object.keys(obj).reduce(
        (acc, key) => {
            const camelCaseKey = converter(key)
            acc[camelCaseKey] = convertKeysToDirectCase(obj[key], selectedCase)
            return acc
        },
        {} as { [key: string]: any }
    )
}
