import React from 'react'

import Text from '../Text/Text'
import allContent from 'content/content'

const content = allContent.components.fieldsRequired

const FieldsRequired: React.FC<{ className?: string }> = ({
    className,
}: {
    className?: string
}) => (
    <span className={className}>
        {content.fieldsRequired1a}
        <Text color='red'>{content.fieldsRequired1b}</Text>
        {content.fieldsRequired1c}
    </span>
)

export default FieldsRequired
