import React from 'react'

import ForgotPasswordForm from 'components/sections/cognito/ForgotPasswordForm/ForgotPasswordForm'

const ForgotPasswordLayout: React.FC = () => {
    return (
        <div className='general-container'>
            <ForgotPasswordForm />
        </div>
    )
}

export default ForgotPasswordLayout
