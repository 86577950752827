import React from 'react'
import classnames from 'classnames'

import Close from 'components/basics/Close/Close'
import styles from './Tag.module.scss'

export const TagsContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className={styles['tags-wrapper']}>{children}</div>
)

type TagProps = {
    label: string
    onClose: () => void
}

const Tag: React.FC<TagProps> = ({ label, onClose }: TagProps) => {
    const containerStyles = classnames(styles.container)

    return (
        <span className={containerStyles}>
            {label}
            <Close onBackground='darkColoured' onClick={onClose} />
        </span>
    )
}

export default Tag
