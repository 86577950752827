import React from 'react'

import SaaSSignUpForm from 'components/sections/app/SaaSSignupForm/SaaSSignUpForm'
import LinkedInTrackingScript from 'components/sections/app/LinkedInTrackingScript/LinkedInTrackingScript'

type SaaSSignUpLayoutProps = {
    setUserData: React.Dispatch<React.SetStateAction<GlobalContextUserData>>
}

const SaaSSignUpLayout: React.FC<SaaSSignUpLayoutProps> = ({ setUserData }) => {
    return (
        <div className='general-container'>
            <LinkedInTrackingScript />
            <SaaSSignUpForm setUserData={setUserData} />
        </div>
    )
}

export default SaaSSignUpLayout
