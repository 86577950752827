export function stringArrayFilterAndSortByMatcher(
    stringArray: string[],
    searchString: string
): string[] {
    const filteredStringArray = stringArray.filter((string) =>
        string.toLowerCase().includes(searchString.toLowerCase())
    )
    const lists: string[][] = []
    const noFirstChar: string[] = []
    const results: string[] = [] // return array - filtered and sorted by priority of match

    // For every location within locations array, compare search string for matches at the start of each word
    for (const wordString of filteredStringArray) {
        const words = wordString.split(' ') // split location name into separate word for comparing

        let index = 0 // keep track of word we are on within the wordString
        for (const word of words) {
            if (lists[index] === undefined) lists.push([]) // create array for each sublist - first/second/ word in name if they don't exist
            index += 1
            const partOfWordAsLongAsSearchString = word.toLowerCase().slice(0, searchString.length)

            // Compare start of word to searchString for match
            if (partOfWordAsLongAsSearchString === searchString.toLowerCase()) {
                // if first part of word matches search string then add it to list array of index matching word index within words array
                lists[index - 1].push(wordString) // put location in array for matching first/second etc. word
                break // break out of loop to avoid adding to other lists (we don't care if same word exists more than one in wordArray
            } else if (index === words.length) {
                // if no match at start of word has been found add to final sublist array
                noFirstChar.push(wordString)
            }
        }
    }

    // add each list (subgroup of locations we created per word in wordString) to the final results in order:
    // 1st sublist matched search string to first characters in first word (of word string)
    // 2nd sublist matched search string to first characters in second word (of word string)
    // ... etc. for longer word strings names that match.
    // Finally, any strings that match somewhere but not the first chars of any word
    for (const sublist of lists) {
        results.push(...sublist)
    }
    results.push(...noFirstChar)

    return results
}
