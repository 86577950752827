import React from 'react'

import FreeTierGettingStarted from 'components/sections/admin/AdminLanding/GettingStartedSection/FreeTierGettingStarted/FreeTierGettingStarted'
import PaidTierGettingStarted from 'components/sections/admin/AdminLanding/GettingStartedSection/PaidTierGettingStarted/PaidTierGettingStarted'

import styles from './GettingStartedSection.module.css'

type GettingStartedSectionProps = {
    onPaidTier: boolean
}

const GettingStartedSection: React.FC<GettingStartedSectionProps> = ({
    onPaidTier,
}): React.ReactElement => {
    return (
        <div className={styles.container}>
            <div className={styles['coloured-top']} />
            <div className={styles.content}>
                {onPaidTier ? <PaidTierGettingStarted /> : <FreeTierGettingStarted />}
            </div>
        </div>
    )
}
export default GettingStartedSection
