import React, { useCallback } from 'react'
import Text from 'components/basics/Text/Text'
import Radio from 'components/basics/Input/Radio/Radio'
import Icon from 'components/basics/Icon/Icon'
import Tooltip from 'components/basics/Tooltip/Tooltip'

import { GUARANTEED } from 'api-data-models/CabinContentModel'
import styles from './CabinGuaranteedList.module.css'
import allContent from 'content/content'

const content = allContent.cruise.cabinPage.cabinPickerSection.guaranteedList

type CabinGuaranteedListProps = {
    setValue(key: string, value: string): void
    selectedCabinNumber: string
}

const CabinGuaranteedList: React.FC<CabinGuaranteedListProps> = ({
    setValue,
    selectedCabinNumber,
}) => {
    const handleCabinSelect = useCallback(() => {
        setValue('selectedCabinNumber', GUARANTEED)
    }, [setValue])

    return (
        <table className={styles['table']}>
            <thead>
                <tr>
                    <th scope='col'>
                        <div className={styles.header}>
                            <Text weight='bold' color='white'>
                                {content.header}
                            </Text>
                            <Tooltip id={'guaranteed-cabins'} content={content.guaranteedTooltip}>
                                <Icon iconName={'Info'} iconColor={'white'} />
                            </Tooltip>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className={styles['table-cell']}>
                            <Radio
                                value={GUARANTEED}
                                checked={selectedCabinNumber === GUARANTEED}
                                text={content.guaranteedLabel}
                                onChange={handleCabinSelect}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CabinGuaranteedList
