import { Cruise } from 'api-data-models/CruisesContentModel'

export const findMinMaxPrice = (cruises: Cruise[]): number[] => {
    const prices = cruises
        .filter((cruise) => typeof cruise.pricing.fromPrice === 'number')
        .map((cruise) => Math.round(+cruise.pricing.fromPrice / 100)) // drop pence/cents

    // return array of 0, 0 if no pricing found. Using anything other than a number gives NaN, which isn't equal to itself, so no easy way to avoid showing the price slider with that.
    // But we already have a check if maxPrice === 0 to hide the price slider, so returning [0, 0] will work fine.
    return prices.length ? [Math.min(...prices), Math.max(...prices)] : [0, 0]
}
