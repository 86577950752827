import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import ResetPasswordForm from 'components/sections/cognito/ResetPasswordForm/ResetPasswordForm'
import { ROUTES } from 'components/sections/app/AppRoutes'

type ResetPasswordLayoutProps = {
    setUserData: React.Dispatch<React.SetStateAction<GlobalContextUserData>>
}

const ResetPasswordLayout: React.FC<ResetPasswordLayoutProps> = ({ setUserData }) => {
    const { state } = useLocation()
    const email = state?.email ?? ''
    const hasJustSubmitted = state?.hasJustSubmitted
    if (hasJustSubmitted) {
        return (
            <div className='general-container'>
                <ResetPasswordForm setUserData={setUserData} email={email} />
            </div>
        )
    } else {
        return <Navigate to={ROUTES.ROOT} />
    }
}

export default ResetPasswordLayout
