import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/basics/Button/Button'
import Heading from 'components/basics/Heading/Heading'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'
import styles from './CrashErrorLayout.module.css'

import allContent from 'content/content'

const content = allContent.components.errorBoundary

const CrashErrorLayout = ({ cleanUpAppOnLogout }: { cleanUpAppOnLogout(): void }): JSX.Element => {
    const navigate = useNavigate()
    const handleClickReload = ({ cleanUpAppOnLogout }: { cleanUpAppOnLogout(): void }): void => {
        cleanUpAppOnLogout()
        navigate(ROUTES.LOGIN)
        window.location.reload()
    }

    return (
        <div className='general-container'>
            <main>
                <Spacing size='quadruple' />
                <div className={styles['crash-error-card']}>
                    <Heading heading='1'>{content.heading}</Heading>
                    <Text size='M'>{content.message}</Text>
                    <Spacing />
                    <Button
                        type='button'
                        onClick={(): void => handleClickReload({ cleanUpAppOnLogout })}
                        text={content.actionButton}
                    />
                </div>
            </main>
        </div>
    )
}
export default CrashErrorLayout
