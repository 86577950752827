import React, { useRef, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Button from 'components/basics/Button/Button'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Modal from 'components/blocks/Modal/Modal'
import Text from 'components/basics/Text/Text'
import { REQUEST_PRODUCT_UPGRADE } from 'graphql-queries/admin/admin-queries'
import { getGraphqlErrorMessage } from 'utils/api-errors-helpers/get-graphql-error-message'

import styles from './Upgrade.module.scss'

type ProductNameType = 'AGENTCONNECTCRUISE' | 'CRUISECONNECT'

type UpgradeProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productName: ProductNameType
    content: Record<string, any>
    upgradeIsProcessing: boolean
}
const Upgrade: React.FC<UpgradeProps> = ({
    apiClient,
    productName = 'AGENTCONNECTCRUISE',
    content,
    upgradeIsProcessing,
}) => {
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [clickedButtonId, setClickedButtonId] = useState<string>('')
    const [isUpgradeConfirmOpen, setIsUpgradeConfirmOpen] = useState<boolean>(false)
    const [apiError, setApiError] = useState<CustomApiError[]>([])
    const [submissionCompleted, setSubmissionCompleted] = useState<boolean>(false)
    const upgradeButtonRef = useRef<HTMLButtonElement | null>(null) // Create a ref for the Upgrade button

    const handleCancelClick = (): void => {
        setIsUpgradeConfirmOpen(false)
        if (upgradeButtonRef.current) {
            upgradeButtonRef.current.focus()
        }
    }

    const handleConfirmClick = async (): Promise<void> => {
        setIsUpgradeConfirmOpen(false)
        setSubmitting(true)
        await apiClient
            .mutate({ mutation: REQUEST_PRODUCT_UPGRADE, variables: { productName } })
            .then((response) => {
                if (response?.data.upgradeTenant.status === 'SUCCESS') {
                    setSubmissionCompleted(true)
                }
            })
            .catch((error) => {
                // setApiError(error.graphQLErrors[0].message)
                setApiError(getGraphqlErrorMessage(error, 'upgradeTenant'))
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const handleOnClickUpgradeButton = ({ buttonId }: { buttonId: string }): void => {
        setClickedButtonId(buttonId)
        setIsUpgradeConfirmOpen(true)
    }

    const openConfirmModalUpgradeButtonId = `open-confirm-modal-upgrade-${productName}`

    if (upgradeIsProcessing) {
        return (
            <InfoBanner
                id={`${productName}-upgrade-in-progress-banner`}
                text={content.upgradeInProgressMessage}
                bannerType='info'
                isCloseable={false}
            />
        )
    }

    return (
        <div className={styles.container}>
            <Text size='M' weight='bold'>
                {content.heading}
            </Text>
            <div>
                <p>
                    <Text>{content.info1}</Text>
                </p>
                <p>
                    <Text>{content.info2}</Text>
                </p>
                <p>
                    <Text>{content.info3}</Text>
                </p>
                <p>
                    <Text>{content.info4}</Text>
                </p>
            </div>
            {apiError.length > 0 && (
                <ErrorList
                    errorsList={apiError}
                    source={`${productName}-upgrade-request`}
                    isCloseable={true}
                />
            )}
            {submissionCompleted && (
                <InfoBanner
                    id={`${productName}-upgrade-success-banner`}
                    text={content.inProgressMessage}
                    bannerType='info'
                    isCloseable={false}
                />
            )}
            {!submissionCompleted && (
                <>
                    <div>
                        <Button
                            disabled={submitting}
                            id={openConfirmModalUpgradeButtonId}
                            text={submitting ? content.submittingButton : content.upgradeButton}
                            onClick={(): void =>
                                handleOnClickUpgradeButton({
                                    buttonId: openConfirmModalUpgradeButtonId,
                                })
                            }
                            ref={upgradeButtonRef}
                            type='button'
                            showSpinner={submitting}
                        />
                    </div>
                    <Modal
                        headerText={content.upgradeModal.heading}
                        isOpen={isUpgradeConfirmOpen}
                        returnFocusId={clickedButtonId}
                        setClosed={(): void => setIsUpgradeConfirmOpen(false)}
                    >
                        <div className={styles['modal-content']}>
                            <div className={styles['modal-text']}>
                                <Text>{content.upgradeModal.text1}</Text>
                                <Text>{content.upgradeModal.text2}</Text>
                                <Text>{content.upgradeModal.text3}</Text>
                            </div>
                            <div className={styles['modal-buttons']}>
                                <Button
                                    type='button'
                                    flavour='tertiary'
                                    text={content.upgradeModal.cancelButton}
                                    onClick={handleCancelClick}
                                />
                                <Button
                                    type='button'
                                    text={content.upgradeModal.confirmButton}
                                    onClick={handleConfirmClick}
                                />
                            </div>
                        </div>
                    </Modal>
                </>
            )}
        </div>
    )
}

export default Upgrade
