import { useState, useEffect } from 'react'
import {
    ApolloClient,
    ApolloQueryResult,
    DocumentNode,
    TypedDocumentNode,
    FetchResult,
} from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import { getGraphqlErrorMessage } from 'utils/api-errors-helpers/get-graphql-error-message'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'

type UseApolloQueryResponse = {
    /** boolean to indicate whether query is still ongoing */
    loading: boolean
    /** the result of the query is stored here */
    result?: ApolloQueryResult<any> | FetchResult<any, Record<string, any>, Record<string, any>>
    /** our own CustomApiError thrown by graphql to indicate whether the query has errored, if there's an error it always returns at least one CustomApiError object */
    error: CustomApiError[] | null
    /** function used to trigger repeat api call */
    refetch: () => void
}

export type UseApolloQueryOptions = {
    /** a specific instance of ApolloClient to be used*/
    client: ApolloClient<any>
    /** any parameters to be used when making the query */
    variables?: Record<string, any>
    /** the query to use when useApolloQuery calls client.query */
    query?: DocumentNode | TypedDocumentNode<any, Record<string, string>>
    /** the mutation to use when useApolloQuery calls client.mutate */
    mutation?: DocumentNode | TypedDocumentNode<any, Record<string, string>>
    /** because this is a hook, we can't use branching logic to not call it within a component that uses it, so use this variable to no execute instead */
    skip?: boolean
    /** the source of where hook was called that can be passed to logger*/
    source: string
}

/** useApolloQuery: makes a query to the provided apollo client and manages
 *  helper states during/after making the query
 * @param { UseApolloQueryOptions } options
 * @returns { UseApolloQueryResponse } */
export function useApolloQuery({
    client,
    variables,
    query,
    mutation,
    skip = false,
    source,
}: UseApolloQueryOptions): UseApolloQueryResponse {
    const [result, setResult] = useState<
        | ApolloQueryResult<any>
        | FetchResult<any, Record<string, any>, Record<string, any>>
        | undefined
    >(undefined)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<CustomApiError[] | null>(null)
    const [newFetch, setNewFetch] = useState<boolean>(true)
    const navigate = useNavigate()

    const userContext = datadogLogs.getGlobalContext()
    //redo fetch if variable values changed
    useEffect(() => {
        if (skip || (!query && !mutation)) {
            setLoading(false)
            return
        }
        const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
        if (!newFetch) return

        /** Check user-session needs refreshing on every GraphQl api call*/

        handleUserSession.then(() => {
            let request

            if (query) {
                request = client.query({
                    query: query,
                    ...(variables && { variables: variables }),
                    errorPolicy: 'none',
                })
            }

            if (mutation) {
                request = client.mutate({
                    mutation: mutation,
                    ...(variables && { variables: variables }),
                    errorPolicy: 'none',
                })
            }

            if (request)
                request
                    .then((response) => {
                        let dataString = 'undefined'
                        if (response) {
                            setResult(response)
                            dataString =
                                JSON.stringify(response?.data).length > 225280 // Apollo char limit
                                    ? 'Data too large to show'
                                    : JSON.stringify(response?.data)
                        }
                        setLoading(false)
                        if (variables) {
                            datadogLogs.logger.info(
                                `source: ${source}, GraphQL-variables: ${JSON.stringify(
                                    variables
                                )}, response-data: ${dataString}`,
                                { userContext }
                            )
                        } else {
                            datadogLogs.logger.info(
                                `source: ${source}, GraphQL-response-data: ${dataString}`,
                                { userContext }
                            )
                        }
                    })
                    .catch((error) => {
                        /** getGraphqlErrorMessage function is handling sending the errors to the DataDog - no need to call it separately */
                        const errorObjectThing = getGraphqlErrorMessage(error, source)
                        setError(errorObjectThing)
                        setLoading(false)
                    })
            setNewFetch(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFetch]) /** do not add any other dependencies */

    const refetch = (): void => {
        /** Reset all the hook states because it's a fresh api call, error and loading and data need clearing. */
        setLoading(true)
        setResult(undefined)
        setError(null)
        setNewFetch(true)
    }

    return { result, loading, error, refetch }
}
