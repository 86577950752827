import { PassengerConfigurationDataAsQueryVar } from '../../hooks/usePassengersInfo'

export const buildApiVariables = ({
    passengerConfigurationDataAsQueryVar = [],
    queryParams,
}: {
    passengerConfigurationDataAsQueryVar?: PassengerConfigurationDataAsQueryVar[]
    queryParams: URLSearchParams
}): Record<string, any> => {
    const queryVariables: Record<string, any> = {}
    queryParams.forEach((value, key) => {
        queryVariables[key] = value
    })

    if (passengerConfigurationDataAsQueryVar.length > 0) {
        queryVariables.passengers = { passengers: passengerConfigurationDataAsQueryVar }
    }
    return queryVariables
}
