import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import classnames from 'classnames'

import Button from 'components/basics/Button/Button'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Select from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'

import callApi, { CallApiOptions } from 'services/callApi/callApi.service'
import { PaymentChoices, OrderContent } from 'api-data-models/order/OrderContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { luhnAlgorithm } from 'utils/luhn-algorithm'
import {
    HTTP_METHODS,
    PAYMENT_OPTIONS,
    PRODUCT_TYPES,
    REGEX,
    VITALLY_EVENTS,
} from 'utils/constants'
import { insertDecimal2CharsFromEnd } from 'utils/string-helpers'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { orderServiceUrls } from 'utils/order-service-urls'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'

import styles from './PaymentForm.module.scss'
import allContent from 'content/content'

const content = allContent.order.orderPage.paymentForm
const contentForm = content.cardForm

type CreditCardInput = {
    creditCardNumber: string
    creditCardExpireMonth: string
    creditCardExpireYear: string
    creditCardCvv: string
    creditCardHolderName: string
    creditCardType: string
}

type APIOrderItemBookVariables = {
    orderId: string
    itemId: string
    creditCard?: CreditCardInput
    paymentAmount: number
    paymentCurrencyCode: string
    paymentOption: PaymentOptionType
}

type PaymentFormData = {
    creditCardNumber?: string
    creditCardExpireMonth?: string
    creditCardExpireYear?: string
    creditCardCvv?: string
    creditCardType?: string
    creditCardHolderName?: string
}

type CardNameTypes = 'VI' | 'MC'

type PaymentFormValidationData = {
    creditCardNumber: string
    creditCardExpireMonth: string
    creditCardExpireYear: string
    creditCardCvv: string
    creditCardType: string
    creditCardHolderName: string
}

export const validatePaymentForm = ({
    formData,
    setCardExpired,
    setCardTypeError,
    setCardValidationError,
}: {
    formData: PaymentFormValidationData
    setCardExpired: Dispatch<SetStateAction<boolean>>
    setCardTypeError: Dispatch<SetStateAction<boolean>>
    setCardValidationError: Dispatch<SetStateAction<boolean>>
}): boolean => {
    const dateToday = new Date()
    // exact date of when credit card expires
    const creditCardExpiryDate = new Date(
        2000 + parseInt(formData.creditCardExpireYear), // will start failing in 2100, should we take care of that? If so it would be best to pass full 4-digit year
        parseInt(formData.creditCardExpireMonth)
    )
    let isValid = true

    // clear custom error fields
    setCardExpired(false)
    setCardTypeError(false)
    setCardValidationError(false)

    // invalid credit card number
    if (!luhnAlgorithm(formData.creditCardNumber)) {
        setCardValidationError(true)
        isValid = false
    }
    // invalid credit card expiry date
    if (dateToday.getTime() >= creditCardExpiryDate.getTime()) {
        setCardExpired(true)
        isValid = false
    }

    // invalid credit card type selected
    const firstDigit = Array.from(formData.creditCardNumber)[0]
    const cardTypeMap = {
        AX: '3',
        VI: '4',
        MC: '5',
        DS: '6',
    }

    if (
        !(formData.creditCardType in cardTypeMap) ||
        firstDigit !== cardTypeMap[formData.creditCardType as CardNameTypes]
    ) {
        setCardTypeError(true)
        isValid = false
    }

    return isValid
}

type PaymentFormProps = {
    orderId: string
    itemId: string
    orderState: OrderContent
    paymentChoices: PaymentChoices
    currencySymbol: string
    currencyCode: string
    backToOrder(): void
}

const PaymentForm = ({
    orderId,
    itemId,
    orderState,
    paymentChoices,
    currencySymbol,
    currencyCode,
    backToOrder,
}: PaymentFormProps): JSX.Element => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const cardTypeOptions = [
        { value: '', text: 'Please select.. ' },
        { value: 'VI', text: 'Visa' },
        { value: 'MC', text: 'Mastercard' },
    ]
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [cardExpired, setCardExpired] = useState(false)
    const [cardTypeError, setCardTypeError] = useState(false)
    const [cardValidationError, setCardValidationError] = useState(false)
    const [itemBookApiError, setItemBookApiError] = useState<CustomApiError[] | null>(null)
    const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentOptionType | null>(null)
    const [paymentAmount, setPaymentAmount] = useState<number | null>(null)

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            creditCardNumber: '',
            creditCardExpireMonth: '',
            creditCardExpireYear: '',
            creditCardCvv: '',
            creditCardType: '',
            creditCardHolderName: '',
        },
    })
    const userContext = datadogLogs.getGlobalContext()

    const handleSelectCardType = (e: any): void => {
        setValue('creditCardType', e.target.value)
    }

    function handlePaymentOptionSelect(paymentOption: PaymentOptionType, amount: number): void {
        setSelectedPaymentType(paymentOption)
        setPaymentAmount(amount)
    }

    const depositData = paymentChoices.deposit ? paymentChoices.deposit : null
    const fullPaymentData = paymentChoices.full ? paymentChoices.full : null
    const onHoldData = paymentChoices.onhold ? paymentChoices.onhold : null

    const depositPaymentButton = depositData && (
        <button
            className={classnames(styles['payment-option'], {
                [styles['payment-option--chosen']]: selectedPaymentType === PAYMENT_OPTIONS.DEPOSIT,
            })}
            onClick={(): void => {
                handlePaymentOptionSelect(PAYMENT_OPTIONS.DEPOSIT, depositData.amountCent)
            }}
            disabled={isSubmitting}
        >
            <div>
                <Text weight='bold' size='M' color={isSubmitting ? 'disabled' : 'dark-gray'}>
                    {content.depositButton}
                </Text>
            </div>
            <Text size='S' weight='bold' color={isSubmitting ? 'disabled' : 'dark-gray'}>
                {depositData.currencySymbol}
                {depositData.amount}
            </Text>
        </button>
    )

    const fullPaymentButton = fullPaymentData && (
        <button
            disabled={isSubmitting}
            className={classnames(styles['payment-option'], {
                [styles['payment-option--chosen']]: selectedPaymentType === PAYMENT_OPTIONS.FULL,
            })}
            onClick={(): void =>
                handlePaymentOptionSelect(PAYMENT_OPTIONS.FULL, fullPaymentData.amountCent)
            }
        >
            <Text weight='bold' size='M' color={isSubmitting ? 'disabled' : 'dark-gray'}>
                {content.fullPaymentButton}
            </Text>
            <Text size='S' weight='bold' color={isSubmitting ? 'disabled' : 'dark-gray'}>
                {fullPaymentData.currencySymbol}
                {fullPaymentData.amount}
            </Text>
        </button>
    )

    const onHoldPaymentButton = onHoldData && (
        <button
            className={classnames(styles['payment-option'], {
                [styles['payment-option--chosen']]: selectedPaymentType === PAYMENT_OPTIONS.ONHOLD,
            })}
            onClick={(): void =>
                handlePaymentOptionSelect(PAYMENT_OPTIONS.ONHOLD, onHoldData.amountCent)
            }
            disabled={isSubmitting}
        >
            <Text weight='bold' size='M' color={isSubmitting ? 'disabled' : 'dark-gray'}>
                {content.onHoldPaymentButton}
            </Text>
            <Text size='S' weight='bold' color={isSubmitting ? 'disabled' : 'dark-gray'}>
                {onHoldData.currencySymbol}
                {onHoldData.amount}
            </Text>
        </button>
    )

    const disabledForm =
        isSubmitting ||
        selectedPaymentType === null ||
        selectedPaymentType === PAYMENT_OPTIONS.ONHOLD

    const formFieldsRequired = selectedPaymentType !== PAYMENT_OPTIONS.ONHOLD

    const formHidden =
        selectedPaymentType === null || selectedPaymentType === PAYMENT_OPTIONS.ONHOLD

    return (
        <div className={styles.container}>
            <div className={styles['payment-section']}>
                <div>
                    <Text>{content.paymentOptions.heading1a}</Text>
                    <Text weight='bold'>{content.paymentOptions.heading1b}</Text>
                    <Text>{content.paymentOptions.heading1c}</Text>
                    <Text weight='bold'>
                        {content.paymentOptions.heading1d}
                        <span className={styles['red-asterisk']}>*</span>
                    </Text>
                </div>
                <div className={styles['payment-options-list']}>
                    {depositPaymentButton}
                    {fullPaymentButton}
                    {onHoldPaymentButton}
                </div>
                <div className={styles['payment-form-wrapper']}>
                    <form
                        className={styles['payment-form__form']}
                        id='payment-form'
                        onSubmit={handleSubmit(
                            async ({
                                creditCardNumber,
                                creditCardExpireMonth,
                                creditCardExpireYear,
                                creditCardCvv,
                                creditCardType,
                                creditCardHolderName,
                            }: PaymentFormData) => {
                                const formData: PaymentFormData = {
                                    creditCardNumber: creditCardNumber,
                                    creditCardExpireMonth: creditCardExpireMonth,
                                    creditCardExpireYear: creditCardExpireYear,
                                    creditCardCvv: creditCardCvv,
                                    creditCardType: creditCardType,
                                    creditCardHolderName: creditCardHolderName,
                                }
                                setIsSubmitting(true)
                                setItemBookApiError(null)
                                const isValid =
                                    selectedPaymentType === PAYMENT_OPTIONS.ONHOLD
                                        ? true
                                        : validatePaymentForm({
                                              formData: formData as PaymentFormValidationData,
                                              setCardExpired,
                                              setCardTypeError,
                                              setCardValidationError,
                                          })
                                if (!isValid) {
                                    setIsSubmitting(false)
                                    return
                                }

                                const orderItemBookVariables: APIOrderItemBookVariables = {
                                    paymentAmount: paymentAmount ?? 0,
                                    paymentCurrencyCode: currencyCode,
                                    paymentOption: selectedPaymentType as PaymentOptionType,
                                    orderId: orderId,
                                    itemId: itemId,
                                    ...(selectedPaymentType !== PAYMENT_OPTIONS.ONHOLD && {
                                        creditCard: {
                                            creditCardNumber: creditCardNumber as string,
                                            creditCardExpireMonth: creditCardExpireMonth as string,
                                            creditCardExpireYear: creditCardExpireYear as string,
                                            creditCardCvv: creditCardCvv as string,
                                            creditCardType: creditCardType as string,
                                            creditCardHolderName: creditCardHolderName as string,
                                        },
                                    }),
                                }
                                const handleUserSession =
                                    checkAndPerformUserSessionRefreshIfNeeded(navigate)
                                handleUserSession.then(() => {
                                    const {
                                        orderId,
                                        itemId,
                                        paymentAmount,
                                        paymentCurrencyCode,
                                        paymentOption,
                                    } = orderItemBookVariables
                                    // convert paymentOption to query parameter than can be passed to url
                                    const orderItemBookUrl = orderServiceUrls.orderItemBook(
                                        orderId,
                                        itemId,
                                        paymentOption,
                                        paymentAmount,
                                        paymentCurrencyCode
                                    )
                                    const body = {
                                        credit_card_number: creditCardNumber,
                                        credit_card_expire_month: creditCardExpireMonth,
                                        credit_card_expire_year: creditCardExpireYear,
                                        credit_card_cvv: creditCardCvv,
                                        credit_card_holder_name: creditCardHolderName,
                                        credit_card_type: creditCardType,
                                    }
                                    const callApiParams: CallApiOptions = {
                                        url: orderItemBookUrl,
                                        method: HTTP_METHODS.POST,
                                    }
                                    if (paymentOption !== PAYMENT_OPTIONS.ONHOLD) {
                                        callApiParams.variables = body
                                    }
                                    callApi(callApiParams)
                                        .then((response) => {
                                            return response.json()
                                        })
                                        .then((data) => {
                                            setItemBookApiError(null)
                                            let dataString = 'undefined'
                                            if (data) {
                                                dataString =
                                                    // DataDog char limit
                                                    JSON.stringify(data).length > 225280
                                                        ? 'Data too large to show'
                                                        : JSON.stringify(data)
                                            }
                                            CustomerSuccess.track({
                                                eventName: VITALLY_EVENTS.BOOK_SUCCESS,
                                                properties: {
                                                    productType: PRODUCT_TYPES.CRUISE,
                                                },
                                            })
                                            datadogLogs.logger.info(
                                                `source: OrderItemBook, paymentOption: ${orderItemBookVariables.paymentOption}, paymentAmount: ${orderItemBookVariables.paymentAmount}, orderId: ${orderItemBookVariables.orderId}, orderItemId: ${orderItemBookVariables.itemId}, Fetch-response-data: ${dataString}`,
                                                { userContext }
                                            )
                                            if (data?.items[0]?.supplier_order_reference) {
                                                // TODO: Slightly concerned that any issue with the booking that doesn't actually have an error error will be trigger navigate to confirmation, but the confirmation page isn't designed to cope with errors or lack of supplierOrderItemReference...just advisory warnings. Speak to BE
                                                navigate(
                                                    `${ROUTES.ORDER_CONFIRMATION}/?orderId=${orderId}`,
                                                    {
                                                        state: {
                                                            existingOrder: orderState,
                                                            orderItemBookResponse: data,
                                                        },
                                                    }
                                                )
                                            }
                                        })
                                        .catch((error) => {
                                            setIsSubmitting(false)
                                            setItemBookApiError(
                                                getRestErrorMessage({
                                                    errors: error,
                                                    source: `ORDER_ITEM_BOOK`,
                                                })
                                            )
                                            CustomerSuccess.track({
                                                eventName: VITALLY_EVENTS.BOOK_FAIL,
                                            })
                                            datadogLogs.logger.error(
                                                `source: OrderItemBook, paymentOption: ${orderItemBookVariables.paymentOption}, paymentAmount: ${orderItemBookVariables.paymentAmount}`,
                                                { userContext },
                                                error
                                            )
                                        })
                                })
                            }
                        )}
                    >
                        {!formHidden && paymentAmount && (
                            <>
                                <div className={styles['payment-form__form-header']}>
                                    <Heading heading='2' size='3' onDarkBackground={true}>
                                        {contentForm.heading}
                                    </Heading>
                                </div>
                                <fieldset
                                    className={styles['payment-form__form-content']}
                                    disabled={disabledForm}
                                >
                                    <legend className={styles['display-contents']}>
                                        <Text size='S'>
                                            {selectedPaymentType === PAYMENT_OPTIONS.DEPOSIT &&
                                                contentForm.infoDepositPay}
                                            {selectedPaymentType === PAYMENT_OPTIONS.FULL &&
                                                contentForm.infoFullPay}
                                        </Text>
                                        <Text size='S' weight='bold'>
                                            {currencySymbol}
                                            {insertDecimal2CharsFromEnd(paymentAmount)}
                                        </Text>
                                    </legend>
                                    <Controller
                                        control={control}
                                        name='creditCardType'
                                        render={({ field: { value } }): React.ReactElement => (
                                            <div>
                                                <LabelledInput
                                                    disabled={disabledForm}
                                                    htmlFor='creditCardType'
                                                    label={contentForm.cardTypeInput}
                                                    required={formFieldsRequired}
                                                    isErrored={cardTypeError}
                                                >
                                                    <Select
                                                        value={value}
                                                        options={cardTypeOptions}
                                                        onChange={handleSelectCardType}
                                                    />
                                                </LabelledInput>
                                                <FieldError
                                                    inputId='creditCardType'
                                                    showError={cardTypeError}
                                                    errorMessage={content.errors.cardTypeError}
                                                />
                                            </div>
                                        )}
                                    />
                                    <div className={styles['inputs-group--horizontal']}>
                                        <Controller
                                            control={control}
                                            name='creditCardNumber'
                                            rules={{
                                                required: formFieldsRequired,
                                                minLength: 15,
                                                maxLength: 16,
                                                pattern: REGEX.DIGITS_ONLY,
                                            }}
                                            render={({
                                                field: { value, ref },
                                            }): React.ReactElement => (
                                                <div className={styles['card-name-and-number']}>
                                                    <LabelledInput
                                                        disabled={disabledForm}
                                                        htmlFor={'creditCardNumber'}
                                                        label={contentForm.cardNumberInput}
                                                        required={formFieldsRequired}
                                                        isErrored={
                                                            !!errors.creditCardNumber ||
                                                            cardValidationError
                                                        }
                                                        ref={ref}
                                                    >
                                                        <TextInput
                                                            inputMode='numeric'
                                                            regExp={REGEX.CREDIT_CARD_DIGITS}
                                                            value={value}
                                                            onChangeCallback={(value): void =>
                                                                setValue('creditCardNumber', value)
                                                            }
                                                        />
                                                    </LabelledInput>
                                                    <FieldError
                                                        inputId='creditCardNumber'
                                                        showError={!!errors.creditCardNumber}
                                                        errorMessage={
                                                            content.errors.cardNumberError
                                                        }
                                                    />
                                                    <FieldError
                                                        inputId='creditCardNumber'
                                                        showError={cardValidationError}
                                                        errorMessage={
                                                            content.errors.cardValidationError
                                                        }
                                                    />
                                                </div>
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name='creditCardHolderName'
                                            rules={{
                                                required: formFieldsRequired,
                                                minLength: 1,
                                                maxLength: 60,
                                                pattern: REGEX.NAME,
                                            }}
                                            render={({
                                                field: { value, ref },
                                            }): React.ReactElement => (
                                                <div className={styles['card-name-and-number']}>
                                                    <LabelledInput
                                                        disabled={disabledForm}
                                                        htmlFor='creditCardHolderName'
                                                        label={contentForm.nameOnCardInput}
                                                        required={formFieldsRequired}
                                                        isErrored={!!errors.creditCardHolderName}
                                                        ref={ref}
                                                    >
                                                        <TextInput
                                                            inputMode='text'
                                                            value={value}
                                                            onChangeCallback={(value): void =>
                                                                setValue(
                                                                    'creditCardHolderName',
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    </LabelledInput>
                                                    <FieldError
                                                        inputId='creditCardHolderName'
                                                        showError={!!errors.creditCardHolderName}
                                                        errorMessage={
                                                            content.errors.cardHolderNameError
                                                        }
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles['inputs-group--horizontal'] +
                                            ' ' +
                                            styles['double-gap']
                                        }
                                    >
                                        <fieldset
                                            disabled={disabledForm}
                                            id='expiry-date'
                                            className={styles['inputs-group--vertical']}
                                        >
                                            <legend>
                                                <Text
                                                    size='S'
                                                    weight='bold'
                                                    color={disabledForm ? 'disabled' : 'dark-gray'}
                                                >
                                                    {contentForm.expiryDateInput}
                                                </Text>
                                            </legend>
                                            <div className={styles['inputs-group--horizontal']}>
                                                <Controller
                                                    control={control}
                                                    name='creditCardExpireMonth'
                                                    rules={{
                                                        required: formFieldsRequired,
                                                        minLength: 1,
                                                        maxLength: 2,
                                                        pattern: REGEX.MONTH,
                                                    }}
                                                    render={({
                                                        field: { value, ref },
                                                    }): React.ReactElement => (
                                                        <div
                                                            className={
                                                                styles['input-small-container']
                                                            }
                                                        >
                                                            <LabelledInput
                                                                disabled={disabledForm}
                                                                htmlFor='creditCardExpireMonth'
                                                                label={contentForm.expiryMonthInput}
                                                                required={formFieldsRequired}
                                                                ref={ref}
                                                                isErrored={
                                                                    !!errors.creditCardExpireMonth ||
                                                                    cardExpired
                                                                }
                                                            >
                                                                <TextInput
                                                                    inputMode='numeric'
                                                                    regExp={REGEX.DIGITS_0_to_2}
                                                                    value={value}
                                                                    placeholder='MM'
                                                                    onChangeCallback={(
                                                                        value
                                                                    ): void =>
                                                                        setValue(
                                                                            'creditCardExpireMonth',
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            </LabelledInput>
                                                            <FieldError
                                                                inputId='creditCardExpireMonth'
                                                                showError={
                                                                    !!errors.creditCardExpireMonth
                                                                }
                                                                errorMessage={
                                                                    content.errors
                                                                        .cardExpireMonthError
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <Controller
                                                    control={control}
                                                    name='creditCardExpireYear'
                                                    rules={{
                                                        required: formFieldsRequired,
                                                        minLength: 2,
                                                        maxLength: 2,
                                                        pattern: REGEX.DIGITS_ONLY,
                                                    }}
                                                    render={({
                                                        field: { value, ref },
                                                    }): React.ReactElement => (
                                                        <div
                                                            className={
                                                                styles['input-small-container']
                                                            }
                                                        >
                                                            <LabelledInput
                                                                disabled={disabledForm}
                                                                htmlFor='creditCardExpireYear'
                                                                label={contentForm.expiryYearInput}
                                                                required={formFieldsRequired}
                                                                ref={ref}
                                                                isErrored={
                                                                    !!errors.creditCardExpireYear ||
                                                                    cardExpired
                                                                }
                                                            >
                                                                <TextInput
                                                                    inputMode='numeric'
                                                                    value={value}
                                                                    regExp={REGEX.DIGITS_0_to_2}
                                                                    placeholder='YY'
                                                                    onChangeCallback={(
                                                                        value
                                                                    ): void =>
                                                                        setValue(
                                                                            'creditCardExpireYear',
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            </LabelledInput>
                                                            <FieldError
                                                                inputId='creditCardExpireYear'
                                                                showError={
                                                                    !!errors.creditCardExpireYear
                                                                }
                                                                errorMessage={
                                                                    content.errors
                                                                        .cardExpireYearError
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <FieldError
                                                inputId='expiry-date'
                                                showError={cardExpired}
                                                errorMessage={content.errors.cardExpiredError}
                                            />
                                        </fieldset>
                                        <Controller
                                            control={control}
                                            name='creditCardCvv'
                                            rules={{
                                                required: formFieldsRequired,
                                                minLength: 3,
                                                maxLength: 4,
                                                pattern: REGEX.DIGITS_ONLY,
                                            }}
                                            render={({
                                                field: { value, ref },
                                            }): React.ReactElement => (
                                                <div
                                                    className={
                                                        styles['input-small-container'] +
                                                        ' ' +
                                                        styles['align-below-label']
                                                    }
                                                >
                                                    <LabelledInput
                                                        disabled={disabledForm}
                                                        htmlFor='creditCardCvv'
                                                        label={contentForm.cvcInput}
                                                        required={formFieldsRequired}
                                                        isErrored={!!errors.creditCardCvv}
                                                        ref={ref}
                                                    >
                                                        <TextInput
                                                            inputMode='numeric'
                                                            regExp={REGEX.DIGITS_ONLY}
                                                            value={value}
                                                            onChangeCallback={(value): void =>
                                                                setValue('creditCardCvv', value)
                                                            }
                                                        />
                                                    </LabelledInput>
                                                    <FieldError
                                                        inputId='creditCardCvv'
                                                        showError={!!errors.creditCardCvv}
                                                        errorMessage={content.errors.cardCvvError}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </fieldset>
                            </>
                        )}
                    </form>
                </div>
                <div className={styles['buttons-wrapper']}>
                    <Button
                        text={content.backButton}
                        flavour='tertiary'
                        onClick={backToOrder}
                        iconName='ChevronLeft'
                        type='button'
                    />
                    <Button
                        text={isSubmitting ? content.submittingPayment : content.submitPayment}
                        type='submit'
                        form='payment-form'
                        disabled={isSubmitting || selectedPaymentType === null}
                        showSpinner={isSubmitting}
                    />
                </div>
            </div>
            {itemBookApiError && <ErrorList errorsList={itemBookApiError} source='PaymentForm' />}
        </div>
    )
}

export default PaymentForm
