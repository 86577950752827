/** simple checksum formula used to validate a variety of
 * identification numbers, such as credit card numbers,
 * IMEI numbers, National Provider Identifier numbers in
 * the United States, Canadian social insurance numbers,
 * Israeli ID numbers, South African ID numbers,
 * Swedish national identification numbers,
 * Swedish Corporate Identity Numbers (OrgNr),
 * Greek Social Security Numbers (ΑΜΚΑ),
 * SIM card numbers, European patent application number and
 * survey codes appearing on McDonald's, Taco Bell, and
 * Tractor Supply Co. receipts. */
export const luhnAlgorithm = (cardNumber: string): boolean => {
    if (cardNumber === undefined) return false
    if (cardNumber.length === 0) return false

    const numberOfDigits = cardNumber.length

    let cardNumberSum = 0
    let isZeroDigit = false
    for (let i = numberOfDigits - 1; i >= 0; i--) {
        let d = cardNumber[i].charCodeAt(0) - '0'.charCodeAt(0)

        if (isZeroDigit === true) d = d * 2

        // We add two digits to handle
        // cases that make two digits
        // after doubling
        cardNumberSum += Math.floor(d / 10) || 10
        cardNumberSum += d % 10

        isZeroDigit = !isZeroDigit
    }
    return cardNumberSum % 10 === 0
}
