import React, { SetStateAction, useState } from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'

import LoginForm from 'components/sections/cognito/LoginForm/LoginForm'
import NewPasswordForm from 'components/sections/cognito/NewPasswordForm/NewPasswordForm'

type LoginLayoutProps = {
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ setUserData }) => {
    const [newPassCognitoUser, setNewPassCognitoUser] = useState<CognitoUser | undefined>()

    return (
        <div className='general-container'>
            {!newPassCognitoUser ? (
                <LoginForm
                    setUserData={setUserData}
                    setNewPassCognitoUser={setNewPassCognitoUser}
                />
            ) : (
                <NewPasswordForm
                    setUserData={setUserData}
                    newPassCognitoUser={newPassCognitoUser}
                    setNewPassCognitoUser={setNewPassCognitoUser}
                />
            )}
        </div>
    )
}
export default LoginLayout
