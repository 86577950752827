import React, { useContext, Dispatch, SetStateAction } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import classnames from 'classnames'

import Button from 'components/basics/Button/Button'
import DescriptionList from 'components/basics/DescriptionList/DescriptionList'
import DescriptionListItemKey from 'components/basics/DescriptionList/DescriptionListItemKey'
import DescriptionListItemValue from 'components/basics/DescriptionList/DescriptionListItemValue'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import Tooltip from 'components/basics/Tooltip/Tooltip'

import { ROUTES } from '../../../../app/AppRoutes'
import { FeatureToggleContext } from 'App'
import { CURRENCY_CODES, SupplierCodes } from 'utils/constants'
import { calculateHourDifference } from 'utils/date-helpers'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { Pricing, CabinTypePricing } from 'api-data-models/CruisesContentModel'

import styles from './PricingSection.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage.resultsListItem.pricingSection
const cabinCategories = allContent.cruise.resultsPage.resultsListItem.cabinCategory
const alternativeSailingDatesContent = allContent.cruise.sharedSections.alternativeSailingDates

export function shouldDisableSelectButton(
    supplierCode: SupplierCodes,
    context: Record<string, boolean>
): boolean {
    return (
        (supplierCode === 'VKG' && !context.TURN_ON_RESULTS_PRICE_BUTTON_VKG) ||
        (supplierCode === 'VVC' && !context.TURN_ON_RESULTS_PRICE_BUTTON_VVC) ||
        (supplierCode === 'DCL' && !context.TURN_ON_RESULTS_PRICE_BUTTON_DCL) ||
        (supplierCode === 'AZA' && !context.TURN_ON_RESULTS_PRICE_BUTTON_AZA) ||
        (supplierCode === 'EXP' && !context.TURN_ON_RESULTS_PRICE_BUTTON_EXP) ||
        (supplierCode === 'SSC' && !context.TURN_ON_RESULTS_PRICE_BUTTON_SSC) ||
        (supplierCode === 'AMA' && !context.TURN_ON_RESULTS_PRICE_BUTTON_AMA) ||
        (supplierCode === 'RSSC' && !context.TURN_ON_RESULTS_PRICE_BUTTON_RSSC) ||
        (supplierCode === 'OCI' && !context.TURN_ON_RESULTS_PRICE_BUTTON_OCI)
    )
}

type PricingProps = {
    /** pricing data from cruise */
    pricing: Pricing
    /** unique key on each cruise used for navigation to details page */
    cruiseId: string
    /** the supplier code, used to populate query params on navigate */
    supplierCode: string
    /** are alternative sailing available for this cruise */
    hasAlternativeSailings: boolean
    /** the embarkDate to validate if it more than 48 hours to it */
    embarkDate: string
    /** alternative sailing dates section open state */
    alternativeSailDatesOpen: boolean
    /** set alternative sailing dates section open stae*/
    setAlternativeSailDatesOpen: Dispatch<SetStateAction<boolean>>
    /** used to not show cruise select button */
    isStarterTier: boolean
}

const PricingSection: React.FC<PricingProps> = ({
    pricing,
    cruiseId,
    supplierCode,
    hasAlternativeSailings,
    embarkDate,
    alternativeSailDatesOpen,
    setAlternativeSailDatesOpen,
    isStarterTier,
}): JSX.Element => {
    const featureToggles = useContext(FeatureToggleContext)
    const { userEmail } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    const noPrices = pricing.cabinTypePricing.length === 0
    const inAvailableTimeRange = calculateHourDifference(embarkDate) > 48
    const selectButtonText = noPrices ? content.selectButton.call : content.selectButton.select
    const pricingList = []
    const disableSelectButton = shouldDisableSelectButton(
        supplierCode as SupplierCodes,
        featureToggles
    )
    const useAltSailDates = featureToggles.TURN_ON_ALT_SAIL_DATES || isTestEmailDomain(userEmail)

    const showButtonToNextPage =
        featureToggles.TURN_ON_RESULTS_PRICE_BUTTON &&
        !isStarterTier &&
        !noPrices &&
        inAvailableTimeRange

    if (!noPrices) {
        pricing.cabinTypePricing.forEach((cabinTypePricing: CabinTypePricing) => {
            if (cabinTypePricing.available)
                pricingList.push(
                    <div key={cabinTypePricing.cabinType} className={styles['pricing-item']}>
                        <DescriptionListItemKey>
                            <Text color='white'>
                                {cabinCategories[`${cabinTypePricing.cabinType}`]}
                            </Text>
                        </DescriptionListItemKey>
                        <DescriptionListItemValue className={styles.fares}>
                            <Text weight='bold' color='white'>
                                {CURRENCY_CODES[`${pricing.currencyCode}`]}
                            </Text>
                            <Text weight='bold' color='white'>
                                {`${cabinTypePricing.fareStringRounded}`}{' '}
                            </Text>{' '}
                            <Text color='white' weight='bold' size='XS'>
                                {pricing.currencyCode}
                            </Text>
                        </DescriptionListItemValue>
                    </div>
                )
        })
    } else {
        pricingList.push(
            <p key='call-to-book' className={styles['center-align']}>
                <Text color='white' weight='bold'>
                    {content.callToBook}
                </Text>
            </p>
        )
    }

    const selectButton =
        disableSelectButton || noPrices ? (
            <Button
                text={selectButtonText}
                type='button'
                disabled={disableSelectButton || noPrices}
                className={styles['select-button']}
            />
        ) : (
            <Link
                noUnderLine={true}
                to={`${ROUTES.CRUISE_SAILING}/?cruiseId=${cruiseId}&supplierCode=${supplierCode}`}
            >
                <Button text={selectButtonText} type='button' className={styles['select-button']} />
            </Link>
        )

    const containerClassNames = classnames(styles.container, {
        [styles['no-bottom-border-radius']]: alternativeSailDatesOpen,
    })

    return (
        <div className={containerClassNames}>
            <DescriptionList className={styles['pricing-items']}>{pricingList}</DescriptionList>
            {!inAvailableTimeRange ? (
                <p key='call-to-book' className={styles['center-align']}>
                    <Text color='white' weight='bold'>
                        {content.callToBookWithin48hours}
                    </Text>
                </p>
            ) : null}
            <div className={styles['button-container']}>
                {showButtonToNextPage &&
                    (disableSelectButton ? (
                        <Tooltip id='selectButtonTooltip' content={content.selectButton.tooltip}>
                            {selectButton}
                        </Tooltip>
                    ) : (
                        selectButton
                    ))}
                {useAltSailDates && hasAlternativeSailings && (
                    <Button
                        text={
                            alternativeSailDatesOpen
                                ? alternativeSailingDatesContent.buttons.hideDates
                                : alternativeSailingDatesContent.buttons.showDates
                        }
                        type='button'
                        flavour='tertiary'
                        onDarkBackground
                        onClick={(): void => setAlternativeSailDatesOpen(!alternativeSailDatesOpen)}
                        className={styles['alternative-sailings-button']}
                    />
                )}
            </div>
        </div>
    )
}

export default PricingSection
