import {
    APIPassengerFormData,
    PHONE_TYPE,
} from '../sections/order/TravellerAddForm/TravellerAddForm'
import { OrderApiTraveller } from 'api-data-models/order/order-api-types'
import { OrderContent } from 'api-data-models/order/OrderContentModel'

export const ORDER_ACTIONS = {
    UPDATE_ORDER_OPTIONAL_EXTRAS: 'orderOptionalExtrasSet',
    UPDATE_CONTACT_DETAILS: 'updateContactDetails',
    UPDATE_TRAVELLER_DETAILS: 'updateTravellerDetails',
    ADD_TRAVELLER_DETAILS: 'addTravellerDetails',
    ASSIGN_TRAVELLERS: 'assignTravellers',
    ASSIGN_LEAD_TRAVELLER: 'assignLeadTraveller',
    ORDER_ITEM_BOOK: 'orderItemBook',
}

export type Action_Keys = keyof typeof ORDER_ACTIONS
export interface OrderDispatchProps {
    payload: any
    type: (typeof ORDER_ACTIONS)[Action_Keys]
}

export const restructureTravellerFormDataToApiResponse = (
    data: APIPassengerFormData
): OrderApiTraveller => {
    return {
        travellerNumber: data.traveller_number,
        accessibilityNeeds: data.accessibility_needs,
        dateOfBirth: data.date_of_birth,
        ...(data.emergency_contact && {
            emergencyContact: {
                firstName: data.emergency_contact.first_name,
                lastName: data.emergency_contact.last_name,
                phoneNumber: data.emergency_contact.phone_number,
                email: data.emergency_contact.email,
            },
        }),
        firstName: data.first_name,
        middleName: data.middle_name ?? '',
        lastName: data.last_name,
        nationality: data.nationality ?? '',
        passport: {
            number: data.passport?.number ?? '',
            startDate: data.passport?.start_date ?? '',
            endDate: data.passport?.end_date ?? '',
        },
        title: data.title,
    }
}

export const orderReducer = (
    orderState: OrderContent,
    action: OrderDispatchProps
): OrderContent => {
    switch (action.type) {
        case ORDER_ACTIONS.UPDATE_CONTACT_DETAILS:
            const updatedNewContactDetails = {
                title: action.payload.title,
                firstName: action.payload.firstName,
                middleName: action.payload.middleName ?? '',
                lastName: action.payload.lastName,
                phoneNumber: {
                    type: PHONE_TYPE,
                    number: action.payload.phoneNumber,
                },
                email: action.payload.email,
                address: {
                    line1: action.payload.addressLine1,
                    line2: action.payload.addressLine2,
                    cityRegion: action.payload.cityRegion,
                    country: action.payload.country,
                    stateProvince: action.payload.stateProvince ?? '',
                    zipPostalCode: action.payload.zipPostalCode,
                },
            }
            return { ...orderState, contactDetails: updatedNewContactDetails }
        case ORDER_ACTIONS.UPDATE_TRAVELLER_DETAILS: // same code to run as ADD_TRAVELLER_DETAILS
        case ORDER_ACTIONS.ADD_TRAVELLER_DETAILS:
            orderState.travellers[action.payload.traveller_number] =
                restructureTravellerFormDataToApiResponse(action.payload)
            return { ...orderState }
        case ORDER_ACTIONS.ASSIGN_TRAVELLERS:
            return {
                ...orderState,
                orderItem: {
                    ...orderState.orderItem,
                    assignedTravellers: action.payload.travellerNumbers,
                },
            }
        case ORDER_ACTIONS.ASSIGN_LEAD_TRAVELLER:
            const newAssignedTravellers_ = orderState.orderItem?.assignedTravellers ?? []
            if (!newAssignedTravellers_.includes(action.payload.travellerNumber)) {
                newAssignedTravellers_.push(action.payload.travellerNumber)
            }
            return {
                ...orderState,
                orderItem: {
                    ...orderState.orderItem,
                    assignedTravellers: newAssignedTravellers_,
                    leadTraveller: action.payload.travellerNumber,
                },
            }
        case ORDER_ACTIONS.ORDER_ITEM_BOOK:
            return {
                ...orderState,
                orderItem: {
                    ...orderState.orderItem,
                    bookedAt: action.payload.booked_at,
                    orderItemStatus: action.payload.items[0].status,
                    orderItemPaymentOption: action.payload.items[0].payment_option,
                    supplierOrderItemReference: action.payload.items[0].supplier_order_reference,
                    advisory: action.payload.items[0].advisory ?? [],
                },
            }
        case ORDER_ACTIONS.UPDATE_ORDER_OPTIONAL_EXTRAS:
            return {
                ...orderState,
                orderItem: OrderContent.getOrderItem(action.payload.items[0]),
            }
        default:
            return { ...orderState }
    }
}
