import React, { FC, useState } from 'react'
import { Grid } from '@mui/material'
import { CruiseProduct } from 'api-data-models/order/OrderContentModel'
import styles from './OrderPreviewCard.module.scss'
import Heading from 'components/basics/Heading/Heading'
import Card from 'components/blocks/Card/Card'
import Button from 'components/basics/Button/Button'
import { getFormattedDate } from 'utils/date-helpers'
import { createStringFromList } from 'utils/string-helpers'
import allContent from 'content/content'
import Modal from 'components/blocks/Modal/Modal'
import PriceBreakdownView from 'components/sections/cruise/PriceBreakdownView/PriceBreakdownView'
import Text from 'components/basics/Text/Text'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import ItineraryString from 'components/blocks/ItineraryString/ItineraryString'
import Spacing from 'components/basics/Spacing/Spacing'

const content = allContent.order.orderPage.cruiseOrderItem

interface OrderPreviewCardProps {
    cruiseProduct: CruiseProduct
    numberOfPassengers: number
    supplierOrderItemReference: string | null
}

const OrderPreviewCard: FC<OrderPreviewCardProps> = ({
    cruiseProduct,
    numberOfPassengers,
    supplierOrderItemReference,
}) => {
    const [isPriceModalOpen, setIsPriceModalOpen] = useState<boolean>(false)
    const [clickedButtonId, setClickedButtonId] = useState('')

    const handleOpenModal = ({ buttonId }: { buttonId: string }): void => {
        setClickedButtonId(buttonId)
        setIsPriceModalOpen(true)
    }

    const embarkDateUS = getFormattedDate(cruiseProduct.embarkDate)
    const disembarkDateUS = getFormattedDate(cruiseProduct.disembarkDate)

    const summaryPrice = cruiseProduct.pricing
    const priceString = `${summaryPrice.currencySymbol}${summaryPrice.totalGrossPrice}`

    const cabinGradeInfo = createStringFromList([
        cruiseProduct.cabinGradeName,
        cruiseProduct.cabinGradeDescription,
        cruiseProduct.cabinGradeCode,
    ])
    const deckInfo = createStringFromList([cruiseProduct.deckLevel, cruiseProduct.deckName])
    const rateCodeInfo = createStringFromList([
        cruiseProduct.rateCodeName,
        cruiseProduct.rateCodeDescription,
        cruiseProduct.rateCode,
    ])
    const shipName = cruiseProduct.shipName
    const cruiseLine = cruiseProduct.lineName
    const itinerary = cruiseProduct.itinerary.portListContentFull

    return (
        <>
            <Modal
                isOpen={isPriceModalOpen}
                headerText={content.priceBreakDown.header}
                returnFocusId={clickedButtonId}
                setClosed={(): void => setIsPriceModalOpen(false)}
            >
                <PriceBreakdownView
                    commission={cruiseProduct.pricing.commission}
                    breakdownPerPassenger={cruiseProduct.breakdownPerPassenger}
                    currency={cruiseProduct.pricing.currencySymbol}
                    totalGrossPrice={cruiseProduct.pricing.totalGrossPrice}
                    cabinGradeCode={cruiseProduct.cabinGradeCode}
                    rateCode={cruiseProduct.rateCode}
                    cabinGradeDescription={cruiseProduct.cabinGradeDescription}
                    rateCodeDescription={cruiseProduct.rateCodeDescription}
                    priceProps={{
                        military: cruiseProduct.pricing?.rateCodeProps?.military,
                        residency: cruiseProduct.pricing?.rateCodeProps?.residency,
                        refundPolicy: cruiseProduct.pricing?.rateCodeProps?.refundPolicy,
                        onBoardCredits: cruiseProduct.pricing.totalObcPrice,
                    }}
                />
            </Modal>
            <Card
                footer={
                    <Button
                        id={'pricing-info-button'}
                        flavour='secondary'
                        text={content.pricingInfo}
                        type='button'
                        onClick={(): void => handleOpenModal({ buttonId: 'pricing-info-button' })}
                    />
                }
            >
                <div className={styles.content}>
                    <div className={styles.content__header}>
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <Heading heading='2' colorOverride='tertiary-blue'>
                                    {cruiseProduct?.cruiseName ?? cruiseProduct.productName}
                                </Heading>
                                {supplierOrderItemReference && (
                                    <div>
                                        <Text weight='bold'>{content.supplierOrderReference}</Text>{' '}
                                        <Text>{supplierOrderItemReference}</Text>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ textAlign: 'end' }}>
                                <Heading heading='2'>{priceString}</Heading>
                            </Grid>
                        </Grid>
                        <Spacing>
                            <ColoredLine />
                        </Spacing>
                    </div>
                    <div className={styles.content__info}>
                        <div>
                            <div>
                                <Text size='S' weight='bold'>
                                    {content.rateCode}
                                </Text>
                            </div>
                            <Text>{rateCodeInfo}</Text>
                        </div>
                        <div>
                            <div>
                                <Text size='S' weight='bold'>
                                    {content.itinerary}
                                </Text>
                            </div>
                            <ItineraryString itinerary={itinerary} />
                        </div>
                        <div>
                            <div>
                                <Text size='S' weight='bold'>
                                    {content.information}
                                </Text>
                            </div>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <DescriptionListDisplay
                                        itemClassName={styles.content__info__list}
                                        overrideMaxKeyLength={16}
                                        keyValueContent={[
                                            [content.voyageCode, cruiseProduct.cruiseId, 'Ship'],
                                            [content.departureDate, embarkDateUS, 'Calendar'],
                                            [content.returnDate, disembarkDateUS, 'Calendar'],
                                            [
                                                content.duration,
                                                `${cruiseProduct.duration} ${content.nights}`,
                                                'Moon',
                                            ],
                                            [content.supplierName, cruiseLine, 'Ship'],
                                            [content.shipName, shipName, 'Ship'],
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DescriptionListDisplay
                                        itemClassName={styles.content__info__list}
                                        overrideMaxKeyLength={16}
                                        keyValueContent={[
                                            [
                                                content.passengers,
                                                `${numberOfPassengers} ${content.passengers}`,
                                                'User',
                                            ],
                                            [content.deck, deckInfo, 'Ship'],
                                            [content.cabinNumber, cruiseProduct.cabinNumber, 'Bed'],
                                            [content.cabinType, cabinGradeInfo, 'Ship'],
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default OrderPreviewCard
