import React from 'react'

import Text from 'components/basics/Text/Text'
import Card from 'components/blocks/Card/Card'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import { CruisePaymentItem } from 'api-data-models/order/OrderContentModel'
import styles from './OrderPaymentScheduleSection.module.scss'
import allContent from '../../../../content/content'

const content = allContent.order.orderPage.orderPaymentSchedule

type OrderPricingSectionProps = {
    paymentScheduleItems: CruisePaymentItem[] | null
    grossTotal: string
}

const OrderPaymentScheduleSection = ({
    paymentScheduleItems,
    grossTotal,
}: OrderPricingSectionProps): JSX.Element | null => {
    if (!paymentScheduleItems) {
        return null
    }
    const currencyCode = paymentScheduleItems[0].currencyCode
    const currencySymbol = paymentScheduleItems[0].currencySymbol

    return (
        <Card header={content.heading}>
            <div className={styles['order-payment-schedule-section__content']}>
                {paymentScheduleItems.map((item) => {
                    const { amount, description, dueDate, paymentType } = item
                    return (
                        <div
                            key={description + paymentType}
                            className={styles['order-payment-schedule-item__price-details']}
                        >
                            <div className={styles['order-payment-schedule-item__price-item']}>
                                <Text weight='bold' className={styles['margin-right-small']}>
                                    {description}
                                </Text>
                                <Text weight='bold' font='mono'>
                                    {currencySymbol}
                                    {amount}
                                </Text>
                            </div>
                            <Text color='gray'>{`${content.due} ${dueDate}`}</Text>
                        </div>
                    )
                })}
                <div className={styles['order-payment-schedule-item__line-margin']}>
                    <ColoredLine padding='single' />
                </div>
                <div className={styles['order-payment-schedule-item__price-details']}>
                    <div className={styles['order-payment-schedule-item__price-item']}>
                        <Text weight='bold'>Total</Text>
                        <Text weight='bold' font='mono'>
                            {currencySymbol}
                            {grossTotal}
                            {currencyCode}
                        </Text>
                    </div>
                </div>
            </div>
        </Card>
    )
}
export default OrderPaymentScheduleSection
