import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { parseQueryParams } from 'utils/parse-query-params'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import OrderPreviewLayout from 'components/layouts/order/OrderPreviewLayout/OrderPreviewLayout'
import { OrderContent } from 'api-data-models/order/OrderContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { submitRetrieveBooking } from 'components/sections/app/HeaderContent/ImportModal/ImportModal'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'

import allContent from 'content/content'

const content = allContent.order.orderPreviewPage
const breadcrumbContent = allContent.app.breadcrumbs

const OrderPreviewPage: React.FC = (): JSX.Element => {
    const location = useLocation()
    const orderDataFromNav: any = location?.state?.orderData

    const { params } = useParams()
    const queryParams = params ?? ''
    const queryVariables = useMemo(
        () => (queryParams?.length ? parseQueryParams(queryParams) : {}),
        [queryParams]
    )
    const hasCorrectParams = queryVariables?.bookingId && queryVariables?.supplierCode

    const [error, setError] = useState<null | CustomApiError[]>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [orderResult, setOrderResult] = useState<OrderContent | undefined>(undefined)

    const handleRetrieveBooking = useCallback(() => {
        submitRetrieveBooking({
            variables: {
                bookingId: queryVariables?.bookingId,
                supplierCode: queryVariables?.supplierCode,
            },
            setIsSubmitting: setIsLoading,
            onSuccess: (orderResponse) => {
                if (orderResponse) {
                    setOrderResult(new OrderContent(orderResponse))
                }
            },
            onError: (err: any) => {
                setError(err)
            },
        })
    }, [queryVariables])

    useEffect(() => {
        if (hasCorrectParams) {
            if (orderDataFromNav && !orderResult) {
                const order = new OrderContent(orderDataFromNav)
                setOrderResult(order)
            }
            if (!orderDataFromNav && !orderResult) {
                handleRetrieveBooking()
            }
        }
    }, [orderDataFromNav, orderResult, hasCorrectParams, handleRetrieveBooking])

    const breadcrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.import },
    ]

    return isLoading ? (
        <LargeSpinner text={`Fetching booking from ${queryVariables?.supplierCode}`} />
    ) : (
        <div className='general-container'>
            <Breadcrumb urlList={breadcrumbItems} />
            {!hasCorrectParams && (
                <InfoBanner
                    id={'incorrect-params-alert'}
                    text={content.errors.queryParamsMissing}
                    bannerType='error'
                />
            )}
            {error && <ErrorList errorsList={error} source='RetrieveBooking' />}
            {orderResult && hasCorrectParams && (
                <OrderPreviewLayout
                    orderState={orderResult}
                    queryVariables={{
                        bookingId: queryVariables?.bookingId,
                        supplierCode: queryVariables?.supplierCode,
                    }}
                />
            )}
        </div>
    )
}

export default OrderPreviewPage
