import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import CabinLayout from 'components/layouts/cruise/CabinLayout/CabinLayout'

/** CabinPage: returns a component that renders the details of a cabin */
const CabinPage: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({ apiClient }) => {
    return <CabinLayout apiClient={apiClient} />
}

export default CabinPage
