import React from 'react'
import { useNavigate } from 'react-router-dom'

import Text from 'components/basics/Text/Text'
import Menu from 'components/blocks/Menu/Menu'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { SalesChannelAPIResponse } from '../SalesChannelManagement'

import allContent from 'content/content'
import styles from './SalesChannelList.module.scss'

const content = allContent.admin.salesChannelManagementPage

type SalesChannelListProps = {
    salesChannelData: SalesChannelAPIResponse[]
}

const SalesChannelList: React.FC<SalesChannelListProps> = ({ salesChannelData }) => {
    const navigate = useNavigate()
    if (salesChannelData.length === 0)
        return (
            <Text weight='bold' size='S'>
                {content.list.noSalesChannels}
            </Text>
        )

    const salesChannelItems = salesChannelData.map((salesChannel): JSX.Element => {
        const salesChannelId = salesChannel.salesChannelId
        const options = [
            {
                label: content.list.actions.edit,
                onClick: (): void => {
                    navigate(`${ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT}/${salesChannelId}`)
                },
            },
        ]
        return (
            <div className={styles['list-item']} key={salesChannel.salesChannelId}>
                <div className={styles['list-item--left']}>{salesChannel.title}</div>
                <div className={styles['list-item--right']}>
                    <div className={styles['list-item--group']}>
                        {salesChannel.userGroups.length > 0
                            ? salesChannel.userGroups.map((group) => group.title).join(', ')
                            : content.list.noLinkedGroups}
                    </div>
                    <Menu
                        id={salesChannelId}
                        options={options}
                        buttonText={content.list.actions.actionMenu}
                    />
                </div>
            </div>
        )
    })

    return (
        <div className={styles['list']}>
            <div className={styles['list-item']}>
                <div className={styles['list-item--left']}>
                    <Text weight='bold' size='S'>
                        {content.list.salesChannelColumn}
                    </Text>
                </div>
                <div className={styles['list-item--right']}>
                    <Text weight='bold' size='S'>
                        {content.list.groupColumn}
                    </Text>
                </div>
            </div>
            {salesChannelItems}
        </div>
    )
}

export default SalesChannelList
