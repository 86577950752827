import React from 'react'
import spacingStyles from './Spacing.module.css'
import classnames from 'classnames'

type SpacingProps = {
    /** level of spacing to apply */
    size?: 'single' | 'double' | 'triple' | 'quadruple'
    /** children always of type ReactNode */
    children?: React.ReactNode
}

/** Spacing: Returns a sizeable 'spacing' element that creates empty space on a page using a div */
const Spacing: React.FC<SpacingProps> = ({ size = 'single', children }: SpacingProps) => {
    const classNames = classnames(spacingStyles[`spacing-${size}`], spacingStyles['spacing'])
    return <div className={classNames}>{children}</div>
}

export default Spacing
