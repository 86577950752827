import React, { ChangeEvent, useCallback } from 'react'
import { getMonth, getYear } from 'date-fns'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import Select from '../Input/Select/Select'
import Button from '../Button/Button'
import styles from './DatePickerHeader.module.scss'
import allContent from 'content/content'

type DatePickerHeaderProps = ReactDatePickerCustomHeaderProps & {
    /** number of years to show in years dropdown*/
    yearsInDropdown: number
    yearsStartFrom?: number
}

const content = allContent.components.datePicker
const monthStrings = allContent.components.monthStrings

/** list of month strings to show as part of the header when in day/month navigation */
const months = [
    monthStrings.jan,
    monthStrings.feb,
    monthStrings.mar,
    monthStrings.apr,
    monthStrings.may,
    monthStrings.jun,
    monthStrings.jul,
    monthStrings.aug,
    monthStrings.sep,
    monthStrings.oct,
    monthStrings.nov,
    monthStrings.dec,
]

/** DatepickerHeader: custom header to be consumed by Datepicker */
const DatepickerHeader: React.FC<DatePickerHeaderProps> = ({
    decreaseMonth,
    increaseMonth,
    date,
    changeMonth,
    changeYear,
    prevMonthButtonDisabled,
    yearsInDropdown,
    yearsStartFrom = getYear(new Date()),
}) => {
    /**  List of years starting from current year. Length specifies how many years to show in the dropdown */
    const years = Array.from(
        { length: yearsInDropdown },
        (_value, index) => `${yearsStartFrom + index}`
    )
    const onMonthSelectChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            changeMonth(months.indexOf(e.target.value))
        },
        [changeMonth]
    )
    const yearOnChangeCallback = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            changeYear(+e.target.value)
        },
        [changeYear]
    )
    return (
        <>
            {!prevMonthButtonDisabled && (
                <Button
                    onClick={(): void => {
                        decreaseMonth()
                    }}
                    className={`${styles['DatePickerHeader__navigation']} ${styles['DatePickerHeader__navigation--previous']}`}
                    flavour='icon'
                    iconName='CircleChevronLeft'
                    type='button'
                    text={content.months.previousButton}
                />
            )}
            <Button
                onClick={(): void => {
                    increaseMonth()
                }}
                className={`${styles['DatePickerHeader__navigation']} ${styles['DatePickerHeader__navigation--next']}`}
                flavour='icon'
                iconName='CircleChevronRight'
                type='button'
                text={content.months.nextButton}
            />
            <div className={styles['DatePickerHeader__navigation-selects']}>
                <Select
                    aria-label={content.months.select}
                    value={months[getMonth(date)]}
                    options={months.map((month) => {
                        return { text: month, value: month }
                    })}
                    onChange={onMonthSelectChange}
                />

                <Select
                    aria-label={content.years.select}
                    value={`${getYear(date)}`}
                    options={years.map((year) => {
                        return { text: year, value: year }
                    })}
                    onChange={yearOnChangeCallback}
                />
            </div>
        </>
    )
}

export default DatepickerHeader
