import React, { SetStateAction, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { useNavigate } from 'react-router-dom'

import Button from 'components/basics/Button/Button'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import Heading from 'components/basics/Heading/Heading'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Link from 'components/basics/Link/Link'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'
import { COMPANY_TIER_NAMES } from 'utils/constants'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import {
    getChargeBeUrl,
    handleUpdateFinanceId,
    processChargbeeCheckout,
} from './subscription-helpers'

import styles from './SubscriptionSetup.module.css'
import allContent from 'content/content'

const content = allContent.landingPage.subscriptionSetup

type SubscriptionSetupProps = {
    managerApiClient: ApolloClient<NormalizedCacheObject>
    companyTier: CompanyTierTypes
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
}
const SubscriptionSetup: React.FC<SubscriptionSetupProps> = ({
    managerApiClient,
    companyTier,
    setUserData,
}: SubscriptionSetupProps) => {
    const navigate = useNavigate()
    const [updatingFinanceId, setUpdatingFinanceId] = useState<boolean>(false)
    const [updatingFinanceIdApiError, setUpdatingFinanceIdApiError] = useState<
        CustomApiError[] | null
    >(null)
    const [processingCheckout, setProcessingCheckout] = useState<boolean>(false)
    const [processingCheckoutApiError, setProcessingCheckoutApiError] = useState<
        CustomApiError[] | null
    >(null)
    const userContext = datadogLogs.getGlobalContext()
    const { tenantId, companyName, firstName, lastName, userEmail } = extractGlobalContextUserData(
        userContext as GlobalContextUserData
    )

    /** User can sit on this page beyond cognito session timeout without the app knowing, so should check and refresh if needed to avoid the submit failing */
    checkAndPerformUserSessionRefreshIfNeeded(navigate)

    const params = window.location.search ?? ''
    const urlParams = new URLSearchParams(params)
    const hostedPageId = urlParams.get('id') // Chargebee returns user with id of hosted checkout so we can fetch subscription details

    /** When user comes back from Chargebee, they should have a hostedCheckId in the params, send this id to api to allow our DB to be updated */
    if (hostedPageId && !processingCheckout && !processingCheckoutApiError) {
        processChargbeeCheckout({
            setProcessingCheckout,
            setProcessingCheckoutApiError,
            managerApiClient,
            hostedPageId: hostedPageId,
            tenantId,
            companyName,
            companyTier,
            userEmail: userEmail,
            navigate,
            setUserData,
        })
    }

    if (updatingFinanceIdApiError) {
        return <ErrorList errorsList={updatingFinanceIdApiError} source='update-tenant' />
    }
    if (processingCheckoutApiError) {
        return <ErrorList errorsList={processingCheckoutApiError} source='processing-checkout' />
    }
    if (updatingFinanceId) {
        return <LargeSpinner text={content.updatingTenant} />
    }
    if (processingCheckout) {
        return <LargeSpinner text={content.processingCheckout} />
    }

    /** ONLY go to Chargebee if url is provided in GitHub - not done in PROD */
    if (
        Boolean(process.env.REACT_APP_CHARGEBEE_CHECKOUT_URL) &&
        (companyTier === COMPANY_TIER_NAMES.CRUISE_STARTER ||
            companyTier === COMPANY_TIER_NAMES.CRUISE_PLUS ||
            companyTier === COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL)
    ) {
        return (
            <>
                <Link
                    href={getChargeBeUrl({
                        tier: companyTier,
                        firstName,
                        lastName,
                        userEmail,
                        companyName,
                        tenantId,
                    })}
                    noUnderLine={true}
                >
                    <Button
                        type='button'
                        text={`Go off to chargebee hosted checkout for your subscription to: ${companyTier}`}
                    />
                </Link>
                <p>{"This is not in PROD, as we don't use chargebee there yet!"}</p>
                <p>We auto update tier without making chargebee subscription...</p>
            </>
        )
    } else if (
        companyTier === COMPANY_TIER_NAMES.CRUISE_STARTER ||
        companyTier === COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL ||
        companyTier === COMPANY_TIER_NAMES.CRUISE_API
    ) {
        /** ONLY auto update tenant with financeId for STARTER and PLUS TRIAL */
        handleUpdateFinanceId({
            setUpdatingFinanceId,
            setUpdatingFinanceIdApiError,
            managerApiClient,
            tenantId,
            companyName,
            companyTier,
            userEmail: userEmail,
            navigate,
            setUserData,
        })
        return <LargeSpinner text={content.updatingTenant} />
    }

    /** This setup only shows for users without FinanceId, so some migrated users might be on Enterprise but without financeID, they likely never finished setting up their accounts. */
    return (
        <div className={styles.card}>
            <Heading heading='1'>{content.setupIssue.heading}</Heading>
            <Text size='M'>{content.setupIssue.message}</Text>
            <Spacing />
            <Link
                rel='noopener'
                target='_blank'
                href={allContent.footer.serviceDeskUrl}
                noUnderLine={true}
            >
                <Button type='button' text={content.setupIssue.supportLinkText} />
            </Link>
        </div>
    )
}

export default SubscriptionSetup
