import React from 'react'
import { Navigate } from 'react-router-dom'

import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { ROUTES } from 'components/sections/app/AppRoutes'

import allContent from 'content/content'
const content = allContent.app.protectedRouteWrapper
type ProtectedRouteWrapperProps = {
    companyTier?: CompanyTierTypes
    children: React.ReactNode
    companyTierRequirement: CompanyTierTypes[]
    financeSystemId?: string
    isFetchingUserData: boolean
}
/** ProtectRoutesWrapper - holds user while financeId or companyTier is unknown,
 * and passes child if on 'PAID' tier for product or redirects to ROOT of app if not.
 * It is not for handling AUTHORISED login status of user, just PAID tiers of products */
const ProtectedRouteWrapper: React.FC<ProtectedRouteWrapperProps> = ({
    companyTier,
    children,
    companyTierRequirement,
    financeSystemId,
    isFetchingUserData,
}): JSX.Element => {
    if (isFetchingUserData) {
        /** Only shown briefly while rerender occurs after login - so don't show text, but use text for testing */
        return <LargeSpinner text={content.fetchingUserData} hideText={true} />
    }

    if (companyTier && companyTierRequirement.includes(companyTier) && financeSystemId) {
        return <>{children}</>
    }

    /** All pages using this wrapper (expect the landing page) require financeSystemId as proof they are fully on a tier in Chargebee and paying.
     * Safe to go back to LOG IN, because if the user is logged on they will end up on /ROOT where the handling of financeId is resolved. */
    return <Navigate to={ROUTES.LOGIN} />
}

export default ProtectedRouteWrapper
