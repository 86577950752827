import classnames from 'classnames'
import React from 'react'

import PaginationRangeSelect from '../PaginationRangeSelect/PaginationRangeSelect'
import TablePaginationButtons from 'components/blocks/TablePaginationButtons/TablePaginationButtons'
import styles from './TablePagination.module.scss'
import Pagination from '../Pagination/Pagination'

export interface TablePaginationProps {
    id?: string
    page: number
    itemsPerPage: number
    itemsPerPageOptions?: number[]
    onPageChange: (page: number) => void
    onItemsPerPageChange: (itemsPerPage: number) => void
    totalPages: number
    className?: string
    paginationType?: 'full' | 'short'
}

const TablePagination: React.FC<TablePaginationProps> = ({
    id,
    page,
    itemsPerPage,
    itemsPerPageOptions,
    totalPages,
    onPageChange,
    onItemsPerPageChange,
    className,
    paginationType = 'short',
}) => {
    return (
        <div className={classnames(styles['table-pagination'], className)}>
            <PaginationRangeSelect
                id={`${id}-paginate-per-page`}
                value={itemsPerPage}
                onChange={onItemsPerPageChange}
                options={itemsPerPageOptions}
            />
            {paginationType === 'full' ? (
                <Pagination
                    currentPage={page}
                    numberOfPages={totalPages}
                    onPageChange={(page: number): void => onPageChange(page)}
                    pageRangeDisplayed={1}
                    size='medium'
                />
            ) : (
                <TablePaginationButtons
                    handlePageChange={onPageChange}
                    page={!totalPages ? 0 : page}
                    totalNumOfPages={totalPages}
                />
            )}
        </div>
    )
}

export default TablePagination
