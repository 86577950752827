import React, { useState, useEffect } from 'react'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import SpinnerCruiseLogo from 'components/blocks/SpinnerCruiseLogo/SpinnerCruiseLogo'
import Text from 'components/basics/Text/Text'
import Tooltip from 'components/basics/Tooltip/Tooltip'

import { ItineraryDay } from 'api-data-models/SailingContentModel'
import { useRest, UseRestOptions } from 'components/hooks/useRest'
import { SupplierCodes } from 'utils/constants'
import {
    ItineraryFromApi,
    ItineraryModelTransformFunction,
} from 'api-data-models/cruise-detail/ItineraryModel'

import styles from './Itinerary.module.scss'

import allContent from 'content/content'

const content = allContent.cruise.sailingPage.itineraryView

type ItineraryProps = {
    cruiseId: string
    supplierCode: string
}

export const ItineraryTable = ({
    itineraryData,
}: {
    itineraryData: ItineraryDay[]
}): JSX.Element => {
    const tableBody =
        itineraryData && itineraryData.length > 0
            ? itineraryData.map((item) => {
                  return (
                      <tr className={styles['table-body-row']} key={item.dayNumber}>
                          <td>{`${content.dayNumberText}${item.dayNumber}`}</td>
                          <td>
                              <div className={styles['date-cells']}>
                                  <div className={styles['date-cell']}>
                                      <Text weight='bold'>{item.itemDate}</Text>
                                  </div>
                                  <div className={styles['arrive-depart-times']}>
                                      {item.arrivalTime &&
                                          `${content.arriveText}${item.arrivalTime}`}
                                      {item.departureTime && item.arrivalTime && ', '}
                                      {item.departureTime &&
                                          `${content.departText}${item.departureTime}`}
                                  </div>
                              </div>
                          </td>
                          <td>{item.portName}</td>
                      </tr>
                  )
              })
            : null

    const transformItineraryIntoText = (): string => {
        return itineraryData && itineraryData.length > 0
            ? itineraryData
                  .map(
                      (item) =>
                          `${content.dayNumberText} ${item.dayNumber}\n` +
                          `${content.dateTimeColumnHeader} - ${item.itemDate} ${
                              item.arrivalTime ? `${content.arriveText} ${item.arrivalTime}` : ''
                          }${item.arrivalTime && item.departureTime ? ', ' : ''}${
                              item.departureTime
                                  ? `${content.departText} ${item.departureTime}`
                                  : ''
                          }\n` +
                          `${content.itineraryColumnHeader} - ${item.portName}`
                  )
                  .join('\n\n')
            : ''
    }
    const handleCopyItinerary = (): void => {
        const textToCopy = transformItineraryIntoText()
        navigator.clipboard.writeText(textToCopy)
    }

    return (
        <table className={styles.table}>
            <thead className={styles['rounded-top-border-radius']}>
                <tr>
                    <td>
                        <Text weight='bold' color='white'>
                            {content.daysColumnHeader}
                        </Text>
                    </td>
                    <td>
                        <Text weight='bold' color='white'>
                            {content.dateTimeColumnHeader}
                        </Text>
                    </td>
                    <td>
                        <Text weight='bold' color='white'>
                            {content.itineraryColumnHeader}
                        </Text>
                    </td>
                    {/* <td> */}
                    <div className={styles['copy-button-container']}>
                        <Tooltip
                            content={content.copiedAfterClickText}
                            id='copy-itinerary'
                            openOnClick={true}
                        >
                            <Button
                                id='copy-itinerary-button'
                                flavour='tertiary'
                                iconName='Copy'
                                text={content.copyButton}
                                onDarkBackground={true}
                                disabled={!itineraryData || itineraryData.length <= 0}
                                onClick={(): void => handleCopyItinerary()}
                                type='button'
                            />
                        </Tooltip>
                    </div>
                    {/* </td> */}
                </tr>
            </thead>
            <tbody>{tableBody}</tbody>
        </table>
    )
}

const Itinerary = ({ cruiseId, supplierCode }: ItineraryProps): JSX.Element => {
    const [itineraryData, setItineraryData] = useState<ItineraryDay[]>()

    const fetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/itinerary?supplier_code=${supplierCode}`,
        source: 'SailingPage - ITINERARY',
        method: 'GET',
    }
    const { result, loading, error } = useRest(fetchOptions)

    useEffect(() => {
        if (result) {
            setItineraryData(ItineraryModelTransformFunction(result as ItineraryFromApi[]))
        }
    }, [result])

    return (
        <Card>
            {loading && (
                <SpinnerCruiseLogo
                    text={content.spinnerLogoText}
                    supplierCode={supplierCode as SupplierCodes}
                />
            )}
            {error && <ErrorList errorsList={error} source='Itinerary - Sailing page' />}
            {!!itineraryData && itineraryData.length === 0 && (
                <Text weight='bold'>{content.notFound}</Text>
            )}
            {itineraryData && itineraryData.length > 0 && (
                <ItineraryTable itineraryData={itineraryData} />
            )}
        </Card>
    )
}

export default Itinerary
