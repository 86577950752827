export type GroupInSalesChannel = {
    groupTitle: string
    groupId: string
}

export type GroupInSalesChannelFromApi = {
    title: string
    userGroupId: string
}

export type SalesChannel = {
    salesChannelId: string
    salesChannelTitle: string
    userGroups: GroupInSalesChannel[]
}

export type SalesChannelFromApi = {
    salesChannelId: string
    title: string
    userGroups: GroupInSalesChannelFromApi[]
}

export const SalesChannelsContent = (salesChannelsData: SalesChannelFromApi[]): SalesChannel[] => {
    return salesChannelsData.map((salesChannel) => ({
        salesChannelId: salesChannel.salesChannelId,
        salesChannelTitle: salesChannel.title,
        userGroups: salesChannel.userGroups.map((group: GroupInSalesChannelFromApi) => ({
            groupTitle: group.title,
            groupId: group.userGroupId,
        })),
    }))
}
