import { ENVIRONMENTS } from '../utils/constants'

const domainAuthInfo: DomainAuthInfoData = {
    /** PAID SITE */
    'https://cruiseconnect.traveltek.net': {
        environment: ENVIRONMENTS.PROD,
        clientId: '6jt7edf740shq14dt1pm3ueu5p',
        userPoolId: 'eu-west-1_TzliCih9b',
    },
    'https://enya.traveltekstage.com': {
        environment: ENVIRONMENTS.STAGE,
        clientId: '4jif60jf8k208dqco8p50dabmk',
        userPoolId: 'eu-west-1_CDBj0vD51',
    },
    'https://enya.traveltekdev.com': {
        environment: ENVIRONMENTS.DEVELOPMENT,
        clientId: '2bdbtliidk6m2i84umkhmtai1g',
        userPoolId: 'eu-west-1_Z1xQjGrl5',
    },
    'http://localhost:3000': {
        environment: ENVIRONMENTS.LOCAL,
        // clientId: '2bdbtliidk6m2i84umkhmtai1g', // DEVELOPMENT
        clientId: '4jif60jf8k208dqco8p50dabmk', // STAGE
        // userPoolId: 'eu-west-1_Z1xQjGrl5', // DEVELOPMENT
        userPoolId: 'eu-west-1_CDBj0vD51', // STAGE
    },
}

export default domainAuthInfo
