import React from 'react'
import classnames from 'classnames'
import radioGroupStyles from './RadioGroup.module.css'

export type RadioProps = React.ComponentProps<'input'> &
    Readonly<{
        children: React.ReactElement | JSX.Element[]
        /** legend for radio group */
        groupName: string
        /** to show legend if wanted */
        showLegend?: boolean
    }>

const RadioGroup: React.FC<RadioProps> = ({
    children,
    className,
    groupName,
    showLegend = false,
    disabled,
}) => {
    const fieldSetClassNames = classnames(radioGroupStyles.fieldSet, className)
    const legendClassName = classnames({ 'visually-hidden': !showLegend })
    return (
        <fieldset className={fieldSetClassNames} disabled={disabled}>
            <legend className={legendClassName}>{groupName}</legend>
            {children}
        </fieldset>
    )
}

export default RadioGroup
