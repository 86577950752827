import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import CrashErrorLayout from 'components/layouts/app/CrashErrorLayout/CrashErrorLayout'
import FooterContent from 'components/sections/app/FooterContent/FooterContent'
import Footer from 'components/blocks/Footer/Footer'
import HeaderSection from 'components/sections/app/HeaderContent/HeaderContent'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'

import styles from './CrashErrorPage.module.css'

export const CrashErrorPage = ({
    cleanUpAppOnLogout,
}: {
    cleanUpAppOnLogout(): void
}): JSX.Element => {
    const { userEmail } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )

    /** To prevent anything that might not work, if app crashes turn off all features */
    const isAuthorised = false
    const companyTier = undefined
    const userRoles: UserRole[] = []

    return (
        <div className={styles.page}>
            <HeaderSection
                isFetchingUserData={false}
                isAuthorised={isAuthorised}
                logUserOut={cleanUpAppOnLogout}
                userEmail={userEmail}
                userRoles={[]}
                companyTier={companyTier}
                hideUserMenu={true}
            />
            <CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />
            <Footer>
                <FooterContent
                    userEmail={userEmail}
                    isAuthorised={isAuthorised}
                    companyTier={companyTier}
                    userRoles={userRoles}
                />
            </Footer>
        </div>
    )
}

export default CrashErrorPage
