import { insertDecimal2CharsFromEnd } from 'utils/string-helpers'

type CabinTypeFromAPI = 'I' | 'O' | 'B' | 'S'

export type CabinGradeFromAPI = {
    available: boolean
    balcony: boolean
    best_rate: boolean
    cabin_type: CabinTypeFromAPI
    cabins: Record<string, any>[]
    code: string
    color_code: string
    description: string
    guaranteed: boolean
    name: string
    order: number
    pricing_rate: string
    requested_rate: string
    status: string
    status_code: string
    status_description: string
    total_amount: number
    total_tax: number
}

// Define the types for the transformation output
type CabinTypes = 'Inside' | 'Outside' | 'Balcony' | 'Suite'

export type CabinTypeGroup = {
    type: CabinTypes
    rateCodes: string[]
    cabinGrades: CabinGrade[]
}

// CabinGrade and Rate types
type Rate = {
    index: number
    available: boolean
    rateCode: string
    totalFarePrice: string
}

export type CabinGrade = {
    available: boolean
    cabinGradeCode: string
    name: string
    description: string
    rates: Rate[]
    type?: CabinTypes
}

const cabinTypeMapping: { [key in CabinTypeFromAPI]: CabinTypes } = {
    I: 'Inside',
    O: 'Outside',
    B: 'Balcony',
    S: 'Suite',
}

export const sortCabinGradesFromApiByTheCheapest = (
    cabinGradesFromApi: CabinGradeFromAPI[]
): CabinGradeFromAPI[] => {
    if (!!cabinGradesFromApi && cabinGradesFromApi.length > 0) {
        return cabinGradesFromApi.sort(
            (a: CabinGradeFromAPI, b: CabinGradeFromAPI) => a?.total_amount - b?.total_amount
        )
    } else {
        return []
    }
}

// Main transformation function
export const CabinGradesModelTransformFunction = (
    apiData: CabinGradeFromAPI[]
): CabinTypeGroup[] => {
    if (!apiData || (!!apiData && !apiData.length)) {
        return []
    }

    // Create a base structure for the data
    const groupedByType: {
        [key: string]: { rateCodes: Set<string>; cabinGrades: CabinGrade[] }
    } = {}

    const cabinGradesByCabinTypesObject: {
        [key: string]: { [key: string]: CabinGrade }
    } = {}

    const sortedCabinGradesFromApi = sortCabinGradesFromApiByTheCheapest(apiData)

    // each cabin grade needs an index so we can place the data in correct order in the cabin grade table columns
    const sortedCabinGradesFromApiWithIndexes = sortedCabinGradesFromApi.map(
        (cabinGradeFromApi, index) => ({
            ...cabinGradeFromApi,
            index,
        })
    )

    sortedCabinGradesFromApiWithIndexes.forEach((cabinGradeFromApi, index) => {
        const cabinType = cabinTypeMapping[cabinGradeFromApi.cabin_type]
        const rateCode = cabinGradeFromApi.pricing_rate

        // Initialize the type group if it doesn't exist
        if (!groupedByType[cabinType]) {
            groupedByType[cabinType] = {
                rateCodes: new Set<string>(),
                cabinGrades: [],
            }
        }

        // Add rate code to the set for this type
        groupedByType[cabinType].rateCodes.add(rateCode)

        // Get or create the CabinGrade for this cabinGradeCode
        const cabinGradeCode = cabinGradeFromApi.code
        const cabinGradeObject: CabinGrade = {
            available: cabinGradeFromApi.available,
            cabinGradeCode,
            name: cabinGradeFromApi.name,
            description: cabinGradeFromApi.description,
            rates: [],
        }

        // If we want to add cabin grade object to the data that has no cabin type property yet then we need to create that cabin type property
        if (!cabinGradesByCabinTypesObject[cabinType]) {
            cabinGradesByCabinTypesObject[cabinType] = {
                [cabinGradeCode]: cabinGradeObject,
            }
            // If cabin typ property exists then just add cabinGradeObject to the correct cabin grade
        } else if (!cabinGradesByCabinTypesObject[cabinType][cabinGradeCode]) {
            cabinGradesByCabinTypesObject[cabinType][cabinGradeCode] = cabinGradeObject
        }

        const rateObject: Rate = {
            index,
            available: cabinGradeFromApi.available,
            rateCode: cabinGradeFromApi.pricing_rate,
            totalFarePrice: insertDecimal2CharsFromEnd(cabinGradeFromApi.total_amount),
        }

        // Add rate information
        return cabinGradesByCabinTypesObject[cabinType][cabinGradeCode].rates.push(rateObject)
    })
    // Transform groupedByType to the final output format
    return Object.keys(groupedByType).map((cabinType) => {
        const rateCodes = Array.from(groupedByType[cabinType].rateCodes)
        // const cabinGrades = Object.values(groupedByType[cabinType].cabinGrades)

        return {
            type: cabinType as CabinTypes,
            rateCodes,
            cabinGrades: Object.values(cabinGradesByCabinTypesObject[cabinType]),
        }
    })
}

export const CabinGradeSingleModelBuild = (cabinGradeFromApi: CabinGradeFromAPI): CabinGrade => {
    return {
        available: cabinGradeFromApi.available,
        cabinGradeCode: cabinGradeFromApi.code,
        name: cabinGradeFromApi.name,
        description: cabinGradeFromApi.description,
        rates: [],
        type: cabinTypeMapping[cabinGradeFromApi.cabin_type],
    }
}
