import React, { useState } from 'react'
import classnames from 'classnames'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'

import styles from './DetailsAccordion.module.scss'

type DetailsAccordionProps = {
    /** Title/header of the accordion */
    title: string
    /** Set to true if Accordion should start in Active state */
    startOpen?: boolean
    /** children always of type ReactNode */
    children?: React.ReactNode
    /** String to be used to form new unique IDs from whilst keeping a sense of common identity */
    baseId: string
}

/** Accordion: returns an accordion that can be clicked by a user to expand the contents passed as children */
const DetailsAccordion: React.FC<DetailsAccordionProps> = ({
    title,
    startOpen = false,
    children,
    baseId,
}: DetailsAccordionProps) => {
    const [isOpen, setIsOpen] = useState(startOpen)
    const toggleAccordion = (): void => {
        setIsOpen(!isOpen)
    }

    const contentClasses = classnames(styles.content, {
        [styles['content-hide']]: !isOpen,
    })

    const contentId = `${baseId}-content`
    const accordionId = `${baseId}-accordion`

    return (
        <div className={styles.container}>
            <div>
                <button
                    type='button'
                    id={accordionId}
                    aria-expanded={isOpen}
                    aria-controls={contentId}
                    className={styles.header}
                    onClick={toggleAccordion}
                >
                    <Text size='S' weight='bold'>
                        {title}
                    </Text>
                    <div className={styles['header--toggle']}>
                        <Icon
                            iconName={isOpen ? 'ChevronUp' : 'ChevronDown'}
                            iconSize='S'
                            iconColor='primary-midnight'
                            className={styles['header-icon']}
                        />
                    </div>
                </button>
            </div>
            <section
                id={contentId}
                aria-labelledby={accordionId}
                aria-hidden={!isOpen}
                className={contentClasses}
            >
                {/* this div is needed so padded content is hidden when closed  */}
                {isOpen && children}
            </section>
        </div>
    )
}

export default DetailsAccordion
