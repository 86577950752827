export const extraDestinationsList = [
    'Alaska',
    'Adriatic',
    'Atlantic',
    'Canary Islands',
    'California',
    'Florida',
    'Hawaii',
    'Midnight Sun',
    'New England',
    'Pacific',
    'Panama Canal',
]
