import React, { ChangeEvent, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'

import Button from 'components/basics/Button/Button'

import Card from 'components/blocks/Card/Card'
import CabinGuaranteedList from './CabinPickerFormComponents/CabinGuaranteedList'
import CabinPickerTable from './CabinPickerFormComponents/CabinPickerTable/CabinPickerTable'
import DeckPlanView from './CabinPickerFormComponents/DeckPlanView'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import RadioGroup from 'components/basics/Input/RadioGroup/RadioGroup'
import Spacing from 'components/basics/Spacing/Spacing'
import Select, { SelectOptionType } from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import { CreateOrderVariables } from 'components/layouts/cruise/CabinLayout/CabinLayout'
import { CabinContent, BeddingConfiguration } from 'api-data-models/CabinContentModel'
import { DiningOption } from 'api-data-models/cruise-detail/DiningOptionsModel'
import { Cabins, Cabin } from 'api-data-models/cruise-detail/CabinsModel'

import * as self from './CabinPickerFormSection'

import styles from './CabinPickerFormSection.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.cabinPage.cabinPickerSection

type CabinPickerFormSectionProps = {
    cabinsRestData: Cabins
    diningData: DiningOption[]
    /** number of passengers */
    numberOfPassengers: number
    /** A single cabin grade result object */
    cabinGraphQLData: CabinContent
    /** callback for submitting form */
    onSubmit(
        apiSubmitData: CreateOrderVariables,
        setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    ): void
    /** the defaultSelectedCabinNumber; used to find the bedding options to offer, and start radio group in table */
    defaultSelectedCabinNumber: string
    /** boolean to indicate form submission is in progress */
    submitting: boolean
    /** ** function to update the submitting status */
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    /** callback to set the selected deck */
    setSelectedDeck(value: string): void
    /** the currently selected deck that the CabinSelectList contents are filtered by */
    selectedDeck: string
}

type FilterCabins = {
    allCabins: Record<string, Cabin>
    selectedDeck: string
    cabinNumbers: string[]
}

type FormData = {
    beddingOption: string
    diningOption: string
    selectedCabinNumber: string
    specialRequest?: string
    numberOfPassengers: number
}

const validateCabinSubmission = ({ data }: { data: FormData }): boolean => {
    let isValid = true
    if (data.beddingOption === '') isValid = false
    if (data.diningOption === '') isValid = false
    if (data.selectedCabinNumber === '') isValid = false
    if (data.numberOfPassengers === 0) isValid = false

    return isValid
}

export const filterCabins = ({ allCabins, selectedDeck, cabinNumbers }: FilterCabins): Cabin[] => {
    const result: Cabin[] = []
    cabinNumbers.forEach((number: string) => {
        if (allCabins[number]?.deck?.name === selectedDeck) {
            result.push(allCabins[number])
        }
    })
    return result
}

const CabinPickerFormSection: React.FC<CabinPickerFormSectionProps> = ({
    cabinsRestData,
    diningData,
    cabinGraphQLData,
    onSubmit,
    defaultSelectedCabinNumber,
    submitting,
    numberOfPassengers,
    setSubmitting,
    setSelectedDeck,
    selectedDeck,
}: CabinPickerFormSectionProps) => {
    const { control, handleSubmit, setValue, getValues } = useForm({
        defaultValues: {
            numberOfPassengers,
            selectedCabinNumber: defaultSelectedCabinNumber,
            beddingOption: '',
            diningOption: '',
            specialRequest: undefined,
        },
    })
    const { cabinNumbers, deckNames, supplierCode, guaranteedCabin } = cabinGraphQLData

    const cabinsFilteredByDeck = self.filterCabins({
        allCabins: cabinsRestData,
        selectedDeck,
        cabinNumbers: cabinNumbers,
    })

    const diningSelectOptions: SelectOptionType[] = []
    diningData.forEach((diningOption: DiningOption) => {
        diningSelectOptions.push({
            text: diningOption.description,
            value: diningOption.code,
        })
    })

    const beddingOptions: SelectOptionType[] = []
    const selectedCabin = getValues('selectedCabinNumber')
    cabinsRestData[selectedCabin]?.beddingConfiguration?.forEach(
        (beddingOption: BeddingConfiguration) => {
            beddingOptions.push({
                text: beddingOption.description,
                value: beddingOption.code,
            })
        }
    )

    // console.log('cabinsRestData', cabinsRestData)

    const currentImage = cabinGraphQLData?.decks?.[selectedDeck]?.image
    const guarCabinOnly = cabinGraphQLData?.defaultSelectedCabinNumber === 'GUAR'

    const deckDropdownOptions = deckNames.map((deckName) => {
        return {
            text: deckName,
            value: deckName,
        }
    })

    const selectedDeckOnChangeCallback = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setSelectedDeck(e.target.value)
        },
        [setSelectedDeck]
    )

    const diningOptionOnChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('diningOption', e.target.value)
        },
        [setValue]
    )

    const beddingOptionOnChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('beddingOption', e.target.value)
        },
        [setValue]
    )
    return (
        <form
            className={styles['cabin-form']}
            onSubmit={handleSubmit(
                ({ beddingOption, diningOption, specialRequest, selectedCabinNumber }) => {
                    setSubmitting(true)
                    const isValid = validateCabinSubmission({
                        data: {
                            beddingOption,
                            diningOption,
                            selectedCabinNumber,
                            specialRequest,
                            numberOfPassengers: numberOfPassengers,
                        },
                    })
                    const createOrderVariables: CreateOrderVariables = {
                        cabinGradeCode: cabinGraphQLData.cabinGrade.code,
                        rateCode: cabinGraphQLData.rateCode,
                        cruiseId: cabinGraphQLData.cruiseId,
                        specialRequest: specialRequest,
                        cabinNumber: selectedCabinNumber,
                        supplierCode: supplierCode,
                        cabinBedConfigurationCode: beddingOption,
                        price: cabinGraphQLData.totalGrossPriceWithoutDecimal,
                        cabinDiningOption: diningOption,
                        numberOfPassengers: numberOfPassengers,
                    }
                    if (isValid) {
                        onSubmit(createOrderVariables, setSubmitting)
                    } else {
                        setSubmitting(false)
                        // todo: add validation handling
                    }
                }
            )}
        >
            {guaranteedCabin && (
                <Controller
                    control={control}
                    name='selectedCabinNumber'
                    render={({ field: { value } }): React.ReactElement => (
                        <RadioGroup
                            groupName='cabin-select'
                            className={styles['radio-group']}
                            disabled={submitting}
                        >
                            <Heading heading='2' size='3'>
                                {content.cabinsHeading}
                            </Heading>
                            <CabinGuaranteedList setValue={setValue} selectedCabinNumber={value} />
                            <Spacing size='double' />
                        </RadioGroup>
                    )}
                />
            )}
            <div className={styles['cabin-form-content']}>
                <div className={styles['cabin-form-column']}>
                    {cabinNumbers.length > 0 && (
                        <Controller
                            control={control}
                            name='selectedCabinNumber'
                            render={({ field: { value } }): React.ReactElement => (
                                <RadioGroup
                                    groupName='cabin-select'
                                    className={styles['radio-group']}
                                    disabled={submitting}
                                >
                                    <>
                                        {deckNames.length > 1 && (
                                            <div className={styles['deck-select']}>
                                                <LabelledInput
                                                    htmlFor='deck-selector'
                                                    label={content.deckDropdownPlaceholder}
                                                    disabled={submitting}
                                                >
                                                    <Select
                                                        placeholder={
                                                            content.deckDropdownPlaceholder
                                                        }
                                                        value={selectedDeck}
                                                        options={deckDropdownOptions}
                                                        onChange={selectedDeckOnChangeCallback}
                                                    />
                                                </LabelledInput>
                                                <Spacing size='double' />
                                            </div>
                                        )}
                                        <CabinPickerTable
                                            cabins={cabinsFilteredByDeck}
                                            setValue={setValue}
                                            selectedCabinNumber={value}
                                        />
                                    </>
                                </RadioGroup>
                            )}
                        />
                    )}
                    {cabinNumbers.length > 0 && <Spacing size='double' />}
                    <div>
                        <Card header={content.extrasHeader} headerSize='3'>
                            <div className={styles['content']}>
                                <div className={styles['fields-required-message']}>
                                    {content.fieldsRequired1a}
                                    <Text color='red'>{content.fieldsRequired1b}</Text>
                                    {content.fieldsRequired1c}
                                </div>
                                <Controller
                                    control={control}
                                    name='beddingOption'
                                    render={({ field: { value } }): React.ReactElement => (
                                        <>
                                            <div className={styles['extras-inputs']}>
                                                <Heading heading='3'>
                                                    {content.beddingHeading}
                                                </Heading>
                                                <LabelledInput
                                                    htmlFor='bedding-options'
                                                    label={content.beddingLabel}
                                                    required={true}
                                                    disabled={submitting}
                                                >
                                                    <Select
                                                        placeholder={content.beddingPlaceHolder}
                                                        value={value}
                                                        onChange={beddingOptionOnChange}
                                                        options={beddingOptions}
                                                    />
                                                </LabelledInput>
                                            </div>
                                        </>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='diningOption'
                                    render={({ field: { value } }): React.ReactElement => (
                                        <div className={styles['extras-inputs']}>
                                            <Heading heading='3'>{content.diningHeader}</Heading>
                                            <LabelledInput
                                                required={true}
                                                htmlFor='dining-options'
                                                label={content.diningLabel}
                                                disabled={submitting}
                                            >
                                                <Select
                                                    placeholder={content.diningPlaceHolder}
                                                    value={value}
                                                    onChange={diningOptionOnChange}
                                                    options={diningSelectOptions}
                                                />
                                            </LabelledInput>
                                        </div>
                                    )}
                                />
                            </div>
                        </Card>
                    </div>
                    <div className={styles['button-container']}>
                        <Button
                            type='submit'
                            showSpinner={submitting}
                            text={submitting ? content.processingSpinner : content.submitButton}
                        />
                    </div>
                </div>
                <div>
                    <DeckPlanView
                        selectedDeck={selectedDeck}
                        guarCabinOnly={guarCabinOnly}
                        currentImage={currentImage}
                    />
                </div>
            </div>
        </form>
    )
}

export default CabinPickerFormSection
