import React from 'react'

import Button from 'components/basics/Button/Button'
import Link from 'components/basics/Link/Link'
import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './PaidTierGettingStarted.module.scss'
import allContent from 'content/content'

const content = allContent.admin.adminLanding.gettingStartedSection.paid

const PaidTierGettingStarted = (): JSX.Element => (
    <div className={styles.container}>
        <Heading heading='2'>{content.title}</Heading>
        <Text>{content.usersManagement.addUsers}</Text>
        <Link noUnderLine={true} to={ROUTES.USER_MANAGEMENT}>
            <Button type='button' text={content.usersManagement.userManagementLink} />
        </Link>
        <Text>{content.groupsManagement.manageGroups}</Text>
        <Link noUnderLine={true} to={ROUTES.GROUP_MANAGEMENT}>
            <Button type='button' text={content.groupsManagement.groupsManagementLink} />
        </Link>
        <Text>{content.credentialsManagement.addSalesChannels}</Text>

        <Link noUnderLine={true} to={ROUTES.SALES_CHANNEL_MANAGEMENT}>
            <Button type='button' text={content.credentialsManagement.salesChannelManagementLink} />
        </Link>
        {/*<Link target='_blank' href={content.credentialsManagement.salesChannelVideoLinkUrl}>*/}
        {/*    {content.credentialsManagement.salesChannelVideoLinkText}*/}
        {/*</Link>*/}
        {/*<Link target='_blank' href={content.credentialsManagement.salesChannelConfluenceLinkUrl}>*/}
        {/*    {content.credentialsManagement.salesChannelConfluenceText}*/}
        {/*</Link>*/}
        <Text>{content.cruiseSearch}</Text>
        <Link noUnderLine={true} to={ROUTES.CRUISE_SEARCH}>
            <Button type='button' text={content.productLinkText} />
        </Link>
    </div>
)

export default PaidTierGettingStarted
