function pluralisePassengers(number: number, passengerType: 'Adult' | 'Child'): string {
    let passengers = `${number} ${passengerType}`
    if (passengerType === 'Adult' && number !== 1) passengers = `${passengers}s`
    if (passengerType === 'Child' && number !== 1) passengers = `${passengers}ren`

    return passengers
}

export function createPassengersText(numberOfAdults: number, numberOfChildren: number): string {
    return (
        pluralisePassengers(numberOfAdults, 'Adult') +
        `${numberOfChildren ? ', ' + pluralisePassengers(numberOfChildren, 'Child') : ''}`
    )
}
