import { capitalizeEachWord } from 'utils/string-helpers'
import { getFormattedDate } from '../../utils/date-helpers'
import { ItineraryItemType } from '../cruises-api-types'
import { formatSixOrFourDigitTimeStringToTwoDigitAmPmString } from 'utils/format-six-or-four-digit-time-string-to-two-digit-am-pm-string'

export type ItineraryFromApi = {
    day_number: number
    item_date: string
    arrival_time: string
    departure_time: string
    port_code: string
    port_name: string
    itinerary_item_type: ItineraryItemType
    country: string
    un_port_name: string
    region: string[]
}

export type ItineraryDay = {
    portName: string
    dayNumber: string
    itemDate: string
    arrivalTime?: string
    departureTime?: string
}

export const ItineraryModelTransformFunction = (
    itineraryDays: ItineraryFromApi[]
): ItineraryDay[] => {
    return itineraryDays.map((itineraryDay: ItineraryFromApi): ItineraryDay => {
        return {
            portName: capitalizeEachWord(itineraryDay.port_name),
            dayNumber: itineraryDay.day_number.toString(),
            itemDate: getFormattedDate(itineraryDay.item_date),
            ...(itineraryDay.arrival_time && {
                arrivalTime: formatSixOrFourDigitTimeStringToTwoDigitAmPmString(
                    itineraryDay.arrival_time
                ),
            }),
            ...(itineraryDay.departure_time && {
                departureTime: formatSixOrFourDigitTimeStringToTwoDigitAmPmString(
                    itineraryDay.departure_time
                ),
            }),
        }
    })
}
