import React, { useMemo, useState } from 'react'

import Button from 'components/basics/Button/Button'
import Link from 'components/basics/Link/Link'
import TablePaginationButtons from 'components/blocks/TablePaginationButtons/TablePaginationButtons'
import Text from 'components/basics/Text/Text'
import Tooltip from 'components/basics/Tooltip/Tooltip'
import { CabinGrade, CabinTypeGroup } from 'api-data-models/cruise-detail/CabinGradesModel'
import { RateCode } from 'api-data-models/cruise-detail/RateCodesModel'
import { CURRENCY_CODES } from 'utils/constants'
import * as self from './CabinGradesTable'

import styles from './CabinGradesTable.module.css'
import allContent from 'content/content'

const content = allContent.cruise.sailingPage.cabinGradesView

const getCabinURL = ({
    cabinGradeCode,
    rateCode,
    cruiseId,
    supplierCode,
}: Record<string, string>): string => {
    return `/cruise/cabin/?cabinGradeCode=${cabinGradeCode}&rateCode=${rateCode}&cruiseId=${cruiseId}&supplierCode=${supplierCode}`
}

type HandlePriceInfo = {
    cabinGrade: Record<string, any>
    rateCode: Record<string, any>
    setIsPriceModalOpen: (V: boolean) => void
    setPriceModalData: (V: Record<string, any>) => void
    cruiseId: string
    supplierCode: string
}

export const handlePriceInfo = ({
    cabinGrade,
    rateCode,
    setIsPriceModalOpen,
    setPriceModalData,
    cruiseId,
    supplierCode,
}: HandlePriceInfo): void => {
    setIsPriceModalOpen(true)
    setPriceModalData({ rateCode, cruiseId, supplierCode, cabinGrade })
}

type CabinGradesTableProps = {
    cabinTypeGroup: CabinTypeGroup
    cruiseId: string
    setIsPriceModalOpen(value: boolean): void
    setPriceModalData: (data: Record<string, any>) => void
    supplierCode: string
    setClickedButtonId: (buttonId: string) => void
    currency: string
    allRateCodes: RateCode[]
}

export const getSortedRateCodes = (
    cabinTypeGroup: CabinTypeGroup,
    allRateCodes: RateCode[]
): RateCode[] => {
    const avg: any = {}

    cabinTypeGroup.cabinGrades.forEach(({ rates }) => {
        rates.forEach((rate) => {
            avg[rate.rateCode] = Array.isArray(avg[rate.rateCode])
                ? [...avg[rate.rateCode], rate?.index]
                : [rate?.index]
        })
    })

    for (const rate in avg) {
        avg[rate] = avg[rate].reduce((acc: number, val: number) => acc + val, 0) / avg[rate].length
    }

    const arrayRateCodes = [...allRateCodes]
        .filter((rate) => cabinTypeGroup.rateCodes.includes(rate.code))
        .map((data) => ({
            ...data,
            avgIndex: avg[data.code],
            firstIndex: cabinTypeGroup.cabinGrades[0].rates.find(
                (rate) => rate.rateCode === data.code
            )?.index,
            lastIndex: cabinTypeGroup.cabinGrades[cabinTypeGroup.cabinGrades.length - 1].rates.find(
                (rate) => rate.rateCode === data.code
            )?.index,
        }))
        .sort((a, b) => {
            if (a.firstIndex && b.firstIndex) return Number(a?.firstIndex) - Number(b?.firstIndex)
            if (a.lastIndex && b.lastIndex) return Number(a?.lastIndex) - Number(b?.lastIndex)
            return Number(a?.avgIndex) - Number(b?.avgIndex)
        })

    return arrayRateCodes
}

const CabinGradesTable: React.FC<CabinGradesTableProps> = ({
    cabinTypeGroup,
    cruiseId,
    setPriceModalData,
    supplierCode,
    setClickedButtonId,
    setIsPriceModalOpen,
    currency,
    allRateCodes,
}: CabinGradesTableProps) => {
    const arrayRateCodes = useMemo(
        () => getSortedRateCodes(cabinTypeGroup, allRateCodes),
        [cabinTypeGroup, allRateCodes]
    )

    const [page, setPage] = useState(1)

    const numColsPerPage = 6
    const totalNumOfPages = Math.ceil(arrayRateCodes.length / numColsPerPage)

    const startIdx = (page - 1) * numColsPerPage
    const endIdx = startIdx + numColsPerPage
    const visibleRateCodes = arrayRateCodes.slice(startIdx, endIdx)

    const handlePageChange = (newPage: number): void => {
        setPage(newPage)
    }

    const cabinGradeRows = cabinTypeGroup.cabinGrades.map((cabinGrade: CabinGrade) => {
        return (
            <tr key={cabinGrade.cabinGradeCode}>
                <td className={styles['cabin-grade-name']}>
                    <Text weight='bold'>{cabinGrade.cabinGradeCode}</Text>
                </td>
                <td>
                    <Text>{cabinGrade.name}</Text>
                </td>
                {visibleRateCodes.map((rateCode: RateCode) => {
                    const rate = cabinGrade.rates.find(
                        (rate: { rateCode: string }) => rate.rateCode === rateCode.code
                    )
                    if (!rate?.available) {
                        const label =
                            rate?.available === false
                                ? content.rateGradeUnavailable
                                : content.rateGradeDoesNotExist
                        return (
                            <td key={rateCode.code}>
                                <div key={rateCode.code} className={styles['empty-cell']}>
                                    —<span className={'visually-hidden'}>{label}</span>
                                </div>
                            </td>
                        )
                    } else {
                        const cabinPageLink = (
                            <Link
                                className={styles['price-link']}
                                to={getCabinURL({
                                    cabinGradeCode: cabinGrade.cabinGradeCode,
                                    rateCode: rateCode.code,
                                    cruiseId,
                                    supplierCode,
                                })}
                            >
                                <Text font='mono' color='tertiary-blue'>
                                    {CURRENCY_CODES[currency]}
                                    {rate.totalFarePrice}
                                </Text>
                            </Link>
                        )
                        return (
                            <td key={rateCode.code}>
                                <div key={rateCode.code} className={styles['data-cell']}>
                                    <Button
                                        id={`${cabinGrade.cabinGradeCode}-${rate.rateCode}`}
                                        iconName='Info'
                                        text={`Open price breakdown ${rate.totalFarePrice}`}
                                        flavour='icon'
                                        onClick={(): void => {
                                            setClickedButtonId(
                                                `${cabinGrade.cabinGradeCode}-${rateCode.code}`
                                            )
                                            self.handlePriceInfo({
                                                cabinGrade: cabinGrade,
                                                cruiseId: cruiseId,
                                                rateCode: {
                                                    code: rateCode.code,
                                                    description: rateCode.description,
                                                    military: rateCode.military,
                                                    residency: rateCode.residency,
                                                    refundPolicy: rateCode.refundPolicy,
                                                },
                                                supplierCode: supplierCode,
                                                setIsPriceModalOpen,
                                                setPriceModalData,
                                            })
                                        }}
                                    />
                                    {cabinPageLink}
                                </div>
                            </td>
                        )
                    }
                })}
            </tr>
        )
    })

    return (
        <div className={styles['table-wrapper']}>
            <table className={styles['table']}>
                <thead>
                    <tr>
                        <th />
                        <th scope='col' className={styles['cabin-grade-col']}>
                            <Text weight='bold'>{content.breakdown.grade}</Text>
                        </th>
                        {visibleRateCodes.map((rateCode) => (
                            <td key={rateCode.code} className={styles['th-td']}>
                                <Tooltip id={rateCode.code} content={rateCode.description}>
                                    <div className={styles['military-residential']}>
                                        <Text weight='bold'>{rateCode.code ?? 'sss'}</Text>
                                        {rateCode.military && (
                                            <Text size='XS'>
                                                {content.militaryRateHighlightText}
                                            </Text>
                                        )}
                                        {rateCode.residency && (
                                            <Text size='XS'>
                                                {content.residentialRateHighlightText}
                                            </Text>
                                        )}
                                    </div>
                                </Tooltip>
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>{cabinGradeRows}</tbody>
            </table>
            <div className={styles['pagination-wrapper']}>
                <TablePaginationButtons
                    handlePageChange={handlePageChange}
                    page={page}
                    totalNumOfPages={totalNumOfPages}
                />
            </div>
        </div>
    )
}

export default CabinGradesTable
