import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { RenderInputComponentProps } from 'react-autosuggest'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import Autosuggest from 'components/blocks/Autosuggest/Autosuggest'
import Button from 'components/basics/Button/Button'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Slider from 'components/basics/Slider/Slider'
import Tag, { TagsContainer } from 'components/blocks/Tag/Tag'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { CURRENCY_CODES } from 'utils/constants'
import { stringArrayFilterAndSortByMatcher } from 'utils/stringArrayFilterAndSortByMatcher'
import { Cruise } from 'api-data-models/CruisesContentModel'
import { MetaDataItem } from 'api-data-models/cruises-api-types'
import * as self from './FilterSection'

import styles from './FilterSection.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage.filter
const NUMBER_OF_PRODUCTS_TO_SUGGEST = 20
const NUMBER_OF_PORTS_TO_SUGGEST = 10

type FilterSectionProps = {
    /** array of all departure ports in results*/
    departurePorts: MetaDataItem[]
    /** state and setter for departure port name filter state*/
    departurePortsFilterState: [string[], (value: string[]) => void]
    /** array of all arrival ports in results*/
    arrivalPorts: MetaDataItem[]
    /** state and setter for arrival port name filter state*/
    arrivalPortsFilterState: [string[], (value: string[]) => void]
    /** array of all visited ports in results */
    visitingPorts: MetaDataItem[]
    /** state and setter for visited port name filter state*/
    visitingPortsFilterState: [string[], (value: string[]) => void]
    /** function that resets the values of all filters */
    clearFilters: () => void
    /** array of product names to be used in the filter section auto-suggest */
    cruiseProductNames: string[]
    /** array of result items from getCruises api */
    cruises: Cruise[]
    /** array of all regions in results */
    regions: MetaDataItem[]
    /** state and setter for regions filter state*/
    regionsFilterState: [string[], (value: string[]) => void]
    /** state and setter for state for cruise line checkboxes */
    supplierNameState: [string[], (value: string) => void]
    /** state and setter for state for ship name checkboxes */
    shipNameState: [string[], (value: string) => void]
    /** state and setter for state for cabin type checkboxes */
    cabinTypeState: [string[], (value: string) => void]
    /** state and setter for state for cruise name filter */
    cruiseProductNameFilterState: [string, (value: string) => void]
    /** UseState setter function for price range filter max value */
    setSelectedMaxPrice(value: number): void
    /** UseState setter function for price range filter min value */
    setSelectedMinPrice(value: number): void
    /** maximum price value from cruise results */
    maxPrice: number
    /** minimum price value from cruise results */
    minPrice: number
}

/** getCheckboxFilterOptions: returns a checkboxGroupOption object of unique filterable items for an array of objects, based on a key to lookup
 @param { object } data - an array of objects
 @param { object } key - the results data that will be filtered
 */
export const getCheckboxFilterOptions = (data: Record<string, any>[], key: string): string[] => {
    return [...new Set(data?.map((item) => item[key]))].sort()
}

// getAvailableCabinTypes: returns an object of unique cabin types with the number of available cabins of given type
export const getAvailableCabinTypes = (cruises: Cruise[]): Record<string, number> => {
    return cruises.reduce(
        (cabinTypes: Record<string, number>, cruise: Cruise): Record<string, number> => {
            const cabins: string[] = cruise.pricing.cabinTypePricing
                .filter((cabin) => cabin.available)
                .map((cabin) => {
                    return cabin.cabinType
                })
            cabins.forEach((type: string): void => {
                if (cabinTypes[type]) {
                    cabinTypes[type] += 1
                } else {
                    cabinTypes[type] = 1
                }
            })
            return cabinTypes
        },
        {}
    )
}

const FilterSection: React.FC<FilterSectionProps> = ({
    departurePorts,
    departurePortsFilterState,
    arrivalPorts,
    arrivalPortsFilterState,
    visitingPorts,
    visitingPortsFilterState,
    clearFilters,
    cruiseProductNames,
    cruises,
    regions,
    regionsFilterState,
    supplierNameState,
    shipNameState,
    cabinTypeState,
    setSelectedMinPrice,
    setSelectedMaxPrice,
    cruiseProductNameFilterState,
    maxPrice,
    minPrice,
}: FilterSectionProps) => {
    const [selectedSupplierNames, handleSelectedSupplierNames] = supplierNameState
    const [selectedShipNames, handleSelectedShipNames] = shipNameState
    const [selectedCabinTypes, handleSelectedCabinTypes] = cabinTypeState
    const [cruiseProductNameFilterValue, setCruiseProductNameFilterValue] =
        cruiseProductNameFilterState
    const [supplierNamesShips, setSupplierNamesShips] = useState<Record<string, string[]>>({})
    const [priceFilterValues, setPriceFilterValues] = useState<number | number[]>([
        minPrice,
        maxPrice,
    ])
    const [productNameSuggestions, setProductNameSuggestions] = useState<string[]>([])
    const [departurePortFilterValue, setDeparturePortFilterValue] = departurePortsFilterState
    const [departurePortSuggestions, setDeparturePortSuggestions] = useState<MetaDataItem[]>([])
    const [departureInputValue, setDepartureInputValue] = useState<string>('')
    const [arrivalPortFilterValue, setArrivalPortFilterValue] = arrivalPortsFilterState
    const [arrivalPortSuggestions, setArrivalPortSuggestions] = useState<MetaDataItem[]>([])
    const [arrivalInputValue, setArrivalInputValue] = useState<string>('')
    const [visitingPortFilterValue, setVisitingPortFilterValue] = visitingPortsFilterState
    const [visitingPortSuggestions, setVisitingPortSuggestions] = useState<MetaDataItem[]>([])
    const [visitingInputValue, setVisitingInputValue] = useState<string>('')
    const [regionFilterValue, setRegionFilterValue] = regionsFilterState
    const [regionSuggestions, setRegionSuggestions] = useState<MetaDataItem[]>([])
    const [regionInputValue, setRegionInputValue] = useState<string>('')

    function getProductSuggestionsList(searchString: string): void {
        const filteredCruiseProductsBySearchString = cruiseProductNames.filter((name) =>
            name.toLowerCase().includes(searchString.toLowerCase())
        )
        const weightedOrderedProductNames = stringArrayFilterAndSortByMatcher(
            filteredCruiseProductsBySearchString,
            searchString
        )
        setProductNameSuggestions(
            weightedOrderedProductNames.slice(0, NUMBER_OF_PRODUCTS_TO_SUGGEST)
        )
    }

    function handleAutoSuggestDeparturePortSearchString({
        searchString,
    }: {
        searchString: string
    }): void {
        // Filter down to departure ports that include the search string and are not already selected
        const filteredDeparturePorts = departurePorts.filter(
            (port) =>
                port.name.toLowerCase().includes(searchString.toLowerCase()) &&
                !departurePortFilterValue.includes(port.name)
        )
        // Set auto suggest list to limited number
        setDeparturePortSuggestions(filteredDeparturePorts.slice(0, NUMBER_OF_PORTS_TO_SUGGEST))
    }

    function handleAutoSuggestArrivalPortSearchString({
        searchString,
    }: {
        searchString: string
    }): void {
        // Filter down to arrival ports that include the search string and are not already selected
        const filteredArrivalPorts = arrivalPorts.filter(
            (port) =>
                port.name.toLowerCase().includes(searchString.toLowerCase()) &&
                !arrivalPortFilterValue.includes(port.name)
        )
        setArrivalPortSuggestions(filteredArrivalPorts.slice(0, NUMBER_OF_PORTS_TO_SUGGEST))
    }

    function handleAutoSuggestVisitingPortSearchString({
        searchString,
    }: {
        searchString: string
    }): void {
        // Filter down to visited ports that include the search string and are not already selected
        const filteredVisitingPorts = visitingPorts.filter(
            (port) =>
                port.name.toLowerCase().includes(searchString.toLowerCase()) &&
                !visitingPortFilterValue.includes(port.name)
        )
        setVisitingPortSuggestions(filteredVisitingPorts.slice(0, NUMBER_OF_PORTS_TO_SUGGEST))
    }

    function handleAutoSuggestRegionSearchString({ searchString }: { searchString: string }): void {
        // Filter down to regions that include the search string and are not already selected
        const filteredRegions = regions.filter(
            (region) =>
                region.name.toLowerCase().includes(searchString.toLowerCase()) &&
                !regionFilterValue.includes(region.name)
        )
        setRegionSuggestions(filteredRegions)
    }

    function getCurrency(cruises: Cruise[]): string {
        // TODO: should use top level currency code from api (not added yet to api)
        const cruise = cruises.findIndex((cruise) => Boolean(cruise?.pricing?.currencyCode)) // returns -1 if no pricing or currencyCode, which will crash next line
        const currencyCode = cruises[cruise]?.pricing?.currencyCode ?? '?' // default to ? when there is no currencyCode available (we won't show the pricing in this case as there is no pricing)
        return CURRENCY_CODES[currencyCode]
    }
    const currency = getCurrency(cruises)

    useEffect(() => {
        // create an object with cruise line names as keys and push all ship names (for that cruise line) into an array as the values.
        const shipsNames: Record<string, string[]> = {}
        if (Object.keys(cruises).length > 0) {
            cruises.forEach((cruise) => {
                if (!shipsNames[cruise.supplierName]) shipsNames[cruise.supplierName] = [] // only add the supplierName if not already present.
                shipsNames[cruise.supplierName].push(cruise.shipName)
            })
            setSupplierNamesShips(shipsNames)
        }
    }, [cruises])

    const handlerFunctionNames: { [key: string]: (arg: string) => void } = {
        supplier: handleSelectedSupplierNames,
        ship: handleSelectedShipNames,
        cabin: handleSelectedCabinTypes,
    }

    const handleCheckboxChange =
        (filter: string) =>
        (event: React.FormEvent<HTMLInputElement | HTMLSpanElement>): void => {
            let target
            if (event.currentTarget.hasAttribute('data-name')) {
                target = event.currentTarget.getAttribute('data-name') ?? ''
                handlerFunctionNames[filter](target)
            } else {
                target = event.currentTarget as HTMLInputElement
                handlerFunctionNames[filter](target.name)
            }
        }
    const shipContainerClassnames = classnames(
        styles['filter__checkbox-group'],
        styles['filter__checkbox-group--ships']
    )

    const SupplierNameCheckboxOptions = self
        .getCheckboxFilterOptions(cruises, 'supplierName')
        .map((supplierName: string, i) => {
            let numResultsForCruise = 0
            cruises.forEach((cruise: Cruise) => {
                if (cruise.supplierName === supplierName) numResultsForCruise += 1
            })
            return (
                <div key={`option-${supplierName}`} className={styles['filter__checkbox-item']}>
                    <Checkbox
                        key={`option-${supplierName}`}
                        text={supplierName}
                        value={supplierName}
                        name={supplierName}
                        checked={selectedSupplierNames.includes(supplierName)}
                        onChange={handleCheckboxChange('supplier')}
                    />
                    <Text
                        size='S'
                        color='disabled'
                        className={styles['filter__checkbox-counter']}
                        onClick={handleCheckboxChange('supplier')}
                        data-name={supplierName}
                    >
                        {`(${numResultsForCruise})`}
                    </Text>
                </div>
            )
        })

    const ShipNameCheckboxOptions = self
        .getCheckboxFilterOptions(cruises, 'shipName')
        .map((shipName: string, i) => {
            const available =
                selectedSupplierNames.length === 0 ||
                selectedSupplierNames.some(
                    (supplierName: string) => supplierNamesShips[supplierName]?.includes(shipName) // returns true if find ship
                )
            let numResultsForShip = 0
            cruises.forEach((cruise: Cruise) => {
                if (cruise.shipName === shipName) {
                    numResultsForShip += 1
                }
            })
            return (
                <div key={`option-${shipName}`} className={styles['filter__checkbox-item']}>
                    <Checkbox
                        text={shipName}
                        disabled={!available}
                        name={shipName}
                        value={shipName}
                        checked={selectedShipNames.includes(shipName)}
                        onChange={handleCheckboxChange('ship')}
                    />
                    <Text
                        size='S'
                        color='disabled'
                        className={styles['filter__checkbox-counter']}
                        onClick={handleCheckboxChange('ship')}
                        data-name={shipName}
                    >
                        {`(${numResultsForShip})`}
                    </Text>
                </div>
            )
        })

    const CabinTypeCheckboxOptions = Object.entries(getAvailableCabinTypes(cruises)).map(
        ([cabinType, numberOfCabins]) => {
            return (
                <div key={`option-${cabinType}`} className={styles['filter__checkbox-item']}>
                    <Checkbox
                        text={cabinType}
                        name={cabinType}
                        value={cabinType}
                        checked={selectedCabinTypes.includes(cabinType)}
                        onChange={handleCheckboxChange('cabin')}
                    />
                    <Text
                        size='S'
                        color='disabled'
                        className={styles['filter__checkbox-counter']}
                        onClick={handleCheckboxChange('cabin')}
                        data-name={cabinType}
                    >
                        {`(${numberOfCabins})`}
                    </Text>
                </div>
            )
        }
    )

    function handlePriceChange(_event: any, value: number | number[]): void {
        if (Array.isArray(value)) {
            setPriceFilterValues(value)
            setSelectedMinPrice(value[0])
            setSelectedMaxPrice(value[1])
        }
    }

    useEffect(() => {
        setPriceFilterValues([minPrice, maxPrice])
    }, [minPrice, maxPrice])

    function handleAddDepartureTag(tag: string): void {
        /**  Check if the tag is not already in the tags array */
        if (!departurePortFilterValue.includes(tag)) {
            setDeparturePortFilterValue([...departurePortFilterValue, tag])
        }
    }

    function handleAddArrivalTag(tag: string): void {
        /** Check if the tag is not already in the tags array */
        if (!arrivalPortFilterValue.includes(tag)) {
            setArrivalPortFilterValue([...arrivalPortFilterValue, tag])
        }
    }

    function handleAddVisitingTag(tag: string): void {
        /** Check if the tag is not already in the tags array */
        if (!visitingPortFilterValue.includes(tag)) {
            setVisitingPortFilterValue([...visitingPortFilterValue, tag])
        }
    }
    function handleAddRegionTag(tag: string): void {
        /** Check if the tag is not already in the tags array */
        if (!regionFilterValue.includes(tag)) {
            setRegionFilterValue([...regionFilterValue, tag])
        }
    }

    function handleRemoveDepartureTag(tag: string): void {
        const newTags = departurePortFilterValue.filter((aDepartureTag) => aDepartureTag !== tag)
        setDeparturePortFilterValue(newTags)
    }

    function handleRemoveArrivalTag(tag: string): void {
        const reducedTags = arrivalPortFilterValue.filter((anArrivalTag) => anArrivalTag !== tag)
        setArrivalPortFilterValue(reducedTags)
    }

    function handleRemoveVisitingTag(tag: string): void {
        const reducedTags = visitingPortFilterValue.filter((anVisitingTag) => anVisitingTag !== tag)
        setVisitingPortFilterValue(reducedTags)
    }
    function handleRemoveRegion(tag: string): void {
        const reducedTags = regionFilterValue.filter((anVisitingTag) => anVisitingTag !== tag)
        setRegionFilterValue(reducedTags)
    }

    const departureTagsSection = (
        <TagsContainer>
            {departurePortFilterValue.map((tag) => (
                <Tag key={tag} label={tag} onClose={(): void => handleRemoveDepartureTag(tag)} />
            ))}
        </TagsContainer>
    )

    const arrivalTagsSection = (
        <TagsContainer>
            {arrivalPortFilterValue.map((tag) => (
                <Tag key={tag} label={tag} onClose={(): void => handleRemoveArrivalTag(tag)} />
            ))}
        </TagsContainer>
    )

    const visitingTagsSection = (
        <TagsContainer>
            {visitingPortFilterValue.map((tag) => (
                <Tag key={tag} label={tag} onClose={(): void => handleRemoveVisitingTag(tag)} />
            ))}
        </TagsContainer>
    )

    const regionTagsSection = (
        <TagsContainer>
            {regionFilterValue.map((tag) => (
                <Tag key={tag} label={tag} onClose={(): void => handleRemoveRegion(tag)} />
            ))}
        </TagsContainer>
    )

    return (
        <div className={styles['filter__container']}>
            <div className={styles['filter__heading']}>
                <Heading heading='2' size='3' onDarkBackground={true}>
                    {content.sectionTitle}
                </Heading>
                <Button
                    type='button'
                    flavour='tertiary'
                    onDarkBackground={true}
                    text={content.clearButton}
                    onClick={clearFilters}
                />
            </div>
            <div className={styles['filter__items']}>
                {maxPrice !== 0 && ( // THIS IS NOT IDEAL, but initial values before filter processing is finished aren't updated in the component if rendered with default values... i.e. bad max/min values
                    <>
                        <Accordion
                            title={<Text weight='bold'>{content.headings.priceRange}</Text>}
                            baseId='price-range-filter'
                            startOpen={true}
                            isPadded={true}
                        >
                            <Slider
                                minValue={minPrice}
                                maxValue={maxPrice}
                                onChange={handlePriceChange}
                                value={priceFilterValues}
                                unit={currency}
                                ariaLabel='Price range filter'
                            />
                        </Accordion>
                        <ColoredLine />
                    </>
                )}
                {!!SupplierNameCheckboxOptions.length && (
                    <>
                        <Accordion
                            title={<Text weight='bold'>{content.headings.supplier}</Text>}
                            baseId='cruise-line-filter'
                            startOpen={true}
                            isPadded={true}
                        >
                            <div className={styles['filter__checkbox-group']}>
                                {SupplierNameCheckboxOptions}
                            </div>
                        </Accordion>
                        <ColoredLine />
                    </>
                )}
                {!!ShipNameCheckboxOptions.length && (
                    <>
                        <Accordion
                            title={<Text weight='bold'>{content.headings.ship}</Text>}
                            baseId='ship-name-filter'
                            startOpen={true}
                            isPadded={true}
                        >
                            <div className={shipContainerClassnames}>{ShipNameCheckboxOptions}</div>
                        </Accordion>
                        <ColoredLine />
                    </>
                )}
                {!!CabinTypeCheckboxOptions.length && (
                    <>
                        <Accordion
                            title={<Text weight='bold'>{content.headings.cabin}</Text>}
                            baseId='cabin-type-filter'
                            startOpen={false}
                            isPadded={true}
                        >
                            <div className={styles['filter__checkbox-group']}>
                                {CabinTypeCheckboxOptions}
                            </div>
                        </Accordion>
                        <ColoredLine />
                    </>
                )}
                <Accordion
                    title={<Text weight='bold'>{content.headings.cruiseName}</Text>}
                    baseId='cruise-name-filter'
                    startOpen={true}
                    isPadded={true}
                >
                    <Autosuggest
                        getSuggestionValue={(value: string): string => {
                            return value
                        }}
                        id='product-name-autosuggest'
                        inputValue={cruiseProductNameFilterValue}
                        name='autosuggest-cruise-product-filter'
                        onBlur={setCruiseProductNameFilterValue}
                        openSuggestionsOnFocus={cruiseProductNameFilterValue?.length > 0}
                        onSuggestionsClearRequested={(): void => setProductNameSuggestions([])}
                        renderSuggestion={(value: string): React.ReactElement => (
                            <span>{value}</span>
                        )}
                        onSuggestionSelected={setCruiseProductNameFilterValue}
                        setInputValue={setCruiseProductNameFilterValue}
                        suggestionsData={productNameSuggestions}
                        suggestionsFetchRequest={getProductSuggestionsList}
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <LabelledInput
                                htmlFor='cruise-name-filter-input'
                                label={content.inputLabels.cruiseNameInputLabel}
                                labelHidden={true}
                                {...rest}
                            >
                                <TextInput clearable={true} />
                            </LabelledInput>
                        )}
                    />
                </Accordion>
                <ColoredLine />
                <Accordion
                    title={<Text weight='bold'>{content.headings.departurePort}</Text>}
                    baseId='departure-port-filter'
                    startOpen={true}
                    isPadded={true}
                >
                    <Autosuggest
                        getSuggestionValue={(value: MetaDataItem): string => {
                            return value.name
                        }}
                        id='departure-autosuggest'
                        inputValue={departureInputValue}
                        name='autosuggest-departure-port-filter'
                        openSuggestionsOnFocus={departureInputValue?.length > 0}
                        setInputValue={setDepartureInputValue}
                        onSuggestionSelected={(value: string): void => {
                            if (value) handleAddDepartureTag(value)
                            setDepartureInputValue('')
                        }}
                        renderSuggestion={(value: MetaDataItem): React.ReactElement => (
                            <Text>{`${value.name} (${value.hits})`}</Text>
                        )}
                        onSuggestionsClearRequested={(): void => setDeparturePortSuggestions([])}
                        suggestionsData={departurePortSuggestions}
                        suggestionsFetchRequest={(searchString: string): void =>
                            handleAutoSuggestDeparturePortSearchString({ searchString })
                        }
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <div>
                                {departureTagsSection}
                                <LabelledInput
                                    htmlFor='departure-port-filter-input'
                                    label={content.inputLabels.departurePortInputLabel}
                                    labelHidden={true}
                                    {...rest}
                                >
                                    <TextInput clearable={true} />
                                </LabelledInput>
                            </div>
                        )}
                    />
                </Accordion>
                <ColoredLine />
                <Accordion
                    title={<Text weight='bold'>{content.headings.arrivalPort}</Text>}
                    baseId='arrival-port-filter'
                    startOpen={true}
                    isPadded={true}
                >
                    <Autosuggest
                        openSuggestionsOnFocus={arrivalInputValue?.length > 0}
                        inputValue={arrivalInputValue}
                        setInputValue={setArrivalInputValue}
                        id='arrival-autosuggest'
                        getSuggestionValue={(value: MetaDataItem): string => {
                            return value.name
                        }}
                        onSuggestionSelected={(value: string): void => {
                            if (value) handleAddArrivalTag(value)
                            setArrivalInputValue('')
                        }}
                        name='autosuggest-arrival-port-filter'
                        renderSuggestion={(value: MetaDataItem): React.ReactElement => (
                            <Text>{`${value.name} (${value.hits})`}</Text>
                        )}
                        onSuggestionsClearRequested={(): void => setArrivalPortSuggestions([])}
                        suggestionsData={arrivalPortSuggestions}
                        suggestionsFetchRequest={(searchString: string): void =>
                            handleAutoSuggestArrivalPortSearchString({ searchString })
                        }
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <div>
                                {arrivalTagsSection}
                                <LabelledInput
                                    htmlFor='arrival-port-filter-input'
                                    label={content.inputLabels.arrivalPortInputLabel}
                                    labelHidden={true}
                                    {...rest}
                                >
                                    <TextInput clearable={true} />
                                </LabelledInput>
                            </div>
                        )}
                    />
                </Accordion>
                <ColoredLine />
                <Accordion
                    title={<Text weight='bold'>{content.headings.visitingPort}</Text>}
                    baseId='visiting-port-filter'
                    startOpen={true}
                    isPadded={true}
                >
                    <Autosuggest
                        getSuggestionValue={(value: MetaDataItem): string => {
                            return value.name
                        }}
                        id='visiting-port-autosuggest'
                        inputValue={visitingInputValue}
                        name='autosuggest-visiting-port-filter'
                        openSuggestionsOnFocus={visitingInputValue?.length > 0}
                        setInputValue={setVisitingInputValue}
                        onSuggestionSelected={(value: string): void => {
                            if (value) handleAddVisitingTag(value)
                            setVisitingInputValue('')
                        }}
                        renderSuggestion={(value: MetaDataItem): React.ReactElement => (
                            <Text>{`${value.name} (${value.hits})`}</Text>
                        )}
                        onSuggestionsClearRequested={(): void => setVisitingPortSuggestions([])}
                        suggestionsData={visitingPortSuggestions}
                        suggestionsFetchRequest={(searchString: string): void =>
                            handleAutoSuggestVisitingPortSearchString({ searchString })
                        }
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <div>
                                {visitingTagsSection}
                                <LabelledInput
                                    htmlFor='visiting-port-filter-input'
                                    label={content.inputLabels.visitingPortInputLabel}
                                    labelHidden={true}
                                    {...rest}
                                >
                                    <TextInput clearable={true} />
                                </LabelledInput>
                            </div>
                        )}
                    />
                </Accordion>
                <ColoredLine />
                <Accordion
                    title={<Text weight='bold'>{content.headings.region}</Text>}
                    baseId='region-filter'
                    startOpen={true}
                    isPadded={true}
                >
                    <Autosuggest
                        openSuggestionsOnFocus={regionInputValue?.length > 0}
                        inputValue={regionInputValue}
                        setInputValue={setRegionInputValue}
                        id='region-autosuggest'
                        getSuggestionValue={(value: MetaDataItem): string => {
                            return value.name
                        }}
                        onSuggestionSelected={(value: string): void => {
                            if (value) handleAddRegionTag(value)
                            setRegionInputValue('')
                        }}
                        name='autosuggest-region-filter'
                        renderSuggestion={(value: MetaDataItem): React.ReactElement => (
                            <Text>{`${value.name} (${value.hits})`}</Text>
                        )}
                        onSuggestionsClearRequested={(): void => setRegionSuggestions([])}
                        suggestionsData={regionSuggestions}
                        suggestionsFetchRequest={(searchString: string): void =>
                            handleAutoSuggestRegionSearchString({ searchString })
                        }
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <div>
                                {regionTagsSection}
                                <LabelledInput
                                    htmlFor='region-filter-input'
                                    label={content.inputLabels.regionInputLabel}
                                    labelHidden={true}
                                    {...rest}
                                >
                                    <TextInput clearable={true} />
                                </LabelledInput>
                            </div>
                        )}
                    />
                </Accordion>
            </div>
        </div>
    )
}

export default FilterSection
