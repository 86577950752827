import React from 'react'
import { Controller } from 'react-hook-form'

import FieldError from 'components/basics/FieldError/FieldError'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { GroupLabel } from 'components/blocks/LabelledInput/LabelledInput'
import { REGEX } from 'utils/constants'

import styles from './DateTextInput.module.scss'
import allContent from 'content/content'

const content = allContent.components.dateTextInput

export interface DateTextInputProps {
    label: string
    required?: boolean
    disabled?: boolean
    generalError?: boolean
    errorMessage?: string
    errors: any
    control: any
    setValue: (name: any, value: string) => void
    validationCheck?: any
    monthFieldName: string
    dayFieldName: string
    yearFieldName: string
    monthFieldLabel: string
    dayFieldLabel: string
    yearFieldLabel: string
}

const DateTextInput: React.FC<DateTextInputProps> = ({
    label,
    required,
    disabled,
    control,
    errors,
    setValue,
    validationCheck,
    generalError,
    errorMessage,
    monthFieldName,
    dayFieldName,
    yearFieldName,
    monthFieldLabel,
    dayFieldLabel,
    yearFieldLabel,
}) => {
    const handleChange =
        (field: string) =>
        (value: string): void => {
            setValue(field, value)
            if (validationCheck) validationCheck()
        }

    return (
        <div className={styles['container']}>
            <fieldset disabled={disabled} className={styles['inputs-group--horizontal']}>
                <legend className={styles['label']}>
                    <GroupLabel required={required}>{label}</GroupLabel>
                </legend>
                <Controller
                    control={control}
                    name={monthFieldName}
                    rules={{
                        required,
                        minLength: 1,
                        maxLength: 2,
                        pattern: REGEX.MONTH,
                    }}
                    render={({ field: { value } }): React.ReactElement => (
                        <div>
                            <TextInput
                                inputMode='numeric'
                                regExp={REGEX.DIGITS_0_to_2}
                                aria-label={monthFieldLabel}
                                value={value}
                                onChangeCallback={handleChange(monthFieldName)}
                                required={required}
                                placeholder={'MM'}
                                className={styles['month-input']}
                                isErrored={!!errors[monthFieldName]}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name={dayFieldName}
                    rules={{
                        required,
                        minLength: 1,
                        maxLength: 2,
                        pattern: REGEX.DAY,
                    }}
                    render={({ field: { value } }): React.ReactElement => (
                        <div>
                            <TextInput
                                inputMode='numeric'
                                regExp={REGEX.DIGITS_0_to_2}
                                aria-label={dayFieldLabel}
                                value={value}
                                onChangeCallback={handleChange(dayFieldName)}
                                required={required}
                                placeholder={'DD'}
                                className={styles['day-input']}
                                isErrored={!!errors[dayFieldLabel]}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name={yearFieldName}
                    rules={{
                        required,
                        minLength: 4,
                        maxLength: 4,
                        pattern: REGEX.YEAR_1900_to_2099,
                    }}
                    render={({ field: { value } }): React.ReactElement => (
                        <div>
                            <TextInput
                                inputMode='numeric'
                                regExp={REGEX.DIGITS_0_to_4}
                                aria-label={yearFieldLabel}
                                value={value}
                                onChangeCallback={handleChange(yearFieldName)}
                                required={required}
                                placeholder={'YYYY'}
                                className={styles['year-input']}
                                isErrored={!!errors[yearFieldName]}
                            />
                        </div>
                    )}
                />
            </fieldset>
            <FieldError
                inputId={monthFieldName}
                showError={!!errors[monthFieldName]}
                errorMessage={content.errors.month}
            />
            <FieldError
                inputId={dayFieldName}
                showError={!!errors[dayFieldName]}
                errorMessage={content.errors.day}
            />
            <FieldError
                inputId={yearFieldName}
                showError={!!errors[yearFieldName]}
                errorMessage={content.errors.year}
            />
            <FieldError
                inputId={monthFieldName}
                showError={!!generalError}
                errorMessage={errorMessage ?? content.errors.generalError}
            />
        </div>
    )
}

export default DateTextInput
