import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    DefaultOptions,
    ApolloError,
    InMemoryCache,
    NormalizedCacheObject,
    Observable,
    Operation,
} from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { v4 } from 'uuid'
import { getCurrentAuthData } from 'utils/auth-header'
import { RetryLink } from '@apollo/client/link/retry'

/** number of times to attempt a request before failing, 3 means: 1 request and 2 retry attempts */
const NUMBER_OF_REQUEST_ATTEMPTS = 3

/** Only retry queries with these names */
const QUERIES_TO_RETRY: QueriesNamesToRetry[] = ['GetCruiseByRateAndGrade']

type QueriesNamesToRetry = 'GetCruiseByRateAndGrade'

/** retry these status codes only */
const RETRY_CODES = [504]

/** Custom fetch handles response when status is not OK, so we get the rest status in our body, so a 200 graphql isn't hiding a 304 api response. */
export async function customFetch(uri: string, options: RequestInit): Promise<Response> {
    return fetch(uri, options).then((response) => {
        // The status and statusText attributes are added to the response body if response.ok is not true
        if (response.ok) return response

        return response.text().then((body) => {
            let data = ''
            /** Only parse the body if response isn't empty otherwise status won't get passed further */
            if (response.body && JSON.stringify(response) !== '{}') data = JSON.parse(body)
            return new Response(
                JSON.stringify({
                    data: data,
                    status: response.status,
                }),
                {
                    status: response.status,
                }
            )
        })
    })
}

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache', // override default fetchPolicy of 'cache-and-network'
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache', // for live pricing etc. do not cache
        errorPolicy: 'all', // override default errorPolicy of 'none'
    },
}

export const l3Options: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-first', // check cache first as data is not changing
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'cache-first', // check cache first as data is not changing
        errorPolicy: 'all', // override default errorPolicy of 'none'
    },
}

export const logError = (error: ApolloError, operation: Operation, source: string): void => {
    const userContext = datadogLogs.getGlobalContext()
    const context = operation.getContext()

    const correlationId = context.headers['x-correlation-id']
    datadogLogs.logger.error(
        `Create-apollo-client Error, source: ${source}, operationName: ${
            operation.operationName
        }, variables: ${JSON.stringify(operation.variables)}, x-correlation-id: ${correlationId}`,
        {
            'x-correlation-id': correlationId,
            correlation_id: correlationId, // NOTE - this is to try to match api
            operationName: operation.operationName,
            query: operation.query,
            variables: operation.variables,
            userContext: userContext,
        },
        error
    )
}

// Create a custom Apollo link to intercept requests
const tracedAuthLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
        /** To make this async (so we can get await getCurrentAuthData) this is now an IICF - self calling callback */
        ;(async (): Promise<any> => {
            const userContext = datadogLogs.getGlobalContext()
            const correlationId = v4() // Generate correlation ID for the request

            /** Log GraphQL request with correlation ID */
            datadogLogs.logger.info(
                `Create-apollo-client Request, operationName: ${
                    operation.operationName
                }, variables: ${JSON.stringify(
                    operation.variables
                )}, x-correlation-id: ${correlationId}`,
                {
                    'x-correlation-id': correlationId,
                    correlation_id: correlationId, // NOTE - this is to try to match api
                    operationName: operation.operationName,
                    query: operation.query,
                    variables: operation.variables,
                    userContext: userContext,
                }
            )

            try {
                const { accessToken, idToken } = await getCurrentAuthData()

                /** Add correlation ID and auth headers into request headers */
                operation.setContext({
                    headers: {
                        'x-correlation-id': correlationId,
                        ...(accessToken && { authorization: accessToken }), // EKS
                        ...(accessToken && { authorizationToken: accessToken }), // connect-manager-service
                        ...(accessToken && { 'id-token': idToken }), // not sure... but latest format needed
                        ...(accessToken && {
                            tokens: JSON.stringify({
                                authorization_token: accessToken, // jarvis
                                id_token: idToken, // EKS
                            }),
                        }),
                    },
                })

                /** Continue with the request */
                const subscription = forward(operation).subscribe({
                    next: (result) => {
                        /** Log GraphQL response with correlation ID */
                        datadogLogs.logger.info(
                            `Create-apollo-client Response, operationName: ${
                                operation.operationName
                            }, variables: ${JSON.stringify(
                                operation.variables
                            )}, x-correlation-id: ${correlationId}`,
                            {
                                'x-correlation-id': correlationId,
                                correlation_id: correlationId, // NOTE - this is to try to match api
                                operationName: operation.operationName,
                                query: operation.query,
                                variables: operation.variables,
                                userContext: userContext,
                            }
                        )
                        observer.next(result)
                    },
                    error: (error) => {
                        logError(error, operation, 'TraceLink')
                        observer.error(error)
                    },
                    complete: () => {
                        observer.complete()
                    },
                })

                /** Return subscription */
                return (): void => {
                    if (!subscription.closed) {
                        subscription.unsubscribe()
                    }
                }
            } catch (error) {
                observer.error(error)
            }
        })() // called immediately
    })
})

const retryIf = (error: any, operation: Operation): boolean => {
    logError(error, operation, 'RetryLink')
    return (
        !!error &&
        RETRY_CODES.includes(error.statusCode) &&
        QUERIES_TO_RETRY.includes(operation.operationName as QueriesNamesToRetry)
    )
}

const retryLink = new RetryLink({
    attempts: {
        max: NUMBER_OF_REQUEST_ATTEMPTS,
        retryIf,
    },
})

export function createApolloClient(
    uri: string | undefined,
    options: DefaultOptions = defaultOptions
): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
        uri: uri ?? '',
        fetch: customFetch,
    })

    return new ApolloClient({
        /** HttpLink is used by default, but manually setting allows us to specify a fetch API */
        link: ApolloLink.from([tracedAuthLink, retryLink, httpLink]),
        /** caches user queries in-memory to reduce redundant fetches */
        cache: new InMemoryCache(),
        defaultOptions: options,
    })
}

export default createApolloClient
