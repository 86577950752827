import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import Spacing from 'components/basics/Spacing/Spacing'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { REGEX, USER_ROLES } from 'utils/constants'
import { getCognitoErrorMessage } from 'utils/cognito-helpers/get-cognito-error-message'
import { extractCognitoFields } from 'utils/cognito-helpers/extract-cognito-fields'
import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './ResetPasswordForm.module.css'
import allContent from 'content/content'

const content = allContent.cognito.resetPasswordForm
const cogntioErrors = allContent.cognito.cognitoErrors

type ResetPasswordDataType = {
    email: string
    code: string
    newPassword: string
}

type ResetPasswordFormProps = {
    setUserData: React.Dispatch<React.SetStateAction<GlobalContextUserData>>
    email: string
}
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
    email,
    setUserData,
}: ResetPasswordFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [resetPasswordApiError, setResetPasswordApiError] = useState<string>('')
    const navigate = useNavigate()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            email: email,
            code: '',
            newPassword: '',
        },
    })

    function handleGoToLogin(): void {
        navigate(ROUTES.ROOT)
    }

    async function handleSubmitClick(formFields: ResetPasswordDataType): Promise<void> {
        setIsSubmitting(true)
        await Auth.forgotPasswordSubmit(
            formFields.email,
            formFields.code.trim(), // Use Trim to remove leading and trailing whitespace - common error from user copying and pasting code from email
            formFields.newPassword
        )
            .then((response) => {
                datadogLogs.logger.info(
                    `source: Login - reset password form ${response} (goto login), userEmail: ${formFields.email}`,
                    { userContext: { userEmail: formFields.email } }
                )

                /** Need to log in now (with new password) to get full user data */
                Auth.signIn(formFields.email, formFields.newPassword)
                    .then((user) => {
                        const userData = extractCognitoFields(user)
                        datadogLogs.logger.info(
                            `source: Login POST reset password, userEmail: ${formFields.email}`,
                            {
                                userContext: userData,
                            }
                        )
                        if (!userData.isActiveTenant) {
                            datadogLogs.logger.info(
                                `source: Login POST reset password INACTIVE TENANT, userEmail: ${formFields.email}`,
                                {
                                    userContext: userData,
                                }
                            )
                            navigate(ROUTES.INACTIVE_TENANT, {
                                state: {
                                    isInactiveTenant: true,
                                },
                            })
                        } else if (userData.userRoles.includes(USER_ROLES.API_USER)) {
                            datadogLogs.logger.info(
                                `source: Login POST reset password API USER, userEmail: ${formFields.email}`,
                                {
                                    userContext: userData,
                                }
                            )
                            navigate(ROUTES.API_USER_WARNING, {
                                state: {
                                    isApiUser: true,
                                    email: email,
                                },
                            })
                        } else {
                            setUserData(userData)
                            datadogLogs.setGlobalContext(userData)
                            navigate(ROUTES.ROOT)
                        }
                    })
                    .catch((error) => {
                        setIsSubmitting(false)
                        setResetPasswordApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                        datadogLogs.logger.warn(
                            `source: LOGIN POST reset password, error using email: ${formFields.email}`,
                            { userEmail: formFields.email },
                            error
                        )
                    })
            })
            .catch((error) => {
                setResetPasswordApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                datadogLogs.logger.error(
                    `Error thrown in ResetPassword Auth.currentAuthenticatedUser, userEmail: ${formFields.email}`,
                    { userEmail: formFields.email },
                    error
                )
                setIsSubmitting(false)
            })
    }

    return (
        <>
            <div className={styles.container}>
                <Heading heading='1'>{content.title}</Heading>
                <Spacing />
                {!isSubmitting ? (
                    <form
                        className={styles.form}
                        onSubmit={handleSubmit(
                            async (formFields: ResetPasswordDataType) =>
                                await handleSubmitClick(formFields)
                        )}
                    >
                        <Controller
                            control={control}
                            name='email'
                            rules={{
                                required: true,
                                minLength: 1,
                                maxLength: 300,
                                pattern: REGEX.EMAIL,
                            }}
                            render={({ field: { onChange, onBlur } }): React.ReactElement => (
                                <div className={styles['input-wrapper']}>
                                    <LabelledInput
                                        required={true}
                                        htmlFor='email'
                                        label={content.emailInput}
                                        aria-describedby='email-error-message'
                                    >
                                        <TextInput
                                            value={email}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            disabled={!!email}
                                        />
                                    </LabelledInput>
                                    <FieldError
                                        inputId='email'
                                        showError={!!errors.email}
                                        errorMessage={content.errors.emailInput}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            control={control}
                            name='code'
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value },
                            }): React.ReactElement => (
                                <div className={styles['input-wrapper']}>
                                    <LabelledInput
                                        required={true}
                                        htmlFor='code'
                                        label={content.codeInput}
                                        isErrored={!!errors.code}
                                        aria-describedby='confirmation-code-error-message'
                                    >
                                        <TextInput
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        />
                                    </LabelledInput>
                                    <FieldError
                                        inputId='code'
                                        showError={!!errors.code}
                                        errorMessage={content.errors.codeInput}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            control={control}
                            name='newPassword'
                            rules={{
                                required: true,
                                minLength: 10,
                                maxLength: 30,
                                pattern: REGEX.PASSWORD,
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }): React.ReactElement => (
                                <div className={styles['input-wrapper']}>
                                    <LabelledInput
                                        required={true}
                                        htmlFor='new-password'
                                        label={content.passInput}
                                        aria-describedby='password-error-message'
                                        hint={REGEX.PASSWORD_TOOL_TIP}
                                    >
                                        <PasswordInput
                                            autoComplete='off'
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        />
                                    </LabelledInput>
                                    <FieldError
                                        inputId='new-password'
                                        showError={!!errors.newPassword}
                                        errorMessage={content.errors.passInput}
                                    />
                                </div>
                            )}
                        />
                        <div className={styles['button-wrapper']}>
                            <Button
                                type='button'
                                flavour='tertiary'
                                disabled={isSubmitting}
                                text={content.cancelButton}
                                onClick={handleGoToLogin}
                            />
                            <Button
                                type='submit'
                                flavour='primary'
                                text={isSubmitting ? content.submitting : content.submitButton}
                                showSpinner={isSubmitting}
                            />
                        </div>
                    </form>
                ) : (
                    <LargeSpinner text={content.submitting} hideText={true} />
                )}
            </div>
            {!!resetPasswordApiError && (
                <InfoBanner
                    id='reset-password-api-error'
                    bannerType='error'
                    text={resetPasswordApiError}
                    isFocusable={true}
                    logType='warn'
                    source='reset-password-page'
                    buttonText={
                        resetPasswordApiError === cogntioErrors.expiredCode
                            ? content.goToForgotPasswordButton
                            : undefined
                    }
                    onButtonClick={(): void => navigate(ROUTES.FORGOT_PASSWORD)}
                />
            )}
        </>
    )
}

export default ResetPasswordForm
