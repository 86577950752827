type ReturnType = GlobalContextUserData & { allData: GlobalContextUserData }

/** The user data we store in our user context is */
export const extractGlobalContextUserData = (userData?: GlobalContextUserData): ReturnType => {
    const challengeName = userData?.challengeName ?? ''
    const companyName = userData?.companyName ?? ''
    const companyTier = userData?.companyTier ?? undefined
    const financeSystemId = userData?.financeSystemId ?? ''
    const firstName = userData?.firstName ?? ''
    const isActiveTenant = userData?.isActiveTenant ?? true
    const lastName = userData?.lastName ?? ''
    const tenantId = userData?.tenantId ?? ''
    const userId = userData?.userId ?? ''
    const userEmail = userData?.userEmail ?? ''
    const userExpiry = userData?.userExpiry
    const userName = userData?.userName ?? ''
    const userRoles = userData?.userRoles ?? []
    const allData = {
        challengeName,
        companyName,
        companyTier,
        financeSystemId,
        firstName,
        isActiveTenant,
        lastName,
        tenantId,
        userId,
        userEmail,
        userExpiry,
        userName,
        userRoles,
    }

    return {
        allData,
        challengeName,
        companyName,
        companyTier,
        firstName,
        financeSystemId,
        isActiveTenant,
        lastName,
        tenantId,
        userId,
        userEmail,
        userExpiry,
        userName,
        userRoles,
    }
}
