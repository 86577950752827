import { datadogLogs } from '@datadog/browser-logs'
import { NavigateFunction } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

import { ROUTES } from 'components/sections/app/AppRoutes'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { COGNITO_ACTIONS } from 'utils/constants'
import { extractCognitoFields } from './extract-cognito-fields'

export const REMAINING_SESSION_TIME_TO_TRIGGER_REFRESH = 50 * 60 * 1000 // 50 mins left do refresh, i.e. 10 mins has passed

export async function refreshSession({
    navigate,
    userContext,
    setUserData,
}: {
    navigate: NavigateFunction
    userContext: GlobalContextUserData
    setUserData?: (userData: GlobalContextUserData) => void
}): Promise<void> {
    await Auth.currentSession().then(async (currentSession) => {
        const refreshToken = currentSession?.getRefreshToken()
        await Auth.currentAuthenticatedUser()
            .then((currentAuthenticatedUser) => {
                const oldUserContext = extractCognitoFields(currentAuthenticatedUser)
                currentAuthenticatedUser?.refreshSession(
                    refreshToken,
                    (error: Error, session: CognitoUserSession) => {
                        if (error) {
                            datadogLogs.logger.error(
                                'Unable to refreshSession on current user',
                                { userContext: oldUserContext },
                                error
                            )
                            navigate(ROUTES.TIMEOUT) // Handle timeout or session expiration
                            return
                        }

                        /** Extract all the latest live user data from refreshed userSession */
                        const updatedUserContext = extractCognitoFields({
                            signInUserSession: {
                                idToken: { payload: session.getIdToken().payload },
                            },
                        })
                        if (!updatedUserContext.isActiveTenant) {
                            datadogLogs.logger.warn(
                                `source: CheckUserSessionRefreshNeeded, now inactive tenant, userEmail: ${updatedUserContext.userEmail}`,
                                {
                                    userContext: { userEmail: updatedUserContext.userEmail },
                                }
                            )
                            navigate(ROUTES.INACTIVE_TENANT, { state: { isInactiveTenant: true } })
                        } else {
                            setUserData && setUserData(updatedUserContext)
                            datadogLogs.setGlobalContext(updatedUserContext)
                        }
                    }
                )
            })
            .catch((error) => {
                datadogLogs.logger.error(
                    'Unable to get authenticated user during session refresh',
                    { userContext },
                    error
                )
                navigate(ROUTES.TIMEOUT)
            })
    })
}

/** Checks for user data, times out if none found, uses expiry to decide if refresh should happen, goes to timeout if error occurs during refresh */
export async function checkAndPerformUserSessionRefreshIfNeeded(
    navigate: NavigateFunction
): Promise<void> {
    const {
        userExpiry,
        userId,
        allData: userContext,
        challengeName,
    } = extractGlobalContextUserData(datadogLogs.getGlobalContext() as GlobalContextUserData)

    /** Do not try to refresh Password challenge users - they have logged on but don't yet have expiry and can't be refreshed.
     * (Should never really trigger this function as can't get to any of the app.) */
    if (challengeName === COGNITO_ACTIONS.new_password) {
        return
    }

    /** Trigger sign out (via timeout page) if no userId or expiry is found. */
    if (!userId || !userExpiry) {
        navigate(ROUTES.TIMEOUT)
        return
    }

    /** Skip refresh unless in the last xx min of session */
    const timeTillExpiryMs = userExpiry - Date.now()
    const shouldRefresh = timeTillExpiryMs <= REMAINING_SESSION_TIME_TO_TRIGGER_REFRESH
    if (!shouldRefresh) {
        return
    }

    /** Attempt to refresh session */
    await refreshSession({ navigate, userContext })
}
