const msPerMinute = 60000

type SetDataToLocalStorage = {
    data: Record<string, any>
    key: string
    expiryMins?: number
}

/**
 * setDataToLocalStorage: sets a JSON of the searchBarData to localStorage under
 * the key passed in along with expiry value if expiryMins value is passed in,
 * otherwise without it.
 @param data - { object } of key/value pairs for data being set to local storage
 @param key - string to store data under
 @param expiryMins - number of minutes used to set expiry on data if provided
 */
export const setDataToLocalStorage = ({ data, key, expiryMins }: SetDataToLocalStorage): void => {
    if (expiryMins) {
        /** set expiry as the current time + a number of minutes converted to ms */
        const expiry = { expiry: Date.now() + expiryMins * msPerMinute }
        /** set searchBarData + expiry to localStorage under the key passed in */
        window.localStorage.setItem(key, JSON.stringify({ ...data, ...expiry }))
    } else {
        /** set searchBarData to localStorage under the key passed in */
        window.localStorage.setItem(key, JSON.stringify({ ...data }))
    }
}

/**
 * getDataFromLocalStorage: returns a JS Object parsed from the value of the key
 * passed in or undefined if not present.
 * If the data contains an expiry time, it will delete the data and return
 * undefined if that time has passed.
 @param key - string to fetch data from
 * @param withExpiry
 */
export const getDataFromLocalStorage = ({
    key,
    withExpiry = false,
}: {
    key: string
    withExpiry?: boolean
}): Record<string, any> | undefined => {
    const data = window.localStorage.getItem(key)
    /** if previousSearchData has not been previously set return undefined */
    if (!data) return undefined

    /** if data is found then parse it to a JS Object*/
    const parsedData = JSON.parse(data)

    /** If data contains expiry value, and current time is greater than it
     *  then return undefined + remove data from localStorage */
    if (parsedData.expiry && parseInt(parsedData.expiry) < Date.now()) {
        window.localStorage.removeItem(key)
        return undefined
    }

    /** return the data, without the expiry key/value */
    if (!withExpiry) {
        const { expiry, ...dataWithoutExpiry } = parsedData
        return dataWithoutExpiry
    }
    return parsedData
}

/**
 * removeDataFromLocalStorage: remove data form LocalStorage by key
 @param key - string to remove data from
 */
export const removeDataFromLocalStorage = ({ key }: { key: string }): undefined => {
    const data = window.localStorage.getItem(key)
    if (data) {
        window.localStorage.removeItem(key)
    }
    return undefined
}
