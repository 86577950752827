import React from 'react'

import nudgerStyles from './Nudger.module.scss'
import Text from '../Text/Text'
import Button from '../Button/Button'
import allContent from 'content/content'

const content = allContent.components.nudger

type NudgerProps = {
    labelText: string
    subLabelText?: string
    minCounterValue?: number
    maxCounterValue?: number
    onChange(value: number): void
    disabled?: boolean
    value?: number
}

const Nudger: React.FC<NudgerProps> = ({
    onChange,
    minCounterValue = 0,
    maxCounterValue = 999,
    value = 0,
    labelText,
    subLabelText,
    disabled = false,
}) => {
    const decreaseDisable = disabled || minCounterValue === value
    const increaseDisable = disabled || maxCounterValue === value

    function increaseCounter(): void {
        onChange(value + 1)
    }

    function decreaseCounter(): void {
        onChange(value - 1)
    }

    return (
        <div className={nudgerStyles.container}>
            <div className={nudgerStyles['text-container']}>
                <Text weight='bold' size='M'>
                    {labelText}
                </Text>
                {subLabelText && (
                    <Text weight='normal' size='S'>
                        {subLabelText}
                    </Text>
                )}
            </div>
            <div className={nudgerStyles['button-container']}>
                <Button
                    type='button'
                    flavour='icon'
                    iconName='CircleMinus'
                    text={`${content.decreaseCounter} - ${labelText}`}
                    onClick={decreaseCounter}
                    disabled={decreaseDisable}
                />
                <Text weight='normal' size='S' className={nudgerStyles.counter}>
                    {value}
                </Text>
                <Button
                    type='button'
                    flavour='icon'
                    iconName='CirclePlus'
                    text={`${content.increaseCounter} - ${labelText}`}
                    onClick={increaseCounter}
                    disabled={increaseDisable}
                />
            </div>
        </div>
    )
}

export default Nudger
