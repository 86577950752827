import React from 'react'
import classnames from 'classnames'
import descriptionListStyles from './DescriptionList.module.css'

type DescriptionListItemKeyProps = React.AllHTMLAttributes<HTMLElement> & {
    /** optional: class-names */
    className?: string
    /** children always of type ReactNode */
    children: React.ReactNode
}

/** DescriptionListItemKey: renders a DT tag */
const DescriptionListItemKey: React.FC<DescriptionListItemKeyProps> = ({
    className,
    children,
    ...rest
}: DescriptionListItemKeyProps) => {
    const classNames = classnames(descriptionListStyles['item-key'], className)

    return (
        <dt className={classNames} {...rest}>
            {children}
        </dt>
    )
}

export default DescriptionListItemKey
