import React from 'react'
import MuiPagination from '@mui/material/Pagination'
import { PaginationItem } from '@mui/material'

import styles from './Pagination.module.scss'

type ButtonType =
    | 'page'
    | 'first'
    | 'last'
    | 'next'
    | 'previous'
    | 'start-ellipsis'
    | 'end-ellipsis'

interface PaginationProps {
    currentPage: number
    numberOfPages: number
    onPageChange: (selected: number) => void
    pageRangeDisplayed?: number
    size?: 'small' | 'medium' | 'large'
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    numberOfPages,
    onPageChange,
    pageRangeDisplayed = 1,
    size = 'medium',
}) => {
    const ariaLabel = (type: ButtonType, page: number | null): string =>
        type === 'page' && page ? `pages-${page}-${numberOfPages}` : type
    const onChange = (_: any, page: number): void => onPageChange(page)

    return (
        <MuiPagination
            size={size}
            count={numberOfPages}
            boundaryCount={pageRangeDisplayed}
            shape='rounded'
            page={currentPage}
            defaultPage={currentPage}
            onChange={onChange}
            getItemAriaLabel={ariaLabel}
            color='primary'
            classes={{
                root: styles['controller-container'],
            }}
            renderItem={(item): any => (
                <PaginationItem
                    {...item}
                    classes={{
                        root: size === 'large' ? styles.buttons : '',
                        selected: styles['active-item'],
                        ellipsis: styles['ellipsis'],
                    }}
                />
            )}
        />
    )
}

export default Pagination
