import React from 'react'
import classnames from 'classnames'
import textStyles from './Text.module.css'

export type TextColours =
    | 'gray'
    | 'dark-gray'
    | 'tertiary-blue'
    | 'primary-blue-40'
    | 'primary-blue'
    | 'white'
    | 'red'
    | 'orange'
    | 'green'
    | 'disabled'
    | 'divider-gray'
    | 'currency-gray'

export type TextWeights = 'normal' | 'bold'
export type TextFonts = 'normal' | 'mono' | 'heading'
export type TextSizes = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL'

type TextProps = React.HTMLAttributes<HTMLSpanElement> &
    React.HTMLAttributes<HTMLParagraphElement> & {
        /** the type of Text element tag to render */
        tagName?: 'span' | 'p'
        /** optional: class-names */
        className?: string
        /** optional: specify the role for accessibility purposes when semantic role has changed */
        role?: string
        /** font style */
        font?: TextFonts
        /** children always of type ReactNode */
        children: React.ReactNode
        /** colour choice*/
        color?: TextColours
        /** XS | S | M | L | XL | XXL*/
        size?: TextSizes
        /** font weight */
        weight?: TextWeights
    }

/** Text: renders a Text tag of a type based on the tagName prop */
const Text: React.FC<TextProps> = ({
    tagName: Wrapper = 'span',
    className,
    font = 'normal',
    children,
    color = 'primary-blue',
    size = 'S', // S is body 16px
    weight = 'normal',
    ...rest
}: TextProps) => {
    const classNames = classnames(
        textStyles[`size-${size}`],
        textStyles[`weight-${weight}`],
        textStyles[`color-${color}`],
        textStyles[`font-${font}`],
        className
    )

    return (
        <Wrapper className={classNames} {...rest}>
            {children}
        </Wrapper>
    )
}

export default Text
