import React from 'react'
import ReactDatePicker, {
    ReactDatePickerCustomHeaderProps,
    ReactDatePickerProps,
} from 'react-datepicker'

import DatePickerHeader from './DatePickerHeader'
import { DATE_FORMAT_USA_SHORT } from 'utils/constants'
import './DatePicker.scss'

type DatePickerProps = ReactDatePickerProps & {
    /** number of years to show in years dropdown*/
    yearsInDropdown?: number
    /** year to start counting in selector*/
    yearsStartFrom?: number
}

/** Datepicker: returns an instance of react-datepicker (https://reactdatepicker.com/) with a custom header */
const DatePicker: React.FC<DatePickerProps> = ({
    calendarStartDay = 0, // start on Sunday (0-6, sun-sat)
    minDate = new Date(),
    dateFormat = DATE_FORMAT_USA_SHORT,
    name,
    yearsInDropdown = 7,
    yearsStartFrom,
    ...rest
}: DatePickerProps) => (
    <div>
        {/* div is needed to keep picker single child (for parent styling children using css gap etc), since the opened calendar is rendered as a sibling */}
        <ReactDatePicker
            calendarStartDay={calendarStartDay}
            dateFormat={dateFormat}
            minDate={minDate}
            name={name}
            formatWeekDay={(nameOfDay): string => nameOfDay[0]}
            {...rest}
            renderCustomHeader={({
                increaseMonth,
                decreaseMonth,
                date,
                prevMonthButtonDisabled,
                ...rest2
            }: ReactDatePickerCustomHeaderProps): React.ReactNode => (
                <DatePickerHeader
                    increaseMonth={increaseMonth}
                    decreaseMonth={decreaseMonth}
                    date={date}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    yearsInDropdown={yearsInDropdown}
                    yearsStartFrom={yearsStartFrom}
                    {...rest2}
                />
            )}
        />
    </div>
)

export default DatePicker
