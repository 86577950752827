import React from 'react'
import classnames from 'classnames'

import styles from './CategoryIndicator.module.css'
import Text from 'components/basics/Text/Text'

export type DestinationCategory =
    | 'port'
    | 'country'
    | 'cruise line'
    | 'ship'
    | 'destination'
    | 'region'

export const DESTINATION_CATEGORIES: {
    PORT: DestinationCategory
    COUNTRY: DestinationCategory
    SHIP: DestinationCategory
    SUPPLIER: DestinationCategory
    DESTINATION: DestinationCategory
    REGION: DestinationCategory
} = {
    PORT: 'port', // metaData un-port name
    COUNTRY: 'country', // metaData country name
    SUPPLIER: 'cruise line', // metaData supplier name
    SHIP: 'ship', // metaData ship name
    DESTINATION: 'destination', // user free typed string
    REGION: 'region', // metaData region
}

/** the index of the category value in the array dictates the sort order of these values  */
const categoryOrder: DestinationCategory[] = [
    DESTINATION_CATEGORIES.DESTINATION,
    DESTINATION_CATEGORIES.REGION,
    DESTINATION_CATEGORIES.COUNTRY,
    DESTINATION_CATEGORIES.SUPPLIER,
    DESTINATION_CATEGORIES.PORT,
    DESTINATION_CATEGORIES.SHIP,
]

export const categoryOrderMap: { [key in DestinationCategory]: number } = categoryOrder.reduce(
    (acc, category, index) => {
        acc[category] = index
        return acc
    },
    {} as { [key in DestinationCategory]: number }
)

type CategoryProps = { category: DestinationCategory }

const CategoryIndicator: React.FC<CategoryProps> = ({
    category,
}: CategoryProps): React.ReactElement => {
    const classNames = classnames(styles['category-indicator'], {
        [styles['category-indicator-port']]: category === DESTINATION_CATEGORIES.PORT,
        [styles['category-indicator-ship']]: category === DESTINATION_CATEGORIES.SHIP,
        [styles['category-indicator-supplier']]: category === DESTINATION_CATEGORIES.SUPPLIER,
        [styles['category-indicator-country']]: category === DESTINATION_CATEGORIES.COUNTRY,
        [styles['category-indicator-destination']]: category === DESTINATION_CATEGORIES.DESTINATION,
        [styles['category-indicator-region']]: category === DESTINATION_CATEGORIES.REGION,
    })
    return (
        <Text weight='bold' className={classNames} size='XS' color='white'>
            {category.toUpperCase()}
        </Text>
    )
}

export default CategoryIndicator
