import React from 'react'
import { Menu as MuiMenu, MenuItem as MuiMenuItem, StyledEngineProvider } from '@mui/material'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import styles from './SortByMenu.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage

type SortOption = {
    text: string
    value: string
}

interface SortByMenuProps {
    value: string
    onChange: (value: string) => void
}

const SortByMenu: React.FC<SortByMenuProps> = ({ value, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (): void => {
        setAnchorEl(null)
    }

    const sortOptions: SortOption[] = [
        { text: content.sortOptionsText.sailDateAscending, value: 'sailDateAsc' },
        { text: content.sortOptionsText.sailDateDescending, value: 'sailDateDesc' },
        { text: content.sortOptionsText.priceAscending, value: 'priceAsc' },
        { text: content.sortOptionsText.priceDescending, value: 'priceDesc' },
        { text: content.sortOptionsText.durationAscending, value: 'durationAsc' },
        { text: content.sortOptionsText.durationDescending, value: 'durationDesc' },
    ]

    return (
        <StyledEngineProvider injectFirst>
            <div className={styles['menu__container']}>
                <Text size='S'>{content.sortLabelText}</Text>
                <button
                    className={styles['menu__button']}
                    aria-controls={open ? 'sort-by-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpen}
                >
                    <Text size='S'>
                        {sortOptions.find((option) => option.value === value)?.text}
                    </Text>
                    <Icon iconName='ChevronDown' iconSize='S' />
                </button>
            </div>
            <MuiMenu
                id='sort-by-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'sort-by-menu' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {sortOptions.map((option) => (
                    <MuiMenuItem
                        key={option.value}
                        className={styles['menu__menu-item']}
                        onClick={(): void => {
                            onChange(option.value)
                            handleClose()
                        }}
                    >
                        <Text>{option.text}</Text>
                    </MuiMenuItem>
                ))}
            </MuiMenu>
        </StyledEngineProvider>
    )
}

export default SortByMenu
