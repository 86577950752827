import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RenderInputComponentProps } from 'react-autosuggest'

import AutosuggestWithQuery from 'components/blocks/AutosuggestWithQuery/AutosuggestWithQuery'
import Button from 'components/basics/Button/Button'
import { capitalizeEachWord } from 'utils/string-helpers'
import createApolloClient, { l3Options } from 'utils/apollo-client/create-apollo-client'
import FieldError from 'components/basics/FieldError/FieldError'
import { GET_IATA_BY_SUBSTRING } from 'graphql-queries/cruise/cruise-queries'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Modal from 'components/blocks/Modal/Modal'
import PhoneInput from 'components/blocks/PhoneInput/PhoneInput'
import Select from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { HandleUpdateGroup } from '../GroupsList/GroupsList'
import { Group, SalesChannel } from 'api-data-models/admin/GroupsContentModel'
import { REGEX } from 'utils/constants'

import { countryData } from '../../../../../data/country-codes'
import allContent from 'content/content'
import styles from './UpdateGroupModal.module.css'

const content = allContent.admin.groupManagementPage.updateGroupModal

const NUMBER_OF_IATA_SUGGESTIONS_TO_RENDER = 8

export type EditGroupFormData = {
    groupTitle?: string
    salesChannelId?: string
    phoneCallingCode: string
    phoneNumber: string
    homeCityCode?: string
}

type EditGroupFormProps = {
    groupDataForUpdate: Group
    salesChannels: SalesChannel[]
    handleUpdateGroup({ userGroupId, salesChannel }: HandleUpdateGroup): void
    isSubmitting: boolean
    onClose: () => void
}

const Form: React.FC<EditGroupFormProps> = ({
    groupDataForUpdate,
    salesChannels,
    handleUpdateGroup,
    onClose,
    isSubmitting,
}): React.ReactElement => {
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [cityCodeInputValue, setCityCodeInputValue] = useState<string>(
        groupDataForUpdate.homeCityCode ?? ''
    )
    const [isCityCodeMissingValueError, setIsCityCodeMissingValueError] = useState(false)
    const [cityCodeSuggestions, setCityCodeSuggestions] = useState<string[]>([])
    const [cityCodeIataValue, setCityCodeIataValue] = useState<string>('')

    const l3ApiClient = createApolloClient(process.env.REACT_APP_L3_API_URL, l3Options)

    /** Make Select Options for sales channel using array of all sales channels*/
    const salesChannelOptions: { value: string; text: string }[] = salesChannels
        .map((salesChannel: SalesChannel) => {
            return {
                text: salesChannel.salesChannelTitle,
                value: salesChannel.salesChannelId,
            }
        })
        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))

    const currentSalesChannelId = groupDataForUpdate.salesChannels.length
        ? groupDataForUpdate.salesChannels[0].salesChannelId
        : '' // placeholder value on select has value ''

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            groupTitle: groupDataForUpdate.groupTitle,
            homeCityCode: groupDataForUpdate.homeCityCode ?? '',
            salesChannelId: currentSalesChannelId,
            phoneCallingCode: groupDataForUpdate.phoneCallingCode ?? '',
            phoneNumber: groupDataForUpdate.phoneNumber ?? '',
        },
    })
    const countryCallingCodeOptions = useMemo(() => {
        return countryData.map((country) => ({
            value: country.phoneCode,
            text: country.flag + ' ' + country.name + ' +' + country.phoneCode,
        }))
    }, [])

    function handleOnChangeContactCntryCllngCd(e: React.ChangeEvent<HTMLSelectElement>): void {
        setValue('phoneCallingCode', e.target.value)
    }
    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((formData: EditGroupFormData): void => {
                /** Not updating the Group Title at moment, but will be used soon. */
                // const groupTitle = formData.groupTitle

                /** Need more than the id for the sales channel - need full object - so fetch from salesChannels
                 * IGNORE TYPE as the find will always find a matching sales channel - because the salesChannels data provided the input value! */

                setSubmitting(true)
                const selectedSalesChannelId = formData.salesChannelId
                if (selectedSalesChannelId) {
                    const selectedSalesChannel = salesChannels.find(
                        (salesChannel) => salesChannel.salesChannelId === selectedSalesChannelId
                    )
                    if (selectedSalesChannel) {
                        const handleUserGroupParams: HandleUpdateGroup = {
                            homeCityCode: cityCodeIataValue ?? '',
                            userGroupId: groupDataForUpdate.groupId,
                            salesChannel: {
                                salesChannelId: selectedSalesChannel.salesChannelId,
                                salesChannelTitle: selectedSalesChannel.salesChannelTitle,
                            },
                        }
                        const phoneNumber = `${formData?.phoneCallingCode}${formData?.phoneNumber}`
                        if (phoneNumber) handleUserGroupParams.phoneNumber = phoneNumber
                        handleUpdateGroup(handleUserGroupParams)
                    }
                }
            })}
        >
            <p>
                <Text>{content.text1}</Text>
            </p>
            <Controller
                control={control}
                name='groupTitle'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.COMPANY_NAME,
                }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                    <div>
                        <LabelledInput
                            required={true}
                            htmlFor='group-title'
                            label={content.groupTitleInput}
                            isErrored={!!errors.groupTitle}
                            disabled={true} // when this field is editable, update to isSubmitting
                        >
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required={true}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='group-title'
                            showError={!!errors.groupTitle}
                            errorMessage={content.errors.groupTitle}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='salesChannelId'
                rules={{ required: true }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => {
                    return (
                        <div>
                            <LabelledInput
                                required={true}
                                htmlFor='sales-channel-id'
                                label={content.salesChannelInput}
                                disabled={isSubmitting}
                                isErrored={!!errors.salesChannelId}
                            >
                                <Select
                                    placeholder={content.salesChannelPlaceholderText}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required={true}
                                    options={salesChannelOptions}
                                    disabled={isSubmitting}
                                />
                            </LabelledInput>
                            <FieldError
                                inputId='sales-channel-id'
                                showError={!!errors.salesChannelId}
                                errorMessage={content.errors.salesChannelId}
                            />
                        </div>
                    )
                }}
            />
            <div>
                <FieldError
                    errorMessage={content.errors.cityCodeValueMissing}
                    showError={isCityCodeMissingValueError}
                    inputId='city-code-value'
                />
                <Controller
                    control={control}
                    name='homeCityCode'
                    render={(): React.ReactElement => (
                        <AutosuggestWithQuery
                            openSuggestionsOnFocus={true}
                            inputValue={cityCodeInputValue}
                            setInputValue={setCityCodeInputValue}
                            getSuggestionValue={(value: IataLocation): string => {
                                if (value.iata) {
                                    setCityCodeIataValue(value.iata)
                                } else {
                                    setIsCityCodeMissingValueError(true)
                                }
                                return value.iata
                            }}
                            name='homeCityCode-autosuggest'
                            renderSuggestion={(value: Record<string, any>): React.ReactElement => (
                                <span>
                                    {value.name} ({capitalizeEachWord(value.placeType)})
                                </span>
                            )}
                            setSuggestionsData={setCityCodeSuggestions}
                            suggestionsData={cityCodeSuggestions}
                            onSuggestionSelected={setCityCodeInputValue}
                            query={GET_IATA_BY_SUBSTRING}
                            staticVariables={{
                                numberOfLocations: NUMBER_OF_IATA_SUGGESTIONS_TO_RENDER,
                            }}
                            client={l3ApiClient}
                            handleResults={(result): void => {
                                if (result?.data.getIataByPlacenameSubString) {
                                    setCityCodeSuggestions(result.data.getIataByPlacenameSubString)
                                }
                            }}
                            renderInputComponent={({
                                ...rest
                            }: RenderInputComponentProps): React.ReactElement => (
                                <LabelledInput
                                    required={false}
                                    htmlFor='homeCityCode'
                                    label={content.homeCityCodeField}
                                    aria-describedby={content.homeCityCodeField}
                                    disabled={submitting}
                                    isErrored={!!errors.homeCityCode}
                                    {...rest}
                                >
                                    <TextInput />
                                </LabelledInput>
                            )}
                        />
                    )}
                />
            </div>
            <PhoneInput
                countryCodeFieldName='phoneCallingCode'
                inputFieldName='phoneNumber'
                generalError={!!errors.phoneNumber || !!errors.phoneCallingCode}
                groupLabel={content.phoneNumberInput}
                countryCodeLabel={content.countryCallingCodeLabel}
                countryCodeOptions={countryCallingCodeOptions}
                control={control}
                inputLabel={content.phoneNumberInput}
                onChange={setValue}
                onCountryCodeChange={handleOnChangeContactCntryCllngCd}
            />
            <div className={styles['button-wrapper']}>
                <Button
                    type='button'
                    flavour='tertiary'
                    text={content.cancelButton}
                    onClick={onClose}
                    disabled={isSubmitting}
                />
                <Button
                    text={isSubmitting ? content.submittingButton : content.confirmButton}
                    type='submit'
                    showSpinner={isSubmitting}
                />
            </div>
        </form>
    )
}

type UpdateGroupModalProps = {
    isOpen: boolean
    isSubmitting: boolean
    groupDataForUpdate: Group | null
    handleUpdateGroup({ userGroupId, salesChannel }: HandleUpdateGroup): void
    onClose: () => void
    salesChannels: SalesChannel[]
    isErrored: boolean
}

const UpdateGroupModal: React.FC<UpdateGroupModalProps> = ({
    isOpen,
    isSubmitting,
    handleUpdateGroup,
    onClose,
    groupDataForUpdate,
    salesChannels,
    isErrored = false,
}): React.ReactElement => {
    return (
        <Modal
            headerText={content.title}
            isOpen={isOpen}
            returnFocusId=''
            setClosed={onClose}
            className={styles.wrapper}
        >
            {isOpen && groupDataForUpdate !== null && (
                <div className={styles.container}>
                    <Form
                        groupDataForUpdate={groupDataForUpdate}
                        salesChannels={salesChannels}
                        handleUpdateGroup={handleUpdateGroup}
                        isSubmitting={isSubmitting}
                        onClose={onClose}
                    />
                    {isErrored && (
                        <InfoBanner
                            bannerType='error'
                            text={content.errors.apiError}
                            id='group-add-modal-error-banner'
                        />
                    )}
                </div>
            )}
        </Modal>
    )
}
export default UpdateGroupModal
