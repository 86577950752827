import React from 'react'
import { PromiseOrValue } from 'graphql/jsutils/PromiseOrValue'
import { ApolloClient, ApolloQueryResult, DocumentNode, TypedDocumentNode } from '@apollo/client'
import { AutosuggestProps } from 'react-autosuggest'

import Autosuggest from '../Autosuggest/Autosuggest'
import * as self from './AutosuggestWithQuery'

type constructQueryOptions = {
    /** instance of an Apollo Client */
    client: ApolloClient<any>
    /** gql query */
    query: DocumentNode | TypedDocumentNode<any, { substring: string }>
    /** any variables for the query that are static */
    staticVariables: Record<string, any>
    /** error handling callback */
    handleError?(error: ErrorEvent): void
    /** result handling callback */
    handleResults?(result: void | ApolloQueryResult<any>): void
}

type AutosuggestInputWithQueryProps = constructQueryOptions & {
    /** To allow the user to free type their input not be forced to use a value from suggestion list */
    onBlur?: (currentInputValue: string, highlightedSuggestion: any) => void
    /** for styling the input container (width etc.) */
    className?: string
    /** callback used to get input string from suggestion list values */
    getSuggestionValue(dataItem: any): string
    /** callback used get current value of input */
    inputValue: string
    /** callback used to execute code after selecting a suggestion */
    onSuggestionSelected(value: any): void
    /** name attribute */
    name: string
    /** function that react-autosuggest will pass each suggest, when suggestions are returned */
    renderSuggestion(dataItem: any): React.ReactElement
    /** callback used to track state of the input value */
    setInputValue(value: string): void
    /** callback used to manage the clearing the suggestions from the autosuggest dropdown */
    onSuggestionsClearRequested?(): void
    /** callback used to manage the suggestions in the autosuggest dropdown */
    setSuggestionsData: React.Dispatch<React.SetStateAction<any[]>>
    /** suggestions data displayed in the autosuggest dropdown */
    suggestionsData: any[]
    /** callback used to render a custom input */
    renderInputComponent: AutosuggestProps<any, any>['renderInputComponent']
    /** id to be assigned if more than one Autosuggest is used on the same page*/
    id?: string
    /** opens the suggestions (if present) on focus */
    openSuggestionsOnFocus?: boolean
}

export const constructQuery = ({
    client,
    query,
    staticVariables,
    handleError,
    handleResults,
}: constructQueryOptions) => {
    return (substring: string): PromiseOrValue<Record<string, any>> =>
        client
            .query({
                query: query,
                variables: { substring, ...staticVariables },
            })
            .catch((e) => {
                if (handleError) handleError(e)
            })
            .then((result) => {
                if (result && handleResults) handleResults(result)
            })
}

export const AutosuggestWithQuery = ({
    className,
    client,
    getSuggestionValue,
    handleError,
    handleResults,
    inputValue,
    name,
    id = name,
    onBlur,
    onSuggestionSelected,
    openSuggestionsOnFocus = false,
    query,
    renderInputComponent,
    renderSuggestion,
    setSuggestionsData,
    suggestionsData,
    staticVariables,
    setInputValue,
}: AutosuggestInputWithQueryProps): JSX.Element => {
    const constructedQuery = self.constructQuery({
        client,
        query,
        staticVariables,
        handleError,
        handleResults,
    })
    return (
        <Autosuggest
            className={className}
            getSuggestionValue={getSuggestionValue}
            name={name}
            id={id}
            inputValue={inputValue}
            onBlur={onBlur}
            onSuggestionsClearRequested={(): void => setSuggestionsData([])}
            onSuggestionSelected={onSuggestionSelected}
            openSuggestionsOnFocus={openSuggestionsOnFocus}
            renderInputComponent={renderInputComponent}
            renderSuggestion={renderSuggestion}
            setInputValue={setInputValue}
            suggestionsData={suggestionsData}
            suggestionsFetchRequest={constructedQuery}
        />
    )
}

export default AutosuggestWithQuery
