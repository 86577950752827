import React, { useState, useEffect } from 'react'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import Button from 'components/basics/Button/Button'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Modal from 'components/blocks/Modal/Modal'
import { PriceBreakdown } from '../PriceBreakdownView/PriceBreakdownView'
import styles from './CabinInfoSection.module.css'
import {
    CabinGrade,
    CabinGradeFromAPI,
    CabinGradeSingleModelBuild,
} from 'api-data-models/cruise-detail/CabinGradesModel'
import { CabinInfoModelBuild, CabinInfoModel } from 'api-data-models/cruise-detail/CabinInfoModel'
import { CruiseDetailFromApi } from 'api-data-models/cruise-detail/SailingInfoModel'
import {
    RateCodesModelTransformFunction,
    RateCodeFromApi,
    RateCode,
} from 'api-data-models/cruise-detail/RateCodesModel'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { useRest, UseRestOptions } from 'components/hooks/useRest'

import allContent from 'content/content'
const content = allContent.cruise.cabinPage

type CabinInfoSectionTableProps = {
    cruiseInfoData: CabinInfoModel
    cabinGradeData: CabinGrade
    rateCodeData: RateCode
    supplierCode: string
}

export const CabinInfoSectionTable: React.FC<CabinInfoSectionTableProps> = ({
    cruiseInfoData,
    cabinGradeData,
    rateCodeData,
    supplierCode,
}) => {
    const [pricingModalOpen, setPricingModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {
        cruiseId: cruiseIdKey,
        embarkDate,
        disembarkDate,
        duration,
        nights,
        supplierName,
        shipName,
        cabinGrade,
    } = content.informationSection.informationKeys

    const informationDataListLeft = cruiseInfoData ? (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [cruiseIdKey, cruiseInfoData.cruiseId],
                [embarkDate, cruiseInfoData.embarkDate],
                [disembarkDate, cruiseInfoData.disembarkDate],
                [duration, `${cruiseInfoData.duration} ${nights}`],
            ]}
        />
    ) : null
    const informationDataListRight =
        cruiseInfoData && cabinGradeData ? (
            <DescriptionListDisplay
                className={styles.columns}
                isAlternatingColor
                keyValueContent={[
                    [
                        cabinGrade,
                        `${cabinGradeData.cabinGradeCode}, ${cabinGradeData.name} (${cabinGradeData.type})`,
                    ],
                    [supplierName, cruiseInfoData.supplierName],
                    [shipName, cruiseInfoData.shipName],
                ]}
            />
        ) : null

    return (
        <Accordion
            baseId='info-section'
            titleSize={'3'}
            title={content.informationSection.heading}
            darkHeading={true}
            isPadded={true}
            startOpen={true}
        >
            <div className={styles.container}>
                {informationDataListLeft}
                {informationDataListRight}
            </div>
            <Button
                className={styles.button}
                id='pricing-info-button'
                text={content.informationSection.pricingButton}
                onClick={(): void => setPricingModalOpen(true)}
                flavour='secondary'
            />
            <Modal
                headerText={content.informationSection.pricingTable.heading}
                isOpen={pricingModalOpen}
                returnFocusId='view-pricing-modal'
                setClosed={(): void => setPricingModalOpen(false)}
                loading={isLoading}
            >
                <PriceBreakdown
                    cruiseId={cruiseInfoData.cruiseId}
                    supplierCode={supplierCode}
                    cabinGradeData={cabinGradeData}
                    rateCodeData={rateCodeData}
                    setIsLoading={setIsLoading}
                />
            </Modal>
        </Accordion>
    )
}

type CabinInfoSectionProps = {
    cruiseId: string
    supplierCode: string
    rateCode: string
    cabinGradeCode: string
}

const CabinInfoSection: React.FC<CabinInfoSectionProps> = ({
    cruiseId,
    supplierCode,
    rateCode,
    cabinGradeCode,
}: CabinInfoSectionProps) => {
    const [cruiseInfoData, setCruiseInfoData] = useState<CabinInfoModel>()
    const [cabinGradeData, setCabinGradeData] = useState<CabinGrade>()
    const [rateCodeData, setRateCodeData] = useState<RateCode>()

    const { passengerConfigurationDataRestRequestBody } = usePassengersInfo()

    const detailsFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/details?supplier_code=${supplierCode}`,
        source: 'SailingPage - CABIN INFO SECTION',
        method: 'GET',
    }

    const {
        result: detailsResult,
        loading: detailsLoading,
        error: detailsError,
    } = useRest(detailsFetchOptions)

    useEffect(() => {
        if (detailsResult) {
            setCruiseInfoData(CabinInfoModelBuild(detailsResult as CruiseDetailFromApi))
        }
    }, [detailsResult])

    const cabinGradeFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCode}/cabin-grades/${cabinGradeCode}?supplier_code=${supplierCode}`,
        source: 'SailingPage - CABIN INFO SECTION',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }
    const {
        result: cabinGradeResult,
        loading: cabinGradeLoading,
        error: cabinGradeError,
    } = useRest(cabinGradeFetchOptions)

    useEffect(() => {
        if (cabinGradeResult) {
            setCabinGradeData(CabinGradeSingleModelBuild(cabinGradeResult as CabinGradeFromAPI))
        }
    }, [cabinGradeResult])

    const rateFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCode}?supplier_code=${supplierCode}`,
        source: 'SailingPage - CABIN INFO SECTION',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }
    const { result: rateResult, loading: rateLoading, error: rateError } = useRest(rateFetchOptions)

    useEffect(() => {
        if (rateResult) {
            setRateCodeData(RateCodesModelTransformFunction([rateResult] as RateCodeFromApi[])[0])
        }
    }, [rateResult])

    return (
        <>
            {(detailsLoading || rateLoading || cabinGradeLoading) && (
                <LargeSpinner text={content.informationSection.spinnerText} />
            )}
            {detailsError && <ErrorList errorsList={detailsError} source='cabin-page-details' />}
            {rateError && <ErrorList errorsList={rateError} source='cabin-page-rate' />}
            {cabinGradeError && (
                <ErrorList errorsList={cabinGradeError} source='cabin-page-cabin-grade' />
            )}
            {cruiseInfoData && cabinGradeData && rateCodeData && (
                <CabinInfoSectionTable
                    cruiseInfoData={cruiseInfoData}
                    cabinGradeData={cabinGradeData}
                    rateCodeData={rateCodeData}
                    supplierCode={supplierCode}
                />
            )}
        </>
    )
}

export default CabinInfoSection
