import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'components/sections/app/AppRoutes'

import Button from 'components/basics/Button/Button'
import Text from 'components/basics/Text/Text'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { VITALLY_EVENTS } from 'utils/constants'

import styles from './FeedbackBanner.module.css'
import allContent from 'content/content'

const content = allContent.feedbackBanner

const FeedbackBanner: React.FC = () => {
    const navigate = useNavigate()
    const handleButtonClick = (): void => {
        navigate(ROUTES.FEATURE_PORTAL)
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.FEATURE_PORTAL_BANNER_OPEN,
            properties: {
                page: window.location.pathname,
            },
        })
    }
    return (
        <div className={styles['container']}>
            <div className={styles['content-row']}>
                <Text size='M' weight='bold'>
                    {content.title}
                </Text>
                <Text>{content.subtitle}</Text>
                <Button text={content.button} onClick={handleButtonClick} flavour='tertiary' />
            </div>
        </div>
    )
}

export default FeedbackBanner
