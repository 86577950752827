import React, { SetStateAction } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { NavigateFunction } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { CHARGEBEE_SUBSCRIPTION_NAMES, COMPANY_TIER_NAMES } from 'utils/constants'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { refreshSession } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { getGraphqlErrorMessage } from 'utils/api-errors-helpers/get-graphql-error-message'
import { PROCESS_CHECKOUT, UPDATE_TENANT } from 'graphql-queries/admin/admin-queries'

export function getChargeBeUrl({
    tier,
    firstName,
    lastName,
    userEmail,
    companyName,
    tenantId,
}: {
    tier: CompanyTierTypes
    firstName: string
    lastName: string
    userEmail: string
    companyName: string
    tenantId: string
}): string {
    const coupon = 'CRUISEPLUSOCTOBEROFFER' //should we make this a github variable?

    let url =
        process.env.REACT_APP_CHARGEBEE_CHECKOUT_URL +
        `?subscription_items[item_price_id][0]=${encodeURIComponent(
            CHARGEBEE_SUBSCRIPTION_NAMES[tier]
        )}-USD-Monthly&utm_source=cb-app-copy&customer[email]=${encodeURIComponent(
            userEmail
        )}&customer[first_name]=${encodeURIComponent(
            firstName
        )}&customer[last_name]=${encodeURIComponent(
            lastName
        )}&customer[company]=${encodeURIComponent(companyName)}&customer[id]=${encodeURIComponent(
            tenantId
        )}`

    if (tier === COMPANY_TIER_NAMES.CRUISE_PLUS) {
        if (coupon) {
            url += `&subscription_items[quantity][0]=1&coupon_ids[1]=${encodeURIComponent(coupon)}`
        } else {
            url += `&subscription_items[quantity][0]=1`
        }
    }
    return url
}

export async function handleUpdateFinanceId({
    setUpdatingFinanceId,
    setUpdatingFinanceIdApiError,
    managerApiClient,
    tenantId,
    companyName,
    companyTier,
    userEmail,
    navigate,
    setUserData,
}: {
    setUpdatingFinanceId: (value: React.SetStateAction<boolean>) => void
    setUpdatingFinanceIdApiError: (value: React.SetStateAction<any>) => void
    managerApiClient: ApolloClient<NormalizedCacheObject>
    tenantId: string
    companyName: string
    companyTier: string
    userEmail: string
    navigate: NavigateFunction
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
}): Promise<void> {
    setUpdatingFinanceId(true)
    setUpdatingFinanceIdApiError(null)

    const userContext = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    managerApiClient
        .mutate({
            mutation: UPDATE_TENANT,
            variables: {
                id: tenantId,
                financeSystemId: tenantId,
                companyTier: companyTier,
            },
        })
        .then(async (response: any) => {
            if (response?.data) {
                datadogLogs.logger.info(
                    `Mutate UPDATE_TENANT Successfully for company: ${companyName}, tenantId: ${tenantId}, tier: ${companyTier}, email: ${userEmail}`
                )
                await refreshSession({ navigate, userContext, setUserData })
            }
        })
        .catch((error: any) => {
            const errorObjectThing = getGraphqlErrorMessage(error, 'UPDATE_TENANT')

            setUpdatingFinanceIdApiError(errorObjectThing)
            setUpdatingFinanceId(false)
        })
}

export async function processChargbeeCheckout({
    setProcessingCheckout,
    setProcessingCheckoutApiError,
    managerApiClient,
    hostedPageId,
    tenantId,
    companyName,
    companyTier,
    userEmail,
    navigate,
    setUserData,
}: {
    setProcessingCheckout: (value: React.SetStateAction<boolean>) => void
    setProcessingCheckoutApiError: (value: React.SetStateAction<any>) => void
    managerApiClient: ApolloClient<NormalizedCacheObject>
    hostedPageId: string
    tenantId: string
    companyName: string
    companyTier: string
    userEmail: string
    navigate: NavigateFunction
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
}): Promise<void> {
    setProcessingCheckout(true)
    setProcessingCheckoutApiError(null)
    const userContext = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    managerApiClient
        .mutate({
            mutation: PROCESS_CHECKOUT,
            variables: {
                hostedPageId: hostedPageId,
                id: tenantId,
                financeSystemId: tenantId,
                companyTier: companyTier,
            },
        })
        .then(async (response: any) => {
            if (response?.data) {
                datadogLogs.logger.info(
                    `Mutate PROCESS_CHECKOUT Successfully for company: ${companyName}, tenantId: ${tenantId}, tier: ${companyTier}, hostedPageId: ${hostedPageId}, email: ${userEmail}`
                )
                await refreshSession({ navigate, userContext, setUserData })
            }
        })
        .catch((error: any) => {
            const errorObjectThing = getGraphqlErrorMessage(error, 'PROCESS_CHECKOUT')

            setProcessingCheckout(false)
            setProcessingCheckoutApiError(errorObjectThing)
        })
}
