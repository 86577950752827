import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'components/sections/app/AppRoutes'

import ResultsLayout from 'components/layouts/cruise/ResultsLayout/ResultsLayout'

import { useRest, UseRestOptions } from '../../hooks/useRest'
import { DATE_FORMAT_Y_M_D_DASH, VITALLY_EVENTS } from 'utils/constants'
import { CruiseSearchResult, Cruise, CruisesMetaData } from 'api-data-models/CruisesContentModel'
import { MetaDataItem, APICruisesResponse } from 'api-data-models/cruises-api-types'
import { setDataToLocalStorage } from 'utils/use-local-storage'
import { CRUISE_SEARCH_DATA_KEY, EXPIRY_TIME } from './SearchPage'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { composeCruiseResultsPageURLQueryVariables } from 'utils/url-helpers'
import { format, startOfToday, startOfTomorrow } from 'date-fns'

/** ResultsPage: renders a results page that calls the Cruise API on render */
const ResultsPage: React.FC<{ cruisesMetaData: CruisesMetaData }> = ({ cruisesMetaData }) => {
    const [cruises, setCruises] = useState<Cruise[] | undefined>(undefined)
    const [cruiseProductNames, setCruiseProductNames] = useState<string[] | undefined>([])
    const [departurePorts, setDeparturePorts] = useState<MetaDataItem[]>([])
    const [arrivalPorts, setArrivalPorts] = useState<MetaDataItem[]>([])
    const [regions, setRegions] = useState<MetaDataItem[]>([])
    const [unPorts, setUnPorts] = useState<MetaDataItem[]>([])

    const windowSearchParams = window.location.search
    const paramsObject = composeCruiseResultsPageURLQueryVariables(windowSearchParams)

    /** This check is to make sure the results page doesn't show error due to embarkDate from the past.
     * It's not possible to select past dates in the search forms, but since the app doesn't require logging in for 30 days
     * users can come back a few days later and get an api error when the page loads.
     * AND because the form pre-populates the last submitted values, logging on and off doesn't then clear the bad date.
     * Because it's not clear the date is a day or so old at a glance, and the user is forced to re-choose the date - even
     * if they just want the default values, its nicer to automatically moves dates from the past to the default 48 hours from now */
    if (
        paramsObject.embarkEarliestDate &&
        paramsObject.embarkEarliestDate < new Date(startOfToday()).toISOString()
    ) {
        const urlObj = new URL(window.location.href)
        urlObj.searchParams.set(
            'embarkEarliestDate',
            format(new Date(startOfTomorrow()), DATE_FORMAT_Y_M_D_DASH)
        )
        const updatedUrl = urlObj.toString()
        window.location.replace(updatedUrl)
    }

    /** If url is missing embarkEarliestDate for any reason, add it or the api call will fail*/
    if (!paramsObject.embarkEarliestDate) {
        const startAfter48hours = new Date(new Date().getTime() + 24000 * 60 * 60 * 2)
        paramsObject.embarkEarliestDate = format(
            new Date(startAfter48hours),
            DATE_FORMAT_Y_M_D_DASH
        )
    }

    const fetchOptions: UseRestOptions = {
        url: process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL + '/cruises',
        variables: paramsObject,
        source: 'ResultsPage - GET_CRUISES',
        method: 'POST',
    }

    const { result, loading, error, refetch } = useRest(fetchOptions)

    const navigate = useNavigate()

    function handleModifySearch(paramsString: string): void {
        navigate(`${ROUTES.CRUISE_RESULTS}/?${paramsString}`)
    }

    useEffect(() => {
        if (Object.keys(paramsObject).length > 0) {
            setDataToLocalStorage({
                data: paramsObject,
                key: CRUISE_SEARCH_DATA_KEY,
                expiryMins: EXPIRY_TIME,
            })
        }
    }, [paramsObject])

    function makeNewSearch(): void {
        setCruises(undefined) // clear results out of local state when modify search triggers another query
        refetch()
    }

    useEffect(() => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.SEARCH_CRUISES,
            properties: {
                destination:
                    paramsObject.destination ||
                    paramsObject.country ||
                    paramsObject.region ||
                    paramsObject.supplierName ||
                    paramsObject.shipName ||
                    paramsObject.visitingPort,
                duration: paramsObject.durationMin
                    ? paramsObject.durationMin + '-' + paramsObject.durationMax
                    : undefined,
                embarkEarliestDate: paramsObject.embarkEarliestDate,
                embarkLatestDate: paramsObject.embarkLatestDate,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // only on mount only

    useEffect(() => {
        if (result?.searchResults && !loading) {
            const cruiseSearchResult: CruiseSearchResult = new CruiseSearchResult(
                result as APICruisesResponse
            )
            setCruises(cruiseSearchResult.cruises)
            setCruiseProductNames(cruiseSearchResult.allCruiseProductNames)
            setDeparturePorts(cruiseSearchResult.cruiseSearchMetaData.allDeparturePorts)
            setArrivalPorts(cruiseSearchResult.cruiseSearchMetaData.allArrivalPorts)
            setRegions(cruiseSearchResult.cruiseSearchMetaData.allRegions)
            setUnPorts(cruiseSearchResult.cruiseSearchMetaData.allUnPorts)
        }
    }, [
        loading,
        result?.searchResults,
        error,
        paramsObject.destination,
        paramsObject.durationMin,
        paramsObject.embarkEarliestDate,
        paramsObject.embarkLatestDate,
        paramsObject.region,
        result,
    ])

    // If result object exists, error is false, and cruises is undefined --> then the model is processing
    const processingResultData = !!result && !cruises && !error
    return (
        <div className='general-container'>
            <ResultsLayout
                loading={loading || processingResultData}
                apiError={error}
                cruiseProductNames={cruiseProductNames ?? []}
                departurePorts={departurePorts}
                arrivalPorts={arrivalPorts}
                regions={regions}
                unPorts={unPorts}
                cruises={cruises ?? []}
                queryParams={paramsObject}
                handleModifySearch={handleModifySearch}
                makeNewSearch={makeNewSearch}
                cruisesMetaData={cruisesMetaData}
            />
        </div>
    )
}

export default ResultsPage
