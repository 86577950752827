type RefundPolicyType =
    | 'REFUNDABLE'
    | 'NON_REFUNDABLE_DEPOSIT'
    | 'NON_REFUNDABLE_FARE'
    | 'NOT_SUPPORTED'

export type RateCodeFromApi = {
    code: string
    description: string
    military: boolean
    refund_policy: RefundPolicyType
    residency: boolean
    currency: string
}

export type RateCode = {
    code: string
    description: string
    refundPolicy: RefundPolicyType
    military: boolean
    residency: boolean
    currency: string
}
export const RateCodesModelTransformFunction = (
    rateCodesFromApi: RateCodeFromApi[]
): RateCode[] => {
    return rateCodesFromApi.map((rateCodeFromApi: RateCodeFromApi): RateCode => {
        return {
            code: rateCodeFromApi.code,
            description: rateCodeFromApi.description,
            refundPolicy: rateCodeFromApi.refund_policy,
            military: rateCodeFromApi.military,
            residency: rateCodeFromApi.residency,
            currency: rateCodeFromApi.currency,
        }
    })
}
