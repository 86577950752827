import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import Button from 'components/basics/Button/Button'
import Close from 'components/basics/Close/Close'
import Icon, { IconColours, IconNames } from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import { handleFocusOnId } from '../../../utils/handle-focus-on-id'

import styles from './InfoBanner.module.scss'
import allContent from 'content/content'
import { datadogLogs } from '@datadog/browser-logs'

const content = allContent.components.infoBanner

type BannerTypes = 'error' | 'warning' | 'success' | 'info'

type InfoBannerProps = {
    /** children always of type ReactNode */
    children?: React.ReactNode
    /** optional: class-names */
    bannerType: BannerTypes
    /** the informative message the banner conveys to the user **/
    text?: string
    /** call back function to be called when button is clicked, also drives the displaying of the button **/
    onButtonClick?: () => void
    /** when there is an action button, this prop gives the button the text that explains what the action is **/
    buttonText?: string
    /** icon of the action button **/
    buttonIcon?: IconNames
    /** can be set to false if we want the user to be unable to close the banner **/
    isCloseable?: boolean
    /** can be set to true if we want the page to scroll to where the banner is rendered **/
    isFocusable?: boolean
    /** type of logging event, used to trigger logging of event */
    logType?: 'error' | 'info' | 'warn'
    /** source string to identify where the message is occurring */
    source?: string
    /** unique banner id - because more than one can be present on the page at one time */
    id: string
}

const InfoBanner: React.FC<InfoBannerProps> = ({
    children,
    text,
    onButtonClick,
    bannerType,
    buttonText,
    buttonIcon,
    isCloseable = true,
    isFocusable = false,
    logType,
    id,
    source = 'infoBanner',
}: InfoBannerProps) => {
    const [isClosed, setIsClosed] = useState(false)
    const userContext = datadogLogs.getGlobalContext()
    /** Send log to datadog when logType is provided */
    useEffect(() => {
        if (logType) {
            datadogLogs.logger[`${logType}`](`source: ${source}, message: ${text}`, { userContext })
        }
    }, [bannerType, logType, source, text, userContext])

    const infoBannerClassNames = classNames(styles['info-banner'], [
        styles[`info-banner__${bannerType}`],
    ])

    let iconName: IconNames = 'Info'
    let iconColor: IconColours = 'white'
    if (bannerType === 'error') {
        iconColor = 'red'
        iconName = 'CircleExclamationSolid'
    }
    if (bannerType === 'warning') {
        iconColor = 'orange'
        iconName = 'TriangleExclamationSolid'
    }
    if (bannerType === 'success') {
        iconColor = 'green'
        iconName = 'CircleCheckSolid'
    }
    if (bannerType === 'info') {
        iconColor = 'tertiary-blue'
        iconName = 'Info'
    }
    useEffect(() => {
        if (isFocusable) handleFocusOnId(id)
    }, [id, isFocusable])

    if (isClosed) return null
    return (
        <div id={id} className={infoBannerClassNames}>
            <div className={styles['content']}>
                <Icon iconColor={iconColor} iconName={iconName} />
                <div className={styles['text-box']}>
                    {text && (
                        <div className={styles['text']}>
                            <Text>{text}</Text>
                        </div>
                    )}
                    {children}
                </div>
            </div>
            <div className={styles['buttons']}>
                {buttonText && onButtonClick && (
                    <Button
                        type='button'
                        flavour='tertiary'
                        text={buttonText}
                        onClick={onButtonClick}
                        iconName={buttonIcon}
                    />
                )}
                {isCloseable && (
                    <Close text={content.closeButton} onClick={(): void => setIsClosed(true)} />
                )}
            </div>
        </div>
    )
}
export default InfoBanner
