import React, { SyntheticEvent, useState } from 'react'
import { ReactDatePickerProps } from 'react-datepicker'

import DatePicker from 'components/basics/DatePicker/DatePicker'
import styles from './DateRangePicker.module.css'

type PickerDate = Date | null
export type RangeDates = [PickerDate, PickerDate]

// Note the Omit of 'onChange' from ReactDatePickerProps since this custom version deals with the onChange directly, but we want all other props exposed.
type DateRangePickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
    /** On Change call back function is passed the start and end date of the range via the internal onChange function. */
    onChangeCallBack(start: PickerDate, end: PickerDate): void
    /** initialStartDate for providing a starting value of the date range */
    initialStartDate?: PickerDate
    /** initialEndDate for providing a starting value of the end of date range*/
    initialEndDate?: PickerDate
    /** number of years to show in years dropdown*/
    yearsInDropdown?: number
}

/** DateRangePicker: returns a DatePicker with date range selection enabled */
const DateRangePicker: React.FC<DateRangePickerProps> = ({
    onChangeCallBack,
    initialStartDate = null,
    initialEndDate = null,
    yearsInDropdown = 7,
    ...rest
}: DateRangePickerProps) => {
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate)
    const [endDate, setEndDate] = useState<Date | null>(
        // Check that start date is being provided a initial value, not just end date.
        initialStartDate && initialEndDate ? initialEndDate : null
    )

    /** handleOnChange manages the start,end date state and passes the current selected dates to the onChangeCallBack */
    function handleOnChange(
        dates: RangeDates,
        _event: SyntheticEvent<any, Event> | undefined
    ): void {
        const [start, end] = dates
        onChangeCallBack(start, end)
        setStartDate(start)
        setEndDate(end)
    }

    /** DayPicker is an instance of Datepicker that displays a day/month navigation and manages the date range functionality */
    return (
        <div className={styles.wrapper}>
            <DatePicker
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                // Type issue with ReactDatePicker used as range picker. Example (https://reactdatepicker.com/#example-date-range-for-one-datepicker) shows same use case as here, but using typescript it is complaining.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                selectsRange
                {...rest}
                // Another wrong type when used as a range (selectsRange = true). It should expect an array of dates not a single date now.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={handleOnChange}
                yearsInDropdown={yearsInDropdown}
            />
        </div>
    )
}

export default DateRangePicker
