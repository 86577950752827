import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import GroupManagement from 'components/sections/admin/GroupManagement/GroupManagement'
import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'

import styles from './GroupManagementLayout.module.css'

type UserManagementLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    companyTier?: CompanyTierTypes
}

const GroupManagementLayout: React.FC<UserManagementLayoutProps> = ({ apiClient, companyTier }) => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation companyTier={companyTier} />
                <GroupManagement apiClient={apiClient} />
            </div>
        </div>
    )
}

export default GroupManagementLayout
