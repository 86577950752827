import React from 'react'
import classnames from 'classnames'
import styles from './Radio.module.scss'

export type RadioProps = React.ComponentProps<'input'> &
    Readonly<{
        /** can hide label if not wanted visually */
        hideLabel?: boolean
        /** text for showing next to radio */
        text: string
        /** for setting text to lighter colour when used on dark backgrounds */
        onDarkBackground?: boolean
        /** toggle to make unavailable */
        disabled?: boolean
        /** toggle for error styling */
        isErrored?: boolean
    }>

const Radio: React.FC<RadioProps> = ({
    disabled = false,
    text,
    hideLabel = false,
    onDarkBackground = false,
    isErrored = false,
    ...rest
}) => {
    const labelClassNames = classnames(
        styles.label,
        { 'visually-hidden': hideLabel },
        { [styles['label--alt']]: onDarkBackground }
    )
    const containerClassNames = classnames(styles.container, {
        [styles['container--disabled']]: disabled,
    })

    const customRadioClassNames = classnames(styles.customRadio, {
        [styles['input--errored']]: isErrored,
    })
    return (
        <label className={containerClassNames}>
            <input disabled={disabled} type='radio' {...rest} />
            <span className={customRadioClassNames} />
            <span className={labelClassNames}>{text}</span>
        </label>
    )
}

export default Radio
