import allContent from 'content/content'

const content = allContent.error.network

export const getNetworkErrorMessage = (statusCode: number): string => {
    let errorMessage = ''
    // TODO: if error message has detail property then prefer it before generic messages
    switch (statusCode) {
        case 400:
            errorMessage = content.badRequest
            break
        case 401:
            errorMessage = content.unauthorized
            break
        case 403:
            errorMessage = content.forbidden
            break
        case 404:
            errorMessage = content.notFound
            break
        case 422:
            errorMessage = content.validation
            break
        case 500:
            errorMessage = content.internalServer
            break
        case 503:
            errorMessage = content.serviceUnavailable
            break
        case 504:
            errorMessage = content.gatewayTimeout
            break
        default:
            errorMessage = content.unknown
    }
    return errorMessage
}
