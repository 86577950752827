import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { getCognitoErrorMessage } from 'utils/cognito-helpers/get-cognito-error-message'
import { REGEX } from 'utils/constants'

import styles from './ForgotPasswordForm.module.css'
import allContent from 'content/content'

const content = allContent.cognito.forgotPasswordForm

type ForgotPasswordDataType = {
    email: string
}

const ForgotPasswordForm: React.FC = () => {
    const [successful, setSuccessful] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [forgotPasswordApiError, setForgotPasswordApiError] = useState<string>('')
    const [formData, setFormData] = useState<ForgotPasswordDataType>()
    const navigate = useNavigate()

    useEffect(() => {
        if (successful) {
            navigate(ROUTES.RESET_PASSWORD, { state: { ...formData, hasJustSubmitted: true } }) // pass the email to reset password form, set it as default and disable input
        }
    }, [successful, formData, navigate])

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ForgotPasswordDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            email: '',
        },
    })

    function handleCancelClick(): void {
        navigate(ROUTES.ROOT)
    }

    async function handleOnSubmit(formFields: ForgotPasswordDataType): Promise<void> {
        setFormData(formFields)
        setForgotPasswordApiError('')
        setIsSubmitting(true)
        await Auth.forgotPassword(formFields.email)
            .then((response) => {
                if (response) {
                    datadogLogs.logger.info(
                        `source: ForgotPassword, success - email: ${formFields.email}`,
                        { userContext: { emailAddress: formFields.email } }
                    )
                    setSuccessful(true)
                    reset()
                }
            })
            .catch((error) => {
                setForgotPasswordApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                datadogLogs.logger.warn(
                    `source: ForgotPassword error , userEmail: ${formFields.email}`,
                    { emailAddress: formFields.email },
                    error
                )
            })
        setIsSubmitting(false)
    }

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <Spacing />
            <Text>{content.text}</Text>
            <form
                className={styles.form}
                onSubmit={handleSubmit(async (formFields: ForgotPasswordDataType) => {
                    await handleOnSubmit(formFields)
                })}
            >
                <Controller
                    control={control}
                    name='email'
                    rules={{
                        required: true,
                        minLength: 1,
                        maxLength: 300,
                        pattern: REGEX.EMAIL,
                    }}
                    render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                        <div className={styles['input-wrapper']}>
                            <LabelledInput
                                required={true}
                                htmlFor='email'
                                label={content.emailInput}
                                aria-describedby='email-error-message'
                                isErrored={!!errors.email}
                            >
                                <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                            </LabelledInput>
                            <FieldError
                                inputId='email'
                                showError={!!errors.email}
                                errorMessage={content.errors.emailInput}
                            />
                        </div>
                    )}
                />
                <div className={styles['button-wrapper']}>
                    <Button
                        type='button'
                        flavour='tertiary'
                        disabled={isSubmitting}
                        text={content.cancelButton}
                        onClick={handleCancelClick}
                    />
                    <Button
                        type='submit'
                        flavour='primary'
                        text={isSubmitting ? content.submitting : content.submitButton}
                        showSpinner={isSubmitting}
                    />
                </div>
                {!!forgotPasswordApiError && (
                    <InfoBanner
                        id='forgot-password-api-error'
                        bannerType='error'
                        text={forgotPasswordApiError}
                        isFocusable={true}
                        logType='warn'
                        source='forgot-password-page'
                    />
                )}
            </form>
        </div>
    )
}

export default ForgotPasswordForm
