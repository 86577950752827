import React from 'react'

import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'
import SubscriptionManage from 'components/sections/admin/SubscriptionManage/SubscriptionManage'

import styles from './SubscriptionsLayout.module.css'

const SubscriptionsLayout = ({
    companyTier,
    financeSystemId,
}: {
    companyTier?: CompanyTierTypes
    financeSystemId?: string
}): JSX.Element => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation companyTier={companyTier} />
                <SubscriptionManage financeSystemId={financeSystemId} />
            </div>
        </div>
    )
}

export default SubscriptionsLayout
