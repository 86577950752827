import React, { useCallback, useEffect, useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { FEATUREBASE_ORG } from 'utils/constants'

type FeaturebaseUserData = {
    email: string
    name: string
    id: string
}

const ChangelogWidget: React.FC<{
    userData: FeaturebaseUserData | null
    onError: (err: any) => void
}> = ({ userData, onError }) => {
    useEffect(() => {
        const win: any = window
        if (win?.Featurebase) {
            win.Featurebase(
                'identify',
                {
                    organization: FEATUREBASE_ORG,
                    ...userData,
                },
                (err: any) => {
                    if (err) {
                        onError(err)
                    } else {
                        win.Featurebase('initialize_changelog_widget', {
                            organization: FEATUREBASE_ORG, // Replace this with your Featurebase organization name
                            theme: 'light', // Choose between dark or light theme
                            fullscreenPopup: true, // Optional - Open a fullscreen announcement
                            locale: 'en', // Change the language
                        })
                    }
                }
            )
        }
    }, [userData, onError])

    return <></>
}

const ChangelogPopup: React.FC = () => {
    const [userData, setUserData] = useState<FeaturebaseUserData | null>(null)

    const userContext = datadogLogs.getGlobalContext() as GlobalContextUserData
    const handleError = useCallback(
        (error: any) => {
            datadogLogs.logger.error(`source: ChangelogWidgetIdentify`, { userContext }, error)
        },
        [userContext]
    )

    useEffect(() => {
        if (userContext?.userEmail && userContext?.userName && !userData) {
            const userDataParsed = extractGlobalContextUserData(userContext)
            setUserData({
                email: userDataParsed.userEmail,
                name: userDataParsed.userName,
                id: userDataParsed.userId,
            })
        }
    }, [userContext, userData])

    if (userData) return <ChangelogWidget userData={userData} onError={handleError} />
    return null
}

export default ChangelogPopup
