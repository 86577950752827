import { ServerError, ServerParseError, ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

// type should  be a union like this: Error | ServerError | ServerParseError instead of any but then we wouldn't be able to check statusCode as it's not a standard attribute of apolloClient ErrorResponse interface
// more about the issue here:
// https://github.com/apollographql/apollo-link/issues/300
function isNetworkError(error: unknown): error is { networkError: any } {
    return hasNetworkError(error) && !hasGqlErrors(error)
}

function getGqlErrors(error: unknown): GraphQLError[] {
    const result: GraphQLError[] = []

    if (hasPopulatedGqlErrors(error)) {
        result.push(...error.graphQLErrors)
    }

    if (hasUnpopulatedGqlErrors(error)) {
        result.push(...error.networkError.result.errors)
    }

    return result
}

function hasGqlErrors(error: unknown): boolean {
    return hasPopulatedGqlErrors(error) || hasUnpopulatedGqlErrors(error)
}

function hasPopulatedGqlErrors(
    error: unknown
): error is { graphQLErrors: ReadonlyArray<GraphQLError> } {
    return Boolean(
        error &&
            typeof error === 'object' &&
            Array.isArray((error as Partial<ApolloError>).graphQLErrors) &&
            (error as ApolloError).graphQLErrors.length
    )
}

function hasUnpopulatedGqlErrors(error: unknown): error is {
    networkError: { result: { errors: ReadonlyArray<GraphQLError> } }
} {
    return Boolean(
        hasNetworkError(error) &&
            'result' in error.networkError &&
            Array.isArray(error.networkError.result.errors) &&
            error.networkError.result.errors.length
    )
}

function hasNetworkError(
    error: unknown
): error is { networkError: Error | ServerError | ServerParseError } {
    return Boolean(error && typeof error === 'object' && (error as ApolloError).networkError)
}

export { getGqlErrors, isNetworkError }
