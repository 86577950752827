import { getFormattedDate } from 'utils/date-helpers'
import { CruiseDetailFromApi } from './SailingInfoModel'

export type CabinInfoModel = {
    cruiseId: string
    embarkDate: string
    disembarkDate: string
    duration: string
    supplierName: string
    shipName: string
}
export const CabinInfoModelBuild = (cruiseDetail: CruiseDetailFromApi): CabinInfoModel => {
    return {
        cruiseId: cruiseDetail.id,
        embarkDate: getFormattedDate(cruiseDetail.embark_date),
        disembarkDate: getFormattedDate(cruiseDetail.disembark_date),
        duration: cruiseDetail.duration.toString(),
        supplierName: cruiseDetail.ship.line.name,
        shipName: cruiseDetail.ship.name,
    }
}
