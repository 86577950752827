export type CabinFromApi = {
    number: string
    description: string
    location: {
        side_of_ship: string
        front_middle_back: string
        inside_oceanview: string
    }
    obstructed_view: string
    accessibility: string
    room_size: string
    deck: {
        name: string
        level: number
        code: string
        description: string
        facilities: [
            {
                name: string
                code: string
                description: string
            },
        ]
        image: string
    }
    min_guest_count: number
    max_guest_count: number
    amenity: [
        {
            name: string
            code: string
            description: string
        },
    ]
    bedding_configuration: [
        {
            number_of_beds: number
            code: string
            description: string
        },
    ]
}

export type BeddingConfiguration = {
    code: string
    description: string
    numberOfBeds: number
}

export type Deck = {
    level: string
    code: string
    name: string
    image: string
}

export type Location = {
    frontMiddleBack: string
    insideOceanview: string
    sideOfShip: string
}

export type Amenity = {
    name: string
    description: string
}

export type Cabin = {
    accessibility: string
    beddingConfiguration: BeddingConfiguration[]
    deck: Deck
    description: string
    location: Location
    number: string
    obstructedView: string
    amenities: Amenity[]
    roomSize: string
    maxGuestCount: number
    minGuestCount: number
}

export type Cabins = Record<string, Cabin>

export const CabinsModelTransformFunction = (cabinFromApi: CabinFromApi[]): Cabins => {
    const cabins: Cabins = {}
    cabinFromApi.forEach((cabin) => {
        const location: Location = {
            frontMiddleBack: cabin.location.front_middle_back,
            insideOceanview: cabin.location.inside_oceanview,
            sideOfShip: cabin.location.side_of_ship,
        }
        const deck: Deck = {
            level: '' + cabin.deck.level,
            code: cabin.deck.code,
            name: cabin.deck.name,
            image: cabin.deck.image,
        }
        const beddingConfiguration: BeddingConfiguration[] = cabin.bedding_configuration.map(
            (bedding) => ({
                code: bedding.code,
                description: bedding.description,
                numberOfBeds: bedding.number_of_beds,
            })
        )
        const amenities: Amenity[] = cabin.amenity?.map((amenity) => ({
            name: amenity.name,
            description: amenity.description,
        }))

        const cabinData: Cabin = {
            accessibility: cabin.accessibility,
            beddingConfiguration: beddingConfiguration,
            deck: deck,
            description: cabin.description,
            location: location,
            number: cabin.number,
            obstructedView: cabin.obstructed_view,
            amenities: amenities,
            roomSize: cabin.room_size,
            maxGuestCount: cabin.max_guest_count,
            minGuestCount: cabin.min_guest_count,
        }
        cabins[cabin.number] = cabinData
    })
    return cabins
}
