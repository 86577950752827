import {
    capitalizeEachWord,
    formatPriceString,
    insertDecimal2CharsFromEnd,
} from 'utils/string-helpers'
import { APIItineraryItem, ItineraryItemType } from './order-api-types'
import {
    CURRENCY_CODES,
    DATE_FORMAT_USA_TIME,
    PAYMENT_OPTIONS,
    PRODUCT_TYPES,
} from 'utils/constants'
import { BreakdownPerPassenger, getBreakDownItemsByPassenger } from '../CabinContentModel'
import {
    calculateHourDifference,
    getFormattedDate,
    getFormattedDateTimeToLocalTimezone,
} from 'utils/date-helpers'

const PORT_LIST_CHAR_LIMIT = 200

export const ITINERARY_ITEM_TYPE_MAP_ORDER: Record<ItineraryItemType, ItineraryItemType> = {
    'at sea': 'at sea',
    port: 'port',
    'cross idl westbound': 'cross idl westbound',
    'cross idl eastbound': 'cross idl eastbound',
    'sail past': 'sail past',
    embark: 'embark',
    disembark: 'disembark',
}

type ItineraryItem = {
    day: number
    portName: string | null
    portCode: string | null
    justPortName: string | null
    arrivalTime?: string
    departureTime?: string
    geolocation: Record<string, any> | null
    itemType: string
    itemDate: string
}

// const stuffUsed =
//     'Traveller' +
//     'ContactDetails' +
//     'PassengerCriteria' +
//     'PaymentChoices' +
//     'OrderContent' +
//     'TravellerRequirements' +
//     'CruiseProduct' +
//     'FlightProduct' +
//     'OrderItemStatus'

type ORDER_STATUS_TYPE =
    | 'UNFULFILLED'
    | 'BOOKED'
    | 'CANCELLED'
    | 'FAILED'
    | 'ON_HOLD'
    | 'ON_REQUEST'

export const ORDER_STATUS: {
    UNFULFILLED: ORDER_STATUS_TYPE
    BOOKED: ORDER_STATUS_TYPE
    CANCELLED: ORDER_STATUS_TYPE
    FAILED: ORDER_STATUS_TYPE
    ON_HOLD: ORDER_STATUS_TYPE
    ON_REQUEST: ORDER_STATUS_TYPE
} = {
    UNFULFILLED: 'UNFULFILLED',
    BOOKED: 'BOOKED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
    ON_HOLD: 'ON_HOLD',
    ON_REQUEST: 'ON_REQUEST',
}

export const ORDER_ITEM_STATUS: {
    UNFULFILLED: 'UNFULFILLED'
    BOOKED: 'BOOKED'
    CANCELLED: 'CANCELLED'
    FAILED: 'FAILED'
    ON_HOLD: 'ON_HOLD'
    ON_REQUEST: 'ON_REQUEST'
} = {
    UNFULFILLED: 'UNFULFILLED',
    BOOKED: 'BOOKED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
    ON_HOLD: 'ON_HOLD',
    ON_REQUEST: 'ON_REQUEST',
}

export const ORDER_ADDITIONAL_INFO_TYPES: {
    FLIGHT: 'FLIGHT'
    HOTEL: 'HOTEL'
    TRANSFER: 'TRANSFER'
    EXCURSION: 'EXCURSION'
} = {
    FLIGHT: 'FLIGHT',
    HOTEL: 'HOTEL',
    TRANSFER: 'TRANSFER',
    EXCURSION: 'EXCURSION',
}

export const ORDER_ADDITIONAL_FLIGHT_INFO_TYPES: {
    OUTBOUND: 'OUTBOUND'
    INBOUND: 'INBOUND'
} = {
    OUTBOUND: 'OUTBOUND',
    INBOUND: 'INBOUND',
}

export const ORDER_ITEM_PAYMENT_TYPE = {
    DEPOSIT: 'Deposit',
    FULL: 'Full',
    ONHOLD: PAYMENT_OPTIONS.ONHOLD,
}

export type OrderStatus = (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS]
export type OrderItemStatus = (typeof ORDER_ITEM_STATUS)[keyof typeof ORDER_ITEM_STATUS]
export type OrderItemPaymentType =
    (typeof ORDER_ITEM_PAYMENT_TYPE)[keyof typeof ORDER_ITEM_PAYMENT_TYPE]

export type CruisePriceItem = {
    readonly currencyCode: string
    readonly currencySymbol: string
    readonly totalGrossPrice: string
    readonly totalGrossPriceCent: number
    readonly totalTfpePrice: string
    readonly totalObcPrice: string
    readonly commission: string
    readonly rateCodeProps?: {
        military?: boolean
        residency?: boolean
        refundPolicy?: RefundPolicyType
    }
}

type ApiPaymentChoice = {
    readonly paymentOption: PaymentOptionType
    readonly paymentAmount: number
    readonly paymentCurrencyCode: string
    readonly dueDate?: string
}

export type PaymentChoice = {
    readonly option: PaymentOptionType
    readonly amount: string
    readonly amountCent: number
    readonly currencyCode: string
    readonly currencySymbol: string
    readonly dueDate?: string
}

export type PaymentChoices = {
    deposit: PaymentChoice | null
    full: PaymentChoice | null
    onhold: PaymentChoice | null
}

export type CruisePaymentItem = {
    readonly amount: string
    readonly currencyCode: string
    readonly currencySymbol: string
    readonly description: string
    readonly dueDate: string
    readonly paymentType: string
}

export type TravellerRequirements = {
    readonly allowSpecialNameChars: boolean
    readonly leadTravellerMinAge: number
}

function getCruisePaymentScheduleItems(
    paymentScheduleItems: Record<string, string>[]
): CruisePaymentItem[] {
    return paymentScheduleItems.map((paymentScheduleItem: Record<string, string>) => ({
        amount: insertDecimal2CharsFromEnd(paymentScheduleItem.amount),
        currencyCode: paymentScheduleItem.currency,
        currencySymbol: CURRENCY_CODES[paymentScheduleItem.currency],
        description: paymentScheduleItem.description,
        dueDate: getFormattedDate(paymentScheduleItem.dueDate),
        paymentType: paymentScheduleItem.paymentType,
    }))
}

/** Class representing an ItineraryDay of the Itinerary array of a Cruise - used to create port list on itinerary. */
export class ItineraryDay {
    /**
     *  @param {string} portCode - is the code for main location on that itinerary day, can be port/excursion references or undefined.
     *  @param {string} portName - is the port name or the excursion day location 'StoneHenge', or other notable events locations like 'crossing equator'). Sea Days are set to empty string.
     */
    readonly portName?: string
    readonly portCode: string
    constructor(itineraryItem: APIItineraryItem) {
        this.portCode = itineraryItem.portCode
        this.portName = this.getPortName({
            name: capitalizeEachWord(itineraryItem?.portName ?? ''),
            itemType: itineraryItem.itineraryItemType,
        })
    }
    getPortName({ name, itemType }: { name?: string; itemType: ItineraryItemType }): string {
        // NOTE: The location is made up of 'Port name, City, Country' (comma seperated), so we split by comma and take 1st in array.
        const locationIsAPort =
            itemType === ITINERARY_ITEM_TYPE_MAP_ORDER.port ||
            itemType === ITINERARY_ITEM_TYPE_MAP_ORDER.embark ||
            itemType === ITINERARY_ITEM_TYPE_MAP_ORDER.disembark
        if (name && locationIsAPort) {
            return name.split(',')[0]
        } else return ''
    }
}

/**  Class representing the Itinerary field of a cruise*/
export class Itinerary {
    /**
     * @param {string} portListContentFull - is a string of port names constructed using all location field from each ItineraryDays.
     * @param {string} portListContentLimited - is either empty string or as many ports before total length exceed fixed char limit when full list exceeds limit.
     */
    readonly portListContentFull: string[]
    readonly portListContentLimited: string[]
    readonly portCodesWithNamesAndDaysAndTimes: ItineraryItem[]
    constructor(itinerary: APIItineraryItem[]) {
        this.portListContentFull = this.getFullPortListContent(itinerary)
        this.portListContentLimited =
            this.portListContentFull.join(' ').length > PORT_LIST_CHAR_LIMIT
                ? this.getTruncatedPortListContent(itinerary)
                : []
        this.getTruncatedPortListContent(itinerary)
        this.portCodesWithNamesAndDaysAndTimes =
            this.getPortCodesWithNamesAndDaysAndTimes(itinerary)
    }

    getPortCodesWithNamesAndDaysAndTimes(itinerary: APIItineraryItem[]): ItineraryItem[] {
        return itinerary.map((item) => {
            const firstPartOfPortName = item.portName ? item.portName.split(',')[0] : null
            const itineraryItem = {
                day: item.dayNumber,
                portName: item.portName,
                justPortName: firstPartOfPortName ? capitalizeEachWord(firstPartOfPortName) : null,
                portCode: item.portCode,
                geolocation: item.geolocation,
                arrivalTime: item.arrivalTime,
                departureTime: item.departureTime,
                itemType: item.itineraryItemType,
                itemDate: item.itemDate,
            }
            return itineraryItem
        })
    }

    /**
     * Get all the ports using 'location' field from each itinerary day array on a cruise itinerary and constructs as single string list.
     * @param { array }  itinerary - an array of itinerary days for a cruise, each contain location and locationCode
     * @return {string} the cruise's itinerary's ports as a string list.
     */
    getFullPortListContent(itinerary: APIItineraryItem[]): string[] {
        const itineraryDayList = itinerary.map((itineraryDay) => new ItineraryDay(itineraryDay))
        const fullPortsList: string[] = []

        itineraryDayList.forEach((itineraryDay: ItineraryDay) => {
            if (itineraryDay.portName) {
                fullPortsList.push(itineraryDay.portName)
            }
        })

        return fullPortsList
    }

    /**
     * Creates the ports list again but stops adding ports when char length is exceeded.
     * @param { array }  itinerary - an array of itinerary days for a cruise, each contain location and locationCode
     * @return {string} the cruise's itinerary's ports as a string list.
     */
    getTruncatedPortListContent(itinerary: APIItineraryItem[]): string[] {
        const itineraryList = itinerary.map((itineraryDay) => new ItineraryDay(itineraryDay))
        const trimmedPortsList: string[] = []

        itineraryList.some((itineraryDay: ItineraryDay) => {
            if (itineraryDay.portName) {
                if (
                    [...trimmedPortsList, itineraryDay.portName].join(' ').length >
                    PORT_LIST_CHAR_LIMIT
                ) {
                    return true // stop iterating through PortsList when char limit has been exceeded
                }
                trimmedPortsList.push(itineraryDay.portName)
                return false
            } else return false
        })

        return trimmedPortsList
    }
}

export type CruiseProduct = {
    readonly productType: ProductType
    readonly cabinGradeName: string
    readonly cabinGradeCode: string
    readonly cabinGradeDescription: string
    readonly cabinNumber: string
    readonly cruiseId: string
    readonly productName: ProductType
    readonly cruiseName?: string
    readonly deckName?: string
    readonly deckLevel?: string
    readonly disembarkDate: string
    readonly duration: number
    readonly embarkDate: string
    readonly lineName: string
    readonly cabinRateName: string
    readonly shipName: string
    readonly rateCode: string
    readonly rateCodeName: string
    readonly rateCodeDescription: string
    readonly itinerary: Itinerary
    readonly pricing: CruisePriceItem
    readonly paymentChoices: PaymentChoices
    readonly paymentScheduleItems: CruisePaymentItem[]
    readonly breakdownPerPassenger: BreakdownPerPassenger
    readonly supplierCode: string
}

export type FlightPriceItem = {
    readonly currencyCode: string
    readonly currencySymbol: string
    readonly price: string
}

type FlightPriceBreakdownItem = {
    readonly amount: string // ?
    readonly name: string // ?
}

type FlightCondition = {
    readonly type: string
    readonly description?: string
    readonly allowed: boolean
    readonly penaltyAmount?: number
    readonly currencyCode?: string
}

type FlightBaggage = {
    readonly type: string
    readonly quantity: number
}

type FlightSegmentPassenger = {
    readonly passengerId: string
    readonly cabinClass: string
    readonly cabinClassMarketingName?: string
    readonly seatClass?: string
    readonly fareBasis?: string
    readonly baggageAllowance: FlightBaggage[]
}

type FlightSegment = {
    readonly arrivalAirport: string
    readonly arrivalAirportCode: string
    readonly arrivalDatetime: string
    readonly arrivalTerminal?: string
    readonly baggageSummary?: string
    readonly checkedBaggage: boolean
    readonly conditions: FlightCondition[]
    readonly departureAirport: string
    readonly departureAirportCode: string
    readonly departureDatetime: string
    readonly departureTerminal?: string
    readonly duration?: string
    readonly fareBrandName?: string
    readonly flightNumber: string
    readonly marketingAirlineCode?: string
    readonly marketingAirlineName: string
    readonly operatingAirlineCode?: string
    readonly operatingAirlineName?: string
    readonly segmentId?: string
    readonly segmentNumber: number
    readonly stops: string[]
    readonly passengers: FlightSegmentPassenger[]
}

type FlightLeg = {
    readonly legNumber: number
    readonly direction?: string
    readonly duration?: string
    readonly segments: FlightSegment[]
}
export type FlightProduct = {
    readonly productType: ProductType
    readonly flightId: string
    readonly currency?: string
    readonly airlineCode?: string
    readonly airlineName?: string
    readonly allowedIdentityDocumentTypes: string[]
    readonly childAges?: number[]
    readonly conditions: string[]
    readonly costBreakdown: FlightPriceBreakdownItem[]
    readonly fareType?: string
    readonly flightLegs: FlightLeg[]
    readonly flightType?: string
    readonly instantPayment: boolean
    readonly name: string
    readonly itineraryString: string
    readonly nettCost?: number
    readonly numberOfAdults?: number
    readonly numberOfChildren?: number
    readonly numberOfInfants?: number
    readonly passengerIdentityDocumentsRequired: boolean
    readonly paymentRequiredBy?: Date
    readonly pricing: FlightPriceItem
    readonly paymentChoices: PaymentChoices
    readonly providerId: string
    readonly providerItemId: string
    readonly selectedOptionalExtras: any[]
}

export type PassengerCriteria = {
    residency?: string
    military?: boolean
    travellerNumber: number
    pastPassengerReference: string | null
    age: number
    assignedOptionalExtras: OptionalExtra[]
}

export type Advisory = {
    code: string
    message: string
}

export type OrderItem = {
    readonly assignedTravellers: number[] // Just the traveler numbers (as added by user to the Travellers array of the basket) of the assigned travellers
    readonly bookedAt: string
    readonly createdAt: string
    readonly leadTraveller?: number // Just the traveller number of the lead traveler
    readonly numberOfTravellers: number // the number of passengers the cruise product pricing has been create against
    readonly orderItemId: string
    readonly orderItemPaymentOption: OrderItemPaymentType
    readonly orderItemStatus: OrderItemStatus
    readonly supplierOrderItemReference: string | null
    readonly product: CruiseProduct | FlightProduct | null
    readonly passengersCriteria?: PassengerCriteria[]
    readonly passengersAdditionalInfo?: OrderAdditionalInfo
    readonly advisory: Advisory[]
}

export type Passport = {
    readonly number: string
    readonly startDate: string
    readonly endDate: string
}

export type EmergencyContact = {
    readonly firstName: string
    readonly lastName: string
    readonly phoneNumber: string
    readonly email: string
}

export type ContactDetailsAddress = {
    readonly line1: string
    readonly line2: string
    readonly cityRegion: string
    readonly country: string
    readonly stateProvince?: string
    readonly zipPostalCode: string
}

export type PhoneNumber = {
    readonly type: string
    readonly number: string
}

export type Traveller = {
    readonly accessibilityNeeds?: boolean
    readonly dateOfBirth: string
    readonly emergencyContact?: EmergencyContact
    readonly firstName: string
    readonly lastName: string
    readonly middleName?: string
    readonly military?: boolean
    readonly militaryNumber?: string
    readonly nationality?: string
    readonly passport?: Passport
    readonly title: string
    readonly travellerNumber: number
}

export type ContactDetails = {
    readonly title: string
    readonly firstName: string
    readonly middleName: string
    readonly lastName: string
    readonly phoneNumber: PhoneNumber
    readonly email: string
    readonly address: ContactDetailsAddress
}

export type FlightAdditionalInfo = {
    name: string
    date: string
    flightTimes: string
    flightNumber: string
}

export type PassengerAdditionalInfo = {
    flightOutbound?: FlightAdditionalInfo
    flightInbound?: FlightAdditionalInfo
    preCruiseStay?: {
        name: string
        date: string
        roomType: string
    }
    postCruiseStay?: {
        name: string
        date: string
        roomType: string
    }
    transfer?: {
        name: string
        date: string
        transfer: string[]
    }
    shoreExcursions?: {
        days: string[]
    }
    excursion?: {
        name: string
        excursionId: string
        description: string
        startAt: string
        endAt: string
        duration: string
        price: string
    }
}

export type OrderAdditionalInfo = Record<string, PassengerAdditionalInfo>

/** this assumes single or round trip with non-stop segments only - will totally fail for multi-leg or segments with stops (MVP is just round trip) */
export function getItineraryString(flightLegs: FlightLeg[]): string {
    return (
        flightLegs[0].segments[0].departureAirportCode +
        ' - ' +
        flightLegs[0].segments[0].arrivalAirportCode
    )
}

export function getFlightSegments(flightSegments: Record<string, any>[]): FlightSegment[] {
    return flightSegments.map((flightSegment) => ({
        arrivalAirport: flightSegment.arrivalAirport,
        arrivalAirportCode: flightSegment.arrivalAirportCode,
        arrivalDatetime: getFormattedDate(flightSegment.arrivalDatetime),
        arrivalTerminal: flightSegment.arrivalTerminal,
        baggageSummary: flightSegment.baggageSummary,
        checkedBaggage: flightSegment.checkedBaggage,
        conditions: flightSegment.conditions as FlightCondition[],
        departureAirport: flightSegment.departureAirport,
        departureAirportCode: flightSegment.departureAirportCode,
        departureDatetime: getFormattedDate(flightSegment.departureDatetime),
        departureTerminal: flightSegment.departureTerminal,
        duration: flightSegment.duration,
        fareBrandName: flightSegment.fareBrandName,
        flightNumber: flightSegment.flightNumber || '',
        marketingAirlineCode: flightSegment.marketingAirlineCode,
        marketingAirlineName: flightSegment.marketingAirlineName || '',
        operatingAirlineCode: flightSegment.operatingAirlineCode,
        operatingAirlineName: flightSegment.operatingAirlineName,
        segmentId: flightSegment.segmentId,
        segmentNumber: flightSegment.segmentNumber,
        stops: flightSegment.stops ?? [],
        passengers: flightSegment.passengers as FlightSegmentPassenger[],
    }))
}

export function getFlightLegs(flightLegs: Record<string, any>[]): FlightLeg[] {
    return flightLegs.map((flightLeg) => ({
        direction: flightLeg.direction,
        duration: flightLeg.duration,
        legNumber: flightLeg.legNumber,
        segments: getFlightSegments(flightLeg.segments),
    }))
}

const getPaymentChoice = (paymentChoice: ApiPaymentChoice): PaymentChoice => {
    return {
        option: paymentChoice.paymentOption,
        amount: insertDecimal2CharsFromEnd(paymentChoice.paymentAmount),
        amountCent: paymentChoice.paymentAmount,
        currencyCode: paymentChoice.paymentCurrencyCode,
        currencySymbol: CURRENCY_CODES[paymentChoice.paymentCurrencyCode],
        dueDate: paymentChoice.dueDate,
    }
}

const getPaymentChoices = (paymentChoices: ApiPaymentChoice[]): PaymentChoices => {
    const returnObject: PaymentChoices = {
        deposit: null,
        full: null,
        onhold: null,
    }
    paymentChoices?.forEach((paymentChoice) => {
        if (paymentChoice.paymentOption === PAYMENT_OPTIONS.DEPOSIT)
            returnObject.deposit = getPaymentChoice({
                ...paymentChoice,
                paymentOption: PAYMENT_OPTIONS.DEPOSIT,
            })
        if (paymentChoice.paymentOption === PAYMENT_OPTIONS.FULL)
            returnObject.full = getPaymentChoice({
                ...paymentChoice,
                paymentOption: PAYMENT_OPTIONS.FULL,
            })
        if (paymentChoice.paymentOption === PAYMENT_OPTIONS.ONHOLD)
            returnObject.onhold = getPaymentChoice({
                ...paymentChoice,
                paymentOption: PAYMENT_OPTIONS.ONHOLD,
            })
    })
    return returnObject
}

const getPassengerTitleByNumber = (passenger: any): string => {
    return passenger.travellerNumber === 1
        ? 'Lead Passenger'
        : `Passenger ${passenger.travellerNumber}`
}

const parseAdditionalFlightInfo = (data: Record<string, any>): FlightAdditionalInfo => {
    const withTransfer = data.itineraryItems?.length > 1
    if (!withTransfer) {
        const itineraryItem = data.itineraryItems[0]
        return {
            name: itineraryItem.departurePort + ' - ' + itineraryItem.arrivalPort,
            flightNumber: itineraryItem.flightNumber,
            date: getFormattedDate(itineraryItem.departureDatetime), //  'Oct 02, 2024',
            flightTimes: `${getFormattedDateTimeToLocalTimezone(
                itineraryItem.departureDatetime,
                DATE_FORMAT_USA_TIME
            )} - ${getFormattedDateTimeToLocalTimezone(
                itineraryItem.arrivalDatetime,
                DATE_FORMAT_USA_TIME
            )} (${calculateHourDifference(
                new Date(itineraryItem.arrivalDatetime),
                new Date(itineraryItem.departureDatetime)
            )} hours)`,
        }
    } else {
        const itineraryItems = data.itineraryItems.sort((a: any, b: any) =>
            a.seqNumber > b.seqNumber ? 1 : -1
        )
        const departureDate = itineraryItems[0].departureDatetime
        const arrivalDate = itineraryItems[itineraryItems.length - 1].arrivalDatetime
        return {
            name: [
                ...itineraryItems.map((item: any) => item.departurePort),
                itineraryItems[itineraryItems.length - 1].arrivalPort,
            ].join(' - '),
            date: getFormattedDate(departureDate), //  'Oct 02, 2024',
            flightNumber: [...itineraryItems.map((item: any) => item.flightNumber)].join(', '),
            flightTimes: `${getFormattedDateTimeToLocalTimezone(
                departureDate,
                DATE_FORMAT_USA_TIME
            )} - ${getFormattedDateTimeToLocalTimezone(
                arrivalDate,
                DATE_FORMAT_USA_TIME
            )} (${calculateHourDifference(new Date(arrivalDate), new Date(departureDate))} hours)`,
        }
    }
}

const parseAdditionalBookingDetails = (passenger: any): Record<string, any> => {
    const result: any = {}

    if (passenger?.additionalBookingDetails?.length) {
        //  flightOutbound
        const flightOutboundRaw = passenger.additionalBookingDetails.find(
            (info: any) =>
                info.detailType === ORDER_ADDITIONAL_INFO_TYPES.FLIGHT &&
                info?.flightType === ORDER_ADDITIONAL_FLIGHT_INFO_TYPES.OUTBOUND
        )
        if (flightOutboundRaw) {
            result.flightOutbound = parseAdditionalFlightInfo(flightOutboundRaw)
        }
        //  flightInbound
        const flightInboundRaw = passenger.additionalBookingDetails.find(
            (info: any) =>
                info.detailType === ORDER_ADDITIONAL_INFO_TYPES.FLIGHT &&
                info?.flightType === ORDER_ADDITIONAL_FLIGHT_INFO_TYPES.INBOUND
        )
        if (flightInboundRaw) {
            result.flightInbound = parseAdditionalFlightInfo(flightInboundRaw)
        }
    }
    return result
}

export const parseOptionalExtrasPerPassengers = (
    assignedOptionalExtras: OptionalExtra[],
    passengersAmount: number
): OptionalExtra[] => {
    return (
        assignedOptionalExtras
            ?.filter((extra: OptionalExtra) => extra.type === 'OPTIONALEXTRA')
            .map((extra: OptionalExtra) => {
                const price = extra.prices[0]
                const totalPrice =
                    price?.pricePer === 'PerPassenger'
                        ? price?.amount * passengersAmount
                        : price?.amount
                return {
                    ...extra,
                    totalPrice,
                    formattedPrice: formatPriceString(totalPrice) || '0.00',
                    renderName: capitalizeEachWord(extra?.name),
                }
            })
            .filter((extra: any) => extra.totalPrice) || []
    )
}

/** Class representing an Order */
export class OrderContent {
    readonly orderId: string
    readonly orderStatus: OrderStatus
    readonly orderItem: OrderItem
    readonly travellers: Record<string, Traveller>
    readonly contactDetails: ContactDetails
    readonly travellerRequirements: TravellerRequirements
    readonly isImported: boolean
    constructor(orderData: Record<string, any>) {
        this.orderId = orderData.id
        this.orderStatus = orderData.status
        this.orderItem = OrderContent.getOrderItem(orderData.items[0] || {})
        this.travellers = OrderContent.getTravellers(orderData?.travellers || [])
        this.contactDetails = OrderContent.getContactDetails(orderData.contactDetails)
        this.isImported = Boolean(orderData.items[0].imported)
        this.travellerRequirements = orderData.travellerRequirements
    }
    public static getContactDetails(contactDetails: ContactDetails | null): ContactDetails {
        return {
            title: contactDetails?.title ?? '',
            firstName: contactDetails?.firstName ?? '',
            middleName: contactDetails?.middleName ?? '',
            lastName: contactDetails?.lastName ?? '',
            phoneNumber: contactDetails?.phoneNumber ?? {
                number: '',
                type: '',
            },
            email: contactDetails?.email ?? '',
            address: contactDetails?.address ?? {
                cityRegion: '',
                stateProvince: '',
                country: '',
                line1: '',
                line2: '',
                zipPostalCode: '',
            },
        }
    }

    public static getTravellers(travellers: Traveller[]): Record<string, Traveller> {
        const theTravellers: Record<string, Traveller> = {}
        travellers.forEach((traveller: Traveller) => {
            const travellerNum = traveller.travellerNumber
            theTravellers[travellerNum.toString()] = traveller
        })
        return theTravellers
    }

    public static getOrderItem(orderItem: Record<string, any>): OrderItem {
        return {
            assignedTravellers: orderItem.assignedTravellers ?? [],
            bookedAt: orderItem.bookedAt,
            createdAt: orderItem.createdAt,
            leadTraveller: orderItem.leadTraveller,
            numberOfTravellers: orderItem.numberOfTravellers,
            orderItemId: orderItem.id,
            orderItemPaymentOption: orderItem.paymentOption,
            orderItemStatus: orderItem.status,
            product: this.getProduct(orderItem.product, orderItem.type),
            supplierOrderItemReference: orderItem.supplierOrderReference // TODO: if this is on the orderItem, API should name it SUPPLIER_ORDER_ITEM_REFERENCE
                ? orderItem?.supplierOrderReference
                : null /** must be null if not present to trigger being a quote not a booked order */,
            passengersCriteria: this.getPassengersCriteria(
                orderItem?.passengersCriteria?.passengers || []
            ), // EMPTY ARRAY UNLESS PRODUCT IS A CRUISE
            passengersAdditionalInfo: this.getPassengersAdditionalInfo(
                orderItem?.passengersCriteria?.passengers || []
            ), // EMPTY ARRAY UNLESS PRODUCT IS A CRUISE
            advisory: orderItem?.advisory ?? [],
        }
    }

    public static getPassengersCriteria(passengers: Record<string, any>[]): PassengerCriteria[] {
        return passengers.map((passenger) => ({
            residency: passenger.residency,
            military: passenger.military,
            travellerNumber: passenger.travellerNumber,
            pastPassengerReference: passenger.pastPassengerReference,
            age: passenger.age,
            assignedOptionalExtras: parseOptionalExtrasPerPassengers(
                passenger?.assignedOptionalExtras,
                passengers.length
            ),
        }))
    }

    public static getPassengersAdditionalInfo(
        passengers: Record<string, any>[]
    ): OrderAdditionalInfo {
        if (passengers?.length) {
            const passengersAdditionalInfo = {
                'Lead Passenger': {},
            }
            for (const passenger of passengers) {
                const keyToSave = getPassengerTitleByNumber(passenger)
                ;(passengersAdditionalInfo as any)[keyToSave] =
                    parseAdditionalBookingDetails(passenger)

                // to avoid empty passengers accordions - should be always in the end of iteration
                if (!Object.values((passengersAdditionalInfo as any)[keyToSave])?.length) {
                    delete (passengersAdditionalInfo as any)[keyToSave]
                }
            }
            return passengersAdditionalInfo
        }
        return {}
    }

    public static getProduct(
        product: Record<string, any>,
        orderItemType: ProductType
    ): CruiseProduct | FlightProduct | null {
        if (orderItemType === PRODUCT_TYPES.CRUISE) {
            return {
                productType: PRODUCT_TYPES.CRUISE,
                cabinGradeCode: product.cabinGradeCode,
                cabinGradeName: product.cabinGradeName,
                cabinGradeDescription: product.cabinGradeDescription,
                cabinNumber: product.cabinNumber,
                cruiseId: product.id,
                productName: product.name,
                cruiseName: product.productName,
                deckName: product.deckName,
                deckLevel: product.deckLevel || 'Level Unknown',
                cabinRateName: product.rateName,
                disembarkDate: product.disembarkDate,
                duration: product.duration,
                embarkDate: product.embarkDate,
                lineName: product.lineName,
                rateCode: product.rateCode,
                rateCodeName: product.rateName,
                rateCodeDescription: product.rateDescription,
                shipName: product.shipName,
                itinerary: new Itinerary(product.itinerary),
                pricing: this.getCruisePriceItem(product.priceItems[0], product.price),
                paymentChoices: getPaymentChoices(product.priceItems[0].paymentChoices),
                paymentScheduleItems: getCruisePaymentScheduleItems(
                    product.priceItems[0].paymentScheduleItems
                ),
                breakdownPerPassenger: getBreakDownItemsByPassenger(
                    product.priceItems[0].breakdownItems
                ),
                supplierCode: product.cruiseLineCode,
            }
        }
        if (orderItemType === PRODUCT_TYPES.FLIGHT) {
            return {
                productType: PRODUCT_TYPES.FLIGHT,
                flightId: product.id,
                currency: CURRENCY_CODES[product.currencyCode] ?? '',
                airlineCode: product.airlineCode ?? '',
                airlineName: product.airlineName ?? '',
                allowedIdentityDocumentTypes: product.allowedIdentityDocumentTypes,
                childAges: product.childAges ?? [],
                conditions: product.conditions ?? [],
                costBreakdown: product.costBreakdown,
                fareType: product.fareType ?? '',
                flightLegs: getFlightLegs(product.flightLegs),
                flightType: product.flightType ?? '',
                instantPayment: product.instantPayment,
                itineraryString: getItineraryString(product.flightLegs) ?? '',
                name: product.name ?? 'Flight',
                nettCost: product.nettCost ?? null,
                numberOfAdults: product.numberOfAdults,
                numberOfChildren: product.numberOfChildren ?? null,
                numberOfInfants: product.numberOfInfants ?? null,
                passengerIdentityDocumentsRequired: product.passengerIdentityDocumentsRequired,
                paymentRequiredBy: product.paymentRequiredBy ?? null,
                pricing: {
                    price: insertDecimal2CharsFromEnd(product.price), // cruise/flight TODO: Api should provide pricing the same structure for each product
                    currencyCode: product.currencyCode,
                    currencySymbol: CURRENCY_CODES[product.currencyCode] ?? '',
                },
                paymentChoices: {
                    deposit: null,
                    full: getPaymentChoice({
                        paymentOption: PAYMENT_OPTIONS.FULL,
                        paymentAmount: product.price,
                        paymentCurrencyCode: product.price.currencyCode,
                    }),
                    onhold: null,
                },
                providerId: product.providerId,
                providerItemId: product.providerItemId,
                selectedOptionalExtras: product.selectedOptionalExtras || [],
            }
        }
        return null
    }
    public static getCruisePriceItem(
        priceItem: Record<string, any>,
        total?: number
    ): CruisePriceItem {
        return {
            currencySymbol: CURRENCY_CODES[priceItem.currency] ?? priceItem.currency,
            currencyCode: priceItem.currency,
            totalGrossPriceCent: priceItem.totalGrossPrice || total,
            totalGrossPrice: insertDecimal2CharsFromEnd(priceItem.totalGrossPrice || total),
            commission: insertDecimal2CharsFromEnd(priceItem.commission),
            totalTfpePrice: insertDecimal2CharsFromEnd(priceItem.totalTfpePrice || 0), // Need to default to 0 as does not exist in new model on eks
            totalObcPrice: insertDecimal2CharsFromEnd(priceItem.totalObcPrice),
            rateCodeProps: {
                military: priceItem?.rateCode?.military,
                residency: priceItem?.rateCode?.residency,
                refundPolicy: priceItem?.rateCode?.refundPolicy,
            },
        }
    }
}

export type DisplayAs = 'PERPERSON' | 'PERCABIN' | 'PerPassenger' | 'PerCabin' // not used currently
export type PriceType = 'PERPERSON' | 'PERCABIN' | 'PerPassenger' | 'PerCabin' // types to define total price (need get rid of old graphql types)
export type ExtraType = 'SPECIALSERVICE' | 'OPTIONALEXTRA' | 'FARECODE'
export type SubExtraType =
    | 'MEDICAL'
    | 'OCCASION'
    | 'DININGSERVICES'
    | 'HOUSEKEEPING'
    | 'SPECIALSERVICE'
    | 'UPGRADE'
    | 'GRATUITIES'
    | 'INSURANCE'
export type CurrencyEnum = 'GBP' | 'USD' | 'AUD' // TODO: think we have this elsewhere... should probably have a global currency type?

export type OptionalExtra = {
    code: string
    description: string
    renderName: string
    displayAs: DisplayAs
    name: string
    prices: {
        amount: number
        currency: CurrencyEnum // TODO: rename this field 'currencyCode' because its not clear once using in components if the value is the symbol or code otherwise
        pricePer: PriceType
        rules: {
            dateRequired: boolean
            maxAge: number | null
            minAge: number | null
            travellersAvail: number[]
        }
    }[]
    subType: SubExtraType
    type: ExtraType
    formattedPrice?: string
    totalPrice?: number
}

/** Class representing an OptionalExtras */
export class OptionalExtrasData {
    readonly optionalExtras: Record<string, OptionalExtra[]>
    constructor(data: Record<string, any>) {
        const optionalExtras =
            data.optionalExtras?.optionalExtras?.map((extra: any) => {
                return {
                    ...extra,
                    renderName: capitalizeEachWord(extra.name),
                    formattedPrice: formatPriceString(extra.prices[0].amount) || '0.00',
                    totalPrice: extra.prices[0].amount,
                }
            }) || []
        const extraInsurance = optionalExtras?.filter((extra: any) => extra.subType === 'INSURANCE')
        const extraOther = optionalExtras?.filter((extra: any) => extra.subType !== 'INSURANCE')

        this.optionalExtras = {
            General: extraOther,
            Insurance: extraInsurance,
        }
    }
}
