import React, { SetStateAction, useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { RenderInputComponentProps } from 'react-autosuggest'
import { useNavigate } from 'react-router-dom'

import AddUserButton from './AddUserButton/AddUserButton'
import Autosuggest from 'components/blocks/Autosuggest/Autosuggest'
import Button from 'components/basics/Button/Button'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import TablePaginationButtons from 'components/blocks/TablePaginationButtons/TablePaginationButtons'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import UsersList from './UsersList/UsersList'
import { Group } from 'api-data-models/admin/GroupsContentModel'
import { User, UsersModel } from 'api-data-models/admin/UsersContentModel'
import { stringArrayFilterAndSortByMatcher } from 'utils/stringArrayFilterAndSortByMatcher'
import { AGENT_CONNECT_LIST_USERS } from 'graphql-queries/admin/admin-queries'
import { COMPANY_TIER_NAMES, USER_ROLES } from 'utils/constants'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import * as self from './UserManagement'

import styles from './UserManagement.module.scss'
import allContent from 'content/content'
import { extractGlobalContextUserData } from '../../../../utils/user-data-helpers/extract-user-data-fields'

const content = allContent.admin.userManagementPage

const USERS_PER_PAGE = 10
const NUMBER_OF_SUGGESTIONS = 3

export function handlePageClick(
    selectedPage: number,
    setCurrentPage: React.Dispatch<SetStateAction<number>>
): void {
    setCurrentPage(selectedPage)
}
export function usersToDisplay(userListData: User[], page: number): User[] {
    const startIndex = (page - 1) * USERS_PER_PAGE
    const endIndex = startIndex + USERS_PER_PAGE

    return userListData.slice(startIndex, endIndex)
}
export function handleRefreshList({
    apiClient,
    setUsersData,
    setApiErrorMessage,
    setFetchingUsers,
}: {
    apiClient: ApolloClient<NormalizedCacheObject>
    setUsersData: React.Dispatch<SetStateAction<[] | User[]>>
    setApiErrorMessage: React.Dispatch<SetStateAction<string | null>>
    setFetchingUsers: React.Dispatch<SetStateAction<boolean>>
}): void {
    const userContext = datadogLogs.getGlobalContext()
    setFetchingUsers(true)
    apiClient
        .query({ query: AGENT_CONNECT_LIST_USERS })
        .then((response) => {
            setFetchingUsers(false)
            if (response?.data?.listAgentConnectUsers) {
                const users = response?.data?.listAgentConnectUsers
                setUsersData(
                    UsersModel(users).sort((a, b) => a.firstName.localeCompare(b.firstName))
                )
                datadogLogs.logger.info(
                    `Query AGENT_CONNECT_LIST_USERS Successfully got users list: ${JSON.stringify(
                        users
                    )}`,
                    { userContext }
                )
            }
        })
        .catch((error) => {
            setFetchingUsers(false)
            datadogLogs.logger.error(
                `Query AGENT_CONNECT_LIST_USERS UserManagement - error: ${JSON.stringify({
                    error,
                })}`,
                { userContext },
                error
            )
            setApiErrorMessage(content.errors.fetchingUsers)
        })
}

type UserManagementProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    groupsListData: Group[]
    fetchingGroups: boolean
    groupsApiErrorMessage: null | string
}

export function handleClearFiltersClick(
    setFilteredUsersListData: React.Dispatch<SetStateAction<[] | User[]>>,
    setUserNamesFilterValue: React.Dispatch<SetStateAction<string>>,
    setUserNameSuggestions: React.Dispatch<SetStateAction<string[]>>
): void {
    setFilteredUsersListData([])
    setUserNamesFilterValue('')
    setUserNameSuggestions([])
}

const UserManagement: React.FC<UserManagementProps> = ({
    apiClient,
    groupsListData,
    fetchingGroups,
    groupsApiErrorMessage,
}) => {
    const navigate = useNavigate()
    const { companyTier } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    const [usersData, setUsersData] = useState<User[] | []>([])
    const [shouldRefreshUsersData, setShouldRefreshUsersData] = useState<boolean>(true)

    const [filteredUsersListData, setFilteredUsersListData] = useState<User[] | []>([])
    const [fetchingUsers, setFetchingUsers] = useState<boolean>(false)
    const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null)

    const [addUserApiError, setAddUserApiError] = useState(null)
    const [addUserApiResponseStatus, setAddUserApiResponseStatus] = useState<string | null>(null)
    const [newUserEmail, setNewUserEmail] = useState<string | null>(null)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [usersFilterValue, setUsersFilterValue] = useState<string>('')
    const [usersSuggestions, setUsersSuggestions] = useState<string[]>([])

    const [updateUserDetailsSuccessMessage, setUpdateUserDetailsSuccessMessage] = useState<
        string | null
    >(null)

    const [updateUserGroupSuccessMessage, setUpdateUserGroupSuccessMessage] = useState<
        string | null
    >(null)

    const [updateUserDetailsError, setUpdateUserDetailsError] = useState<string | null>(null)
    const [updateUserGroupError, setUpdateUserGroupError] = useState<string | null>(null)

    const hasApiUser =
        usersData.filter((user) => {
            return user.userRoles.includes(USER_ROLES.API_USER)
        }).length > 0

    const showCreateApiUserButton =
        companyTier === COMPANY_TIER_NAMES.CRUISE_API && usersData && !hasApiUser

    const showCreateAgentUserButton = companyTier !== COMPANY_TIER_NAMES.CRUISE_API

    /** Make api call to fetch users on initial landing and to refresh data after editing a user */
    useEffect((): void => {
        if (shouldRefreshUsersData) {
            const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
            handleUserSession.then(() => {
                self.handleRefreshList({
                    apiClient,
                    setFetchingUsers,
                    setApiErrorMessage,
                    setUsersData,
                })
            })
        }
        setShouldRefreshUsersData(false)
    }, [apiClient, shouldRefreshUsersData, navigate])

    useEffect(() => {
        setFilteredUsersListData(
            usersData.filter((user) =>
                user.firstName.toLowerCase().includes(usersFilterValue.toLowerCase())
            )
        )
    }, [usersFilterValue, usersData])

    function getUsersSuggestionsList(searchString: string): void {
        const filteredUsersBySearchString = usersData
            .map((user) => user.firstName)
            .filter((firstName) => firstName.toLowerCase().includes(searchString.toLowerCase()))
        const weightedOrderedUserNames = stringArrayFilterAndSortByMatcher(
            filteredUsersBySearchString,
            searchString
        )
        setUsersSuggestions(weightedOrderedUserNames.slice(0, NUMBER_OF_SUGGESTIONS))
    }
    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            {addUserApiError && (
                <InfoBanner id='user-add-error-banner' bannerType='error' text={addUserApiError} />
            )}
            {updateUserDetailsSuccessMessage && (
                <InfoBanner
                    id='update-user-details-success-banner'
                    bannerType='info'
                    text={updateUserDetailsSuccessMessage}
                />
            )}
            {updateUserGroupSuccessMessage && (
                <InfoBanner
                    id='update-user-group-success-banner'
                    bannerType='info'
                    text={updateUserGroupSuccessMessage}
                />
            )}

            {updateUserGroupError && (
                <InfoBanner
                    id='update-user-group-success-banner'
                    bannerType='error'
                    text={updateUserGroupError}
                />
            )}
            {updateUserDetailsError && (
                <InfoBanner
                    id='update-user-error-banner'
                    bannerType='error'
                    text={updateUserDetailsError}
                />
            )}
            {addUserApiResponseStatus === 'SUCCESS' && (
                <InfoBanner
                    id='user-add-success-banner'
                    text={
                        content.userAddSuccessMessage1 +
                        newUserEmail +
                        content.userAddSuccessMessage2
                    }
                    bannerType='success'
                    isCloseable={true}
                />
            )}
            {showCreateAgentUserButton && (
                <AddUserButton
                    setNewUserEmail={setNewUserEmail}
                    groupsListData={groupsListData}
                    fetchingGroups={fetchingGroups}
                    groupsApiErrorMessage={groupsApiErrorMessage}
                    apiClient={apiClient}
                    setApiError={setAddUserApiError}
                    setApiResponseStatus={setAddUserApiResponseStatus}
                />
            )}
            {showCreateApiUserButton && (
                <AddUserButton
                    setNewUserEmail={setNewUserEmail}
                    groupsListData={groupsListData}
                    fetchingGroups={fetchingGroups}
                    groupsApiErrorMessage={groupsApiErrorMessage}
                    apiClient={apiClient}
                    setApiError={setAddUserApiError}
                    setApiResponseStatus={setAddUserApiResponseStatus}
                    isApiUser={true}
                />
            )}
            <div className={styles['users-filter']}>
                <Text>{content.filter.title}</Text>
                <div className={styles['users-filter-content']}>
                    <Autosuggest
                        openSuggestionsOnFocus={usersFilterValue?.length > 0}
                        inputValue={usersFilterValue}
                        setInputValue={(value): void => {
                            let input = value
                            if (typeof value === 'object') input = Object.values(value).join('')
                            setUsersFilterValue(input)
                        }}
                        onBlur={(value): void => {
                            if (!value)
                                handleClearFiltersClick(
                                    setFilteredUsersListData,
                                    setUsersFilterValue,
                                    setUsersSuggestions
                                )
                            if (value) setUsersFilterValue(value)
                        }}
                        id='user-autosuggest'
                        getSuggestionValue={(firstName): string => {
                            return firstName
                        }}
                        onSuggestionSelected={(value: string): string => {
                            setUsersFilterValue(value)
                            const filteredUserList = usersData.filter((user) =>
                                user.firstName.toLowerCase().includes(value.toLowerCase())
                            )
                            if (filteredUserList) setFilteredUsersListData(filteredUserList)
                            return value
                        }}
                        name='autosuggest-user-filter'
                        renderSuggestion={(value: string): React.ReactElement => {
                            return <span>{value}</span>
                        }}
                        onSuggestionsClearRequested={(): void => setUsersSuggestions([])}
                        suggestionsData={usersSuggestions}
                        suggestionsFetchRequest={getUsersSuggestionsList}
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <LabelledInput
                                htmlFor='user-filter'
                                label={content.filter.title}
                                labelHidden
                                {...rest}
                            >
                                <TextInput />
                            </LabelledInput>
                        )}
                    />
                    <div>
                        <Button
                            text={content.filter.button}
                            type='button'
                            flavour='secondary'
                            onClick={(): void =>
                                handleClearFiltersClick(
                                    setFilteredUsersListData,
                                    setUsersFilterValue,
                                    setUsersSuggestions
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={styles['users-header']}>
                    <Heading heading='2' size='3' onDarkBackground={true}>
                        {content.list.heading}
                    </Heading>
                    <Button
                        type='button'
                        flavour='text'
                        iconName='ArrowsRotate'
                        text={content.list.refresh}
                        onDarkBackground={true}
                        onClick={(): void =>
                            handleRefreshList({
                                apiClient,
                                setFetchingUsers,
                                setApiErrorMessage,
                                setUsersData,
                            })
                        }
                    />
                </div>
                <div className={styles['users-content']}>
                    {fetchingUsers && <LargeSpinner text={content.fetchingUsers} />}
                    {apiErrorMessage && (
                        <InfoBanner
                            id='api-error-banner'
                            bannerType='error'
                            text={apiErrorMessage}
                        />
                    )}
                    {!fetchingUsers && !apiErrorMessage && usersData.length === 0 && (
                        <p>{content.list.noUsers}</p>
                    )}
                    {!fetchingUsers && !!usersData?.length && (
                        <UsersList
                            setUpdateUserGroupError={setUpdateUserGroupError}
                            setUpdateUserDetailsError={setUpdateUserDetailsError}
                            setUpdateUserGroupSuccessMessage={setUpdateUserGroupSuccessMessage}
                            setUpdateUserDetailsSuccessMessage={setUpdateUserDetailsSuccessMessage}
                            setShouldRefreshUsersData={setShouldRefreshUsersData}
                            fetchingGroups={fetchingGroups}
                            groupsListData={groupsListData}
                            groupsApiErrorMessage={groupsApiErrorMessage}
                            usersData={usersToDisplay(
                                filteredUsersListData.length > 0
                                    ? filteredUsersListData
                                    : usersData,
                                currentPage
                            )}
                            apiClient={apiClient}
                        />
                    )}
                    {!!usersData?.length && (
                        <TablePaginationButtons
                            handlePageChange={(selected: number): void =>
                                handlePageClick(selected, setCurrentPage)
                            }
                            page={currentPage}
                            totalNumOfPages={
                                filteredUsersListData.length > 0
                                    ? Math.ceil(filteredUsersListData.length / USERS_PER_PAGE)
                                    : Math.ceil(usersData.length / USERS_PER_PAGE)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserManagement
