import { useState } from 'react'

type UseFilterCheckbox = [string[], (value: string) => void]

/**
 produces an array of strings that represent checkboxes currently checked and stores them as state
 intended for use with a checkbox group
 @param { string } value - the target value in the array to add or remove
 @param { Array<string> } - the array of currently checked checkboxes
 @param { function } setGroupState - useState dispatch
 */
const useFilterCheckbox = (initialState: string[]): UseFilterCheckbox => {
    const [groupState, setGroupState] = useState<string[]>(initialState)

    const handleGroupState = (value: string): void => {
        if (!value) {
            setGroupState([])
            return
        }
        const find = groupState.indexOf(value)
        /** remove from array we find its index, or add to array if its not currently in (ie. find = -1) */
        if (find > -1) {
            groupState.splice(find, 1)
        } else {
            groupState.push(value)
        }
        /** Need to spread the array to cause a rerender - the array reference stays the same without it */
        setGroupState([...groupState])
    }

    return [groupState, handleGroupState]
}

export default useFilterCheckbox
