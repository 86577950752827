import React, { SetStateAction } from 'react'
import { Navigate } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import LargeSpinner from 'components/basics/Spinners/LargeSpinner'

import { ROUTES } from 'components/sections/app/AppRoutes'
import { COMPANY_TIER_NAMES } from 'utils/constants'

import allContent from 'content/content'
import SubscriptionSetup from '../../sections/app/SubscriptionSetup/SubscriptionSetup'

const content = allContent.landingPage

/** LandingPage is needed to handle where user should go after being Authorised.
 * Show spinner while fetching userData, and handle based on companyTier and user role.
 * Is also the holding area for if user has no financeSystemId, or there's an error in fetching user data.
 */
const LandingPage: React.FC<{
    companyTier?: CompanyTierTypes
    financeSystemId?: string
    isFetchingUserData: boolean
    managerApiClient: ApolloClient<NormalizedCacheObject>
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
}> = ({ managerApiClient, isFetchingUserData, companyTier, financeSystemId, setUserData }) => {
    if (isFetchingUserData) {
        /** Only shown briefly while rerender occurs after login - so don't show text, but use text for testing */
        return (
            <div className='general-container'>
                <LargeSpinner text={content.fetchingUserData} hideText={true} />
            </div>
        )
    }

    if (!financeSystemId && companyTier) {
        return (
            <div className='general-container'>
                <SubscriptionSetup
                    managerApiClient={managerApiClient}
                    companyTier={companyTier}
                    setUserData={setUserData}
                />
            </div>
        )
    }

    if (financeSystemId && companyTier) {
        if (COMPANY_TIER_NAMES.CRUISE_API === companyTier) {
            /** API Tier user can't make agent users, api user are blocked at log in, so much be admin user - without app access */
            return <Navigate to={ROUTES.ADMIN_DASHBOARD} />
        }
        /** FOR NOW CRUSE SEARCH IS LANDING PAGE FOR EVERY APP TIER other than API */
        return <Navigate to={ROUTES.CRUISE_SEARCH} />
    } else {
        return (
            <div className='general-container'>
                <p>{content.notOnASubscription}</p>
                {/** Shouldn't be possible to have financeId and not a valid companyTier, but meh. */}
            </div>
        )
    }
}

export default LandingPage
