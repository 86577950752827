import React, { ChangeEventHandler } from 'react'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'

import { GroupLabel } from 'components/blocks/LabelledInput/LabelledInput'
import Select from 'components/basics/Input/Select/Select'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import FieldError from 'components/basics/FieldError/FieldError'
import { REGEX } from 'utils/constants'

import styles from './PhoneInput.module.scss'
import allContent from 'content/content'

const content = allContent.components.phoneInput

export interface PhoneInputProps {
    countryCodeFieldName: string
    inputFieldName: string
    countryCodeLabel: string
    inputLabel: string
    generalError?: boolean
    disabled?: boolean
    required?: boolean
    groupLabel: string
    control?: any
    /** Country Code Options - array of text/values */
    countryCodeOptions: any[]
    onCountryCodeChange: ChangeEventHandler<HTMLSelectElement>
    /** To pass phone number mask */
    inputPlaceHolder?: string
    onChange: (name: any, value: string) => void
    className?: string
}

const PhoneInput: React.FC<PhoneInputProps> = ({
    countryCodeFieldName,
    inputFieldName,
    countryCodeLabel,
    inputLabel,
    generalError,
    disabled,
    required,
    groupLabel = content.label,
    control,
    countryCodeOptions,
    onCountryCodeChange,
    onChange,
    inputPlaceHolder = '',
    className,
}) => {
    const wrapperClassNames = classNames(styles['container'], className)

    return (
        <div className={wrapperClassNames}>
            <fieldset disabled={disabled} className={styles['inputs-group--horizontal']}>
                <legend>
                    <GroupLabel required={required}>{groupLabel}</GroupLabel>
                </legend>
                <Controller
                    control={control}
                    name={countryCodeFieldName}
                    rules={{ required }}
                    render={({ field: { value } }): React.ReactElement => (
                        <Select
                            required={required}
                            placeholder={content.countryCallingCodePlaceholder}
                            value={value}
                            options={countryCodeOptions}
                            aria-label={countryCodeLabel}
                            onChange={onCountryCodeChange}
                            className={styles['country-code']}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={inputFieldName}
                    rules={{ pattern: REGEX.DIGITS_ONLY, required }}
                    render={({ field: { value } }): React.ReactElement => (
                        <TextInput
                            value={value}
                            inputMode='tel'
                            aria-label={inputLabel}
                            placeholder={inputPlaceHolder}
                            className={styles['phone-number']}
                            onChangeCallback={(value): void => {
                                onChange(inputFieldName, value)
                            }}
                        />
                    )}
                />
            </fieldset>
            <FieldError
                inputId={inputFieldName}
                showError={!!generalError}
                errorMessage={content.errors.phoneNumber}
            />
        </div>
    )
}

export default PhoneInput
