import React, { SetStateAction, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import Spacing from 'components/basics/Spacing/Spacing'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { REGEX, USER_ROLES } from 'utils/constants'
import { extractCognitoFields } from 'utils/cognito-helpers/extract-cognito-fields'

import styles from './NewPasswordForm.module.css'
import allContent from 'content/content'
const content = allContent.cognito.newPasswordForm

type NewPasswordFormProps = {
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
    newPassCognitoUser: CognitoUser | undefined
    setNewPassCognitoUser: React.Dispatch<SetStateAction<CognitoUser | undefined>>
}

type NewPasswordFormDataType = {
    newPassword: string
}

/** This password form is for when a user has signed up, logged in with temp password, and needs to choose a new*/
const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
    setUserData,
    newPassCognitoUser,
    setNewPassCognitoUser,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isApiPasswordError, setIsApiPasswordError] = useState<boolean>(false)
    const [showSessionExpiredError, setShowSessionExpiredError] = useState<boolean>(false)
    const navigate = useNavigate()

    const userContext = datadogLogs.getGlobalContext()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<NewPasswordFormDataType>({
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            newPassword: '',
        },
    })
    function handleResetSessionClick(): void {
        setNewPassCognitoUser(undefined)
        navigate(ROUTES.ROOT)
    }

    async function handleSubmitClick(formFields: NewPasswordFormDataType): Promise<void> {
        setIsSubmitting(true)
        setShowSessionExpiredError(false)
        setIsApiPasswordError(false)
        await Auth.completeNewPassword(newPassCognitoUser, formFields.newPassword)
            .then((user) => {
                const userData = extractCognitoFields(user)
                if (!userData.isActiveTenant) {
                    datadogLogs.logger.warn(
                        `source: completeNewPassword, now inactive tenant, userEmail: ${userData.userEmail}`,
                        {
                            userContext: { userEmail: userData.userEmail },
                        }
                    )
                    navigate(ROUTES.INACTIVE_TENANT, { state: { isInactiveTenant: true } })
                } else if (userData.userRoles.includes(USER_ROLES.API_USER)) {
                    datadogLogs.logger.info(
                        `source: Login API USER, userEmail: ${userData.userEmail}`,
                        {
                            userContext: userData,
                        }
                    )
                    navigate(ROUTES.API_USER_WARNING, {
                        state: {
                            isApiUser: true,
                            email: userData.userEmail,
                        },
                    })
                } else {
                    datadogLogs.logger.info(
                        `source: CompleteNewPassword, success: ${userData.userName}`,
                        { userContext: userData }
                    )
                    setUserData(userData)
                    datadogLogs.setGlobalContext(userData)
                }
            })
            .catch((error) => {
                setIsSubmitting(false)
                setIsApiPasswordError(true)
                if (error.message === 'Invalid session for the user, session is expired.') {
                    setShowSessionExpiredError(true)
                    setIsApiPasswordError(false)
                }
                datadogLogs.logger.error(
                    `source:  NewPasswordForm - Auth.completeNewPassword failed, error: ${JSON.stringify(
                        error
                    )}`,
                    { userContext },
                    error
                )
            })
    }

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <Spacing />
            <form
                className={styles.form}
                onSubmit={handleSubmit(async (formFields: NewPasswordFormDataType) => {
                    await handleSubmitClick(formFields)
                })}
            >
                <Controller
                    control={control}
                    name='newPassword'
                    rules={{
                        required: true,
                        minLength: 10,
                        maxLength: 30,
                        pattern: REGEX.PASSWORD,
                    }}
                    render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                        <div className={styles['input-wrapper']}>
                            <LabelledInput
                                required={true}
                                htmlFor='new-password'
                                label={content.passInput}
                                disabled={isSubmitting}
                                aria-describedby='new-password-error-message'
                                hint={REGEX.PASSWORD_TOOL_TIP}
                            >
                                <PasswordInput
                                    autoComplete='off'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </LabelledInput>
                            <FieldError
                                inputId='new-password'
                                showError={!!errors.newPassword}
                                errorMessage={content.errors.passInput}
                            />
                            <Spacing />
                        </div>
                    )}
                />
                {isApiPasswordError && (
                    <InfoBanner
                        id='new-password-api-error'
                        bannerType='error'
                        text={content.errors.apiError}
                        logType='warn'
                        source='choose-password-page'
                    />
                )}
                {showSessionExpiredError && (
                    <InfoBanner
                        id='session-expired-error'
                        bannerType='error'
                        text={content.errors.sessionExpiredError}
                        buttonText={content.sessionExpiredErrorButton}
                        onButtonClick={handleResetSessionClick}
                        logType='warn'
                        source='choose-password-page'
                    />
                )}
                <div className={styles['button-wrapper']}>
                    <Button
                        type='button'
                        flavour='tertiary'
                        disabled={isSubmitting}
                        text={content.cancelButton}
                        onClick={handleResetSessionClick}
                    />
                    <Button
                        type='submit'
                        flavour='primary'
                        showSpinner={isSubmitting}
                        text={isSubmitting ? content.submitting : content.submitButton}
                    />
                </div>
            </form>
        </div>
    )
}

export default NewPasswordForm
