import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import Icon from 'components/basics/Icon/Icon'
import List from 'components/basics/List/List'
import Text from 'components/basics/Text/Text'
import ResultsListItem from './ResultsListItem/ResultsListItem'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { COMPANY_TIER_NAMES } from 'utils/constants'
import { Cruise } from 'api-data-models/CruisesContentModel'

import styles from './ResultsList.module.css'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage

type ResultsListProps = {
    /** array of result items from getCruises */
    cruisesToDisplay: Cruise[]
}

const ResultsList: React.FC<ResultsListProps> = ({ cruisesToDisplay }) => {
    const cruiseResultsListItems = cruisesToDisplay.map((cruise: Cruise) => {
        const uniqueId = cruise.cruiseId + '-' + cruise.supplierCode + '-' + cruise.itemIndex
        const listItemKey = `${uniqueId}-cruise-item`
        const { companyTier } = extractGlobalContextUserData(
            datadogLogs.getGlobalContext() as GlobalContextUserData
        )
        const isStarterTier = companyTier === COMPANY_TIER_NAMES.CRUISE_STARTER

        return (
            <ResultsListItem
                key={uniqueId}
                listKey={listItemKey}
                cruise={cruise}
                isStarterTier={isStarterTier}
            />
        )
    })
    if (cruisesToDisplay.length === 0)
        return (
            <p>
                <Text>{content.filteredOutAll}</Text>
            </p>
        )
    return (
        <div className={styles.container}>
            <div className={styles['price-info-text']}>
                <Icon iconName='Info' iconColor='tertiary-blue' />
                <Text>{content.priceBasedOnTwoAdultsInfo}</Text>
            </div>
            <List className={styles.list}>{cruiseResultsListItems}</List>
        </div>
    )
}

export default ResultsList
