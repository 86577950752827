import React from 'react'
import classnames from 'classnames'
import descriptionListStyles from './DescriptionList.module.css'

type DescriptionListItemValueProps = React.AllHTMLAttributes<HTMLElement> & {
    /** optional: class-names */
    className?: string
    /** children always of type ReactNode */
    children: React.ReactNode
}

/** DescriptionListItemValue: renders a DD tag */
const DescriptionListItemValue: React.FC<DescriptionListItemValueProps> = ({
    className,
    children,
}: DescriptionListItemValueProps) => {
    const classNames = classnames(descriptionListStyles['item-value'], className)

    return <dd className={classNames}>{children}</dd>
}

export default DescriptionListItemValue
