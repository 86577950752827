import React from 'react'
import classnames from 'classnames'

import styles from './ColoredLine.module.scss'

type ColoredLineProps = React.HTMLAttributes<HTMLHRElement> & {
    className?: string
    padding?: 'single' | 'double' | 'non'
    onDarkBackground?: boolean
}

const ColoredLine: React.FC<ColoredLineProps> = ({
    className,
    padding = 'non',
    onDarkBackground = false,
}) => {
    const coloredLineClassNames = classnames(
        styles['colored-line'],
        { [styles[`padding--${padding}`]]: padding === 'non' },
        { [styles[`padding--${padding}`]]: padding === 'single' },
        { [styles[`padding--${padding}`]]: padding === 'double' },
        { [styles['colored-line--on-dark-background']]: onDarkBackground },
        className
    )

    return <div data-testid='colored-line' className={coloredLineClassNames} />
}

export default ColoredLine
