import React from 'react'

import SailingLayout from 'components/layouts/cruise/SailingLayout/SailingLayout'
import { CruisesMetaData } from 'api-data-models/CruisesContentModel'

/** SailingPage: returns either a component that render the details of a sailing, or false */
const SailingPage: React.FC<{ cruisesMetaData: CruisesMetaData }> = ({ cruisesMetaData }) => {
    return <SailingLayout cruisesMetaData={cruisesMetaData} />
}

export default SailingPage
