import React, { useState } from 'react'
import { Tooltip as ReactTooltip, PlacesType } from 'react-tooltip'
import classnames from 'classnames'
import styles from './Tooltip.module.scss'

type TooltipProps = React.HTMLAttributes<HTMLSpanElement> &
    React.HTMLAttributes<HTMLParagraphElement> & {
        /** tooltip id */
        id: string
        /** optional: class-names */
        className?: string
        /** children always of type ReactNode */
        children: React.ReactNode
        /** content to show in tooltip */
        content: string
        /** tooltip position */
        position?: PlacesType
        /** used to make tooltip light on dark background */
        onDarkBackground?: boolean
        /** indicate if it hides on hovering tooltip */
        clickable?: boolean
        /** passed in when tooltip is to display after clicking its child, rather than on hover*/
        openOnClick?: boolean
        /** used to handle show and hide tooltip when openOnClick prop is used*/
        setIsOpen?(value: boolean): void
        /** time in ms which the tooltip will disappear after, default is 2000ms*/
        timeOutMs?: number
    }

const Tooltip: React.FC<TooltipProps> = ({
    id,
    className,
    children,
    content,
    onDarkBackground = true, // feels like should be false, but need to update all uses first
    position = 'top',
    clickable = true,
    openOnClick = false,
    timeOutMs = 2500,
    ...rest
}: TooltipProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const tooltipClassNames = classnames(styles['custom-tooltip'], className)
    const targetClassNames = classnames(styles['custom-tooltip-target'])

    const handleChildClick = (): void => {
        setIsOpen(true)
        setTimeout(() => {
            setIsOpen(false)
        }, timeOutMs)
    }

    const toolTipId = id + '-tooltip'
    return (
        <span {...rest}>
            <ReactTooltip
                id={toolTipId}
                clickable={clickable}
                className={tooltipClassNames}
                variant={onDarkBackground ? 'light' : 'dark'}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                openOnClick={openOnClick}
            />
            <span
                id={`target-${id}`}
                data-tooltip-id={toolTipId}
                data-tooltip-content={content}
                data-tooltip-place={position}
                className={targetClassNames}
                onClick={openOnClick ? handleChildClick : undefined}
                onKeyDown={openOnClick ? handleChildClick : undefined}
            >
                {children}
            </span>
        </span>
    )
}

export default Tooltip
