import React, { useEffect, useState, SetStateAction } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import InlineSpinner from 'components/basics/Spinners/InlineSpinner'
import Menu from 'components/blocks/Menu/Menu'
import Text from 'components/basics/Text/Text'
import UpdateGroupModal from '../UpdateGroupModal/UpdateGroupModal'
import {
    Group,
    GroupsContentFunction,
    SalesChannel,
} from 'api-data-models/admin/GroupsContentModel'
import { UPDATE_USER_GROUP } from 'graphql-queries/admin/admin-queries'

import styles from './GroupsList.module.scss'
import allContent from 'content/content'

const content = allContent.admin.groupManagementPage.list

type GroupsListProps = {
    groupsListData: Group[]
    apiClient: ApolloClient<NormalizedCacheObject>
    salesChannels: SalesChannel[]
    fetchingSalesChannels: boolean
    setShouldRefreshGroupsListData: React.Dispatch<SetStateAction<boolean>>
}

export type HandleUpdateGroup = {
    userGroupId: string
    salesChannel: { salesChannelId: string; salesChannelTitle: string }
    phoneNumber?: string
    homeCityCode?: string
}

const GroupsList: React.FC<GroupsListProps> = ({
    groupsListData,
    apiClient,
    salesChannels,
    fetchingSalesChannels,
    setShouldRefreshGroupsListData,
}) => {
    const [isGroupUpdateModalOpen, setIsGroupUpdateModalOpen] = useState(false)
    const [isUpdatingGroup, setIsUpdatingGroup] = useState(false)
    const [clickedEditButtonId, setClickedEditButtonId] = useState<string>()
    const [groupDataForUpdate, setGroupDataForUpdate] = useState<Group | null>(null)
    const [renderGroup, setRenderGroup] = useState<Group[]>(groupsListData)
    const [error, setError] = useState<boolean>()

    useEffect(() => {
        setRenderGroup(groupsListData)
    }, [groupsListData])

    const handleEditButtonClick = (group: Group): void => {
        setGroupDataForUpdate(group)
        setClickedEditButtonId(group.groupId)
        setIsGroupUpdateModalOpen(true)
    }

    const handleUpdateGroup = ({
        userGroupId,
        salesChannel,
        phoneNumber,
        homeCityCode,
    }: HandleUpdateGroup): void => {
        setIsUpdatingGroup(true)
        apiClient
            .mutate({
                mutation: UPDATE_USER_GROUP,
                variables: {
                    salesChannelId: salesChannel.salesChannelId,
                    userGroupId: userGroupId,
                    phoneNumber: phoneNumber ? '+' + phoneNumber : undefined,
                    ...(Boolean(homeCityCode) && { homeCityCode }),
                },
            })
            .then((response) => {
                if (response.data) {
                    setRenderGroup((prev) =>
                        prev.map((group) => {
                            if (group.groupId === userGroupId) {
                                const updatedGroup: Group[] = GroupsContentFunction([
                                    response.data.updateUserGroup,
                                ])
                                return updatedGroup[0]
                            }
                            return group
                        })
                    )
                    setIsUpdatingGroup(false)
                    datadogLogs.logger.info(
                        `Sales channel: ${salesChannel.salesChannelId} assigned to group: ${userGroupId}`
                    )
                    setIsGroupUpdateModalOpen(false)
                    setShouldRefreshGroupsListData(true)
                }
            })
            .catch((error) => {
                setError(true)
                setIsUpdatingGroup(false)
                datadogLogs.logger.error(
                    `Error assigning sales channel: ${salesChannel.salesChannelId} to group: ${userGroupId}`,
                    {},
                    error
                )
            })
    }

    return (
        <div>
            <div className={styles['list-header']}>
                <Text weight='bold' size='L'>
                    {content.groupColumn}
                </Text>
                <Text weight='bold' size='L'>
                    {content.salesChannelColumn}
                </Text>
            </div>
            {renderGroup.map((group) => {
                const shouldRenderSpinner = isUpdatingGroup && clickedEditButtonId === group.groupId
                return (
                    <div className={styles['list-item']} key={group.groupId}>
                        <div className={styles['list-item--left']}>{group.groupTitle}</div>
                        <div className={styles['list-item--right']}>
                            {group.salesChannels.map((salesChannel) => (
                                <div key={salesChannel.salesChannelId}>
                                    <div className={styles['list-item--right--sc-title']}>
                                        {salesChannel.salesChannelTitle}
                                    </div>
                                </div>
                            ))}
                            <div className={styles['list-item--right--actions-button']}>
                                {!fetchingSalesChannels && !shouldRenderSpinner && (
                                    <Menu
                                        id={group.groupId}
                                        options={[
                                            {
                                                label: content.edit,
                                                disabled: salesChannels.length === 0,
                                                onClick: () => handleEditButtonClick(group),
                                            },
                                        ]}
                                        buttonText={content.actionMenu}
                                    />
                                )}
                                {shouldRenderSpinner && (
                                    <InlineSpinner text={content.actionMenuProcessing} />
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
            {!renderGroup.length && <p>{content.noGroupsCreated}</p>}
            {!fetchingSalesChannels && salesChannels.length > 0 && (
                <UpdateGroupModal
                    salesChannels={salesChannels}
                    isOpen={isGroupUpdateModalOpen}
                    handleUpdateGroup={handleUpdateGroup}
                    groupDataForUpdate={groupDataForUpdate}
                    onClose={(): void => {
                        const returnFocusTo = document.getElementById(clickedEditButtonId ?? '')
                        setIsGroupUpdateModalOpen(false)
                        setGroupDataForUpdate(null)
                        returnFocusTo?.focus()
                    }}
                    isSubmitting={isUpdatingGroup}
                    isErrored={!!error}
                />
            )}
        </div>
    )
}

export default GroupsList
