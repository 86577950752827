import React from 'react'
import classnames from 'classnames'
import headerStyles from './Header.module.css'

interface HeaderProps {
    className?: string
    children: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({ className, children }) => {
    const classNames = classnames(`${headerStyles.header}`, className)

    return (
        <header className={classNames}>
            <div className={headerStyles.content}>{children}</div>
        </header>
    )
}

export default Header
