import { CruiseProduct } from 'api-data-models/order/OrderContentModel'
import { getFormattedDate } from 'utils/date-helpers'
import { createStringFromList } from 'utils/string-helpers'

/** Only to be used on single CruiseOrderItem Orders. Flights/Hotels will be suppoerted when live, as will multiple products. */
function getOrderQuote({
    orderTemplate,
    cruiseProduct,
    supplierOrderItemReference,
    numberOfPassengers,
}: {
    orderTemplate: string
    cruiseProduct: CruiseProduct
    supplierOrderItemReference: string | null
    numberOfPassengers: number
}): string {
    const cruisePriceItem = cruiseProduct.pricing

    let populatedTemplate = orderTemplate

    // Replace Content Headings
    populatedTemplate = populatedTemplate.replaceAll(
        '{{ content_traveltek_ref }}',
        ' Supplier Reference '
    )
    populatedTemplate = populatedTemplate.replaceAll('{{ content_nights }}', ' Nights ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_passengers }}', ' Passenger ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_itinerary }}', ' Itinerary ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_ship }}', ' Cruise Ship ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_deck }}', ' Deck ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_cruise_line }}', ' Cruise Line ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_rate_code }}', ' Rate Code ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_cabin_number }}', ' Cabin No ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_voyage_code }}', ' Voyage Code ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_cabin_type }}', ' Cabin Type ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_summary }}', ' Summary ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_price_fare }}', ' Cruise Fare ')
    populatedTemplate = populatedTemplate.replaceAll('{{ content_price_total }}', ' Total ')

    // Replace single supplied values
    populatedTemplate = populatedTemplate.replaceAll(
        '{{ supplierOrderItemReference }}',
        ` ${supplierOrderItemReference ?? 'Not Available'} `
    )
    populatedTemplate = populatedTemplate.replaceAll(
        '{{ numberOfPassengers }}',
        ` ${numberOfPassengers} `
    )

    type CruiseFields =
        | 'productType'
        | 'cabinGradeName'
        | 'cabinGradeCode'
        | 'cabinGradeDescription'
        | 'cabinNumber'
        | 'cruiseId'
        | 'productName'
        | 'cruiseName'
        | 'deckName'
        | 'deckLevel'
        | 'disembarkDate'
        | 'duration'
        | 'embarkDate'
        | 'lineName'
        | 'cabinRateName'
        | 'shipName'
        | 'rateCode'
        | 'rateCodeName'
        | 'rateCodeDescription'
        | 'itinerary'
        | 'pricing'
        | 'paymentChoices'
        | 'paymentScheduleItems'
        | 'breakdownPerPassenger'
        | 'supplierCode'

    type PricingKeys =
        | 'currencyCode'
        | 'currencySymbol'
        | 'totalGrossPrice'
        | 'totalTfpePrice'
        | 'commission'

    const cruiseKeys = Object.keys(cruiseProduct) as CruiseFields[]
    const cruisePricingKeys = Object.keys(cruisePriceItem) as PricingKeys[]

    cruiseKeys.forEach((key: CruiseFields) => {
        const value = cruiseProduct[key]
        if (key === 'itinerary') {
            const itineraryStr = cruiseProduct.itinerary.portListContentFull.join(', ')
            populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${itineraryStr} `)
        } else if (key === 'embarkDate') {
            const embarkDate = getFormattedDate(cruiseProduct.embarkDate)
            populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${embarkDate} `)
        } else if (key === 'disembarkDate') {
            const disembarkDate = getFormattedDate(cruiseProduct.disembarkDate)
            populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${disembarkDate} `)
        } else if (key === 'cabinGradeName') {
            const cabinType = createStringFromList([
                cruiseProduct.cabinGradeName,
                cruiseProduct.cabinGradeDescription,
                cruiseProduct.cabinGradeCode,
            ])
            populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${cabinType} `)
        } else if (key === 'deckName') {
            const deckInfo = createStringFromList([cruiseProduct.deckLevel, cruiseProduct.deckName])
            populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${deckInfo} `)
        } else {
            populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${value} `)
        }
    })

    cruisePricingKeys.forEach((key: PricingKeys) => {
        const value = cruisePriceItem[key]
        populatedTemplate = populatedTemplate.replaceAll(`{{ ${key} }}`, ` ${value}`)
    })

    return populatedTemplate
}

export default getOrderQuote
