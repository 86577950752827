import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import classnames from 'classnames'

import linkStyles from './Link.module.scss'

type LinkProps = {
    className?: string
    /** Alternate style for use on dark backgrounds */
    onDarkBackground?: boolean
    /** Style toggle to hide the default underline */
    noUnderLine?: boolean
    /** children always of type ReactNode */
    children: React.ReactNode
    /** External to app link. NOTE: a traveltek url needs only rel='noopener', all other external urls should have rel='noopener noreferrer' */
    href?: string
    /** Internal to app url, uses RouterLink */
    to?: string
    /** callback for additional logic */
    onClick?: () => any
    /** to make bold style text  on link */
    bold?: boolean
}

/** Link: returns a customisable anchor tag */
const Link: React.FC<LinkProps & React.AllHTMLAttributes<HTMLHtmlElement>> = ({
    children,
    onDarkBackground = false,
    noUnderLine = false,
    onClick,
    href,
    to,
    className,
    bold = false,
    ...rest
}: LinkProps) => {
    const linkClassNames = classnames(
        linkStyles.link,
        { [linkStyles[`link--alt`]]: onDarkBackground },
        { [linkStyles[`link--no-underline`]]: noUnderLine },
        { [linkStyles[`link--bold`]]: bold },
        className
    )
    if (to) {
        return (
            <RouterLink className={linkClassNames} to={to} onClick={onClick}>
                {children}
            </RouterLink>
        )
    }
    return (
        <a className={linkClassNames} href={href} onClick={onClick} {...rest}>
            {children}
        </a>
    )
}

export default Link
