import React from 'react'
import classnames from 'classnames'

import DescriptionList from 'components/basics/DescriptionList/DescriptionList'
import DescriptionListItemKey from 'components/basics/DescriptionList/DescriptionListItemKey'
import DescriptionListItemValue from 'components/basics/DescriptionList/DescriptionListItemValue'
import Icon, { IconNames } from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'

import datalistDisplayStyles from './DescriptionListDisplay.module.css'

type DatalistDisplayProps = {
    /** array of arrays of keys/values displayed in DescriptionListItemKey and values displayed in DatalistItemValue
     * array chosen rather than object to allow duplicate values for keys*/
    keyValueContent: ([string, string] | [string, string, IconNames])[]
    /** set key column width manually */
    overrideMaxKeyLength?: number
    isAlternatingColor?: boolean
    className?: string
    itemClassName?: string
}

/** DescriptionListDisplay: creates a datalist from pairs of keys as static content and values as dynamic content */
const DescriptionListDisplay: React.FC<DatalistDisplayProps> = ({
    keyValueContent,
    overrideMaxKeyLength = undefined,
    isAlternatingColor = false,
    className,
    itemClassName,
}: DatalistDisplayProps) => {
    /** get the length of the word with the biggest number of characters and use it to fill width of all words with fewer characters so that they all align */
    /** Keys exist in each array at index 0 */
    const maxLen = overrideMaxKeyLength
        ? overrideMaxKeyLength
        : Math.max(...keyValueContent.map((keyContent) => keyContent[0].length))

    const classNames = classnames(
        datalistDisplayStyles['datalist-container'],
        { [datalistDisplayStyles['datalist-container--isAlternatingColor']]: isAlternatingColor },
        itemClassName
    )
    return (
        <DescriptionList className={className}>
            {keyValueContent.map((keyContent) => {
                const [key, value, iconName] = keyContent
                const width = maxLen * 0.67 + 'rem'
                return (
                    <div key={`${key}`} className={classNames}>
                        <DescriptionListItemKey
                            className={datalistDisplayStyles['datalist-key-spacing']}
                            style={{
                                minWidth: width,
                            }}
                        >
                            {iconName && (
                                <Icon
                                    className={datalistDisplayStyles['datalist-key-icon']}
                                    iconName={iconName}
                                    iconColor='orange'
                                    iconSize='XS'
                                />
                            )}
                            <Text weight='bold'>{key}</Text>
                        </DescriptionListItemKey>
                        <DescriptionListItemValue>
                            <Text>{value}</Text>
                        </DescriptionListItemValue>
                    </div>
                )
            })}
        </DescriptionList>
    )
}

export default DescriptionListDisplay
