export type PassengerFromApi = {
    past_passenger_reference?: string
    age: number
    residency?: string
    military?: boolean
    traveller_number: string
}
export const PassengerRestBodyModelBuild = (passenger: Record<string, any>): PassengerFromApi => {
    return {
        age: passenger.age,
        past_passenger_reference: passenger.pastPassenger,
        traveller_number: passenger.travellerNumber.toString(),
        ...(passenger.residency && {
            residency: passenger.residency.iata,
        }),
        ...(passenger.military && {
            military: true,
        }),
    }
}
