export const isCorrectTimeFormat = (value: string | number | null): boolean => {
    let input
    if (value) {
        input = value.toString()
    } else {
        input = ''
    }
    const regex1 = /^[0-9]{2}(:)[0-9]{2}/
    const regex2 = /^[0-9]{2}(:)[0-9]{2}(:)[0-9]{2}/

    return !!input.match(regex1) || !!input.match(regex2)
}

export const formatSixOrFourDigitTimeStringToTwoDigitAmPmString = (inputTime: string): string => {
    if (typeof inputTime !== 'string' || !isCorrectTimeFormat(inputTime)) {
        return inputTime
    }

    const timeParts = inputTime.split(':').map((part) => parseInt(part, 10))

    const hours = timeParts[0]
    const minutes = timeParts[1]
    const formattedHours = hours % 12 || 12 // Convert 24-hour to 12-hour format

    const period = hours < 12 ? 'am' : 'pm'
    const formattedMinutes = minutes.toString().padStart(2, '0') // Pad minutes with a 0

    return formattedHours.toString() + ':' + formattedMinutes + period
}
