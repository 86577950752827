import React, { useEffect, useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import PriceBreakdownView from 'components/sections/cruise/PriceBreakdownView/PriceBreakdownView'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import {
    Breakdown,
    getBreakDownItemsByPassenger,
} from 'api-data-models/cruise-detail/BreakdownModel'
import { insertDecimal2CharsFromEnd } from 'utils/string-helpers'
import { CURRENCY_CODES, VITALLY_EVENTS } from 'utils/constants'
import { useRest, UseRestOptions } from 'components/hooks/useRest'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

import styles from './PriceBreakdown.module.css'
import allContent from 'content/content'

const content = allContent.cruise.sailingPage.cabinGradesView.breakdown

interface PriceBreakdownProps {
    cabinGradeCode: string
    rateCode: string
    cabinGradeDescription: string
    rateCodeDescription: string
    currency: string
    cruiseId: string
    supplierCode: string
    setIsLoading?: (value: boolean) => void
    priceProps?: {
        military?: boolean
        residency?: boolean
        wifi?: boolean
        refundPolicy?: RefundPolicyType
        onBoardCredits?: number
    }
}

export const PriceBreakdown: React.FC<PriceBreakdownProps> = (props): JSX.Element => {
    const {
        cabinGradeCode,
        rateCode,
        cabinGradeDescription,
        rateCodeDescription,
        currency,
        cruiseId,
        supplierCode,
        setIsLoading,
        priceProps,
    } = props
    const [breakdownPerPassenger, setBreakdownPerPassenger] = useState<null | Record<
        string,
        Breakdown
    >>(null)
    const userContext = datadogLogs.getGlobalContext()
    const { passengerConfigurationDataAsQueryVar, passengerConfigurationDataRestRequestBody } =
        usePassengersInfo()
    const queryVariables = {
        cabinGradeCode,
        rateCode,
        cruiseId,
        supplierCode,
        passengers: {
            passengers: passengerConfigurationDataAsQueryVar,
        },
    }

    const [pricePropsToCheck, setPricePropsToCheck] = useState(priceProps)

    useEffect(() => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.LIVE_PRICING_SAILING_BREAKDOWN,
            properties: {
                cruiseId: queryVariables.cruiseId,
                supplierCode: queryVariables.supplierCode,
                rateCode: queryVariables.rateCode,
                cabinGradeCode: queryVariables.cabinGradeCode,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // only run on mount

    const fetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates/${rateCode}/cabin-grades/${cabinGradeCode}/price-information?supplier_code=${supplierCode}`,
        source: 'SailingPage - PRICE BREAKDOWN',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }

    const { result, loading, error } = useRest(fetchOptions)

    useEffect(() => {
        if (setIsLoading) setIsLoading(loading)
    }, [setIsLoading, loading])

    useEffect(() => {
        if (result?.breakdown_items) {
            const breakdownItems = result.breakdown_items
            setBreakdownPerPassenger(getBreakDownItemsByPassenger(breakdownItems))
            setPricePropsToCheck({
                ...pricePropsToCheck,
                onBoardCredits: result?.total_obc_price ?? 0,
            })

            datadogLogs.logger.info(
                `source: ${VITALLY_EVENTS.LIVE_PRICING_SAILING_BREAKDOWN}, cruiseId: ${queryVariables.cruiseId}, supplierCode: ${queryVariables.supplierCode}, rateCode: ${queryVariables.rateCode}, cabinGradeCode: ${queryVariables.cabinGradeCode}`,
                userContext
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        queryVariables.cabinGradeCode,
        queryVariables.cruiseId,
        queryVariables.rateCode,
        queryVariables.supplierCode,
        result,
    ]) // do not include userContext

    if (error) {
        return (
            <>
                <ErrorList errorsList={error} source='cabin-price-breakdown' />
                <p>
                    Using rateCode: {rateCode}, cabinGradeCode: {cabinGradeCode}, cruiseId:
                    {cruiseId}, supplierCode: {supplierCode}
                </p>
            </>
        )
    }
    if (loading) {
        return (
            <div className={styles.fetching}>
                <LargeSpinner text={content.fetching} />
            </div>
        )
    }
    if (breakdownPerPassenger) {
        return (
            <PriceBreakdownView
                commission={insertDecimal2CharsFromEnd(result?.commission)}
                breakdownPerPassenger={breakdownPerPassenger}
                currency={CURRENCY_CODES[currency]}
                totalGrossPrice={insertDecimal2CharsFromEnd(
                    breakdownPerPassenger?.totals?.GROSS?.price
                )}
                cabinGradeCode={cabinGradeCode}
                rateCode={rateCode}
                cabinGradeDescription={cabinGradeDescription}
                rateCodeDescription={rateCodeDescription}
                priceProps={pricePropsToCheck}
            />
        )
    }

    return <div /> // needed for the ansyc state update of priceData which is called after loading becomes false
}
