import { gql } from '@apollo/client'

export const GET_USA_STATE_BY_IATA = gql`
    query GetUsaStateByIata($iata: String!) {
        getUsaStateByIata(iata: $iata) {
            name
        }
    }
`

export const GET_IATA_BY_SUBSTRING = gql`
    query GetIataBySubstring($substring: String!, $numberOfLocations: Int!) {
        getIataByPlacenameSubString(substring: $substring, numberOfLocations: $numberOfLocations) {
            iata
            placeType
            name
        }
    }
`

export const GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE = gql`
    query GetCruiseByRateAndGrade(
        $cabinGradeCode: String!
        $rateCode: String!
        $supplierCode: String!
        $cruiseId: String!
        $passengers: Passengers!
    ) {
        cruise(
            id: $cruiseId
            rateCode: $rateCode
            supplierCode: $supplierCode
            cabinGradeCode: $cabinGradeCode
            passengers: $passengers
        ) {
            diningOptions {
                dining {
                    availableInd
                    code
                    description
                    status {
                        code
                        description
                    }
                }
            }
            duration
            disembarkDate
            embarkDate
            id
            priceItems {
                breakdownItems {
                    code
                    name
                    passengerNumber
                    price
                    fareType
                    itemType
                }
                commission
                cabinGrade {
                    available
                    balcony
                    cabinType
                    cabins {
                        accessibility
                        amenity {
                            name
                            description
                        }
                        beddingConfiguration {
                            code
                            description
                        }
                        deck {
                            code
                            image
                            level
                            name
                        }
                        description
                        location {
                            frontMiddleBack
                            insideOceanview
                            sideOfShip
                        }
                        maxGuestCount
                        minGuestCount
                        number
                        obstructedView
                        roomSize
                    }
                    code
                    colorCode
                    description
                    guaranteed
                    name
                }
                currency
                rateCode
                totalFarePrice
                totalGrossPrice
                totalNcfPrice
                totalObcPrice
                totalTfpePrice
            }
            rateCodes {
                code
                description
                name
                rateType
                refundPolicy
            }
            ship {
                line {
                    name
                    code
                }
                name
            }
            product {
                name
            }
        }
    }
`
