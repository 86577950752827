import React from 'react'
import { Slider as MUISlider } from '@mui/material'

import Text from '../Text/Text'
import styles from './Slider.module.scss'

type SliderProps = {
    minValue: number
    maxValue: number
    onChange(_event: any, value: number | number[]): void
    value: number | number[]
    unit?: string
    ariaLabel: string
}

const Slider: React.FC<SliderProps> = ({
    minValue,
    maxValue,
    onChange,
    value,
    unit = '',
    ariaLabel,
}: SliderProps) => {
    return (
        <span>
            <MUISlider
                getAriaLabel={(): string => ariaLabel}
                min={minValue}
                max={maxValue}
                onChange={onChange}
                valueLabelDisplay='off'
                value={value}
                disableSwap
                classes={{
                    root: styles['MuiSlider-root'],
                    rail: styles['MuiSlider-rail'],
                    thumb: styles['MuiSlider-thumb'],
                    // active: styles['MuiSlider-active'],
                    focusVisible: styles['Mui-focusVisible'],
                }}
            />
            {Array.isArray(value) && (
                <div className={styles['slider__range-values']}>
                    <Text weight='bold' size='S' color='primary-blue'>
                        {unit + value[0]}
                    </Text>
                    <Text weight='bold' size='S' color='primary-blue'>
                        {unit + value[1]}
                    </Text>
                </div>
            )}
            {!Array.isArray(value) && (
                <div className={styles['slider__range-values']}>
                    <Text weight='bold' size='S' color='primary-blue'>
                        {unit + value}
                    </Text>
                </div>
            )}
        </span>
    )
}

export default Slider
