import React, { ReactNode, useState } from 'react'
import classnames from 'classnames'

import Heading, { HeadingSizes } from 'components/basics/Heading/Heading'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import styles from './Accordion.module.scss'

type AccordionProps = {
    /** Title/header of the accordion */
    title: string | ReactNode
    /** Font size to display the accordion title/header */
    titleSize?: HeadingSizes
    /** Title (heading tag) level, between <h1>-<h6> */
    titleLevel?: '1' | '2' | '3' | '4' | '5' | '6'
    /** Set to true if Accordion should start in Active state */
    startOpen?: boolean
    /** children always of type ReactNode */
    children?: React.ReactNode
    /** String to be used to form new unique IDs from whilst keeping a sense of common identity */
    baseId: string
    /** Gives dark bottom border with margin on header */
    underscore?: boolean
    /** Gives heading dark background light font and icon colour when true */
    darkHeading?: boolean
    /** Gives content dark background and larger bottom radius */
    darkContent?: boolean
    /** Applies padding to heading */
    isPadded?: boolean
    /** Text for toggle near Chevron icon */
    toggleText?: string
    className?: string
}

/** Accordion: returns an accordion that can be clicked by a user to expand the contents passed as children */
const Accordion: React.FC<AccordionProps> = ({
    title,
    titleSize = '2',
    titleLevel = '2',
    startOpen = false,
    children,
    baseId,
    underscore,
    darkHeading = false,
    darkContent = false,
    isPadded = false,
    toggleText = '',
    className,
}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(startOpen)
    const toggleAccordion = (): void => {
        setIsOpen(!isOpen)
    }

    const iconClasses = classnames(styles['header-icon'], {
        [styles['header--icon-light']]: darkHeading,
    })
    const containerClasses = classnames(styles.container, {
        [`${className}`]: !!className,
    })

    const contentClasses = classnames(
        styles.content,
        { [styles['content-hide']]: !isOpen },
        { [styles['content--bottom-border']]: darkHeading && isOpen && !darkContent },
        { [styles['content--dark']]: darkContent }
    )

    const headerClasses = classnames(
        styles.header,
        { [styles['header--underscore']]: underscore },
        { [styles['header--dark-background']]: darkHeading },
        { [styles['header--padded']]: isPadded },
        { [styles['header--closed']]: !isOpen && darkHeading }
    )

    const headerButtonClasses = classnames(styles.header)

    const contentId = `${baseId}-content`
    const accordionId = `${baseId}-accordion`

    return (
        <div className={containerClasses}>
            <div className={headerClasses}>
                <button
                    type='button'
                    id={accordionId}
                    aria-expanded={isOpen}
                    aria-controls={contentId}
                    className={headerButtonClasses}
                    onClick={toggleAccordion}
                >
                    {typeof title === 'string' ? (
                        <Heading
                            heading={titleLevel}
                            onDarkBackground={darkHeading}
                            size={titleSize}
                        >
                            {title}
                        </Heading>
                    ) : (
                        title
                    )}
                    <div className={styles['header--toggle']}>
                        {toggleText && (
                            <Text color='white' size='S'>
                                {toggleText}
                            </Text>
                        )}
                        <Icon
                            iconName={isOpen ? 'ChevronUp' : 'ChevronDown'}
                            iconSize='S'
                            iconColor='primary-midnight'
                            className={iconClasses}
                        />
                    </div>
                </button>
            </div>
            <section
                id={contentId}
                aria-labelledby={accordionId}
                aria-hidden={!isOpen}
                className={contentClasses}
            >
                {/* this div is needed so padded content is hidden when closed  */}
                <div>{children}</div>
            </section>
        </div>
    )
}

export default Accordion
