import React from 'react'
import { useNavigate } from 'react-router-dom'
import Heading from 'components/basics/Heading/Heading'
import Button from 'components/basics/Button/Button'
import Text from 'components/basics/Text/Text'

import allContent from 'content/content'
import { ROUTES } from '../../../sections/app/AppRoutes'
import styles from './TimeoutLayout.module.css'
import { datadogLogs } from '@datadog/browser-logs'
import { extractGlobalContextUserData } from '../../../../utils/user-data-helpers/extract-user-data-fields'
const content = allContent.app.timeoutPage

const TimeoutLayout: React.FC<{ cleanUpAppOnLogout(): void }> = ({ cleanUpAppOnLogout }) => {
    const navigate = useNavigate()
    cleanUpAppOnLogout()
    const { userEmail, userName } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    datadogLogs.logger.warn(
        'source: timeout-page, message: Your session has expired. Please log in again.',
        {
            userContext: {
                userEmail: userEmail,
                userName: userName,
                expiry: Date.now(),
            },
        }
    )

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.goToLogin}</Heading>
            <Text>{content.message}</Text>
            <Button text={content.loginButtonText} onClick={(): void => navigate(ROUTES.LOGIN)} />
        </div>
    )
}
export default TimeoutLayout
