import React from 'react'

import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Text from 'components/basics/Text/Text'
import { CruiseProduct, OptionalExtra } from 'api-data-models/order/OrderContentModel'
import { CruiseSummaryPrices } from 'components/layouts/order/OrderLayout/OrderLayout'

import styles from './OrderPricingSection.module.scss'
import allContent from '../../../../content/content'

const content = allContent.order.orderPage.cruiseOrderItem.pricingSummary

type CruisePricingSectionProps = {
    cruise: CruiseProduct
    cruiseSummaryPrices: CruiseSummaryPrices | null
    lastPriceUpdateTimeStamp: string
}

const CruisePricingSection = ({
    cruise,
    cruiseSummaryPrices,
    lastPriceUpdateTimeStamp,
}: CruisePricingSectionProps): JSX.Element => {
    const currencySymbol = cruise?.pricing?.currencySymbol ?? ''
    const productName = cruise ? cruise.productName : ''
    return (
        <div className={styles['pricing-summary-item__price-details']}>
            <div className={styles['pricing-summary-item__price-item']}>
                <Text weight='bold'>{productName}</Text>
                <Text font='mono'>
                    {currencySymbol}
                    {cruiseSummaryPrices?.cruisePrice}
                </Text>
            </div>
            <Text weight='bold'>{content.optionalExtras}</Text>
            {cruiseSummaryPrices?.optionalExtrasSelected?.length ? (
                cruiseSummaryPrices?.optionalExtrasSelected.map((extra: OptionalExtra) => (
                    <div className={styles['pricing-summary-item__price-item']} key={extra.code}>
                        <Text>{extra.renderName}</Text>
                        <Text font='mono'>{`${currencySymbol}${extra.formattedPrice}`}</Text>
                    </div>
                ))
            ) : (
                <Text>{content.none}</Text>
            )}
            <ColoredLine padding='non' />
            <div className={styles['pricing-summary-item__price-item']}>
                <Text weight='bold'>{content.total}</Text>
                <Text weight='bold' font='mono'>
                    {currencySymbol}
                    {cruiseSummaryPrices?.totalPrice}
                </Text>
            </div>
            {lastPriceUpdateTimeStamp && (
                <div>
                    {content.priceAccurateAsOf} {lastPriceUpdateTimeStamp}
                </div>
            )}
        </div>
    )
}

export default CruisePricingSection
