import React from 'react'

import ListItem from '../List/ListItem'
import Link from '../Link/Link'
import List from '../List/List'
import Text from '../Text/Text'
import Icon from '../Icon/Icon'
import styles from './Breadcrumb.module.css'

type Crumb = {
    url?: string
    text: string
}

type BreadCrumbProps = {
    urlList: Crumb[]
}

/** Breadcrumb provides a list of internal app links using react-router-dom to navigate between app pages (do not use external links in this) */
const Breadcrumb: React.FC<BreadCrumbProps> = ({ urlList }) => {
    const navTrail = urlList.map((crumb: Crumb, i) => {
        return (
            <ListItem
                key={`${crumb.text}`}
                listKey={`${crumb.text}`}
                className={styles['list-item']}
            >
                {i > 0 && (
                    <Icon
                        iconName='ChevronRight'
                        className={styles['arrow-divider']}
                        iconColor='primary-midnight-40'
                    />
                )}
                {crumb.url ? (
                    <Text weight='bold' color='primary-blue-40'>
                        <Link to={crumb.url} className={styles.link} noUnderLine={true}>
                            {crumb.text}
                        </Link>
                    </Text>
                ) : (
                    <Text weight='bold' color='primary-blue'>
                        {crumb.text}
                    </Text>
                )}
            </ListItem>
        )
    })

    return <List className={styles.list}>{navTrail}</List>
}

export default Breadcrumb
