import React, { forwardRef } from 'react'
import classnames from 'classnames'

import Icon, { IconNames } from '../../Icon/Icon'
import styles from './TextInput.module.css'
import Close from 'components/basics/Close/Close'

export type TextInputProps = React.ComponentPropsWithRef<'input'> & {
    /** use same id for associating a label via its htmlFor prop (NOTE, this is passed automatically by our LabelledInput) */
    id?: string
    /** is a callback that is passed the event target value from the ChangeEvent */
    onChangeCallback?(value: string): void
    iconName?: IconNames
    /** toggle for error styling */
    isErrored?: boolean
    /** regular expression matching, only allows valid chars to added to the value */
    regExp?: RegExp
    /** toggle for disabled styling and function */
    disabled?: boolean
    clearable?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
    const {
        iconName = undefined,
        id,
        className,
        onChangeCallback,
        onChange,
        isErrored = false,
        regExp,
        disabled,
        clearable,
        ...rest
    } = props
    const textInputClassNames = classnames(
        `${styles['text-input']}`,
        { [styles['text-input--errored']]: isErrored },
        { [styles['text-input--disabled']]: disabled },
        { [styles['text-input--icon-left-padding']]: iconName },
        { [styles['text-input--icon-right-padding']]: clearable }
    )

    const containerClassNames = classnames(`${styles['container']}`, className)

    return (
        <div className={containerClassNames}>
            {iconName && <Icon iconName={iconName} iconSize='S' className={styles['input-icon']} />}
            <input
                id={id}
                className={textInputClassNames}
                type='text'
                ref={ref}
                onChange={(e): void => {
                    if (regExp && !regExp.test(e.target.value)) return
                    if (onChangeCallback) onChangeCallback(e.target.value)
                    else if (onChange) onChange(e)
                }}
                disabled={disabled}
                {...rest}
            />
            {clearable && rest.value && (
                <div className={styles['clear-icon']}>
                    <Close
                        onClick={(): void => {
                            if (onChangeCallback) onChangeCallback('')
                            else if (onChange) onChange({ target: { value: '' } } as any)
                        }}
                    />
                </div>
            )}
        </div>
    )
})
TextInput.displayName = 'TextInput'

export default TextInput
