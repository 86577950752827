import React, { Component } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import CrashErrorPage from 'components/pages/app/CrashErrorPage'
import { extractGlobalContextUserData } from '../../../utils/user-data-helpers/extract-user-data-fields'

interface ErrorBoundaryProps {
    cleanUpAppOnRestart(): void
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): any {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: Record<any, any>): void {
        const { allData, userEmail, tenantId, companyName } = extractGlobalContextUserData(
            datadogLogs.getGlobalContext() as GlobalContextUserData
        )

        datadogLogs.logger.error(
            `source: Error-Boundary, userEmail: ${userEmail}, tenantId: ${tenantId}, companyName: ${companyName}`,
            { userContext: allData, errorInfo: errorInfo },
            error
        )
    }
    render(): any {
        if (this.state.hasError) {
            return <CrashErrorPage cleanUpAppOnLogout={this.props.cleanUpAppOnRestart} />
        }
        return this.props.children
    }
}
export default ErrorBoundary
