import React from 'react'

import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'
import Icon from 'components/basics/Icon/Icon'
import Link from 'components/basics/Link/Link'
import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './FreeTierGettingStarted.module.scss'
import allContent from 'content/content'

const content = allContent.admin.adminLanding.gettingStartedSection.free

const FreeTierGettingStarted = (): JSX.Element => {
    return (
        <div className={styles.container}>
            <Heading heading='2'>{content.title}</Heading>
            <p className={styles.listItem}>
                <Icon iconName='User' />
                <Text>{content.description1a}</Text>
                <Link to={ROUTES.USER_MANAGEMENT}>{content.description1aLinkText}</Link>
            </p>
            <p className={styles.listItem}>
                <Icon iconName='Ship' />
                <Text>{content.description2a}</Text>
                <Link to={ROUTES.CRUISE_SEARCH}>{content.description2aLinkText}</Link>
            </p>
            <p>
                <Text>{content.description3}</Text>
            </p>
        </div>
    )
}

export default FreeTierGettingStarted
