import React, { SetStateAction, useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import Icon, { IconNames } from 'components/basics/Icon/Icon'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import InlineSpinner from 'components/basics/Spinners/InlineSpinner'
import Menu from 'components/blocks/Menu/Menu'
import Modal from 'components/blocks/Modal/Modal'
import Text from 'components/basics/Text/Text'
import { User } from 'api-data-models/admin/UsersContentModel'
import {
    AGENT_CONNECT_USER_DISABLE,
    AGENT_CONNECT_USER_ENABLE,
    AGENT_CONNECT_USER_RESET_PASSWORD,
    AGENT_CONNECT_USER_UPDATE,
    ASSIGN_USER_TO_A_GROUP,
} from 'graphql-queries/admin/admin-queries'
import { Group } from 'api-data-models/admin/GroupsContentModel'
import UpdateUserModal, {
    UpdatedUserDetailsData,
    UpdatedUserGroupData,
} from '../UpdateUserModal/UpdateUserModal'
import { USER_ROLES } from 'utils/constants'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'

import styles from './UsersList.module.scss'
import allContent from 'content/content'

const content = allContent.admin.userManagementPage.list

const USER_STATUS_ICONS = {
    FORCE_CHANGE_PASSWORD: { icon: 'Clock' },
    CONFIRMED: { icon: 'Check' },
    DISABLED: { icon: 'Cross' },
}

type UsersListProps = {
    usersData: User[]
    apiClient: ApolloClient<NormalizedCacheObject>
    fetchingGroups: boolean
    groupsListData: Group[]
    groupsApiErrorMessage: string | null
    setShouldRefreshUsersData: React.Dispatch<SetStateAction<boolean>>
    setUpdateUserDetailsSuccessMessage: React.Dispatch<SetStateAction<string | null>>
    setUpdateUserGroupSuccessMessage: React.Dispatch<SetStateAction<string | null>>
    setUpdateUserGroupError: React.Dispatch<SetStateAction<string | null>>
    setUpdateUserDetailsError: React.Dispatch<SetStateAction<string | null>>
}

const UsersList: React.FC<UsersListProps> = ({
    usersData,
    apiClient,
    fetchingGroups,
    groupsListData,
    groupsApiErrorMessage,
    setShouldRefreshUsersData,
    setUpdateUserDetailsSuccessMessage,
    setUpdateUserGroupSuccessMessage,
    setUpdateUserGroupError,
    setUpdateUserDetailsError,
}) => {
    const [renderUserList, setRenderUserList] = useState<User[]>(usersData)
    const [isDoingApiCall, setIsDoingApiCall] = useState(false)
    const [apiCallOccurringForEmail, setApiCallOccurringForEmail] = useState<string | null>(null)
    const [isPasswordConfirmModalOpen, setIsPasswordConfirmModalOpen] = useState<boolean>(false)
    const [isUserUpdateModalOpen, setIsUserUpdateModalOpen] = useState<boolean>(false)
    const [userDataForUpdate, setUserDataForUpdate] = useState<User | null>(null)
    const [clickedUsersActionButtonId, setClickedUsersActionButtonId] = useState<string>('') // set ID of button that opens modal so focus can be returned on close
    const [confirmedResetPassword, setConfirmedResetPassword] = useState<boolean>(false)
    const [resetPasswordEmail, setResetPasswordEmail] = useState<string | null>(null)
    const [disableUserError, setDisableUserError] = useState<string | null>(null)
    const [enableUserError, setEnableUserError] = useState<string | null>(null)
    const [resetPasswordError, setResetPasswordError] = useState<string | null>(null)
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState<string | null>(null)
    const userContext = datadogLogs.getGlobalContext()
    const { userEmail: loggedInUserEmail } = extractGlobalContextUserData(
        userContext as GlobalContextUserData
    )

    useEffect(() => {
        setRenderUserList(usersData)
    }, [usersData])

    useEffect(() => {
        if (resetPasswordEmail && confirmedResetPassword && !isDoingApiCall) {
            setResetPasswordError(null)
            setResetPasswordSuccess(null)
            setIsDoingApiCall(true)
            setApiCallOccurringForEmail(resetPasswordEmail)
            apiClient
                .mutate({
                    mutation: AGENT_CONNECT_USER_RESET_PASSWORD,
                    variables: { emailAddress: resetPasswordEmail },
                })
                .then((response) => {
                    setResetPasswordEmail(null)
                    setConfirmedResetPassword(false)
                    setIsDoingApiCall(false)
                    setApiCallOccurringForEmail(null)
                    if (response?.data?.agentConnectUserResetPassword) {
                        setResetPasswordSuccess(
                            `${resetPasswordEmail} ${content.resetPasswordSuccess} ${response.data.agentConnectUserResetPassword.password}`
                        )
                        datadogLogs.logger.info(
                            `Mutate AGENT_CONNECT_USER_RESET_PASSWORD Successfully reset user password: ${resetPasswordEmail}`
                        )
                    }
                })
                .catch((error) => {
                    setResetPasswordEmail(null)
                    setConfirmedResetPassword(false)
                    setIsDoingApiCall(false)
                    setApiCallOccurringForEmail(null)
                    datadogLogs.logger.error(
                        `Mutate AGENT_CONNECT_USER_RESET_PASSWORD UserList - email: ${resetPasswordEmail}, error: ${JSON.stringify(
                            {
                                error,
                            }
                        )}`,
                        { userContext },
                        error
                    )
                    setResetPasswordError(`${content.errors.resetPassword} ${resetPasswordEmail}`)
                })
        }
    }, [apiClient, confirmedResetPassword, isDoingApiCall, resetPasswordEmail, userContext])

    const updateUserList = (prevUserList: User[], userDataForUpdate: any): User[] => {
        return prevUserList.map((user: User) => {
            if (user.emailAddress === userDataForUpdate.emailAddress) {
                user.userGroups = [userDataForUpdate.userGroup]
                return { ...user, ...userDataForUpdate }
            }
            return user
        })
    }

    const updateUserDetails = (formData: UpdatedUserDetailsData): void => {
        const { userId, emailAddress } = formData
        if (emailAddress && userId) {
            setIsDoingApiCall(true)
            setApiCallOccurringForEmail(formData.emailAddress)
            apiClient
                .mutate({
                    mutation: AGENT_CONNECT_USER_UPDATE,
                    variables: formData,
                })
                .then((response) => {
                    setIsDoingApiCall(false)
                    setApiCallOccurringForEmail(null)
                    setUpdateUserDetailsSuccessMessage(content.updateUserDetailsSuccessMessage)
                    datadogLogs.logger.info(
                        `Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP Successfully update user: ${
                            formData.emailAddress
                        }, response: ${JSON.stringify(response)}`
                    )
                })
                .catch((error) => {
                    setIsDoingApiCall(false)
                    setApiCallOccurringForEmail(null)
                    setUpdateUserDetailsError(
                        `${content.errors.updateUser} ${formData.emailAddress}`
                    )
                    datadogLogs.logger.error(
                        'Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP App error:',
                        {},
                        error
                    )
                })
                .finally(() => {
                    const returnFocusTo = document.getElementById(clickedUsersActionButtonId)
                    setShouldRefreshUsersData(true)
                    setIsUserUpdateModalOpen(false)
                    setUserDataForUpdate(null)
                    returnFocusTo?.focus()
                })
        }
    }

    const updateUserGroup = (data: UpdatedUserGroupData): void => {
        const { userId, groupId } = data
        if (userId) {
            setIsDoingApiCall(true)
            apiClient
                .mutate({
                    mutation: ASSIGN_USER_TO_A_GROUP,
                    variables: { userGroupId: groupId, userId: userId },
                })
                .then(() => {
                    setIsDoingApiCall(false)
                    setApiCallOccurringForEmail(null)
                    setRenderUserList((prevUserList) =>
                        updateUserList(prevUserList, { ...data, userGroups: [groupId] })
                    )
                    setUpdateUserGroupSuccessMessage(content.updateUserGroupSuccessMessage)
                    datadogLogs.logger.info(
                        `Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP Successfully update user: ${data.userId}`
                    )
                })
                .catch((error) => {
                    setIsDoingApiCall(false)
                    setApiCallOccurringForEmail(null)
                    setUpdateUserGroupError(`${content.errors.updateUser} ${data.userId}`)
                    datadogLogs.logger.error(
                        'Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP App error:',
                        {},
                        error
                    )
                })
                .finally(() => {
                    const returnFocusTo = document.getElementById(clickedUsersActionButtonId)
                    setShouldRefreshUsersData(true)
                    setIsUserUpdateModalOpen(false)
                    setUserDataForUpdate(null)
                    returnFocusTo?.focus()
                })
        }
    }

    const handleUpdateUser = ({
        userDetailsData,
        userGroupData,
    }: {
        userDetailsData: UpdatedUserDetailsData | null
        userGroupData: UpdatedUserGroupData | null
    }): void => {
        if (userDetailsData) {
            updateUserDetails(userDetailsData)
        }
        if (userGroupData) {
            updateUserGroup(userGroupData)
        }
    }

    const handleDisableUser = (email: string): void => {
        setIsDoingApiCall(true)
        setApiCallOccurringForEmail(email)
        apiClient
            .mutate({
                mutation: AGENT_CONNECT_USER_DISABLE,
                variables: { emailAddress: email },
            })
            .then(() => {
                setIsDoingApiCall(false)
                setApiCallOccurringForEmail(null)
                setRenderUserList((prev) =>
                    prev.map((user) =>
                        user.emailAddress === email ? { ...user, enabled: false } : user
                    )
                )
                datadogLogs.logger.info(
                    `Mutate AGENT_CONNECT_USER_DISABLE Successfully disable user: ${email}`
                )
            })
            .catch((error) => {
                setIsDoingApiCall(false)
                setApiCallOccurringForEmail(null)
                setDisableUserError(`${content.errors.disableUser} ${email}`)
                datadogLogs.logger.error(
                    `Mutate AGENT_CONNECT_USER_DISABLE UserList - email: ${resetPasswordEmail}, error: ${JSON.stringify(
                        {
                            error,
                        }
                    )}`
                )
            })
    }

    const handleEnableUser = (email: string): void => {
        setIsDoingApiCall(true)
        setApiCallOccurringForEmail(email)
        apiClient
            .mutate({
                mutation: AGENT_CONNECT_USER_ENABLE,
                variables: { emailAddress: email },
            })
            .then(() => {
                setIsDoingApiCall(false)
                setApiCallOccurringForEmail(null)
                setRenderUserList((prev) =>
                    prev.map((user) =>
                        user.emailAddress === email ? { ...user, enabled: true } : user
                    )
                )
                datadogLogs.logger.info(
                    `Mutate AGENT_CONNECT_USER_ENABLE Successfully enable user: ${email}`
                )
            })
            .catch((error) => {
                setIsDoingApiCall(false)
                setApiCallOccurringForEmail(null)
                setEnableUserError(`${content.errors.enableUser} ${email}`)
                datadogLogs.logger.error(
                    `Mutate AGENT_CONNECT_USER_ENABLE UserList - email: ${resetPasswordEmail}, error: ${JSON.stringify(
                        {
                            error,
                        }
                    )}`
                )
            })
    }
    return (
        <>
            {disableUserError && (
                <InfoBanner
                    id='disable-user-error-banner'
                    bannerType='error'
                    text={disableUserError}
                />
            )}
            {enableUserError && (
                <InfoBanner
                    id='enable-user-error-banner'
                    bannerType='error'
                    text={enableUserError}
                />
            )}
            {resetPasswordError && (
                <InfoBanner
                    id='password-reset-error-banner'
                    bannerType='error'
                    text={resetPasswordError}
                />
            )}
            {resetPasswordSuccess && (
                <InfoBanner
                    id='password-reset-success-banner'
                    bannerType='success'
                    text={resetPasswordSuccess}
                />
            )}
            {groupsApiErrorMessage && (
                <InfoBanner
                    id='fetch-groups-data-error-banner'
                    bannerType='error'
                    text={groupsApiErrorMessage}
                />
            )}
            <div className={styles.list}>
                <div className={styles['list-header']}>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.userColumn}
                        </Text>
                    </div>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.confirmationStatusColumn}
                        </Text>
                    </div>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.userRoleColumn}
                        </Text>
                    </div>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.linkedGroupsColumn}
                        </Text>
                        <div />
                    </div>
                </div>
                {renderUserList.map((user: User) => {
                    let userRole = USER_ROLES.AGENT
                    if (user.userRoles?.includes(USER_ROLES.ADMIN)) userRole = USER_ROLES.ADMIN
                    if (user.userRoles?.includes(USER_ROLES.API_USER))
                        userRole = USER_ROLES.API_USER
                    const actionMenuOptions = [
                        {
                            label: content.resetPassword,
                            onClick: (): void => {
                                setResetPasswordEmail(user.emailAddress)
                                setIsPasswordConfirmModalOpen(true)
                                setClickedUsersActionButtonId(
                                    user.emailAddress + '-reset-password-action-menu-button'
                                )
                            },
                            disabled: !!resetPasswordEmail,
                        },
                        {
                            label: content.edit,
                            onClick: (): void => {
                                setUserDataForUpdate(user)
                                setIsUserUpdateModalOpen(true)
                                setClickedUsersActionButtonId(
                                    user.emailAddress + '-edit-user-action-menu-button'
                                )
                            },
                        },
                        // Do not add enable/disable option for users own email.
                        ...(loggedInUserEmail !== user.emailAddress
                            ? [
                                  {
                                      label: user.enabled ? content.disable : content.enable,
                                      onClick: (): void => {
                                          if (user.enabled) {
                                              handleDisableUser(user.emailAddress)
                                          } else {
                                              handleEnableUser(user.emailAddress)
                                          }
                                          setClickedUsersActionButtonId(
                                              user.emailAddress + '-enable-action-menu-button'
                                          )
                                      },
                                  },
                              ]
                            : []),
                    ]

                    return (
                        <div className={styles['list-item']} key={user.emailAddress}>
                            <div className={styles['list-item--left']}>
                                <Text weight='bold'>
                                    {user.firstName} {user.lastName}
                                </Text>
                                <Text>{user.emailAddress}</Text>
                            </div>
                            <div className={styles['list-item--middle']}>
                                <div className={styles['status-wrapper']}>
                                    {user.enabled ? (
                                        <>
                                            <Icon
                                                iconName={
                                                    USER_STATUS_ICONS[user.userStatus]
                                                        .icon as IconNames
                                                }
                                                iconColor='tertiary-blue'
                                            />
                                            <Text>{content.userStatus[user.userStatus]}</Text>
                                        </>
                                    ) : (
                                        <Text>{content.userStatus.DISABLED}</Text>
                                    )}
                                </div>
                            </div>
                            <div className={styles['list-item--middle']}>
                                <div className={styles['status-wrapper']}>
                                    <Text weight='bold'>{content.userRoles[userRole]}</Text>
                                </div>
                            </div>
                            <div className={styles['list-item--right']}>
                                {user.userGroups.map((group) => {
                                    return <div key={group.groupId}>{group.groupTitle}</div>
                                })}
                                <div>
                                    {isDoingApiCall &&
                                    apiCallOccurringForEmail === user.emailAddress ? (
                                        <div className={styles['inline-spinner']}>
                                            <InlineSpinner text={content.actionMenuProcessing} />
                                        </div>
                                    ) : (
                                        <Menu
                                            id={user.userId}
                                            options={actionMenuOptions}
                                            buttonText={content.actionMenu}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <UpdateUserModal
                fetchingGroups={fetchingGroups}
                groupsListData={groupsListData}
                groupsApiErrorMessage={groupsApiErrorMessage}
                isSubmitting={isDoingApiCall}
                onClose={(): void => {
                    const returnFocusTo = document.getElementById(clickedUsersActionButtonId)
                    setIsUserUpdateModalOpen(false)
                    setUserDataForUpdate(null)
                    returnFocusTo?.focus()
                }}
                userDataForUpdate={userDataForUpdate}
                handleUpdateUser={handleUpdateUser}
                isOpen={isUserUpdateModalOpen}
            />
            <Modal
                isOpen={isPasswordConfirmModalOpen}
                returnFocusId={clickedUsersActionButtonId}
                setClosed={(): void => {
                    const returnFocusTo = document.getElementById(clickedUsersActionButtonId)
                    setResetPasswordEmail(null)
                    setIsPasswordConfirmModalOpen(false)
                    returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
                }}
                headerText={content.resetPasswordModal.title}
            >
                <div className={styles['reset-password-modal-content']}>
                    <p>
                        <Text>{content.resetPasswordModal.text1}</Text>{' '}
                        <Text weight='bold'>{resetPasswordEmail}</Text>
                    </p>
                    <p>
                        <Text>{content.resetPasswordModal.text2}</Text>
                    </p>
                    <div className={styles['button-group']}>
                        <Button
                            type='button'
                            flavour='secondary'
                            text={content.resetPasswordModal.close}
                            onClick={(): void => {
                                const returnFocusTo = document.getElementById(
                                    clickedUsersActionButtonId
                                )
                                setResetPasswordEmail(null)
                                setIsPasswordConfirmModalOpen(false)
                                returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
                            }}
                        />
                        <Button
                            type='button'
                            flavour='primary'
                            text={content.resetPasswordModal.confirm}
                            onClick={(): void => {
                                const returnFocusTo = document.getElementById(
                                    clickedUsersActionButtonId
                                )
                                setIsPasswordConfirmModalOpen(false)
                                setConfirmedResetPassword(true)
                                returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UsersList
