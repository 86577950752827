import React, { JSX } from 'react'

import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import OrderItem from '../OrderItem/OrderItem'
import {
    CruiseProduct,
    FlightProduct,
    ORDER_ITEM_PAYMENT_TYPE,
    ORDER_ITEM_STATUS,
    OrderAdditionalInfo,
    OrderItemPaymentType,
    OrderItemStatus,
} from 'api-data-models/order/OrderContentModel'

import styles from './OrderConfirmation.module.css'
import allContent from 'content/content'
import OrderImportedAdditionalInformation from '../OrderImportedAdditionalInformation/OrderImportedAdditionalInformation'

const content = allContent.order.orderPage.confirmationPage

export const orderConfirmationHeading = ({
    orderItemPaymentOption,
    orderItemStatus,
}: {
    orderItemPaymentOption: OrderItemPaymentType
    orderItemStatus: OrderItemStatus
}): JSX.Element => {
    const orderCameBackOnHoldButUserDidNotChooseOnHold =
        orderItemStatus === ORDER_ITEM_STATUS.ON_HOLD &&
        orderItemPaymentOption !== ORDER_ITEM_PAYMENT_TYPE.ONHOLD &&
        orderItemPaymentOption !== null
    return (
        <div className={styles['order-confirmation__heading_container']}>
            <div className={styles['booking-message-outer']}>
                {orderCameBackOnHoldButUserDidNotChooseOnHold ? (
                    <InfoBanner
                        id='converted-to-on-hold-warning-banner'
                        isFocusable={true}
                        isCloseable={false}
                        text={content.paymentFailedButPutOnHold}
                        bannerType='warning'
                        logType='error'
                        source='order-page-confirmation-banner'
                    />
                ) : (
                    <InfoBanner
                        id='payment-success-banner'
                        isFocusable={true}
                        isCloseable={false}
                        text={content.bookingSuccessful}
                        bannerType='success' // no need to log on this banner - the api response handler logs the success.
                    />
                )}
            </div>
        </div>
    )
}

type ConfirmationProps = {
    numberOfTravellers: number
    orderId: string
    orderItemStatus: string
    orderItemPaymentOption?: string
    product: CruiseProduct | FlightProduct
    supplierOrderItemReference: string
    additionalData?: OrderAdditionalInfo
}

const OrderConfirmation = ({
    numberOfTravellers,
    orderId,
    orderItemStatus,
    orderItemPaymentOption,
    product,
    supplierOrderItemReference,
    additionalData,
}: ConfirmationProps): JSX.Element => {
    return (
        <div className={styles['order-confirmation__container']}>
            <OrderItem
                product={product}
                numberOfPassengers={numberOfTravellers}
                supplierOrderItemReference={supplierOrderItemReference}
                orderItemStatus={orderItemStatus}
                orderItemPaymentOption={orderItemPaymentOption}
                orderId={orderId}
            />
            {additionalData && <OrderImportedAdditionalInformation data={additionalData} />}
        </div>
    )
}

export default OrderConfirmation
