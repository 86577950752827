import React from 'react'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import styles from './ItineraryString.module.css'

export interface ItineraryStringProps {
    itinerary: string[]
}

const ItineraryString: React.FC<ItineraryStringProps> = ({ itinerary }) => {
    return (
        <span>
            {itinerary.map((portName, i) => {
                if (i === 0) {
                    return <Text key={portName + i}>{portName}</Text>
                } else {
                    return (
                        <Text key={portName + i}>
                            <Icon
                                iconName='ChevronRight'
                                className={styles['itinerary-divider-icon']}
                                iconColor='pink'
                                iconSize='XS'
                            />
                            {portName}
                        </Text>
                    )
                }
            })}
        </span>
    )
}

export default ItineraryString
