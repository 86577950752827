import React from 'react'
import classnames from 'classnames'
import { Controller } from 'react-hook-form'

import Icon from 'components/basics/Icon/Icon'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import Text from 'components/basics/Text/Text'
import Select from 'components/basics/Input/Select/Select'
import { Passenger } from '../PassengerConfiguration'

import styles from './PassengerItem.module.scss'
import allContent from 'content/content'
import Close from 'components/basics/Close/Close'

import { HackyUpdateType } from '../PassengerConfiguration'

const content = allContent.cruise.sharedSections.passengerConfigurationSection.passengerItem

export type PassengerItemTypes = 'child' | 'adult' | 'placeholder'

type PassengerItemProps = {
    passenger: Passenger
    index?: number
    control?: any
    closePassenger?: (passengerNumber: number, ageType: PassengerItemTypes) => void
    updatePassenger?: ({
        passengerNumber,
        property,
        value,
        update,
        fields,
    }: {
        passengerNumber: number
        property: 'age' | 'pastPassenger' | 'military' | 'residency'
        value: string | number
        update: HackyUpdateType
        fields: Passenger[]
    }) => void
    update: HackyUpdateType
    fields: Passenger[]
}

const childAgeOptions = Array.from({ length: 18 }, (_, index) => (index + 1).toString())

const PassengerItem: React.FC<PassengerItemProps> = ({
    passenger,
    index,
    control,
    closePassenger,
    updatePassenger,
    update,
    fields,
}) => {
    const handleOnPassengerCloseClick = (): void => {
        if (closePassenger) closePassenger(passenger.ageTypeNumber, passenger.ageType)
    }

    const containerClassNames = classnames(styles.container, {
        [styles['container--placeholder']]: passenger?.ageType === 'placeholder',
    })

    const showCloseButton =
        (!passenger?.lead && passenger?.ageType === 'adult') || passenger?.ageType === 'child'

    return (
        <div className={containerClassNames}>
            {passenger?.ageType === 'adult' && typeof index !== 'undefined' && index >= 0 && (
                <div>
                    <div className={styles['heading']}>
                        <Icon iconName='User' />
                        <Heading heading='2'>
                            {passenger.lead ? content.leadPassengerHeading : content.adultHeading}
                        </Heading>
                    </div>
                    <div className={styles['past-passenger']}>
                        <div className={styles['past-passenger-input']}>
                            <Controller
                                name={`passengers.${index}.pastPassenger`}
                                control={control}
                                render={({ field: { value } }): React.ReactElement => (
                                    <LabelledInput
                                        label={content.pastPassengerLabel}
                                        htmlFor={`passengers.${index}.pastPassenger`}
                                    >
                                        <TextInput
                                            defaultValue={passenger.pastPassenger}
                                            placeholder={content.pastPassengerPlaceHolder}
                                            onBlur={(e): void => {
                                                if (updatePassenger) {
                                                    updatePassenger({
                                                        passengerNumber: index,
                                                        property: 'pastPassenger',
                                                        value: e.target.value,
                                                        update,
                                                        fields,
                                                    })
                                                }
                                            }}
                                        />
                                    </LabelledInput>
                                )}
                            />
                        </div>
                    </div>
                </div>
            )}
            {passenger?.ageType === 'child' && typeof index !== 'undefined' && index >= 0 && (
                <div>
                    <div className={styles['heading']}>
                        <Icon iconName='User' />
                        <Heading heading='2'>{content.childHeading}</Heading>
                    </div>
                    <div className={styles['child-age-input']}>
                        <Controller
                            name={`passengers.${index}.age`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value } }): React.ReactElement => (
                                <LabelledInput
                                    required
                                    label={content.childAgeLabel}
                                    htmlFor={`passengers.${index}.age`}
                                >
                                    <Select
                                        // TODO: The default value doesn't seem right to be either the age or the placeholder text...
                                        // I believe we should set actual input value default on the form using form defaults
                                        defaultValue={
                                            passenger.age
                                                ? passenger.age
                                                : content.childAgePlaceHolder
                                        }
                                        placeholder={content.childAgePlaceHolder}
                                        options={childAgeOptions.map((age) => {
                                            return { text: age, value: age }
                                        })}
                                        onChange={(e): void => {
                                            if (updatePassenger)
                                                updatePassenger({
                                                    passengerNumber: index,
                                                    property: 'age',
                                                    value: +e.target.value,
                                                    update,
                                                    fields,
                                                })
                                        }}
                                    />
                                </LabelledInput>
                            )}
                        />
                        <Text>{content.childAgeInstructionMessage}</Text>
                    </div>
                </div>
            )}
            {passenger?.ageType === 'placeholder' && <Icon iconName='User' />}
            {showCloseButton && (
                <div className={styles['close-button']}>
                    <Close text={content.closeButton} onClick={handleOnPassengerCloseClick} />
                </div>
            )}
        </div>
    )
}

export default PassengerItem
