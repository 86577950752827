export type DiningData = {
    dining: DiningOptionsFromAPI[]
}

export type DiningOptionsFromAPI = {
    code: string
    available_ind: boolean
    description: string
}

export type DiningOption = {
    availableInd: boolean
    code: string
    description: string
}

export const getDiningOptions = (diningOptions: DiningOption[]): DiningOption[] => {
    if (!diningOptions) return []
    const filteredArray = diningOptions.filter((option) => option.availableInd)

    const arrayForSorting = [...filteredArray]
    arrayForSorting.sort((a: DiningOption, b: DiningOption) => parseInt(a.code) - parseInt(b.code))
    return arrayForSorting
}

export const DiningOptionsModelTransformFunction = (
    diningOptionsData: DiningData
): DiningOption[] => {
    const transformedDiningData = diningOptionsData.dining.map((diningOption) => ({
        availableInd: diningOption.available_ind,
        code: diningOption.code,
        description: diningOption.description,
    }))

    return getDiningOptions(transformedDiningData)
}
