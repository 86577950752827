import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Card from 'components/blocks/Card/Card'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Menu from 'components/blocks/Menu/Menu'
import TablePagination from 'components/blocks/TablePagination/TablePagination'
import Text from 'components/basics/Text/Text'
import SendQuoteModal, { QuoteTemplateDynamicFields } from '../SendQuoteModal/SendQuoteModal'
import { Order } from 'api-data-models/order/AllOrdersContentModel'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { FeatureToggleContext } from 'App'

import styles from './AllOrdersLayout.module.scss'
import allContent from 'content/content'

const content = allContent.order.allOrdersPage
const tableContent = content.tableContent

type PaginationValues = 5 | 10 | 15 | 20 | 30 | 50 | 100
const tablePaginationOptions: PaginationValues[] = [5, 10, 15, 20, 30, 50, 100]

const AllOrdersLayout: React.FC<{
    loading: boolean
    error: CustomApiError[] | null
    orders: Order[]
    refreshOrders?(): void
    isBooked: boolean
    pageSize: number
    pageNumber: number
    pagesTotal: number
    setPageSize: (value: number) => void
    setPageNumber: (value: number) => void
}> = ({
    loading,
    error,
    orders,
    isBooked,
    pagesTotal,
    setPageNumber,
    pageNumber,
    pageSize,
    setPageSize,
}): JSX.Element => {
    const [isSendQuoteModalOpen, setIsSendQuoteModalOpen] = useState(false)
    const [quoteTemplateData, setQuoteTemplateData] = useState<QuoteTemplateDynamicFields | null>(
        null
    )

    const handleSendQuoteModalOpen = (order: Order): void => {
        setIsSendQuoteModalOpen(true)
        setQuoteTemplateData(order.templateData)
    }
    // pageSize * each line height + header height
    const loaderHeight = pageSize * 59 + 56.5

    return (
        <div>
            <SendQuoteModal
                isOpen={isSendQuoteModalOpen}
                onClose={(): void => setIsSendQuoteModalOpen(false)}
                returnFocusId={'sendQuoteButton'}
                templateData={quoteTemplateData}
            />

            {error && <ErrorList errorsList={error} source='all-orders-page' />}
            {!error && orders && (
                <div className={styles.content}>
                    {isBooked ? (
                        <Card withoutBottomBorder>
                            {loading ? (
                                <LargeSpinner
                                    text={content.fetchingOrder}
                                    minHeight={loaderHeight}
                                />
                            ) : orders.length ? (
                                <OrdersTable orders={orders} hasSupplierRef />
                            ) : (
                                <Text className={styles['text']}>
                                    {tableContent.noBookedOrdersFoundText}
                                </Text>
                            )}
                            <TablePagination
                                id={'bookedOrders'}
                                page={pageNumber}
                                itemsPerPage={pageSize}
                                totalPages={pagesTotal}
                                onPageChange={setPageNumber}
                                onItemsPerPageChange={setPageSize}
                                className={styles['orders-pagination']}
                                itemsPerPageOptions={tablePaginationOptions}
                                paginationType='full'
                            />
                        </Card>
                    ) : (
                        <Card withoutBottomBorder>
                            {loading ? (
                                <LargeSpinner
                                    text={content.fetchingOrder}
                                    minHeight={loaderHeight}
                                />
                            ) : orders.length ? (
                                <OrdersTable
                                    orders={orders}
                                    onSendQuoteModalOpen={handleSendQuoteModalOpen}
                                />
                            ) : (
                                <Text className={styles['text']}>
                                    {tableContent.noUnfulfilledOrdersFoundText}
                                </Text>
                            )}
                            <TablePagination
                                id={'quotes'}
                                page={pageNumber}
                                itemsPerPage={pageSize}
                                totalPages={pagesTotal}
                                onPageChange={setPageNumber}
                                onItemsPerPageChange={setPageSize}
                                className={styles['orders-pagination']}
                                itemsPerPageOptions={tablePaginationOptions}
                                paginationType='full'
                            />
                        </Card>
                    )}
                </div>
            )}
        </div>
    )
}

export default AllOrdersLayout

const OrdersTable: React.FC<{
    orders: Order[]
    hasSupplierRef?: boolean
    onSendQuoteModalOpen?: (order: Order) => void
}> = ({ orders, hasSupplierRef, onSendQuoteModalOpen }): JSX.Element | null => {
    const navigate = useNavigate()
    const featureToggles = useContext(FeatureToggleContext)
    const { userEmail } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    const importOrderEnabled = featureToggles.TURN_ON_ORDERS_IMPORT || isTestEmailDomain(userEmail)

    const currentPageOfOrders = [...orders]
    return currentPageOfOrders.length ? (
        <table className={styles['table']}>
            <thead>
                <tr>
                    <th>
                        <Text weight='bold'>{tableContent.reference}</Text>
                    </th>
                    <th>
                        {hasSupplierRef ? (
                            <Text weight='bold'>{tableContent.dateBooked}</Text>
                        ) : (
                            <Text weight='bold'>{tableContent.dateCreated}</Text>
                        )}
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.supplier}</Text>
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.departureDate}</Text>
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.passenger}</Text>
                    </th>
                    <th>
                        <Text weight='bold'>{tableContent.total}</Text>
                    </th>
                    {hasSupplierRef && importOrderEnabled && (
                        <th>
                            <Text weight='bold'>{tableContent.imported}</Text>
                        </th>
                    )}
                    <th />
                </tr>
            </thead>
            <tbody>
                {currentPageOfOrders.map((order: Order) => {
                    const redirectUrl = `${
                        order.isImported && importOrderEnabled ? ROUTES.ORDER_IMPORT : ROUTES.ORDER
                    }/?orderId=${order.orderId}`

                    return (
                        <tr key={order.orderId}>
                            <td>
                                <Text weight='bold'>{order.trimmedOrderId}</Text>
                            </td>
                            {hasSupplierRef ? (
                                <td>{order.bookedAt}</td>
                            ) : (
                                <td>{order.createdAt}</td>
                            )}
                            <td width={250}>{order.supplierName}</td>
                            <td>{order.departureDate}</td>
                            <td>{order.passengerContactDetailsLastname}</td>
                            <td>
                                <Text font='mono'>
                                    {order.currencySymbol}
                                    {order.totalPrice}
                                </Text>
                            </td>
                            {hasSupplierRef && importOrderEnabled && (
                                <th>
                                    <Text weight='bold'>{order.isImported ? 'Yes' : '-'}</Text>
                                </th>
                            )}
                            <td style={{ padding: 0 }}>
                                {!hasSupplierRef ? (
                                    <Menu
                                        id={order.orderId}
                                        options={[
                                            {
                                                label: tableContent.viewQuote,
                                                onClick: () => navigate(redirectUrl),
                                            },
                                            ...(featureToggles.TURN_ON_EMAIL_QUOTES
                                                ? [
                                                      {
                                                          label: tableContent.emailQuote,
                                                          onClick: (): void => {
                                                              onSendQuoteModalOpen &&
                                                                  onSendQuoteModalOpen(order)
                                                          },
                                                      },
                                                  ]
                                                : []),
                                        ]}
                                        buttonText={tableContent.actions}
                                    />
                                ) : (
                                    <Link to={redirectUrl}>{tableContent.orderLinkText}</Link>
                                )}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    ) : null
}
