import { combineCurrencyAndPrice } from 'utils/string-helpers'
import { CURRENCY_CODES } from 'utils/constants'
import { getFormattedDate } from '../utils/date-helpers'

type LeadInPriceFromAPI = {
    fare: number
    rateCode: string
    taxesFeesAndPortExpenses: number
    cabinDescription: string
    cabinType: string
    cabinGrade: string
    available: boolean
    currency: string
}

export type AlternativeSailingDatesFromApi = {
    id: string
    embarkDate: string
    leadInPrices: LeadInPriceFromAPI[]
}

type AlternativeSailingDatesPricing = {
    inside: string
    outside: string
    balcony: string
    suite: string
}
export type AlternativeSailingDate = {
    cruiseId: string
    embarkDate: string
    pricing: AlternativeSailingDatesPricing
}

export const AlternativeSailingDatesFunction = (
    alternativeSailingDates: AlternativeSailingDatesFromApi[]
): AlternativeSailingDate[] => {
    return alternativeSailingDates.map(
        (alternativeSailingDate: AlternativeSailingDatesFromApi): AlternativeSailingDate => {
            const pricing = alternativeSailingDate.leadInPrices.reduce(
                (priceObject, currentValue) => {
                    const cabinType = currentValue.cabinType.toLowerCase()
                    return {
                        ...priceObject,
                        [cabinType]: currentValue.available
                            ? combineCurrencyAndPrice(
                                  '' + Math.round(+currentValue.fare / 100),
                                  CURRENCY_CODES[currentValue.currency]
                              )
                            : undefined,
                    }
                },
                {}
            )
            return {
                cruiseId: alternativeSailingDate.id,
                embarkDate: getFormattedDate(alternativeSailingDate.embarkDate),
                pricing: pricing as AlternativeSailingDatesPricing,
            }
        }
    )
}
