import React from 'react'
import Text, { TextColours, TextSizes } from '../Text/Text'

export type HeadingTypes = '1HeroTitle' | '1HeroSubtitle' | '1' | '2' | '3' | '4' | '5' | '6'
export type HeadingSizes = '1' | '2' | '3'

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
    /** headings cover <h1>-<h6> with '1-6', or '1HeroTitle' and '1HeroSubtitle' for extra large */
    heading: HeadingTypes
    /** Can add extra styles if needed. */
    className?: string
    /** Text to display in the heading (can be another component to allow icons etc.) */
    children: React.ReactNode
    /** Size (1/2/3) is set automatically based on the heading prop, but you can override to another font size. */
    size?: HeadingSizes
    /** Set font to white when on dark background */
    onDarkBackground?: boolean
    /** so user can override the auto chosen colour */
    colorOverride?: TextColours
}

/** Heading: component that returns a section heading tag between <h1>-<h6>
 * Sets colours and size to match design options */
const Heading: React.FC<HeadingProps> = ({
    heading,
    size,
    onDarkBackground = false,
    className,
    children,
    colorOverride,
}: HeadingProps) => {
    let Wrapper: keyof JSX.IntrinsicElements
    let textSize: TextSizes
    let level: '1' | '2' | '3' | '4' | '5' | '6'
    let color: TextColours

    if (heading === '1HeroTitle') {
        /** H1 Hero Title - BLUE and XXXL */
        Wrapper = `h1`
        textSize = 'XXXL'
        color = 'tertiary-blue'
    } else if (heading === '1HeroSubtitle') {
        /** H1 Hero Subtitle - BLUE and XXL */
        Wrapper = `h1`
        textSize = 'XXL'
        color = 'tertiary-blue'
    } else {
        /** Heading Level <h1>-<h6> */
        const fontSize: Record<string, TextSizes> = {
            '1': 'XL', //     H1 36px Page Title - BLUE
            '2': 'L', //      H2 26px Page Subtitle - GRAY
            '3': 'M', //      H3 21px Subsection Heading - GRAY
            '4': 'M', //      H4 21px Subsection Heading - GRAY
            '5': 'M', //      H5 21px Subsection Heading - GRAY
            '6': 'M', //      H6 21px Subsection Heading - GRAY
        }
        level = heading
        Wrapper = `h${level}` as keyof JSX.IntrinsicElements
        textSize = size ? fontSize[size] : fontSize[level]
        color = level === '1' ? 'tertiary-blue' : 'primary-blue'
    }

    if (onDarkBackground) color = 'white'

    /** if user is overriding the colour then let that happen */
    if (colorOverride) color = colorOverride

    return (
        /** All headings are font type heading, and weight bold. */
        <Wrapper>
            <Text className={className} color={color} font='heading' size={textSize} weight='bold'>
                {children}
            </Text>
        </Wrapper>
    )
}

export default Heading
