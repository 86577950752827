import { insertDecimal2CharsFromEnd } from '../../utils/string-helpers'

type Currency = 'GBP' | 'USD' | 'AUD'

type FareTypeFromApi =
    | 'FEES_TAXES_PORT_EXPENSES'
    | 'FARE'
    | 'COMMISSION'
    | 'NCF'
    | 'ONBOARD_CREDIT'
    | 'MISC'
    | 'GROSS'
    | 'BONUS_COMMISSION'
    | 'NET_FARE'
    | 'NET_DUE'
    | 'TRANSPORTATION_ADDON'
    | 'INSURANCE'
    | 'PACKAGES'
    | 'SPECIAL_SERVICES'
    | 'PAYMENT_APPLIED'
    | 'AVERAGE CRUISE RATE PER PERSON WITH NCFS'
    | 'GUEST SUB TOTAL'
    | 'PROMOTIONAL ONBOARD CREDIT'
    | 'DISCOUNT'

type BreakdownItemFromApi = {
    code: string
    currency: Currency
    description?: string
    fare_type: FareTypeFromApi
    item_type: 'Agent' | 'Guest'
    name: string
    passenger_number: number
    price: number
}

type PaymentScheduleItemFromApi = {
    due_date: string
    description: string
    amount: number
    is_deposit: boolean
    currency: Currency
    payment_type: 'Agent' | 'Guest'
}

type PaymentChoiceFromApi = {
    due_date: string
    payment_option: PaymentOptionType
    payment_amount?: number
    payment_currency_code: Currency
}

export type PriceInformationFromApi = {
    commission: number
    currency: Currency
    payment_schedule_items?: PaymentScheduleItemFromApi[]
    breakdown_items?: BreakdownItemFromApi[]
    payment_choices?: PaymentChoiceFromApi[]
    total_obc_price: number
}

type BreakdownItem = {
    name: string
    price: number | string
}

export type Breakdown = Record<FareTypeFromApi, BreakdownItem> | Record<string, never>

export type BreakdownPerPassenger =
    | Record<string, Breakdown | Record<string, never>>
    | Record<string, never>

export function getBreakDownItemsByPassenger(
    breakdownItems: BreakdownItemFromApi[]
): BreakdownPerPassenger {
    if (!breakdownItems || breakdownItems.length <= 0) return {}
    const breakdownsPerPassenger:
        | Record<string, Breakdown | Record<string, never>>
        | Record<string, never> = {} // any should be type Breakdown but can't make code below happy with typescript

    breakdownItems.forEach((bdItem) => {
        const { passenger_number, fare_type, name, price } = bdItem

        if (!breakdownsPerPassenger[passenger_number]) {
            breakdownsPerPassenger[passenger_number] = {}
        } // if a passengerNumber as key doesn't exist, create object

        if (fare_type === 'GROSS') {
            breakdownsPerPassenger[passenger_number].GROSS = {
                // add breakdown item object using fareType as key and value being: { price, name }
                price: insertDecimal2CharsFromEnd(price),
                name: 'GROSS',
            }
        } else if (fare_type === 'NCF') {
            breakdownsPerPassenger[passenger_number].NCF = {
                // add breakdown item object using fareType as key and value being: { price, name }
                price: insertDecimal2CharsFromEnd(price),
                name: 'Non Commissionable Fare',
            }
        } else if (fare_type === 'FEES_TAXES_PORT_EXPENSES') {
            breakdownsPerPassenger[passenger_number].FEES_TAXES_PORT_EXPENSES = {
                // add breakdown item object using fareType as key and value being: { price, name }
                price: insertDecimal2CharsFromEnd(price),
                name: 'Fees Taxes and Port Expenses',
            }
        } else if (!['COMMISSION', 'ONBOARD_CREDIT'].includes(fare_type)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            breakdownsPerPassenger[passenger_number][name] = {
                // add breakdown item object using fareType as key and value being: { price, name }
                price: insertDecimal2CharsFromEnd(price),
                name: name,
            }
        }
    })
    breakdownsPerPassenger.totals = breakdownsPerPassenger['0'] // I assume this line can potentially cause index error. Something to discuss!
    delete breakdownsPerPassenger['0']
    return breakdownsPerPassenger
}
