import React from 'react'

type ListItemProps = React.AllHTMLAttributes<HTMLLIElement> & {
    /** unique key for each <li> rendered to help react track dynamically rendered contents */
    listKey: string
}

/** ListItem: renders a li tag wrapping children */
const ListItem: React.FC<ListItemProps> = ({
    listKey,
    className,
    children,
    ...rest
}: ListItemProps) => {
    return (
        <li key={listKey} className={className} {...rest}>
            {children}
        </li>
    )
}

export default ListItem
