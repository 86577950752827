import React, { forwardRef, useState } from 'react'
import classnames from 'classnames'

import Icon from 'components/basics/Icon/Icon'
import TextInput, { TextInputProps } from 'components/basics/Input/TextInput/TextInput'

import styles from './PasswordInput.module.css'
import allContent from 'content/content'

const content = allContent.components.passwordInput

const PasswordInput = forwardRef<HTMLInputElement, TextInputProps>(
    ({ className, ...rest }, ref) => {
        const [showPassword, setShowPassword] = useState(false)

        const handleShowHidePasswordOnClick = (): void => {
            setShowPassword(!showPassword)
        }

        const containerClassNames = classnames(styles.container, className)

        return (
            <div className={containerClassNames}>
                <TextInput ref={ref} type={showPassword ? 'text' : 'password'} {...rest} />
                <button
                    aria-label={showPassword ? content.hidePassword : content.showPassword}
                    type='button'
                    className={styles['show-password-button']}
                    onClick={handleShowHidePasswordOnClick}
                >
                    <Icon iconName={showPassword ? 'EyeSlash' : 'Eye'} iconSize='S' />
                </button>
            </div>
        )
    }
)

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
