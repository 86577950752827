import React from 'react'
import Image from 'components/basics/Image/Image'
import Text from 'components/basics/Text/Text'
import * as self from './DeckPlanView'

import styles from './DeckPlanView.module.css'

import allContent from 'content/content'

const content = allContent.cruise.cabinPage

export const parseDeckImageUrl = (url?: string | null): string => {
    if (!url) return ''
    if (url && !url.includes('https://')) return `https://${url}`
    return url
}

export interface DeckPlanViewProps {
    selectedDeck: string
    guarCabinOnly: boolean
    currentImage?: string
}

const DeckPlanView: React.FC<DeckPlanViewProps> = ({
    selectedDeck,
    guarCabinOnly,
    currentImage,
}) => {
    return (
        <div className={styles['deck-legend-image-container']}>
            <div className={styles['deck-image-container']}>
                {/* No deck selected */}
                {!selectedDeck && !guarCabinOnly && content.decks.noSelected}
                {/* Deck is selected but there are no deck image */}
                {((selectedDeck && !currentImage) || guarCabinOnly) && (
                    <div className={styles['placeholder-container']}>
                        <Image
                            alt={content.decks.placeholderAltText}
                            src='https://www.traveltek.com/wp-content/uploads/2024/01/deck-image-placeholder.png'
                            className={styles['placeholder-image']}
                        />
                        <Text className={styles['overlay-text']} size='XXL'>
                            {content.decks.notAvailable}
                        </Text>
                    </div>
                )}
                {selectedDeck && currentImage && (
                    <Image
                        alt={`image for deck - ${selectedDeck}`}
                        src={self.parseDeckImageUrl(currentImage)}
                    />
                )}
            </div>
        </div>
    )
}

export default DeckPlanView
