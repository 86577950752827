export const checkCabinParams = (queryVariables: Record<string, any>): boolean => {
    const cruiseId = queryVariables?.get('cruiseId')
    const supplierCode = queryVariables?.get('supplierCode')
    const rateCode = queryVariables?.get('rateCode')
    const cabinGradeCode = queryVariables?.get('cabinGradeCode')

    return (
        Boolean(cruiseId) && Boolean(supplierCode) && Boolean(rateCode) && Boolean(cabinGradeCode)
    )
}
