/** parseQueryParams: take a query string and parse ONLY EXPECTED cruise search key/values from it into an object
 * @param {string} queryParamsString - query string params with leading ? removed */
export const composeCruiseResultsPageURLQueryVariables = (
    queryParamsString: string
): {
    destinationInputCategory?: string
    country?: string
    destination?: string
    embarkEarliestDate?: string
    embarkLatestDate?: string
    supplierCode?: string
    supplierName?: string
    shipName?: string
    embarkPort?: string
    disembarkPort?: string
    cruiseId?: string
    leadinPriceMin?: string
    leadinPriceMax?: string
    cabinType?: string
    productName?: string
    visitingPort?: string
    region?: string
    durationMin?: number
    durationMax?: number
} => {
    const urlSearchParams = new URLSearchParams(queryParamsString)
    const paramsObject = Object.fromEntries(urlSearchParams.entries())
    const {
        destinationInputCategory,
        country,
        destination,
        embarkEarliestDate,
        embarkLatestDate,
        durationMin,
        durationMax,
        supplierCode,
        supplierName,
        shipName,
        embarkPort,
        disembarkPort,
        cruiseId,
        leadinPriceMin,
        leadinPriceMax,
        cabinType,
        productName,
        visitingPort,
        region,
    } = paramsObject
    const parsedDurationMin = parseInt(durationMin)
    const parsedDurationMax = parseInt(durationMax)

    // return only expected params, do not consume anything else from the url, for the sailing details end point.
    return {
        ...(destination && { destination }),
        ...(destinationInputCategory && { destinationInputCategory }),
        ...(visitingPort && { visitingPort }),
        ...(country && { country }),
        ...(embarkEarliestDate && { embarkEarliestDate }),
        ...(embarkLatestDate && { embarkLatestDate }),
        ...(durationMin && { durationMin: parsedDurationMin }),
        ...(durationMax && { durationMax: parsedDurationMax }),
        ...(supplierCode && { supplierCode }),
        ...(supplierName && { supplierName }),
        ...(shipName && { shipName }),
        ...(embarkPort && { embarkPort }),
        ...(disembarkPort && { disembarkPort }),
        ...(cruiseId && { cruiseId }),
        ...(leadinPriceMin && { leadinPriceMin }),
        ...(leadinPriceMax && { leadinPriceMax }),
        ...(cabinType && { cabinType }),
        ...(productName && { productName }),
        ...(region && { region }),
    }
}
