import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react'
import styles from './Checkbox.module.scss'
import classnames from 'classnames'
import InlineSpinner from 'components/basics/Spinners/InlineSpinner'
import allContent from 'content/content'

const content = allContent.components.checkbox

export type CheckboxProps = Omit<React.ComponentProps<'input'>, 'value'> & {
    /** Called when toggled */
    onChange(event: ChangeEvent<HTMLInputElement>): void
    /** the value (sent to the form onSubmit) you wish the checkbox to have (an unchecked checkbox is not sent to the form, they do not use true/false as value. */
    value?: string | boolean
    /** text used for the label */
    text: string | ReactNode
    /** for setting text to lighter colour when used on dark backgrounds */
    onDarkBackground?: boolean
    /** toggle to make unavailable */
    disabled?: boolean
    /** toggle for error styling */
    isErrored?: boolean
    /** toggle to disable button show small spinner */
    showSpinner?: boolean
    checkedLoadingText?: string
    uncheckedLoadingText?: string
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
    showSpinner = false,
    disabled = showSpinner,
    onChange,
    text,
    value,
    onDarkBackground = false,
    isErrored = false,
    checkedLoadingText = content.adding,
    uncheckedLoadingText = content.removing,
    ...rest
}) => {
    const containerClassNames = classnames(styles.container, {
        [styles['container--disabled']]: disabled,
    })
    const inputClassNames = classnames(
        styles.checkbox,
        { [styles['input--errored']]: isErrored },
        { [styles['hide']]: showSpinner }
    )
    const labelClassNames = classnames(
        styles.label,
        { [styles['label--errored']]: isErrored },
        { [styles['label--alt']]: onDarkBackground },
        { [styles['hide']]: showSpinner }
    )
    return (
        <label className={containerClassNames}>
            <input
                disabled={disabled}
                onChange={onChange}
                type='checkbox'
                value={typeof value === 'boolean' ? undefined : value} // HTML input value should be a string
                checked={typeof value === 'boolean' ? value : undefined} // Use checked for boolean value
                {...rest}
            />
            <span className={inputClassNames} />
            <span className={labelClassNames}>{text}</span>
            {showSpinner && (
                <InlineSpinner
                    text={`${text} (${rest.checked ? uncheckedLoadingText : checkedLoadingText})`}
                />
            )}
        </label>
    )
}

export default Checkbox
