import React, { useState, useEffect } from 'react'
import { useRest, UseRestOptions } from 'components/hooks/useRest'
import { useNavigate } from 'react-router-dom'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Modal from 'components/blocks/Modal/Modal'
import RateCodes from 'components/sections/cruise/RateCodes/RateCodes'
import SpinnerCruiseLogo from 'components/blocks/SpinnerCruiseLogo/SpinnerCruiseLogo'
import { PriceBreakdown } from './PriceBreakdown/PriceBreakdown'
import {
    CabinTypeGroup,
    CabinGradeFromAPI,
    CabinGradesModelTransformFunction,
} from 'api-data-models/cruise-detail/CabinGradesModel'
import CabinGradesTable from './CabinGradesTable/CabinGradesTable'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import {
    RateCode,
    RateCodeFromApi,
    RateCodesModelTransformFunction,
} from 'api-data-models/cruise-detail/RateCodesModel'
import { SailingInfoModel } from 'api-data-models/cruise-detail/SailingInfoModel'
import { SupplierCodes } from 'utils/constants'

import styles from './CabinGrades.module.css'
import allContent from 'content/content'

const content = allContent.cruise.sailingPage.cabinGradesView

export const CabinGrades = ({
    sailingInfoData,
    resultsPageUrl,
}: {
    sailingInfoData: SailingInfoModel
    resultsPageUrl: string
}): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)
    const [rateCodesData, setRateCodesData] = useState<RateCode[]>()
    const [isPriceModalOpen, setIsPriceModalOpen] = useState(false)
    const [priceModalData, setPriceModalData] = useState<Record<string, any>>()
    const [clickedButtonId, setClickedButtonId] = useState('')
    const [cabinGradesData, setCabinGradesData] = useState<CabinTypeGroup[]>()
    const navigate = useNavigate()

    const cruiseId = sailingInfoData.cruiseId
    const supplierCode = sailingInfoData.supplierCode

    const { passengerConfigurationDataRestRequestBody } = usePassengersInfo()
    const ratesFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/rates?supplier_code=${supplierCode}`,
        source: 'SailingPage - RATECODES',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }
    const {
        result: ratesResult,
        loading: ratesLoading,
        error: ratesError,
    } = useRest(ratesFetchOptions)

    useEffect(() => {
        if (ratesResult) {
            const rateCodesData = RateCodesModelTransformFunction(ratesResult as RateCodeFromApi[])
            setRateCodesData(rateCodesData)
        }
    }, [ratesResult])

    const cabinGradesFetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_DETAIL_SERVICE_URL +
            `/cruise/${cruiseId}/cabin-grades?supplier_code=${supplierCode}`,
        source: 'SailingPage - CATEGORIES',
        method: 'POST',
        variables: passengerConfigurationDataRestRequestBody,
    }
    const {
        result: cabinGradesResult,
        error: cabinGradesError,
        loading: cabinGradesLoading,
    } = useRest(cabinGradesFetchOptions)

    useEffect(() => {
        if (cabinGradesResult) {
            setCabinGradesData(
                CabinGradesModelTransformFunction(cabinGradesResult as CabinGradeFromAPI[])
            )
        }
    }, [cabinGradesResult])

    const cabinTypePriceAccordions = cabinGradesData?.map((cabinTypeGroup, index) => {
        return (
            <Accordion
                key={cabinTypeGroup.type}
                titleSize='3'
                titleLevel='2'
                darkHeading={true}
                isPadded={true}
                baseId={index.toString()}
                title={cabinTypeGroup.type}
                className={styles.accordions}
                startOpen={index === 0}
            >
                {rateCodesData && (
                    <CabinGradesTable
                        cabinTypeGroup={cabinTypeGroup}
                        cruiseId={cruiseId}
                        setPriceModalData={setPriceModalData}
                        supplierCode={supplierCode}
                        setClickedButtonId={setClickedButtonId}
                        currency={rateCodesData[0].currency}
                        setIsPriceModalOpen={setIsPriceModalOpen}
                        allRateCodes={rateCodesData}
                    />
                )}
            </Accordion>
        )
    })
    return (
        <div className={styles.container}>
            <InfoBanner
                bannerType='info'
                text={content.priceInfoBanner}
                id='price-based-on-two-info'
                isCloseable={true}
            />
            {(ratesLoading || cabinGradesLoading) && (
                <SpinnerCruiseLogo
                    text={content.cabinGradesLogoSpinner}
                    supplierCode={supplierCode as SupplierCodes}
                />
            )}
            {ratesError && <ErrorList errorsList={ratesError} source='Ratecodes - Sailing page' />}
            {cabinGradesError && (
                <ErrorList errorsList={cabinGradesError} source='CabinGrades - Sailing page' />
            )}
            {rateCodesData && cabinGradesData && (
                <>
                    <RateCodes rateCodesData={rateCodesData} />
                    {cabinTypePriceAccordions}
                    <Modal
                        isOpen={isPriceModalOpen}
                        headerText={content.breakdown.header}
                        returnFocusId={clickedButtonId}
                        setClosed={(): void => setIsPriceModalOpen(false)}
                        loading={isLoading}
                    >
                        <PriceBreakdown
                            cabinGradeCode={priceModalData?.cabinGrade?.cabinGradeCode}
                            rateCode={priceModalData?.rateCode?.code}
                            cabinGradeDescription={priceModalData?.cabinGrade?.description}
                            rateCodeDescription={priceModalData?.rateCode?.description}
                            currency={rateCodesData[0].currency}
                            supplierCode={priceModalData?.supplierCode}
                            cruiseId={priceModalData?.cruiseId}
                            setIsLoading={setIsLoading}
                            priceProps={{
                                military: priceModalData?.rateCode?.military,
                                residency: priceModalData?.rateCode?.residency,
                                refundPolicy: priceModalData?.rateCode?.refundPolicy,
                            }}
                        />
                    </Modal>
                </>
            )}
            {cabinGradesData && !cabinGradesData.length && (
                <InfoBanner
                    bannerType='error'
                    text={content.errors.noPricesAvailable}
                    id='error-banner-no-available-prices'
                    buttonText='Go back to results'
                    onButtonClick={(): void => navigate(resultsPageUrl)}
                    isCloseable={false}
                    logType='warn'
                    source='sailing-page'
                />
            )}
        </div>
    )
}

export default CabinGrades
