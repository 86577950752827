import { datadogLogs } from '@datadog/browser-logs'

export function createGenericError({
    description,
    source,
    error,
    status,
}: {
    description: string
    source: string
    error: any
    status?: number
}): CustomApiError {
    const userContext = datadogLogs.getGlobalContext()
    datadogLogs.logger.error(
        `Response source: ${source}, ${description}`, // This should probably be in content?
        { userContext },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error // Error type is apollo which doesn't match datadog logger but it extends Error so is fine. (we think)
    )
    return {
        error_description: description,
        error_type: status !== 422 ? 'error' : 'warn',
        id: 0,
        error_code: 0,
        status,
    }
}
